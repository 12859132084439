import { css } from 'styled-components';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  getBoxShadow,
  px,
  styleComponent,
  getTypography
} from 'utils';

import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.InputText);
const inputStyler = styleComponent(style);

const paddingMixin = (isSmall: boolean) =>
  isSmall
    ? css`
        ${paddingVertical(0)} ${paddingHorizontal(0)};
      `
    : css`
        ${paddingVertical(1)} ${paddingHorizontal(1)};
      `;

const widthMixin = (width?: number) => (!!width ? `${width}rem` : '100%');

const inputCss = (
  t: TThemeVariables,
  {
    alignText,
    isSmall,
    width
  }: { alignText: string; isSmall: boolean; width?: number }
) => css`
  appearance: none;
  position: relative;
  padding: ${paddingMixin(isSmall)};
  ${getTypography('Default')};
  color: ${t.textColor};
  background: ${t.backgroundColor};
  border: ${px(t.borderSize)} solid ${t.borderColor};
  border-radius: ${px(t.borderRadius)};
  box-shadow: ${getBoxShadow('input')};
  display: block;
  width: ${widthMixin(width)};
  text-align: ${alignText};

  &:focus {
    border-color: ${t.status.focus.borderColor};

    ${t.borderRadius != null && t.borderRadius > 0
      ? css`
          outline: none;
          box-shadow: 0 0 0 ${px(t.status.focus.outlineSize)}
            ${t.status.focus.outlineColor};
        `
      : css`
          outline: ${px(t.status.focus.outlineSize)} solid
            ${t.status.focus.outlineColor};
          box-shadow: none;
        `};

    outline-offset: 0;
  }

  &[disabled] {
    background-color: ${t.status.disabled.backgroundColor};
    cursor: not-allowed;
  }

  &[readonly] {
    cursor: pointer;
  }

  &::placeholder {
    color: ${t.placeholder.textColor};
  }
`;

const themeStyleCallback = (styles: any) => styles.InputText;

export const inputStyle = inputStyler(inputCss, themeStyleCallback) as any;
