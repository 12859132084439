import { component } from 'builder';
import { IProps } from './props';
import Base from './base';

export const Header = component<IProps>({
  name: 'Header',
  base: Base,
  props: {
    children: null,
    as: null,
    using: null,
    color: null,
    fontFamily: null
  }
});
