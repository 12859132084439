/* @flow  */
import React from 'react';
import styled from 'styled-components';

import { overlayStyle, wrapperStyle, contentStyle } from './style';

const Wrapper = styled.div([wrapperStyle]);
Wrapper.displayName = 'Wrapper';
const Content = styled.div([contentStyle]);
const Overlay = styled.div([overlayStyle]);
Overlay.displayName = 'Overlay';

const getTriggerAriaProps = (isOpen: boolean) => ({
  'aria-expanded': isOpen,
  'aria-haspopup': true
});

export default (props: any) => {
  const {
    trigger,
    children,
    isOpen,
    isRight,
    toggleDropdown,
    closeDropdown,
    openDropdown,
    handlerOverlayClick,
    isHover,
    ...rest
  } = props;

  return (
    <div style={{ display: 'inline-block' }}>
      <Wrapper
        {...rest}
        onMouseEnter={isHover ? () => openDropdown() : undefined}
        onMouseLeave={isHover ? () => closeDropdown() : undefined}
        onClick={!isHover ? () => toggleDropdown() : undefined}
      >
        {React.cloneElement(trigger, getTriggerAriaProps(isOpen))}
        <Content styleProps={{ isOpen, isRight }}>{children}</Content>
      </Wrapper>
      {isOpen && (
        <Overlay onClick={!isHover ? handlerOverlayClick : undefined} />
      )}
    </div>
  );
};
