import { component } from 'builder';
import { INotificationProps } from './props';
import Base from './base';

export const Notification = component<INotificationProps>({
  name: 'Notification',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    type: '',
    title: '',
    subtitle: ''
  }
});
