import { css } from 'styled-components';
import tc from 'tinycolor2';
import {
  buildLinearGradient,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.GaugeChart);
const styler = styleComponent(style);

const gaugeCustomStyleCallback = (styles: any) => styles.GaugeChart.Gauge;
const valueCustomStyleCallback = (styles: any) => styles.GaugeChart.Value;
const remainingCustomStyleCallback = (styles: any) =>
  styles.GaugeChart.Remaining;
const progressbarCustomStyleCallback = (styles: any) =>
  styles.GaugeChart.Progressbar;

const gaugeCustomCss = (t: TThemeVariables) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1.5rem 1fr;
  justify-content: stretch;
`;

const valueCustomCss = (t: TThemeVariables) => css`
  min-height: 6rem;
  border-left: 2px solid ${t.barColor};
  padding-left: 1rem;
  margin-bottom: 0.5rem;
  justify-self: start;

  & > p {
    max-width: 12.5rem;
  }
`;

const remainingCustomCss = (t: TThemeVariables) => css`
  min-height: 6rem;
  border-right: 2px solid ${t.borderLight};
  padding-right: 1rem;
  padding-top: 1rem;
  justify-self: end;
  text-align: right;
  & > p {
    max-width: 12.5rem;
  }
`;

const progressbarCustomCss = (t: TThemeVariables) => css`
  margin-bottom: 0.5rem;
  background: ${({ styleProps }: any) =>
    buildLinearGradient(
      t.barColor,
      tc(t.borderLight)
        .setAlpha(0.5)
        .toString(),
      styleProps.progress
    )};
`;

export const gaugeStyle = styler(gaugeCustomCss, gaugeCustomStyleCallback);
export const valueStyle = styler(valueCustomCss, valueCustomStyleCallback);
export const remainingStyle = styler(
  remainingCustomCss,
  remainingCustomStyleCallback
);
export const progressbarStyle = styler(
  progressbarCustomCss,
  progressbarCustomStyleCallback
);
