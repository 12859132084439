import React from 'react';

import Header from './header';
import Description from './description';
import Wrapper from './content-wrapper';

export default (props: any) => {
  const { title, children } = props;
  return (
    <Wrapper>
      {title && <Header>{title}</Header>}
      {children && <Description>{children}</Description>}
    </Wrapper>
  );
};
