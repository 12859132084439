import { component } from 'builder';
import { IFormGroupProps } from './props';
import { FormGroupBase } from './base/group';

export const FormGroup = component<IFormGroupProps>({
  name: 'FormGroup',
  base: FormGroupBase,
  props: {
    children: null,
    label: null,
    htmlFor: null
  }
});
