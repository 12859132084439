import invariant from 'invariant';
import React from 'react';
import * as Components from '@parmenion/library';
import { withTheme } from 'styled-components';
import { omit } from 'rambda';
import { Markdown } from './markdown';
import standaloneReadme from '../../../docs/typography/standalone.md';
import headingReadme from '../../../docs/typography/heading.md';
import contentReadme from '../../../docs/typography/content.md';
import settingReadme from '../../../docs/typography/setting.md';
import accessingReadme from '../../../docs/typography/accessing.md';

const {
  Row,
  Column,
  Hint,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Separated,
  Stacked,
  Default
} = Components;
import { Heading } from '../ui';

const walkTypography = typography =>
  Object.keys(typography).map(style => (
    <TypeBox name={style} fontStyle={typography[style]}>
      Aa
    </TypeBox>
  ));

const Layout = props => (
  <Column shrink>
    <Row>{props.children}</Row>
  </Column>
);

const TypeBox = props => (
  <div style={styles.container}>
    <Column>
      <Heading>{props.name}</Heading>
      <div style={styles.background(props.backgroundColor)}>
        <span style={{ ...props.fontStyle, ...styles.center }}>
          {props.children}
        </span>
      </div>

      <Table>
        <TableRow>
          <TableHeader>Property</TableHeader>
          <TableHeader>Type</TableHeader>
        </TableRow>
        {Object.keys(omit(['fontFamily'], props.fontStyle)).map(property => (
          <TableRow key={property}>
            <TableCell>{property}</TableCell>
            <TableCell>{props.fontStyle[property]}</TableCell>
          </TableRow>
        ))}
      </Table>
    </Column>
  </div>
);

export default withTheme(({ theme }) => {
  return (
    <Separated>
      <Stacked>
        <Heading>Typography styles</Heading>
        <Layout>{walkTypography(theme.typography)}</Layout>
      </Stacked>
      <Stacked>
        <Heading>Setting typography styles</Heading>
        <Markdown source={settingReadme} />
      </Stacked>
      <Stacked>
        <Heading>Using standalone components</Heading>
        <Markdown source={standaloneReadme} />
      </Stacked>
      <Stacked>
        <Heading>Using html H1....6 tags</Heading>
        <Markdown source={headingReadme} />
      </Stacked>
      <Stacked>
        <Heading>Mixed html tags</Heading>
        <Markdown source={contentReadme} />
      </Stacked>
      <Stacked>
        <Heading>Use within your own styles</Heading>
        <Markdown source={accessingReadme} />
      </Stacked>
    </Separated>
  );
});

const styles = {
  heading: {
    padding: '5px 0'
  },
  container: {
    width: '300px',
    padding: '20px',
    margin: '2px',
    border: '1px solid #ccc'
  },
  wrapper: {
    width: '100%'
  },
  background: backgroundColor => ({
    height: '100px',
    width: '100px',
    border: '1px solid #ccc',
    backgroundColor: ' #ccc',
    display: 'flex',
    margin: 'auto',
    margin: '20px 0'
  }),
  center: {
    display: 'flex',
    margin: 'auto'
  }
};
