import React from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'rambda';
import {
  BREAKPOINTS,
  calculateSize,
  getSpaceBetween,
  space,
  computeAlignItems,
  computeJustifyContent,
  computeWrap,
  computeShrink
} from './common';
export { computeAlignItems, computeJustifyContent };
const px = value => `${value}px`;

export const Box = _props => {
  let isChildABox = false;
  let props = _props;

  try {
    isChildABox = React.Children.only(props.children).type === Box;
  } catch (ex) {
    // React.Children.only throws an error if more than one child
  }

  if (isChildABox) {
    props = Object.assign(
      {},
      _props,
      React.Children.only(props.children).props
    );
  }

  const hasPosition =
    props.top != null ||
    props.right != null ||
    props.bottom != null ||
    props.left != null;

  const { fill, wrap, ...rest } = props;

  // is it a spacing container?
  if (props.horizontal || props.stacked) {
    const flattenedChildren = React.Children.toArray(props.children);
    const spaceBetween = getSpaceBetween(props);
    const children = flattenedChildren.map((child, index) =>
      child.props != null ? (
        <InternalBox
          className={child.props && child.props.className}
          key={index}
          _fill={child.props.fill}
          _shrink={child.props.shrink}
          order={child.props.order}
          w={child.props.width ? child.props.width : child.props.w}
          h={child.props.height ? child.props.height : child.props.h}
          __boxIsSpacingChild
          __boxParentSpaceBetween={spaceBetween}
          {...(child.type === Box ? child.props : {})}
        >
          {child.type === Box ? child.props.children : child}
        </InternalBox>
      ) : (
        <InternalBox
          className={child.props && child.props.className}
          key={index}
          __boxIsSpacingChild
          __boxParentSpaceBetween={spaceBetween}
        >
          {child}
        </InternalBox>
      )
    );

    return (
      <StyledBox
        {...rest}
        wrap={wrap}
        hasPosition={hasPosition}
        isSpacingContainer
        spaceBetween={spaceBetween}
      >
        {children}
      </StyledBox>
    );
  }

  return (
    <StyledBox {...rest} hasPosition={hasPosition} isSpacingContainer={false} />
  );
};

const InternalBox = Box; // for some reason we get inexact type incompatibility errors so we alias to any

const getResponsivePropValue = (propValue, breakpointIndex) => {
  if (!Array.isArray(propValue)) {
    if (breakpointIndex !== 0) return null;

    return propValue;
  }

  return propValue[breakpointIndex];
};

const calculatePosition = (value, spacing) => {
  if (value === true) {
    return 0;
  } else if (value == null || value === false) {
    return undefined;
  }

  return px(space(value, spacing));
};

const RESPONSIVE_PROP_SELECTOR = {
  w: value => ({ width: calculateSize(value) }),
  h: value => ({ height: calculateSize(value) }),
  top: (value, props) => ({
    top: calculatePosition(value, props.theme.layout.spacing)
  }),
  right: (value, props) => ({
    right: calculatePosition(value, props.theme.layout.spacing)
  }),
  bottom: (value, props) => ({
    bottom: calculatePosition(value, props.theme.layout.spacing)
  }),
  left: (value, props) => ({
    left: calculatePosition(value, props.theme.layout.spacing)
  }),
  order: value => ({ order: value }),
  visible: value => ({ display: value === true ? 'flex' : 'none' })
};

const RESPONSIVE_PROPS = Object.keys(RESPONSIVE_PROP_SELECTOR);

const getValuesForResponsiveProps = (props, breakpointIndex) => {
  const style = {};

  for (const propName of RESPONSIVE_PROPS) {
    const propValue = props[propName];

    if (getResponsivePropValue(propValue, breakpointIndex) != null) {
      const propStyleSelector = RESPONSIVE_PROP_SELECTOR[propName];

      Object.assign(
        style,
        propStyleSelector(
          getResponsivePropValue(propValue, breakpointIndex),
          props
        )
      );
    }
  }

  return style;
};

const mediaQueries = BREAKPOINTS.map(
  (size, breakpointIndex) =>
    css`
      @media (min-width: ${size}px) {
        ${props => getValuesForResponsiveProps(props, breakpointIndex + 1)};
      }
    `
);

const getSpacingContainerStyle = props => {
  const style = {};

  const spaceBetweenPx = space(props.spaceBetween, props.theme.layout.spacing);

  const isHorizontal = props.horizontal;

  style.flexDirection = isHorizontal ? 'row' : 'column';
  style.margin = `${px(-(spaceBetweenPx / 2))}`;

  style.alignItems = computeAlignItems(props);
  style.alignContent = computeAlignItems(props);
  style.justifyContent = computeJustifyContent(props);
  style.alignContent = computeAlignItems(props);
  style.flexWrap = computeWrap(props);
  style.flexShrink = computeShrink(props);

  if (style.alignItems == null) delete style.alignItems;
  if (style.justifyContent == null) delete style.justifyContent;
  if (style.alignContent == null) delete style.alignContent;

  return style;
};

const getStandaloneContainerStyle = props => {
  const style = {};

  style.alignItems = computeAlignItems(props);
  style.alignContent = computeAlignItems(props);
  style.justifyContent = computeJustifyContent(props);
  style.alignContent = computeAlignItems(props);

  if (style.alignItems == null) delete style.alignItems;
  if (style.justifyContent == null) delete style.justifyContent;
  if (style.alignContent == null) delete style.alignContent;
  return style;
};

const addPaddingToChildren = () => {
  return css`
    padding: ${props =>
      px(space(props.__boxParentSpaceBetween, props.theme.layout.spacing) / 2)};
    flex-grow: ${props => (props._fill ? 1 : 0)};
    flex-shrink: ${props => (props._shrink ? 1 : 0)};
  `;
};

const StyledBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  position: ${props => (props.hasPosition ? 'absolute' : 'relative')};
  box-sizing: border-box;
  flex-direction: column;

  ${props =>
    props.isSpacingContainer
      ? getSpacingContainerStyle
      : getStandaloneContainerStyle};

  ${props => props.__boxIsSpacingChild && addPaddingToChildren()};

  ${props => getValuesForResponsiveProps(props, 0)} ${mediaQueries};
`;
