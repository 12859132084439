import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const breadcrumb = (colors: IColorsType, bounds: IBoundsType) => ({
  wrapper: {
    backgroundColor: colors.lightest
  },

  link: {
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.lighter
  },

  itemWrapper: {
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.lighter
  }
});
