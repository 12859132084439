import { IColorsType } from '../../core/colors';
import { IBoundsType } from '../../core/bounds';

export const field = (colors: IColorsType, bounds: IBoundsType) => ({
  error: {
    borderColor: colors.error,
    borderSize: bounds.border.size * 2,
    textColor: colors.error
  },
  warning: {
    borderColor: colors.warning,
    borderSize: bounds.border.size * 2,
    textColor: colors.warning
  },
  help: {
    textColor: colors.neutrals.darkest
  }
});
