import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  styleComponent,
  paddingHorizontal,
  paddingVertical,
  px,
  getTypography
} from 'utils';
import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const headingStyler = styleComponent(withComponentTheme);

const headingCss = (t: TThemeVariables, { isOpen }: { isOpen: boolean }) => css`
  position: relative;
  ${getTypography('SubHeading')};
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  color: ${t.headingColor};
  background-color: ${isOpen
    ? t.status.isOpen.headingBackgroundColor
    : t.headingBackgroundColor};

  &:hover {
    background-color: ${isOpen
      ? t.status.hoverWhenOpen.headingBackgroundColor
      : t.status.hover.headingBackgroundColor};
    cursor: pointer;

    > i {
      background-color: ${t.arrowHoverBackgroundColor};
    }
  }
`;

const headingThemeCallback = (styles: any) => styles.Accordion.Heading;
export const Heading = styled.div([
  headingStyler(headingCss, headingThemeCallback)
]) as any;
