import { css } from 'styled-components';
import { selectComponentThemeStylesFor } from './select-component-theme-styles';

type TComponentStyleSelector = {
  (styleCallback: any): (props: any) => string;
};

export const styleComponent = (withComponentTheme: any) => (
  componentStyle: any,
  themeStyleCallback: (styles: any) => any
): TComponentStyleSelector => {
  const customStyles = selectComponentThemeStylesFor(themeStyleCallback);
  return (props: any) => {
    const hasCustomTheme = themeStyleCallback(props.theme.styles);
    const styles = withComponentTheme((componentTheme: any, styleProps: any) =>
      hasCustomTheme != null
        ? css`
            ${customStyles(componentTheme)};
          `
        : css`
            ${componentStyle(componentTheme, styleProps)};
          `
    );

    return styles;
  };
};
