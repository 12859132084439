const border = {
  radius: 0,
  size: 2
};

const outline = {
  size: 1
};

export const bounds = {
  border,
  outline
};

export type IBoundsType = typeof bounds;
