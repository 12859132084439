import { component } from 'builder';
import React from 'react';
import styled, { css } from 'styled-components';
import { getBoxShadow } from 'utils';
import { ToggleProps } from './props';

const styler = (component: any) => (props: any) => component(props.theme);

const labelCss = (t: any) => css`
  position: relative;
  display: inline-flex;
  outline: none;
  padding-left: 6rem;
`;

const inputCss = (t: any) => css`
  height: 0;
  width: 0;

  &:checked + div {
    background-color: ${t.colors.primary};
  }
  &:checked + div:before {
    transform: translateX(26px);
    border: 5px solid ${t.colors.primary};
  }

  &:focus + div {
    box-shadow: ${getBoxShadow('large')};
  }
`;

const sliderCss = (t: any) => css`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: ${t.colors.neutrals.lighter};
  transition: 0.3s;
  width: 5rem;
  height: 3rem;
  border-radius: 3rem;

  &:before {
    position: absolute;
    content: '';
    box-sizing: border-box;
    width: 3rem;
    height: 3rem;
    background: #fff;
    border-radius: 3rem;
    transition: 0.3s;
    border: 5px solid ${t.colors.neutrals.lighter};
    background-color: white;
    transition: 0.3s;
  }
`;

const Label = styled.label([styler(labelCss)]);

const Input = styled.input([styler(inputCss)]);

const Slider = styled.div([styler(sliderCss)]);

class ToggleBase extends React.Component<ToggleProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isChecked: props.isChecked || false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState({ isChecked: !this.state.isChecked });

    this.props.onChange && this.props.onChange(this.props.value);
  }

  render() {
    return (
      <Label>
        <Input
          type="checkbox"
          value={this.props.value}
          onChange={this.handleChange}
          checked={this.state.isChecked}
        />
        {this.props.children}
        <Slider />
      </Label>
    );
  }
}

export const Toggle = component<ToggleProps>({
  name: 'Toggle',
  base: ToggleBase,
  container: 'div',
  props: {
    isChecked: undefined,
    onChange: undefined,
    value: undefined,
    children: null
  }
});
