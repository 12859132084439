import styled, { css } from 'styled-components';
import { getTypography, selectComponentThemeVariablesFor } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme);

export const StaticCharacter = styled.div([
  style(
    t => css`
      ${getTypography('SubHeading')};
    `
  )
]);

const getWidth = (maxLength: number, charWidth: number, padding: number) =>
  maxLength * charWidth + padding;

export const InputContainer = styled.div([
  style(
    (
      t,
      { isSmall, maxLength, charWidth = 12, padding = 0, paddingRight = 0 }
    ) => css`
      min-width: 30px;
      max-width: ${getWidth(maxLength, charWidth, padding)}px;
    `
  )
]);
