import React from 'react';

export const TextAreaBase = ({
  isDisabled,
  isSmall,
  alignText,
  didUpdate,
  isReadOnly,
  inputRef,
  labelledby,
  isRequired,
  isInvalid,
  Container,
  ...rest
}: any) => {
  const ariaProps = {
    'aria-required': false,
    'aria-invalid': false
  };
  if (labelledby !== null) ariaProps['aria-labelledby'] = labelledby;
  if (isRequired !== null) ariaProps['aria-required'] = isRequired;
  if (isInvalid !== null) ariaProps['aria-invalid'] = isInvalid;
  return (
    <Container
      {...rest}
      {...ariaProps}
      ref={inputRef}
      disabled={isDisabled}
      readOnly={isReadOnly}
    />
  );
};
