import { css } from 'styled-components';
import { TThemeVariables } from './types';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.FuzzySearch);
const styler = styleComponent(style);

const searchCustomStyleCallback = (styles: any) => styles.FuzzySearch.Search;
const wrapperCustomStyleCallback = (styles: any) => styles.FuzzySearch.Wrapper;

const selectStyles = (t: TThemeVariables) => css`
  .customSelect {
    min-width: 150px;
    background: ${t.backgroundColor};
    outline: none;

    &__value-container {
      padding: 0.5rem 0.75rem;
      border-radius: 0px;
    }
    &__value-container--has-value {
      font-weight: 400;
    }

    &__control {
      border: 1px solid ${t.borderColor};
      border-radius: 0px;

      &:hover {
        border-color: ${t.borderColorDark};
      }
    }

    &__control--menu-is-open {
      border-radius: 0px;
    }

    &__control--is-focused {
      border: 1px solid ${t.borderColorDark};
      box-shadow: inset 0 0 0 2px ${t.borderColorDark};
      background: ${t.backgroundColorFocus};
      &:hover {
        border-color: ${t.borderColorDark};
      }
    }

    &__control--is-active {
      border: 1px solid ${t.borderColorDark};
      box-shadow: inset 0 0 0 2px ${t.borderColorDark};
      &:hover {
        border-color: ${t.borderColorActive};
      }
    }
    &__control--is-disabled {
      background-color: ${t.backgroundColorFocus};
      border: 1px solid ${t.borderColorDisabled};
      cursor: not-allowed;

      .customSelect__dropdown-indicator {
        color: ${t.borderColor};
      }
    }

    &__dropdown-indicator {
      color: ${t.borderColorDark};
    }

    &__indicator-separator {
      visibility: hidden;
    }
  }
`;

const wrapperCustomCss = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const searchCustomCss = (t: TThemeVariables) => css`
  ${selectStyles} & {
    .customSelect__value-container,
    .customSelect__value-container * {
      cursor: pointer;
    }
    .customSelect__menu {
      margin-top: 0;
      border: 1px solid ${t.borderColorDisabled};
      background-color: ${t.backgroundColorFocus};
      box-shadow: unset;
      border-radius: 0;
    }
    .customSelect__menu-list {
      ${SearchResultsStyle};
    }

    .customSelect__menu-list > * {
      box-shadow: inset 0 -1px 0px 0 ${t.borderColorDisabled};
      padding: 1.5rem 0;
      & button {
        margin: 0 0.5rem 0 0;
        padding: 0;
        box-shadow: none;
      }
    }
    .customSelect__dropdown-indicator {
      svg {
        color: ${t.borderColor};
      }
    }
  }
`;

const SearchResultsStyle = (t: TThemeVariables) => css`
  overflow-y: auto;
  max-height: 30rem;
  .customSelect__option {
    cursor: pointer;
    padding: 1rem 1.5rem;
    :active {
      color: ${t.selectedColor};
    }
  }

  .customSelect__option--is-focused {
    background-color: inherit;
    color: ${t.selectedColor};
    text-decoration: underline;
  }

  .customSelect__option--is-selected {
    background-color: inherit;
    color: ${t.selectedColor};
  }
`;
export const search = styler(searchCustomCss, searchCustomStyleCallback);
export const wrapper = styler(wrapperCustomCss, wrapperCustomStyleCallback);
