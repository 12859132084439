import React from 'react';
import ReactDOM from 'react-dom';
import { elementStringExtractor } from 'utils';
import { componentWillAppendToBody } from 'react-append-to-body';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { IModalProps } from './props';
import Overlay from './components/Overlay';
import ModalInner from './components/ModalInner';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';

const keyEventsMap = {
  escape: 27
};

const CHANNEL = '__styled-components__';
const CHANNEL_NEXT = `${CHANNEL}next__`;

const CONTEXT_CHANNEL_SHAPE = PropTypes.shape({
  getTheme: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func
});

const Modal = componentWillAppendToBody((props: any) => props.children);

if (!ReactDOM.createPortal) {
  Modal.contextTypes = {
    [CHANNEL_NEXT]: CONTEXT_CHANNEL_SHAPE
  };
}

export default class ModalBase extends React.Component<IModalProps> {
  onClose: () => void;
  overlayRef: React.Ref<any> | null;
  modalInnerRef: any | null;

  constructor(props: IModalProps) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.overlayRef = React.createRef();
    this.modalInnerRef = React.createRef();
  }

  handleKeyDown({ keyCode }: { keyCode: number }) {
    if (
      this.props.closeOnEsc &&
      keyEventsMap.escape === keyCode &&
      this.props.isOpen
    ) {
      this.props.onClose();
    }
  }

  updateBodyStyle({ isOpen }: any) {
    const body = document.querySelector('body');
    if (isOpen) {
      disableBodyScroll(body);
    } else {
      enableBodyScroll(body);
    }
  }

  componentDidMount() {
    this.updateBodyStyle({ isOpen: this.props.isOpen });

    if (document.body)
      document.body.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    if (document.body)
      document.body.removeEventListener('keydown', this.handleKeyDown);

    this.updateBodyStyle({ isOpen: false });
  }

  componentDidUpdate(prevProps: IModalProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.updateBodyStyle({ isOpen: true });
      if (this.modalInnerRef && this.modalInnerRef.current) {
        this.modalInnerRef.current.focus();
      }
    }
    if (!this.props.isOpen && prevProps.isOpen) {
      this.updateBodyStyle({ isOpen: false });
    }
  }

  handleOverlayClick = () => {
    if (this.props.closeOnClickAway) {
      this.props.onClose();
    }
  };

  handleModalContentsClick = (e: React.SyntheticEvent<HTMLDivElement>) => {
    // stop clicking inside the modal from propagating to the overlay which will result in the modal closing
    e.stopPropagation();
  };

  handleOnClose = (e: React.SyntheticEvent<HTMLDivElement>) => {
    // stop a race between unmounting the component and the event propagating.
    e.stopPropagation();
    this.props.onClose();
  };

  handleModalContentsTouch = (e: React.SyntheticEvent<HTMLDivElement>) => {
    // stop previous layer from preventing scroll (we want modal contents to scroll).
    e.stopPropagation();
  };

  render() {
    const {
      children,
      closeOnClickAway,
      isOpen,
      onClose,
      zIndex,
      ...rest
    } = this.props;
    return (
      isOpen && (
        <FocusTrap>
          <Modal>
            <Overlay
              ref={(_ref: any) => (this.overlayRef = _ref)}
              role="dialog"
              aria-modal={true}
              aria-labelledby={elementStringExtractor(rest.title)}
              onClick={this.handleOverlayClick}
              zIndex={zIndex}
            >
              <ModalInner
                handler={this.handleOnClose}
                onClick={this.handleModalContentsClick}
                onTouchStart={this.handleModalContentsTouch}
                onTouchMove={this.handleModalContentsTouch}
                modalInnerRef={this.modalInnerRef}
                {...rest}
              >
                {children}
              </ModalInner>
            </Overlay>
          </Modal>
        </FocusTrap>
      )
    );
  }
}
