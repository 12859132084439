import { component } from 'builder';
import { Base } from './base/breadcrumb-item';
import { IBreadcrumbItemProps } from './props';
import { linkStyle } from './style';
export const BreadcrumbItem = component<IBreadcrumbItemProps>({
  name: 'BreadcrumbItem',
  base: Base,
  canOverrideContainer: true,
  container: 'a',
  style: linkStyle,
  props: {
    href: null,
    label: null,
    onClick: () => {}
  }
});
