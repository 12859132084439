import {
  PrimaryButton,
  PrimaryInvertedButton,
  PrimaryOutlineButton
} from '../Buttons/';
import { builder } from './builder';

export const PrimaryButtonWithIcon = builder(
  'PrimaryButtonWithIcon',
  PrimaryButton
);
export const PrimaryInvertedButtonWithIcon = builder(
  'PrimaryInvertedButtonWithIcon',
  PrimaryInvertedButton
);
export const PrimaryOutlineButtonWithIcon = builder(
  'PrimaryOutlineButtonWithIcon',
  PrimaryOutlineButton
);
