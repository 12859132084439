import { withTheme } from 'styled-components';
import React from 'react';
import {
  isInValidCharacter,
  selectCharacter,
  invokeOnComplete,
  focusNextInput,
  focusPreviousInput,
  canDelete,
  blurCurrentInput,
  convertStringToMaskValues,
  convertMaskvaluesToString,
  getNextValidIndex,
  onPaste,
  groupInputsByMaskType,
  addPartDescriptionsToMask,
  fillBlanksInCharacters,
  getPastedValues,
  IPartDesc,
  EventWithClipBoard
} from './utils';

import MaskedInputs from './masked-inputs';
import MeasureInputCharacterSize from './measure-character-size';

type InputCharacterState = {
  maskValues: any;
  mask: Array<IPartDesc>;
  cursor?: {
    activeInput: number;
    selectionStart: number;
  };
  inputDimensions?: {
    charWidth?: number;
    padding?: number;
  };
};

class Base extends React.Component<any, InputCharacterState> {
  maskInputRefs: any;

  static displayName = 'InputCharactersBase';

  constructor(props: any) {
    super(props);

    const mask = props.grouped
      ? groupInputsByMaskType(props.mask)
      : addPartDescriptionsToMask(props.mask);

    this.maskInputRefs = {};
    this.state = {
      maskValues: {},
      mask,
      inputDimensions: {
        charWidth: 0,
        padding: 0
      }
    };
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.value !== this.props.value) {
      this.receiveValue(this.props.value);
    }
  }

  componentDidMount() {
    this.receiveValue(
      fillBlanksInCharacters(this.props.value, this.state.mask)
    );
  }

  setInputDimensions({
    charWidth,
    padding
  }: {
    charWidth: number;
    padding: number;
  }) {
    this.setState({
      inputDimensions: {
        charWidth,
        padding
      }
    });
  }

  setCursorPosition(node: HTMLInputElement) {
    if (this.state && this.state.cursor) {
      const { cursor } = this.state;
      if (
        node != null &&
        this.maskInputRefs[cursor.activeInput] &&
        node.name === this.maskInputRefs[cursor.activeInput].name
      ) {
        node.selectionStart = node.selectionEnd = cursor.selectionStart;
      }
    }
  }

  receiveValue(newValue: any) {
    this.setState({
      maskValues: convertStringToMaskValues(this.state.mask, newValue)
    });
  }

  updateValue(maskValues: any) {
    const value = convertMaskvaluesToString(this.state.mask, maskValues);
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  handleChangeCharacter(
    maskIndex: number,
    newCharacter: string,
    maxLength: number
  ) {
    if (isInValidCharacter(newCharacter, this.state.mask[maskIndex])) {
      selectCharacter(this.maskInputRefs[maskIndex]);
      return;
    }
    this.updateValue({
      ...this.state.maskValues,
      [maskIndex]: newCharacter
    });

    if (newCharacter.length < maxLength) {
      this.setState({
        cursor: {
          selectionStart: this.maskInputRefs[maskIndex].selectionStart,
          activeInput: maskIndex
        }
      });
    }

    if (newCharacter !== '' && newCharacter.length === maxLength) {
      focusNextInput(maskIndex, this.maskInputRefs, this.state.mask);
      blurCurrentInput(this.maskInputRefs[maskIndex], this.props.blurOnChange);
      invokeOnComplete(this.props.onComplete);
      this.setState({
        cursor: undefined
      });
    }
  }

  handleKeyDown(
    maskIndex: number,
    { key }: React.KeyboardEvent<HTMLInputElement>
  ) {
    const currentCharacter = this.state.maskValues[maskIndex];
    if (canDelete(currentCharacter, key)) {
      focusPreviousInput(maskIndex, this.maskInputRefs);
    }
  }

  handlePaste(e: EventWithClipBoard) {
    e.preventDefault();

    const { canPaste, allowedPastedValues } = getPastedValues(
      e,
      this.state.mask
    );

    if (canPaste)
      this.props.onChange(
        convertMaskvaluesToString(this.state.mask, allowedPastedValues)
      );

    this.props.onPaste(e);
  }

  render() {
    const {
      mask,
      name,
      isDisabled,
      value,
      onChange,
      isSmall,
      onComplete,
      onPaste,
      theme,
      ...rest
    } = this.props;

    const propsToPass = {};

    if (rest['data-testid']) {
      propsToPass['data-testid'] = rest['data-testid'];
    }

    return (
      <div className="InputCharactersWrapper" {...propsToPass}>
        <MaskedInputs
          onPaste={this.handlePaste.bind(this)}
          isSmall={this.props.isSmall}
          type={this.props.type}
          name={this.props.name}
          mask={this.state.mask}
          refs={this.maskInputRefs}
          values={this.state.maskValues}
          isDisabled={this.props.isDisabled}
          onBlur={this.props.onBlur}
          onChange={this.handleChangeCharacter.bind(this)}
          onKeyDown={this.handleKeyDown.bind(this)}
          labelledby={this.props.labelledby}
          isRequired={this.props.isRequired}
          isInvalid={this.props.isInvalid}
          didUpdate={this.setCursorPosition.bind(this)}
          inputDimensions={this.state.inputDimensions}
          pattern={this.props.pattern}
          inputMode={this.props.inputMode}
          labels={this.props.labels}
        />
        <MeasureInputCharacterSize
          setDimensions={this.setInputDimensions.bind(this)}
        />
      </div>
    );
  }
}

export default withTheme(Base);
