import { withState } from 'recompose';
import { component } from 'builder';
import { IToolTipProps } from './props';
import Base from './base';

export const ToolTip = component<IToolTipProps>({
  name: 'ToolTip',
  base: Base,
  props: {
    children: null,
    label: null,
    position: 'top',
    width: '',
    isVisible: false,
    align: 'left',
    setIsVisible: () => {}
  }
});

const statefulConfig = {
  initialValue: false
};

export const StatefulToolTip = withState<
  IToolTipProps,
  boolean,
  'isVisible',
  'setIsVisible'
>(
  'isVisible',
  'setIsVisible',
  statefulConfig.initialValue
)(ToolTip as any);
