import styled, { css } from 'styled-components';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  getBoxShadow,
  px,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.TextArea);
const textAreaStyler = styleComponent(style);
const textAreaStylerCallback = (styles: any) => styles.TextArea;
const textAreaCss = (t: TThemeVariables) => css`
  position: relative;
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  ${getTypography('Default')};
  color: ${t.textColor};
  background: ${t.backgroundColor};
  border: ${px(t.borderSize)} solid ${t.borderColor};
  border-radius: ${px(t.borderRadius)};
  box-shadow: ${getBoxShadow('input')};
  display: block;
  width: 100%;
  height: auto;
  background-image: none;
  resize: vertical;

  &:focus {
    border-color: ${t.status.focus.borderColor};
    outline: ${px(t.status.focus.outlineSize)} solid
      ${t.status.focus.outlineColor};
    outline-offset: 0;
    box-shadow: none;
  }

  &[disabled] {
    background-color: ${t.status.disabled.backgroundColor};
    cursor: not-allowed;
  }

  &[readonly] {
    cursor: pointer;
  }

  &::placeholder {
    color: ${t.placeholder.textColor};
  }
`;

export const inputStyle = textAreaStyler(textAreaCss, textAreaStylerCallback);
