import React from 'react';
import Fuse from 'fuse.js';
import styled from 'styled-components';

import { InputText } from '@parmenion/library';
import { Box, LightBackground } from '../ui';

const INVALID_SEARCH_ITEMS = `Invalid itemsToSearch`;

const canSearch = items => items != null && items.length;

export class ComponentSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      searchInstance: this.update(props)
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ searchInstance: this.update(nextProps) });
  }

  update = nextProps => {
    if (!canSearch(nextProps.itemsToSearch))
      throw new Error(INVALID_SEARCH_ITEMS);
    return new Fuse(nextProps.itemsToSearch, nextProps.options);
  };

  onChange = searchTerm => this.setState({ searchTerm });

  render() {
    const results =
      this.state.searchTerm.length > 0
        ? this.state.searchInstance.search(this.state.searchTerm)
        : this.props.itemsToSearch;

    return (
      <Wrapper>
        <Container>
          <InputText
            placeholder="Search by component name"
            name="search"
            value={this.searchTerm}
            onChange={this.onChange}
          />
          {results.map(match => (
            <ComponentContainer key={match.key}>
              {this.props.onMatch(match)}
            </ComponentContainer>
          ))}
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background: 'white';
  padding: 20px 0;
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  margin: -5px;
`;

const ComponentContainer = styled.div`
  position: relative;
  border-bottom: 1px solid black;
  text-decoration: none;
  color: initial;
  padding: 10px 0;
`;
