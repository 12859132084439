import { component } from 'builder';
import { IIconButtonProps } from './props';
import { Base } from './base';

import { style } from './style';

const spec = {
  name: 'IconButton',
  base: Base,
  style,
  container: 'span',
  canOverrideContainer: true,
  props: {
    size: 1,
    name: null,
    color: null,
    onClick: null,
    onKeyPress: null,
    iconMap: null,
    tabIndex: 0
  }
};

export const IconButton = component<IIconButtonProps>(spec);
