import { component } from 'builder';
import { INavProps, INavItemProps } from './props';
import { NavBase, NavItemBase } from './base';

import { itemStyle } from './style';

export const Nav = component<INavProps>({
  name: 'Nav',
  base: NavBase,
  props: {
    children: null
  }
});

export const NavItem = component<INavItemProps>({
  name: 'NavItem',
  base: NavItemBase,
  container: 'a',
  canOverrideContainer: true,
  style: itemStyle,
  props: {
    isActive: false,
    label: null,
    href: null
  },
  styleProps: ['isActive']
});
