import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';
import { computeJustifyContent, computeAlignItems } from '../core';

const style = selectComponentThemeVariablesFor(theme => theme.Table);
const styler = styleComponent(style);

const tableCallback = (styles: any) => styles.Table.Wrapper;
const rowExpandCallback = (styles: any) => styles.Table.TableRowExpandable;
const cellWithExpanderCallback = (styles: any) =>
  styles.Table.TableCellExpanded;
const rowCallback = (styles: any) => styles.Table.Row;
const cellCallback = (styles: any) => styles.Table.Cell;
const headerCallback = (styles: any) => styles.Table.Header;
const cellWithExpanderWrapperCallback = (styles: any) =>
  styles.Table.CellExpander;

const getColors = (t: any, { isInverted }: any) =>
  isInverted ? t.inverted : t.standard;

const TableCustomStyle = (t: TThemeVariables) => css`
  border-top: 2px solid ${props => getColors(t, props).borderColor};
  border-bottom: 2px solid ${props => getColors(t, props).borderColor};
  color: ${props => getColors(t, props).color};
  margin: 0;
  padding: 0;
`;

const rowCustomStyle = (t: TThemeVariables) => css`
  list-style: none;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0 1rem;
  border-top: 1px solid ${props => getColors(t, props).borderColor};
  ${(props: any) =>
    props.isStriped && `background-color: ${getColors(t, props).hoverColor}`};

  &:hover {
    ${props =>
      props.withHover && `background-color: ${getColors(t, props).hoverColor}`};
  }
  &:first-child {
    border-top: 0;
    border-bottom: 1px solid ${props => getColors(t, props).borderColor};
  }

  ${props =>
    props.noHeader &&
    `
    &:first-child {
      display: none;
    }
  `} 
  @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
    display: grid;
    grid-template-columns: 1fr;
    &:first-child {
      display: none;
    }
  };

  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    display: grid;
    grid-template-columns: ${props =>
      props.columns != null
        ? `${props.columns}`
        : `repeat(${props.children.length}, 1fr)`};
  };
`;

const rowExpandCustomStyle = (t: TThemeVariables) => css`
  list-style: none;
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 0 1rem;
  border-top: 1px solid ${props => getColors(t, props).borderColor};
  ${(props: any) =>
    props.isStriped && `background-color: ${getColors(t, props).hoverColor}`};

  &:hover {
    ${props =>
      props.withHover && `background-color: ${getColors(t, props).hoverColor}`};
  }
  &:first-child {
    border-top: 0;
    border-bottom: 1px solid ${props => getColors(t, props).borderColor};
  }

  ${props =>
    props.noHeader &&
    `
    &:first-child {
      display: none;
    }
  `} 
  @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
    display: grid;
    grid-template-columns: 1fr;
    &:first-child {
      display: none;
    }
  };

  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    display: grid;
    grid-template-columns: ${props =>
      props.columns != null
        ? `${props.columns}`
        : `repeat(${props.children.length}, 1fr)`};
  };
  user-select: none;
  visibility: ${props => (props.isOpen ? `visible` : `hidden`)};
  && {
    transition: height 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.2);
    height: ${props => props.expandHeight}px;
    overflow: ${props => (props.isOpen ? `visible` : `hidden`)};;
  }
  && > span {
    padding: 2rem 1rem;
  }
`;

const tableCellStyle = (t: TThemeVariables) => css`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    padding: 1rem 0;
    whitespace: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
  }
  justify-content: ${props => computeJustifyContent(props)};
  align-items: ${props => computeAlignItems(props)};
`;

const headerCustomStyle = (t: TThemeVariables) => css`
  ${tableCellStyle};
  font-weight: bold;
`;
const cellCustomStyle = (t: TThemeVariables) => css`
  ${tableCellStyle};
  @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
    &::before {
      content: attr(data-name);
    }
  }
`;

const cellWithExpanderCustomStyle = (t: TThemeVariables) => css`
  ${tableCellStyle};
  && {
    grid-template-columns: 1fr;

    position: absolute;
    top: 0;
    right: 0;
    &:focus svg {
      color: ${t.primary};
    }
    svg {
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.2);
      transform: rotate(0deg);
    }
    &[open] svg {
      transform: rotate(45deg);
    }
    span {
      margin: 0;
    }
  }
`;

const cellWithExpanderWrapperCustomStyle = (t: TThemeVariables) => css`
  position: relative;
`;

export const tableContainerStyle = styler(TableCustomStyle, tableCallback);
export const cellWithExpanderStyle = styler(
  cellWithExpanderCustomStyle,
  cellWithExpanderCallback
);
export const rowExpandStyle = styler(rowExpandCustomStyle, rowExpandCallback);
export const rowStyle = styler(rowCustomStyle, rowCallback);
export const headerStyle = styler(headerCustomStyle, headerCallback);
export const cellStyle = styler(cellCustomStyle, cellCallback);
export const cellWithExpanderWrapperStyle = styler(
  cellWithExpanderWrapperCustomStyle,
  cellWithExpanderWrapperCallback
);
