import React from 'react';
import { Box } from '../../../core/';

type FieldsProps = {
  children: React.ReactNode;
};

export const FormFields: React.StatelessComponent<any> = ({
  children
}: FieldsProps) => (
  <Box stacked spaceBetween={3}>
    {children}
  </Box>
);

FormFields.displayName = 'FormFields';
