import { errorStyle, errorOutlineStyle, errorInvertedStyle } from './styles';

import { builder } from './builder';
export const ErrorButton = builder(errorStyle, 'ErrorButton');
export const ErrorInvertedButton = builder(
  errorInvertedStyle,
  'ErrorInvertedButton'
);
export const ErrorOutlineButton = builder(
  errorOutlineStyle,
  'ErrorOutlineButton'
);
