import React from 'react';
import { IAccordionProps, IAccordionPanelProps } from './props/';

export default ({
  children,
  openPanelIndex,
  onChangeOpenPanelIndex,
  ...rest
}: IAccordionProps) => {
  const panels = React.Children.map(
    children,
    (panel: React.ReactElement<IAccordionPanelProps>, index: number) => {
      return React.cloneElement(panel as any, {
        isOpen:
          (!isNaN(openPanelIndex) && openPanelIndex === index) ||
          panel.props.isOpen,
        onClickHeader: () =>
          typeof onChangeOpenPanelIndex === 'function'
            ? onChangeOpenPanelIndex(index === openPanelIndex ? -1 : index)
            : null
      });
    }
  );

  return (
    <div role="presentation" {...rest}>
      {panels}
    </div>
  );
};
