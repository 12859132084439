import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ITheme, buildTheme } from './index';

const allowedThemeParts = [
  'typography',
  'layout',
  'bounds',
  'colors',
  'decoration',
  'breakpoints',
  'containers,'
];

const coreThemeDefaults = {
  layout: {
    paddingHorizontal: [],
    paddingVertical: [],
    spacing: {}
  }
};

export const withCustomTheme = (
  Component: React.ComponentType<any>,
  overrides: ITheme
) => {
  const theme = buildTheme(overrides) as any;

  const { icons, ...themeWithoutIcons } = theme;

  const customTheme =
    overrides.styles != null
      ? { ...coreThemeDefaults, ...overrides }
      : themeWithoutIcons;

  return (props: any) => (
    <ThemeProvider theme={customTheme}>
      {<Component {...props} />}
    </ThemeProvider>
  );
};
