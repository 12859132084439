import tc from 'tinycolor2';

import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const toolTip = (colors: IColorsType, bounds: IBoundsType) => ({
  linkBorder: colors.neutrals.darkest,
  linkColor: colors.primary,
  label: {
    textColor: colors.lightest,
    backgroundColor: colors.darkest,
    shadowColor: colors.darkest,
    borderRadius: bounds.border.radius
  }
});
