import React from 'react';

import { Icon, IIconName } from '../Icon';
import { Box } from '../core';

import {
  Item,
  IconContainer,
  ArrowContainer,
  Label,
  Description
} from './components';

const renderIcon = (icon: IIconName | React.ReactElement<any>) =>
  typeof icon === 'string' ? (
    <IconContainer>
      <Icon name={icon} size={0.8} />
    </IconContainer>
  ) : (
    <IconContainer>{React.cloneElement(icon, { size: 0.8 })}</IconContainer>
  );

const renderArrow = (hasArrow: boolean) =>
  hasArrow && (
    <ArrowContainer>
      <Icon name="chevronRight" size={1.25} />
    </ArrowContainer>
  );

export default ({
  Container,
  label,
  description,
  icon,
  hasArrow,
  isActive,
  ...rest
}: any) => {
  const styleProps = {
    isActive
  };

  return (
    <Item role="none" {...rest} styleProps={styleProps}>
      <Container role="menuitem">
        <Box horizontal shrink wrap={false} noSpaceBetween vAlign="center">
          {icon && renderIcon(icon)}
          <Box fill shrink stacked noSpaceBetween>
            <Label styleProps={styleProps}>{label}</Label>
            {description && (
              <Description styleProps={styleProps}>{description}</Description>
            )}
          </Box>
          {renderArrow(hasArrow)}
        </Box>
      </Container>
    </Item>
  );
};
