import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  px,
  paddingHorizontal,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Field);
const errorStyler = styleComponent(style);
const errorStylerCallback = (styles: any) => styles.Form.Field.Error;
const errorTextStylerCallback = (styles: any) => styles.Form.Field.ErrorText;

const errorCss = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  ${(props: any) =>
    props.hasError &&
    css`
      border-left: ${px(t.error.borderSize)} solid ${t.error.borderColor};
      padding-left: ${paddingHorizontal(0)};
    `};
`;

export const ErrorWrapper = styled.div([
  errorStyler(errorCss, errorStylerCallback)
]) as any;

ErrorWrapper.displayName = 'ErrorWrapper';

const errorTextCss = (t: TThemeVariables) => css`
  color: ${props => t.error.textColor};
  ${getTypography('Default')};
`;

export const ErrorText = styled.span([
  errorStyler(errorTextCss, errorTextStylerCallback)
]);

ErrorText.displayName = 'ErrorText';
