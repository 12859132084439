import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
import { ILayoutType } from '../core/layout';

export const radioCheckboxButton = (
  colors: IColorsType,
  layout: ILayoutType,
  bounds: IBoundsType
) => ({
  input: {
    width: layout.paddingVertical[1] * 2,
    height: layout.paddingVertical[1] * 2
  },

  label: {
    offset: layout.paddingHorizontal[1] * 2.5,
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.light,
    borderRadius: bounds.border.radius,
    backgroundColor: colors.lightest,
    borderBottom: bounds.border.size * 1.5,

    status: {
      focus: {
        backgroundColor: tc(colors.lightest)
          .darken(6)
          .toString(),
        borderColor: tc(colors.neutrals.light)
          .darken(12)
          .toString()
      },
      activeHover: {
        backgroundColor: tc(colors.lightest)
          .darken(10)
          .toString()
      },
      success: {
        borderColor: colors.success
      },
      warning: {
        borderColor: colors.warning
      },
      danger: {
        borderColor: colors.error
      },
      neutral: {
        borderColor: colors.neutrals.normal
      }
    }
  }
});
