import React from 'react';
import { Wrapper } from './wrapper';

interface ILabelProps {
  htmlFor?: string;
  id?: string;
  children: React.ReactNode;
}

export const Label: React.StatelessComponent<ILabelProps> = ({
  htmlFor,
  id,
  children
}) => (
  <Wrapper id={id} htmlFor={htmlFor}>
    {children}
  </Wrapper>
);

Label.displayName = 'Label';
