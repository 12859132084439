import {
  ErrorButton,
  ErrorInvertedButton,
  ErrorOutlineButton
} from '../Buttons/';
import { builder } from './builder';

export const ErrorButtonWithIcon = builder('ErrorButtonWithIcon', ErrorButton);
export const ErrorInvertedButtonWithIcon = builder(
  'ErrorInvertedButtonWithIcon',
  ErrorInvertedButton
);
export const ErrorOutlineButtonWithIcon = builder(
  'ErrorOutlineButtonWithIcon',
  ErrorOutlineButton
);
