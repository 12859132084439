const fontFamily =
  '"-apple-system", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

const defaults = {
  fontStyle: 'normal',
  fontVariant: 'normal',
  fontWeight: 'normal',
  lineHeight: '1.428571429',
  fontSize: '19px',
  fontFamily
};

export const typography = {
  Title: {
    ...defaults,
    fontWeight: 'bold',
    fontSize: '50px'
  },
  Heading: {
    ...defaults,
    fontWeight: 'bold',
    fontSize: '35px'
  },
  SubHeading: {
    ...defaults,
    fontWeight: 'bold',
    fontSize: '24px'
  },
  Lead: {
    ...defaults,
    fontWeight: '100',
    fontSize: '19px'
  },
  Default: {
    ...defaults
  },
  Label: {
    ...defaults,
    fontWeight: 'bold',
    fontSize: '19px'
  },
  Hint: {
    ...defaults,
    fontSize: '16px'
  }
};
