import { ITheme } from '../theme';

type IThemeComponents = ITheme['components'];

type ComponentThemeVariablesStyler<ComponentThemeType> = {
  (styleCallback: (t: ComponentThemeType, props: any) => any): (
    props: any
  ) => string;
};

export function selectComponentThemeVariablesFor<InferredComponentThemeType>(
  componentThemeSelector: (
    components: IThemeComponents
  ) => InferredComponentThemeType
): ComponentThemeVariablesStyler<InferredComponentThemeType> {
  return styleCallback => (props: any) => {
    const componentThemeVariables =
      props.theme.components && componentThemeSelector(props.theme.components);
    return styleCallback(componentThemeVariables, props.styleProps);
  };
}
