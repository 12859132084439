import React from 'react';
import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  px,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const DIMENSIONS = 15;
const style = selectComponentThemeVariablesFor(theme => theme.Alert);
const arrowStyler = styleComponent(style);

const ArrowIcon = ({ className }: { className: string }) => (
  <span className={className}>
    <svg
      width={`${px(DIMENSIONS)}`}
      height={`${px(DIMENSIONS)}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1200 1200"
    >
      <path d="M 0,1200 600,0 1200,1200 z" />
    </svg>
  </span>
);

interface IStyleProps {
  isSmall: boolean;
  status: 'default' | 'success' | 'warning' | 'info' | 'danger';
}

const arrowStyle = (
  t: TThemeVariables,
  { isSmall, status }: IStyleProps
) => css`
  position: absolute;
  bottom: 100%;
  left: ${isSmall ? paddingHorizontal(0) : paddingHorizontal(1)};
  width: ${px(DIMENSIONS)};
  height: ${px(DIMENSIONS)};
  fill: ${t[status].arrowColor};
  transform: translate(0, 0);
  display: flex;
`;

const themeStyleCallback = (styles: any) => styles.Alert.Arrow;

const Component: any = styled(ArrowIcon)([
  arrowStyler(arrowStyle, themeStyleCallback)
]);
Component.displayName = 'Arrow';

export default Component;
