import React from 'react';
import styled from 'styled-components';
import { IRowFreeTextGroupedProps } from './props';
import { rowGroupedStyle } from './style';

const RowGrouped = styled.div([rowGroupedStyle]);

export default ({ children }: IRowFreeTextGroupedProps) =>
  children ? (
    <RowGrouped
      styleProps={{ grouped: true, rowType: 'freetext-grouped' }}
      role="listitem"
    >
      {children}
    </RowGrouped>
  ) : null;
