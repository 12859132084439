import React from 'react';
import styled from 'styled-components';
import { IDetailListProps, IDetailProps } from './props';

import { Default, Heading, SubHeading } from '../Typography';

import { wrapperStyle, rowStyle, headingStyle, contentStyle } from './style';

const Wrapper = styled.div([wrapperStyle]);
const RowWrapper = styled.div([rowStyle]);
const HeadingWrapper = styled.div([headingStyle]);
const ContentWrapper = styled.div([contentStyle]);

const DetailListRow = ({ title, children, action, tooltip }: any) =>
  title ? (
    <RowWrapper key={title} role="listitem" styleProps={{ grouped: false }}>
      <div>
        <SubHeading>{`${title}:`}</SubHeading>
        {tooltip && <SubHeading>{tooltip}</SubHeading>}
      </div>
      {children}
      <Default>{action && action}</Default>
    </RowWrapper>
  ) : null;

export default ({ heading, children }: IDetailListProps) => {
  const components = React.Children.map(
    children,
    (child: React.ReactElement<IDetailProps>, index: number) => {
      const childProps = child.props;
      const { title, action, tooltip, ...rest } = { ...childProps };
      return (
        <DetailListRow
          title={title}
          action={action}
          tooltip={tooltip}
          key={`row-${index}`}
        >
          {React.isValidElement(child) && React.cloneElement(child, rest)}
        </DetailListRow>
      );
    }
  );
  return (
    <Wrapper role="list">
      <HeadingWrapper>
        <Heading>{heading}</Heading>
      </HeadingWrapper>
      <ContentWrapper role="group">{components && components}</ContentWrapper>
    </Wrapper>
  );
};
