import { css } from 'styled-components';
import { identity } from 'rambda';
import {
  selectComponentThemeVariablesFor,
  getBoxShadow,
  paddingHorizontal,
  px,
  paddingVertical,
  getTypography
} from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.FileUpload);

const progressStatusHeight = 5;

const errorMixin = (t: any) => css`
  background-color: ${t.progress.status.error.backgroundColor};
  transform: translateX(100%);
  transition: transform 0s;
`;

export const progress = style(
  (t, { progress, error }) => css`
    position: absolute;
    top: auto;
    height: 5px;
    left: -100%;
    bottom: 0;
    width: 100%;
    transform: translateX(${progress}%);
    transition: transform 0.3s ease-in;
    background-color: ${t.progress.status.success.backgroundColor};
    ${error && errorMixin(t)};
  `
);

export const content = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)}
    calc(${paddingVertical(1)} + ${progressStatusHeight}px);
`;
export const container = style(
  t => css`
    position: relative;
    overflow: hidden;
    ${getTypography('Default')};
    background-color: ${t.container.backgroundColor};
    box-shadow: ${getBoxShadow('normal')};
    border-width: ${px(t.container.borderSize)};
    border-radius: ${px(t.container.borderRadius)};
    border-style: solid;
    border-color: ${t.container.borderColor};
  `
);

export const meta = css`
  display: flex;
`;

export const name = css`
  font-weight: bold;
`;

export const size = css`
font-weight normal;
padding-left: ${paddingHorizontal(1)}`;

export const closer = css`
  underline: none;
`;

export const inputContainer = css`
  position: relative;
`;
export const label = css`
  display: inline-block;
`;

export const input = css`
  position: absolute;
  left: -9999px;
`;
export const dragContainer = style(
  t => css`
    border: ${px(t.container.borderSize)} dashed ${t.container.borderColor};
    box-shadow: ${getBoxShadow('normal')};
    padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
    ${getTypography('Default')};
    text-align: center;
    display: flex;
    flex: 1;
  `
);
