import { IColorsType } from 'theme/core/colors';
import tc from 'tinycolor2';

export const content = (colors: IColorsType) => ({
  weights: {},
  margins: {
    h1: 20,
    h2: 18,
    h3: 16,
    h4: 14,
    h5: 12,
    h6: 10,
    default: 20
  },

  link: {
    color: 'blue',
    decoration: 'underline',
    status: {
      hover: 'darkblue'
    }
  },

  blockquote: {
    backgroundColor: tc(colors.informational)
      .lighten(35)
      .toString(),
    borderColor: tc(colors.auxillary)
      .lighten(20)
      .toString()
  }
});
