import React from 'react';
import {
  theme,
  ThemeProvider,
  Content,
  Container,
  Nav,
  NavItem,
  Select,
  Row,
  Column
} from '@parmenion/library';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { GatewayDest, Gateway } from 'react-gateway';
import styled from 'styled-components';
import logo from './assets/parmenion-logo-icon.svg';
import { Stacked, Box, Wrapper, Heading } from './ui';
import components from './components/all-components';
import getCategories from './categorisation';
import { PageSidebar } from './components/side-bar';
const categories = getCategories(components.modules[0].components);

import { changeTheme } from './actions';

export default connect(
  state => ({
    currentTheme: state.theme
  }),
  dispatch => ({
    changeCurrentTheme: theme => dispatch(changeTheme(theme))
  })
)(({ router, currentTheme, changeCurrentTheme, ...props }) => (
  <ThemeProvider theme={theme}>
    <Box>
      <PageHeaderContainer>
        <Box fill stacked spaceBetween={0}>
          <Box horizontal vAlign="center" hAlign="spaceBetween">
            <Row vAlign="center" spaceBetween={1}>
              <Column>
                <Link to="/">
                  <img src={`/${logo}`} width={50} />
                </Link>
              </Column>
              <Column>
                <Heading>Component Library</Heading>
              </Column>
            </Row>
            <Nav>
              <NavItem
                label="Home"
                href="/"
                isActive={/^\/$/.test(router.location.pathname)}
                as={<Link to="/" />}
              />
              <NavItem
                label="Quick Start"
                href="/quick-start"
                isActive={/^\/quick-start$/.test(router.location.pathname)}
                as={<Link to="/quick-start" />}
              />
              <NavItem
                label="Usage"
                href="/usage"
                isActive={/^\/usage$/.test(router.location.pathname)}
                as={<Link to="/usage" />}
              />
              <NavItem
                label="Design System"
                href="/page/core/semantic"
                isActive={/^\/page\/core\/semantic$/.test(
                  router.location.pathname
                )}
                as={<Link to="/page/core/semantic" />}
              />
              <NavItem
                label="Components"
                href="/components"
                isActive={/^\/components/.test(router.location.pathname)}
                as={<Link to="/components" />}
              />
            </Nav>
          </Box>
        </Box>
      </PageHeaderContainer>
      <PageLayout>
        <PageLayoutColumnWithSideBar>
          <PageLayoutFillColumn>
            <PageSidebar
              title="List by type"
              router={router}
              categories={categories}
            />
          </PageLayoutFillColumn>
        </PageLayoutColumnWithSideBar>
        <PageLayoutColumn>
          <GatewayDest name="header" component={Wrapper} />
          {props.children}
        </PageLayoutColumn>
      </PageLayout>

      <Footer>
        <Container>
          <Content>
            Built by {process.env.CI_RUNNER_DESCRIPTION} on{' '}
            {process.env.BUILD_TIME_DISPLAY} pipeline #
            {process.env.CI_PIPELINE_ID} for ref{' '}
            {process.env.CI_COMMIT_REF_NAME} commit{' '}
            {process.env.CI_COMMIT_SHA.substring(0, 7)}
          </Content>
        </Container>
      </Footer>
    </Box>
  </ThemeProvider>
));

const PageLayout = styled.div`
  display: flex;
  flex: 1;
`;

const PageLayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const PageLayoutColumnWithSideBar = styled(PageLayoutColumn)`
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 300px;
`;

const PageLayoutFillColumn = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: whitesmoke;
`;

const PageHeaderContainer = styled.div`
  background: whitesmoke;
  padding: 20px;
  position: relative;
  z-index: 1;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 6px;
    box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.neutrals.light};
  background: whitesmoke;
  padding: 20px;
  color: gray;
`;
