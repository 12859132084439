import React from 'react';
import { withTheme } from 'styled-components';
import {
  Row,
  Column,
  Hint,
  Separated,
  Stacked,
  Default,
  utils
} from '@parmenion/library';

const { getSpacing } = utils;

import { Markdown } from './markdown';
import incrementsReadme from '../../../docs/spacing/increments.md';
import settingReadme from '../../../docs/spacing/setting.md';
import accessingReadme from '../../../docs/spacing/accessing.md';
import { Heading } from '../ui';

const walkSpacing = object => {
  return Object.keys(object).map(key => (
    <Row shrink>
      <Layout title={key}>
        <ListSpacing values={object[key]} />
      </Layout>
    </Row>
  ));
};

const Layout = props => (
  <Column shrink>
    <div style={styles.heading}>
      <Default>{props.title}</Default>
    </div>
    <Row>{props.children}</Row>
  </Column>
);

const ListSpacing = ({ values }) => (
  <Row>
    {values.map((value, index) => (
      <SpacingBox value={value} index={index} />
    ))}
  </Row>
);

const SpacingBox = ({ value, index }) => (
  <Column>
    <div style={styles.container}>
      <div style={{ ...styles.renderFrame, ...{ borderWidth: value } }} />
      <div
        style={{ ...styles.borders, ...styles.borderTop, ...{ height: value } }}
      />
      <div
        style={{
          ...styles.borders,
          ...styles.borderRight,
          ...{ width: value }
        }}
      />
      <div
        style={{
          ...styles.borders,
          ...styles.borderBottom,
          ...{ height: value }
        }}
      />
      <div
        style={{ ...styles.borders, ...styles.borderLeft, ...{ width: value } }}
      />
    </div>
    <Default>
      {index} = {value}
      px
    </Default>
  </Column>
);

export default withTheme(props => {
  const { theme } = props;
  const mySpacing = getSpacing(props);

  const SomeComponent = () => (
    <Column>
      <div style={styles.container}>
        <div
          style={{
            ...styles.renderFrame,
            ...{
              borderTopWidth: myStyles.paddingTop,
              borderRightWidth: myStyles.paddingRight,
              borderBottomWidth: myStyles.paddingBottom,
              borderLeftWidth: myStyles.paddingLeft
            }
          }}
        />
        <div
          style={{
            ...styles.borders,
            ...styles.borderTop,
            ...{ height: myStyles.paddingTop }
          }}
        />
        <div
          style={{
            ...styles.borders,
            ...styles.borderRight,
            ...{ width: myStyles.paddingRight }
          }}
        />
        <div
          style={{
            ...styles.borders,
            ...styles.borderBottom,
            ...{ height: myStyles.paddingBottom }
          }}
        />
        <div
          style={{
            ...styles.borders,
            ...styles.borderLeft,
            ...{ width: myStyles.paddingLeft }
          }}
        />
      </div>
    </Column>
  );

  const myStyles = {
    paddingTop: mySpacing('vertical', 1),
    paddingRight: mySpacing('spacing', 2),
    paddingBottom: mySpacing('spacing', 3),
    paddingLeft: mySpacing('horizontal', 2)
  };

  return (
    <Separated>
      <Stacked>
        <Heading>Spacing increments</Heading>
        <Markdown source={incrementsReadme} />
        <Layout title="">{walkSpacing(theme.layout)}</Layout>
      </Stacked>
      <Stacked>
        <Heading>Setting the spacing</Heading>
        <Markdown source={settingReadme} />
      </Stacked>
      <Stacked>
        <Heading>Accessing spacing within your project</Heading>
        <Markdown source={accessingReadme} />
        <Layout title="">
          <SomeComponent />
        </Layout>
      </Stacked>
    </Separated>
  );
});

const styles = {
  heading: {
    padding: '5px 0'
  },
  borders: {
    borderStyle: 'dashed',
    borderColor: 'rgb(255, 0, 20)',
    borderWidth: 0,
    position: 'absolute'
  },
  borderTop: {
    top: 0,
    right: 0,
    left: 0,
    borderBottomWidth: 1
  },
  borderBottom: {
    bottom: 0,
    right: 0,
    left: 0,
    borderTopWidth: 1
  },
  borderRight: {
    top: 0,
    right: 0,
    bottom: 0,
    borderLeftWidth: 1
  },
  borderLeft: {
    top: 0,
    left: 0,
    bottom: 0,
    borderRightWidth: 1
  },
  container: {
    position: 'relative',
    width: '150px',
    height: '150px',
    border: '1px solid #ccc'
  },
  renderFrame: {
    borderStyle: 'solid',
    borderColor: 'rgb(143, 196, 233)',
    position: 'absolute',
    borderWidth: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  },
  background: backgroundColor => ({
    width: '150px',
    height: '150px',
    backgroundColor: '#ccc'
  })
};
