import { component } from 'builder';
import { ISeparatedProps } from './props';
import Base from './base';

export const Separated = component<ISeparatedProps>({
  name: 'Separated',
  base: Base,
  props: {
    children: null,
    spaceBetween: 3
  }
});
