import React from 'react';
import { PageHeader, Wrapper } from './ui';
import { Markdown } from './components/markdown';

import readme from '../../README.md';

export default () => (
  <Wrapper>
    <PageHeader
      header="Usage"
      lead="How to use and work with the Component Library"
    />
    <Markdown source={readme} />
  </Wrapper>
);
