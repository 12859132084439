import { component } from 'builder';

import { IProps, IFileListItemProps } from './props';
import Base from './base';
import BaseFileListItem from './components/FileListItem';
import { Children } from 'react';

export const FileUploader = component<IProps>({
  name: 'FileUploader',
  base: Base,
  props: {
    name: null,
    onChange: () => {},
    children: null
  }
});

export const FileListItem = component<IFileListItemProps>({
  name: 'FileListItem',
  base: BaseFileListItem,
  props: {
    file: null,
    onRemove: () => {},
    error: false,
    progress: 0
  }
});
