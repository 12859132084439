import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Nav);
const navStyler = styleComponent(style);

const containerCss = (t: TThemeVariables) => css`
  position: relative;
  margin: -${paddingVertical(1)};
`;

const listCss = (t: TThemeVariables) => css`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex: 1 0;
  flex-wrap: wrap;
`;

const wrapperCss = (t: TThemeVariables) => css`
  padding: ${paddingVertical(1)};
  perspective: 1000px;
`;

const activeMixin = (isActive: boolean, t: TThemeVariables) =>
  isActive
    ? css`
        background: ${t.backgroundColor};
        color: ${t.textColor};
      `
    : ``;

const itemCss = (t: TThemeVariables, { isActive }: any) =>
  css`
    ${activeMixin(isActive, t)};
    display: block;

    text-decoration: none;
    padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
    ${getTypography('Default')};
    &:focus {
      outline: 3px solid ${t.borderColor};
    }

    &:active {
      transform: scale(0.99) rotateX(15deg);
      transform-origin: center bottom;
      outline: none;
    }
  `;

const containerStyleCallback = (styles: any) => styles.Nav.Container;
export const containerStyle = navStyler(containerCss, containerStyleCallback);

const listStyleCallback = (styles: any) => styles.Nav.List;
export const listStyle = navStyler(listCss, listStyleCallback);

const wrapperStyleCallback = (styles: any) => styles.Nav.Wrapper;
export const wrapperStyle = navStyler(wrapperCss, wrapperStyleCallback);

const itemStyleCallback = (styles: any) => styles.Nav.Item;
export const itemStyle = navStyler(itemCss, itemStyleCallback);
