import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent
} from 'utils';

import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const arrowStyler = styleComponent(withComponentTheme);

const ARROW_HEIGHT = 10;
const ARROW_WIDTH = 6;

const arrowCss = (t: TThemeVariables, { isOpen }: any) => css`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  width: 49px;
  background-color: ${t.arrowBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: '';
    display: block;
    border-width: ${ARROW_HEIGHT}px ${ARROW_WIDTH}px 0px;
    border-color: ${t.arrowBorderColor} transparent transparent;
    border-style: solid;
    transform: rotate(0deg) ${isOpen && `rotate(180deg)`};
    transition: all 0.2s;
  }
  &:hover {
    background-color: ${t.arrowHoverBackgroundColor};
  }
`;

const themeStyleCallback = (styles: any) => styles.Accordion.Arrow;

export const Arrow = styled.i([
  arrowStyler(arrowCss, themeStyleCallback)
]) as any;
