import { initial } from 'lodash/fp';
const options = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};

export const formatDate = timestamp =>
  new Intl.DateTimeFormat('en-GB', options).format(new Date(timestamp));

export const omitTimeZone = timestamp =>
  timestamp != null ? initial(timestamp.split(' ')).join(' ') : '1970-01-01';
