import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Link);
const styler = styleComponent(style);

const wrapperCallback = (styles: any) => styles.Link.Wrapper;
const resultLinkCallback = (styles: any) => styles.Link.ResultLink;
const textWrapperCallback = (styles: any) => styles.Link.TextWrapper;
const iconWrapperCallback = (styles: any) => styles.Link.IconWrapper;

const wrapperCustomCss = (t: TThemeVariables) => css`
  color: ${t.linkColor};
  text-decoration: underline;

  :focus,
  :hover {
    cursor: pointer;
    color: ${t.linkHoverColor};
  }

  :active {
    color: ${t.linkActiveColor};
  }

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: right;
  flex: 0 1 auto;

  direction: ${({ styleProps }: any) =>
    (styleProps && styleProps.direction) || 'rtl'};

  * {
    flex: 0 1 auto;
  }

  * + * {
    flex: 0 0 auto;
  }

  * + * {
    margin: ${({ styleProps }: any) =>
      styleProps && styleProps.direction === 'rtl'
        ? 'auto 0.1rem auto 0'
        : 'auto 0 auto 0.1rem'};
  }
`;
const resultLinkCustomCss = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: row;
  justify-content: right;
  color: ${t.linkColor};
  text-decoration: none;
  line-height: 1.5rem;
  padding: 1.5rem 0.5rem 1.5rem 0;
  border-bottom: 1px solid ${t.borderColor};
  :hover {
    text-decoration: underline;
  }
`;
const iconWrapperCustomCss = (t: TThemeVariables) => css`
  color: ${t.linkHoverColor};
  margin-left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const textWrapperCustomCss = (t: TThemeVariables) => css`
  flex-grow: 1;
`;

export const wrapperStyle = styler(wrapperCustomCss, wrapperCallback);
export const resultLinkStyle = styler(resultLinkCustomCss, resultLinkCallback);
export const iconWrapperStyle = styler(
  iconWrapperCustomCss,
  iconWrapperCallback
);
export const textWrapperStyle = styler(
  textWrapperCustomCss,
  textWrapperCallback
);
