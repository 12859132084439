import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../';

import { overlayStyle } from './style';
const Overlay = styled.div([overlayStyle]);

export default (props: any) => (
  <div>
    <Overlay styleProps={{ isInverted: props.isInverted }} />
    <Spinner isInverted={props.isInverted} isCentered={true} />
  </div>
);
