import React from 'react';
import styled, { withTheme } from 'styled-components';
import containerStyle from './style';

const Wrapper = styled.div([containerStyle]);

export default withTheme((props: any) => (
  <Wrapper
    styleProps={{
      breakpoints: props.theme.breakpoints,
      alignment: props.alignment
    }}
    className={props.className}
  >
    {props.children}
  </Wrapper>
));
