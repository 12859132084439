import React from 'react';

export const TabItemBase = ({
  index,
  activeTab,
  label,
  onClick,
  onKeyPress,
  className,
  tabIndex,
  children
}: any) => {
  let tabClassName = className + ' tab-item';
  if (activeTab === children) {
    tabClassName = tabClassName + ' tab-item-active';
  }

  return (
    <li
      id={`tab-${index}`}
      onClick={() => onClick(children)}
      onKeyPress={onKeyPress}
      className={tabClassName}
      aria-selected={activeTab === children[0]}
      role="tab"
      aria-label={label}
      aria-controls={`tabpanel-${index}`}
      tabIndex={tabIndex}
    >
      {children}
    </li>
  );
};
