import styled, { css } from 'styled-components';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const bodyStyler = styleComponent(withComponentTheme);

const bodyCss = (t: TThemeVariables, { height }: any) => css`
  display: block;
  height: ${px(height)};
  transition: height 300ms ease-out;
  overflow: hidden;
`;
const themeStyleCallback = (styles: any) => styles.Accordion.Body;

export const Body = styled.div([
  bodyStyler(bodyCss, themeStyleCallback)
]) as any;
