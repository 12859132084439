import React from 'react';
import styled from 'styled-components';
import { wrapperStyle } from './style';
import { Tab } from './';
import { ITabsProps, ITabsState } from './props';

const LEFT = 'LEFT';
const RIGHT = 'RIGHT';

const Wrapper = styled.div([wrapperStyle]);

export class TabsBase extends React.Component<ITabsProps, ITabsState> {
  tabIndexCount: number = 0;

  constructor(props: any) {
    super(props);

    this.state = {
      activeTab: props.config[0].tabHeader,
      tabFocussed: false
    };
  }

  componentDidMount() {
    if (document.body) {
      document.body.addEventListener('keydown', this.onKeyEvent);
      document.body.addEventListener('focusin', this.onFocusEvent);
    }
  }

  componentWillUnmount() {
    if (document.body) {
      document.body.removeEventListener('keydown', this.onKeyEvent);
      document.body.removeEventListener('focusin', this.onFocusEvent);
    }
  }

  keyEvents = {
    ArrowLeft: (event: Event) => {
      if (!this.state.tabFocussed) return;
      this.setState(
        {
          activeTab: this.getNextActiveTab(LEFT)
        },
        () => {
          const eventTarget: HTMLElement | any = event.target;
          eventTarget.focus();
        }
      );
    },
    ArrowRight: (event: Event) => {
      if (!this.state.tabFocussed) return;
      this.setState(
        {
          activeTab: this.getNextActiveTab(RIGHT)
        },
        () => {
          const eventTarget: HTMLElement | any = event.target;
          eventTarget.focus();
        }
      );
    },
    ' ': (event: Event) => {
      const target: HTMLElement | any = event.target;
      target.click();
    },
    Enter: (event: Event) => {
      event.preventDefault();
      const target: HTMLElement | any = event.target;
      target.click();
    },
    Home: () => {
      if (!this.state.tabFocussed) return;
      this.setState({ activeTab: this.props.config[0].tabHeader });
    },
    End: () => {
      if (!this.state.tabFocussed) return;
      this.setState({
        activeTab: this.props.config[this.props.config.length - 1].tabHeader
      });
    }
  };

  tabNavFunctions = {
    LEFT: (activeTabIndex: number) => {
      return activeTabIndex === 0
        ? this.props.config.length - 1
        : activeTabIndex - 1;
    },
    RIGHT: (activeTabIndex: number) => {
      return activeTabIndex === this.props.config.length - 1
        ? 0
        : activeTabIndex + 1;
    }
  };

  onClickTabItem = (tab: any) => {
    this.setState({
      activeTab: tab
    });
  };

  onKeyEvent = (event: any) => {
    const keyAction = this.keyEvents[event.key];
    if (keyAction) keyAction(event);
  };

  onFocusEvent = (event: any) => {
    const element: HTMLElement = event.target;

    if (element.id.includes('tab-')) {
      this.setState({ tabFocussed: true });
    } else if (this.state.tabFocussed) {
      this.setState({ tabFocussed: false });
    }
  };

  getActiveTabIndex = () => {
    let activeTabIndex = -1;
    for (let child of this.props.config) {
      if (child && child.tabHeader === this.state.activeTab) {
        activeTabIndex = this.props.config.indexOf(child);
        break;
      }
    }
    return activeTabIndex;
  };

  getNextActiveTab = (direction: any) => {
    const activeTabIndex = this.getActiveTabIndex();
    let nextTabIndex = activeTabIndex;
    const navAction = this.tabNavFunctions[direction];
    if (navAction) nextTabIndex = navAction(activeTabIndex);
    return this.props.config[nextTabIndex].tabHeader;
  };

  render() {
    const {
      onClickTabItem,
      onKeyEvent,
      props: { className, config },
      state: { activeTab }
    } = this;

    this.tabIndexCount = 0;

    return (
      <Wrapper className={className + ' tabbed-view'}>
        <ul className={'tab-bar'} role="tablist" aria-orientation="horizontal">
          {config.map((child, index) => {
            const { tabHeader } = child;
            return (
              <Tab
                index={index}
                activeTab={activeTab}
                key={'tab-header-' + index}
                label={tabHeader.props.label}
                onClick={onClickTabItem}
                onKeyPress={onKeyEvent}
                className={className}
                tabIndex={this.tabIndexCount}
              >
                {tabHeader}
              </Tab>
            );
          })}
        </ul>
        <div className={className + ' tab-content'}>
          {config.map((child, index) => {
            if (child.tabHeader !== activeTab) return null;
            return (
              <div
                id={`tabpanel-${index}`}
                className={className + ' tab-content-active'}
                key={'tab-content-' + index}
                tabIndex={this.tabIndexCount}
                role="tabpanel"
                aria-labelledby={`tab-${index}`}
                aria-hidden={this.state.activeTab !== child.tabHeader}
              >
                {child.content}
              </div>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}
