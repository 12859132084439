import React from 'react';
import styled from 'styled-components';
import { wrapperStyle, inputWrapperCss } from './style';
import { InputNumber } from '../InputNumber';

const Wrapper = styled.div([wrapperStyle]);
const InputWrapper = styled.div([inputWrapperCss]);

export const StepperBase = (props: any) => {
  const {
    plusButton,
    minusButton,
    inputField,
    value,
    increment,
    precision,
    onChange,
    onClick,
    onBlur,
    isRequired,
    isInvalid,
    labelledby,
    ...rest
  } = props;

  const ariaProps = {
    labelledby,
    isRequired,
    isInvalid
  };

  const hasPrecision = () => {
    return precision !== null && precision !== undefined;
  };

  const handleClick = (name: string, onClick: (value: string) => void) => (
    e: any
  ) => {
    const modifier = increment || 1;
    let newValue;
    if (name === 'minus') {
      newValue = Number(value) - modifier;
    } else {
      newValue = Number(value) + modifier;
    }
    return hasPrecision()
      ? onClick(newValue.toFixed(precision))
      : onClick(newValue);
  };

  const handleBlur = (value: any) => {
    if (hasPrecision()) {
      const newValue = Number(value).toFixed(precision);
      onChange && onChange(newValue);
    } else {
      return;
    }
  };

  return (
    <Wrapper {...rest}>
      {React.isValidElement(minusButton) &&
        React.cloneElement<any>(minusButton, {
          onClick: handleClick('minus', onClick)
        })}
      <InputWrapper onBlur={() => handleBlur(value)}>
        {React.isValidElement(inputField) &&
          React.cloneElement<typeof InputNumber>(
            inputField as any,
            {
              value: value,
              onChange: onChange,
              ...ariaProps
            } as any
          )}
      </InputWrapper>
      {React.isValidElement(plusButton) &&
        React.cloneElement<any>(plusButton, {
          onClick: handleClick('plus', onClick)
        })}
    </Wrapper>
  );
};
