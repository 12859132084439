import { css } from 'styled-components';
import { identity } from 'rambda';
import { selectComponentThemeVariablesFor, getBoxShadow } from 'utils';

const style = selectComponentThemeVariablesFor(identity);

export const wrapperStyle = css`
  position: relative;
  z-index: 1;
  display: inline-block;
`;

export const overlayStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: transparent;
`;

export const contentStyle = style(
  (t, { isOpen, isRight }) => css`
    position: absolute;
    left: ${isRight ? 'auto' : '0'};
    right: ${isRight ? '0' : 'auto'};
    width: 100%;
    min-width: 300px;
    transition: all 0.45s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    transform: ${isOpen ? 'scaleY(1)' : 'scaleY(0)'};
    transform-origin: left top 0px;
    opacity: ${isOpen ? '1' : '0'};
    box-shadow: ${getBoxShadow('large')};
  `
);
