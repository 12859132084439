import { Heading } from '../Typography';
import React from 'react';
import styled from 'styled-components';
import { IGroupedProps } from './props';
import {
  wrapperGroupedStyle,
  actionWrapperGroupedStyle,
  headingWrapperGroupedStyle,
  contentWrapperGroupedStyle
} from './style';

const WrapperGrouped = styled.div([wrapperGroupedStyle]);
const ActionWrapperGrouped = styled.div([actionWrapperGroupedStyle]);
const HeadingWrapperGrouped = styled.div([headingWrapperGroupedStyle]);
const ContentWrapperGrouped = styled.div([contentWrapperGroupedStyle]);

export default ({
  heading,
  action,
  children,
  noTopBorder,
  noBottomBorder
}: IGroupedProps) => {
  return (
    <WrapperGrouped
      role="list"
      styleProps={{ noBottomBorder, noTopBorder, action, heading }}
    >
      {heading && (
        <HeadingWrapperGrouped>
          <Heading>{heading}</Heading>
        </HeadingWrapperGrouped>
      )}
      <ContentWrapperGrouped role="group">{children}</ContentWrapperGrouped>
      {action && <ActionWrapperGrouped>{action}</ActionWrapperGrouped>}
    </WrapperGrouped>
  );
};
