import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  px,
  paddingHorizontal,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Field);
const warningStyler = styleComponent(style);
const warningStylerCallback = (styles: any) => styles.Form.Field.Warning;
const warningTextStylerCallback = (styles: any) =>
  styles.Form.Field.WarningText;

const warningCss = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  ${(props: any) =>
    props.hasWarning &&
    !props.hasError &&
    css`
      border-left: ${px(t.warning.borderSize)} solid ${t.warning.borderColor};
      padding-left: ${paddingHorizontal(0)};
    `};
`;

export const WarningWrapper = styled.div([
  warningStyler(warningCss, warningStylerCallback)
]) as any;

WarningWrapper.displayName = 'WarningWrapper';

const warningTextCss = (t: TThemeVariables) => css`
  color: ${props => t.warning.textColor};
  ${getTypography('Default')};
`;

export const WarningText = styled.span([
  warningStyler(warningTextCss, warningTextStylerCallback)
]);

WarningText.displayName = 'WarningText';
