import React from 'react';

import { Background, Position } from './style';
import { Svg } from '../Svg';

export const PatternBase = (props: any) => (
  <Background {...props}>
    <Position
      scale={props.scale}
      position={props.position}
      rotate={props.rotate}
    >
      <Svg {...props} />
    </Position>
  </Background>
);
