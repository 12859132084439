import invariant from 'invariant';
import React from 'react';
import * as Components from '@parmenion/library';
import { withTheme } from 'styled-components';
import { omit } from 'rambda';
import { Markdown } from '../markdown';
import introReadme from '../../../../docs/semantic-layer/intro.md';
import atomsReadme from '../../../../docs/semantic-layer/atoms.md';
import moleculesReadme from '../../../../docs/semantic-layer/molecules.md';
import organismsReadme from '../../../../docs/semantic-layer/organisms.md';
import templatesReadme from '../../../../docs/semantic-layer/templates.md';
import pagesReadme from '../../../../docs/semantic-layer/pages.md';
import summaryReadme from '../../../../docs/semantic-layer/summary.md';
import { Heading, DarkBackground } from '../../ui';
import {
  Template,
  Page,
  Atoms,
  Search as Molecule,
  Header as Organism
} from './components';

const { Separated, Stacked } = Components;

export default withTheme(({ theme }) => {
  return (
    <Separated>
      <Stacked>
        <Heading>Background</Heading>
        <Markdown source={introReadme} />
      </Stacked>
      <Stacked>
        <Heading>Atoms</Heading>
        <Markdown source={atomsReadme} />
        <Atoms />
      </Stacked>
      <Stacked>
        <Heading>Molecules</Heading>
        <Markdown source={moleculesReadme} />
        <Molecule />
      </Stacked>
      <Stacked>
        <Heading>Organisms</Heading>
        <Markdown source={organismsReadme} />
        <Organism />
      </Stacked>
      <Stacked>
        <Heading>Templates</Heading>
        <Markdown source={templatesReadme} />
        <Template />
      </Stacked>
      <Stacked>
        <Heading>Pages</Heading>
        <Markdown source={pagesReadme} />
        <Page />
      </Stacked>
      <Stacked>
        <Heading>Summary</Heading>
        <Markdown source={summaryReadme} />
      </Stacked>
    </Separated>
  );
});

const styles = {
  heading: {
    padding: '5px 0'
  },
  container: {
    width: '300px',
    padding: '20px',
    margin: '2px',
    border: '1px solid #ccc'
  },
  wrapper: {
    width: '100%'
  },
  background: backgroundColor => ({
    height: '100px',
    width: '100px',
    border: '1px solid #ccc',
    backgroundColor: ' #ccc',
    display: 'flex',
    margin: 'auto',
    margin: '20px 0'
  }),
  center: {
    display: 'flex',
    margin: 'auto'
  }
};
