import React from 'react';
import { Select } from '../Forms/Select';

export default (props: any) => {
  const {
    onFocus,
    onBlur,
    onChange,
    isOpen,
    isActive,
    isSmall,
    isClearable,
    isDisabled,
    placeholder,
    items,
    value,
    Container,
    ...rest
  } = props;

  return (
    <Container {...rest}>
      <Select
        isAutocomplete
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        isOpen={isOpen}
        isActive={isActive}
        isSmall={isSmall}
        isClearable={isClearable}
        isDisabled={isDisabled}
        placeholder={placeholder}
        items={items}
        value={value}
      />
    </Container>
  );
};
