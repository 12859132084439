import React from 'react';
import styled, { css } from 'styled-components';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  px,
  styleComponent,
  getTypography
} from 'utils';

import { IconButton } from '../../IconButton';
import { Box } from '../../core';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Modal);

const TitleStyle = (t: TThemeVariables) => css`
  position: relative;
  padding: ${paddingVertical(2)} ${paddingHorizontal(2)};
  border-bottom: ${px(t.title.borderSize)} solid ${t.title.borderColor};
  background-color: ${t.title.backgroundColor};
  color: ${t.title.textColor};
  ${getTypography('Heading')};
  font-weight: 400;
  & [data-testid='close-modal'] {
    :focus {
      outline: 1px auto -webkit-focus-ring-color;
    }
  }
`;

const styler = styleComponent(style);
const styleCallback = (styles: any) => styles.Modal.Title;

const StyledContent = styled.div([styler(TitleStyle, styleCallback)]);

export default ({ children, handler, id }: any) => (
  <StyledContent id={id} tabIndex={0}>
    <Box horizontal wrap={false} vAlign="center">
      <Box shrink fill>
        {children}
      </Box>
      <Box>
        <IconButton
          testID="close-modal"
          name="close"
          onClick={handler}
          onKeyPress={handler}
          color="lightest"
        />
      </Box>
    </Box>
  </StyledContent>
);
