import { css, keyframes } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Spinner);
const spinnerStyler = styleComponent(style);
const spinnerStylerCallback = (styles: any) => styles.Spinner;

const centeredMixin = (isCentered: boolean) =>
  isCentered
    ? css`
        position: absolute;
        z-index: 20;
        left: 0;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translate(0, -50%);
        justify-content: center;
      `
    : ``;

const bouncedelay = keyframes`
    0%, 80%, 100% {
      transform: scale(0.0);
    }
    40% {
      transform: scale(1.0);
    }
  `;

export const wrapperStyle = style(
  (t, { isCentered }) =>
    css`
      display: flex;
      align-items: center;
      ${centeredMixin(isCentered)};
    `
);

const spinnerCss = (t: TThemeVariables, { isInverted }: any) => css`
  width: 8px;
  height: 8px;
  background-color: ${isInverted
    ? t.inverted.backgroundColor
    : t.backgroundColor};
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  animation: ${bouncedelay} 1.4s infinite ease-in-out both;

  &:nth-child(2) {
    animation-delay: 0.16s;
  }
  &:nth-child(3) {
    animation-delay: 0.32s;
  }
`;

export const circleStyle = spinnerStyler(
  spinnerCss,
  spinnerStylerCallback
) as any;
