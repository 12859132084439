import React from 'react';
import * as Components from '@parmenion/library';
import { Heading, DarkBackground, LightBackground } from '../../../ui';
import logo from '../../../assets/parmenion-logo-icon.svg';

const {
  Row,
  Column,
  Hint,
  Table,
  Separated,
  Stacked,
  Default,
  PrimaryButton,
  InputText,
  Label,
  Nav,
  NavItem,
  Title
} = Components;

export const Atoms = () => (
  <DarkBackground>
    <Stacked>
      <PrimaryButton>A button is an atom</PrimaryButton>
      <InputText value="An input is an atom" />
      <Label>A Label is an atom</Label>
    </Stacked>
  </DarkBackground>
);

export const Search = () => (
  <DarkBackground>
    <Stacked>
      <Label>Search the site</Label>
      <Row>
        <InputText placeholder="Enter search term" />
        <PrimaryButton>Search</PrimaryButton>
      </Row>
    </Stacked>
  </DarkBackground>
);

export const Header = () => (
  <LightBackground>
    <Row hAlign="spaceBetween" vAlign="bottom">
      <Nav>
        <img src={`/${logo}`} height={50} width={50} />
        <NavItem label="Home" />
        <NavItem label="About" isActive />
        <NavItem label="Blog" />
      </Nav>
      <Stacked>
        <Label>Search the site</Label>
        <Row>
          <InputText placeholder="Enter search term" />
          <PrimaryButton>Search</PrimaryButton>
        </Row>
      </Stacked>
    </Row>
  </LightBackground>
);

const Banner = ({ img, title, heading }) => (
  <DarkBackground backgroundImage={`url(${img})`}>
    <Row hAlign="right" vAlign="bottom" height={300}>
      <LightBackground>
        <Stacked>
          <Title>{title}</Title>
          <Heading>{heading}</Heading>
        </Stacked>
      </LightBackground>
    </Row>
  </DarkBackground>
);

const CopyAboveImage = ({ src, children }) => (
  <LightBackground padding={0}>
    <Stacked>
      <Default>{children}</Default>
      <img src={src} width={'100%'} height={150} />
    </Stacked>
  </LightBackground>
);

const CopyRightOfImage = ({ src, children }) => (
  <LightBackground padding={0}>
    <Row wrap={false}>
      <Column shrink>
        <img src={src} width={200} height={200} />
      </Column>
      <Column shrink>
        <Default>{children}</Default>
      </Column>
    </Row>
  </LightBackground>
);

const ImageRow = ({ images }) => (
  <LightBackground padding={0}>
    <Row wrap={false}>
      <Column shrink>
        <img src={images[0]} width={100} height={100} />
      </Column>
      <Column shrink>
        <img src={images[1]} width={100} height={100} />
      </Column>
      <Column shrink>
        <img src={images[2]} width={100} height={100} />
      </Column>
    </Row>
  </LightBackground>
);

export const Layout = ({
  title,
  heading,
  img,
  leftContent,
  rightContent,
  imageSet
}) => (
  <Stacked>
    <Header />
    <Banner title={title} heading={heading} img={img} />
    <Row>
      <Column w={[1, 1, 0.5, 1 / 3]}>{leftContent}</Column>
      <Column w={[1, 1, 0.5, 2 / 3]}>
        <Stacked>
          {rightContent}
          <ImageRow images={imageSet} />
        </Stacked>
      </Column>
    </Row>
  </Stacked>
);

export const Template = () => (
  <Layout
    title="Title"
    heading="Heading"
    leftContent={
      <CopyAboveImage src={'http://via.placeholder.com/250x150'}>
        Enean et ligula congue, consequat orci sit amet, blandit lacus. Nam
        blandit libero sed turpis tempus, eu rhoncus urna viverra. Cras auctor
        ac magna a pharetra. Vestibulum venenatis sem vitae venenatis vehicula.
      </CopyAboveImage>
    }
    rightContent={
      <CopyRightOfImage src={'http://via.placeholder.com/200x200'}>
        enean et ligula congue, consequat orci sit amet, blandit lacus. Nam
        blandit libero sed turpis tempus, eu rhoncus urna viverra.
      </CopyRightOfImage>
    }
    imageSet={[
      `http://via.placeholder.com/100x100`,
      `http://via.placeholder.com/100x100`,
      `http://via.placeholder.com/100x100`
    ]}
  />
);

export const Page = () => (
  <Layout
    title="Title"
    heading="Heading"
    img={'https://picsum.photos/g/915/330/?random'}
    leftContent={
      <CopyAboveImage src={'https://picsum.photos/250/150/?random'}>
        Now indulgence dissimilar for his thoroughly has terminated. Agreement
        offending commanded my an. Change wholly say why eldest period. Are
        projection put celebrated particular unreserved joy unsatiable its. In
        then dare good am rose bred or. On am in nearer square wanted.
      </CopyAboveImage>
    }
    rightContent={
      <CopyRightOfImage src={'https://picsum.photos/200/200/?random'}>
        Arrived totally in as between private. Favour of so as on pretty though
        elinor direct. Reasonable estimating be alteration we themselves
        entreaties me of reasonably. Direct wished so be expect polite valley.
        Whose asked stand it sense no spoil to. Prudent you too his conduct
        feeling limited and. Side he lose paid as hope so face upon be. Goodness
        did suitable learning put.
      </CopyRightOfImage>
    }
    imageSet={[
      'https://picsum.photos/100/100/?random',
      'https://picsum.photos/100/100/?random',
      'https://picsum.photos/100/100/?random'
    ]}
  />
);
