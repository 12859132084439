import { css } from 'styled-components';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  px,
  styleComponent,
  getTypography
} from 'utils';

import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Checkbox);
const labelStyler = styleComponent(style);
const labelStyleCallback = (styles: any) => styles.Checkbox.Label;

const labelCss = (t: TThemeVariables) => css`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  padding-left: ${px(t.label.offset)};
  text-align: left;
  font-weight: normal;
  cursor: pointer;
  user-select: none;
  ${getTypography('Default')};
`;

export const label = labelStyler(labelCss, labelStyleCallback);
