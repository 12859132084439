import React from 'react';
import InputMask from 'react-input-mask';

export const InputMaskedBase: React.StatelessComponent<any> = ({
  isDisabled,
  isReadOnly,
  inputRef,
  mask,
  isRequired,
  isInvalid,
  guide,
  didUpdate,
  isSmall,
  alignText,
  labelledby,
  ...rest
}: any) => {
  const ariaProps = {
    'aria-required': false,
    'aria-invalid': false
  };
  if (labelledby !== null) ariaProps['aria-labelledby'] = labelledby;
  if (isRequired !== null) ariaProps['aria-required'] = isRequired;
  if (isInvalid !== null) ariaProps['aria-invalid'] = isInvalid;

  const Component = InputMask as any;

  return (
    <Component
      {...rest}
      {...ariaProps}
      alwaysShowMask={guide}
      type="text"
      mask={mask}
      ref={(input: any) => inputRef && input && inputRef(input.inputElement)}
      disabled={isDisabled}
      readOnly={isReadOnly}
    />
  );
};

InputMaskedBase.displayName = 'InputMaskedBase';
