import { identity } from 'rambda';
import { component } from 'builder';
import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  getColor,
  getTypography
} from 'utils';
import { ITypography } from './props';

const style = selectComponentThemeVariablesFor(identity);

const resetMargins = css`
  margin-top: 0;
  margin-bottom: 0;
`;

const BaseTitle = styled.h1([
  style(
    t =>
      css`
        ${getTypography('Title')} ${resetMargins};
      `
  )
]);

export const Title = component<ITypography>({
  name: 'Title',
  base: BaseTitle,
  props: {
    children: null
  }
});

export const headingStyle = css`
  ${getTypography('Heading')} ${resetMargins};
`;

const BaseHeading = styled.h2([style(t => headingStyle)]);

export const Heading = component<ITypography>({
  name: 'Heading',
  base: BaseHeading,
  props: {
    children: null
  }
});

const BaseSubHeading = styled.h3([
  style(
    t =>
      css`
        ${getTypography('SubHeading')} ${resetMargins};
      `
  )
]);

export const SubHeading = component<ITypography>({
  name: 'SubHeading',
  base: BaseSubHeading,
  props: {
    children: null
  }
});

const BaseLead = styled.p([
  style(
    t =>
      css`
        ${getTypography('Lead')} ${resetMargins};
      `
  )
]);

export const Lead = component<ITypography>({
  name: 'Lead',
  base: BaseLead,
  props: {
    children: null
  }
});

const BaseDefault = styled.span([
  style(
    t =>
      css`
        ${getTypography('Default')} ${resetMargins};
      `
  )
]);

export const Default = component<ITypography>({
  name: 'Default',
  base: BaseDefault,
  props: {
    children: null
  }
});

const BaseLabel = styled.span([
  style(
    t =>
      css`
        ${getTypography('Default')} ${resetMargins};
      `
  )
]);

export const Label = component<ITypography>({
  name: 'Label',
  base: BaseLabel,
  props: {
    children: null
  }
});

const BaseHint = styled.span([
  style(
    t =>
      css`
        ${getTypography('Hint')} ${resetMargins};
      `
  )
]);

export const Hint = component<ITypography>({
  name: 'Hint',
  base: BaseHint,
  props: {
    children: null
  }
});
