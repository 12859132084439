import React from 'react';
import { withTheme } from 'styled-components';
import {
  Row,
  Column,
  Hint,
  Separated,
  Stacked,
  Default,
  Icon
} from '@parmenion/library';

import introReadme from '../../../docs/icons/intro.md';
import { Markdown } from './markdown';
import { Heading } from '../ui';

const walkIcons = object => {
  return Object.keys(object).map(key => (
    <Row shrink>
      <Layout title={key}>
        <IconBox name={key} Component={Icon} />
      </Layout>
    </Row>
  ));
};

const IconBox = ({ name, Component }) => (
  <div style={styles.container}>
    <Column>
      <div style={styles.renderFrame}>
        <Component name={name} color={'darkest'} size={2} testID={name} />
      </div>
      <Heading>{name}</Heading>
    </Column>
  </div>
);

const Layout = props => (
  <Column shrink>
    <Row>{props.children}</Row>
  </Column>
);

export default withTheme(props => {
  const { theme } = props;

  return (
    <Separated>
      <Stacked>
        <Markdown source={introReadme} />
      </Stacked>
      <Stacked>
        <Layout title="List of default theme icons">
          {walkIcons(theme.icons)}
        </Layout>
      </Stacked>
    </Separated>
  );
});

const styles = {
  heading: {
    padding: '5px 0'
  },
  container: {
    width: '150px',
    padding: '5px',
    margin: '2px',
    border: '1px solid #ccc'
  },
  renderFrame: {
    border: '1px solid #ccc',
    backgroundColor: 'white',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 50px 50px',
    width: '100%',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
};
