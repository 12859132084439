import React from 'react';

type State = {
  isOpen: boolean;
};

export default (Component: React.ComponentType<any>) =>
  class extends React.PureComponent<any, State> {
    constructor(props: any) {
      super(props);
      this.state = { isOpen: false };
    }

    _isOpen = (isOpen: boolean) => this.setState({ isOpen });

    openDropdown = () => this._isOpen(true);
    closeDropdown = () => this._isOpen(false);
    toggleDropdown = () => this._isOpen(!this.state.isOpen);
    handlerOverlayClick = () => this.closeDropdown();

    render() {
      return (
        <Component
          {...this.props}
          isOpen={this.state.isOpen}
          openDropdown={this.openDropdown}
          closeDropdown={this.closeDropdown}
          toggleDropdown={this.toggleDropdown}
          handlerOverlayClick={this.handlerOverlayClick}
        />
      );
    }
  };
