import React from 'react';
import { map, addIndex } from 'rambda';
import { InputTel } from '../InputTel';
import { InputText } from '../InputText';
import { InputPassword } from '../InputPassword';
import { InputNumber } from '../InputNumber';
import { InputContainer, StaticCharacter } from './styles';
import { Box } from '../../core';
import { IPartDesc, trimSpaces } from './utils';
import { Label } from './label';

const componentTypeMap = {
  tel: InputTel,
  text: InputText,
  password: InputPassword,
  number: InputNumber
};

const mapWithIndex = addIndex(map);
const selectText = (event: React.SyntheticEvent<HTMLInputElement>) =>
  event.currentTarget.select();

const MaskedInputs = (props: any) => {
  let InputComponent = InputText;
  let inputCount: number = 0;
  if (componentTypeMap.hasOwnProperty(props.type))
    InputComponent = componentTypeMap[props.type];

  return (
    <Box horizontal vAlign="center" onPasteCapture={props.onPaste}>
      {mapWithIndex((maskPart: IPartDesc, index: number) => {
        const labelledby =
          props.labels && props.labels[inputCount]
            ? `label-${inputCount}`
            : props.labelledby;
        const inputvalue = props.values[index] || '';

        if (!maskPart.isInput)
          return <StaticCharacter key={index}>{maskPart.mask}</StaticCharacter>;
        return (
          <InputContainer
            key={index}
            styleProps={{
              isSmall: props.isSmall,
              maxLength: maskPart.maxLength,
              charWidth: props.inputDimensions.charWidth,
              padding: props.inputDimensions.padding
            }}
          >
            {props.labels && props.labels[inputCount] && (
              <Label id={`label-${inputCount}`}>
                {props.labels[inputCount]}
              </Label>
            )}
            <InputComponent
              type={props.type}
              pattern={props.pattern}
              inputMode={props.inputMode}
              inputRef={ref => {
                props.refs[index] = ref;
              }}
              value={inputvalue}
              alignText="center"
              maxLength={maskPart.maxLength.toString()}
              isDisabled={props.isDisabled}
              isSmall={props.isSmall}
              onBlur={props.onBlur}
              onFocus={selectText}
              onInput={value => {
                props.onChange(index, value, maskPart.maxLength);
              }}
              onKeyDown={e => props.onKeyDown(index, e)}
              didUpdate={props.didUpdate}
              labelledby={labelledby}
              isRequired={props.isRequired}
              isInvalid={props.isInvalid}
              name={`${props.name}[${inputCount++}]`}
            />
          </InputContainer>
        );
      }, props.mask)}
    </Box>
  );
};

export default MaskedInputs;
