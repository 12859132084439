import React, { Dispatch, SetStateAction } from 'react';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel
} from '@microsoft/signalr';
import {
  IMessageProps,
  IProgressContextProps,
  ITaskLogProgressContextProps
} from './props';
import { Row } from '../Row';
import { GaugeRadialChart } from '../GaugeRadialChart';
import { Spinner } from '../Spinner';

const TaskLogProgressContext = React.createContext<
  ITaskLogProgressContextProps
>({ apiUri: '', accessTokenFactory: () => '' });
export const TaskLogProgressProvider: React.FC<IProgressContextProps> = ({
  apiUri,
  accessTokenFactory,
  children
}) => (
  <TaskLogProgressContext.Provider value={{ apiUri, accessTokenFactory }}>
    {children}
  </TaskLogProgressContext.Provider>
);

const updateProgress = (
  setTotal: Dispatch<SetStateAction<number>>,
  setCompleted: Dispatch<SetStateAction<number>>,
  setStatus: Dispatch<SetStateAction<string>>
) => (msg: IMessageProps) => {
  setStatus(msg.name);
  setTotal(msg.total);
  setCompleted(msg.items);
};

const monitorProgress = (
  jobId: string,
  hub: HubConnection,
  setTotal: Dispatch<SetStateAction<number>>,
  setCompleted: Dispatch<SetStateAction<number>>,
  setStatus: Dispatch<SetStateAction<string>>,
  onComplete: () => {}
) => async () => {
  const update = updateProgress(setTotal, setCompleted, setStatus);
  hub.on('TaskProgressed', update);
  hub.on('TaskComplete', onComplete);
  update(await hub.invoke('WatchTask', jobId));
};

const startMonitoring = (
  taskLogApiUri: string,
  accessTokenFactory: () => string,
  jobId: string,
  setTotal: Dispatch<SetStateAction<number>>,
  setCompleted: Dispatch<SetStateAction<number>>,
  setStatus: Dispatch<SetStateAction<string>>,
  onComplete: () => {}
) => () => {
  const start = async (hub: HubConnection) => {
    const monitor = monitorProgress(
      jobId,
      hub,
      setTotal,
      setCompleted,
      setStatus,
      onComplete
    );
    await hub.start();
    monitor();
    hub.onreconnected(monitor);
  };

  const connection = new HubConnectionBuilder()
    .withUrl(taskLogApiUri + '/task-progress', { accessTokenFactory })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build();

  start(connection);

  return () => {
    if (connection) {
      connection.stop();
    }
  };
};

export default ({
  jobId,
  onComplete
}: {
  jobId: string;
  onComplete: () => {};
}) => {
  const { apiUri, accessTokenFactory } = React.useContext(
    TaskLogProgressContext
  );
  const [status, setStatus] = React.useState('Waiting for updates...');
  const [total, setTotal] = React.useState(0);
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(
    startMonitoring(
      apiUri,
      accessTokenFactory,
      jobId,
      setTotal,
      setCompleted,
      setStatus,
      onComplete
    ),
    []
  );

  return (
    <Row hAlign="center" spaceBetween={3} vAlign="center">
      {total > 0 ? (
        <GaugeRadialChart progress={String((completed / total) * 100)}>
          <p>
            {completed || 0}/{total || 0}
          </p>
        </GaugeRadialChart>
      ) : (
        <Spinner />
      )}
      <p>{status}</p>
    </Row>
  );
};
