import React from 'react';
import styled, { StyledFunction, css } from 'styled-components';
import { Box } from '../../core';
import { Icon } from '../../';
import humanFileSize from '../utils/fileSize';
import * as styles from '../style';

const ProgressBar = styled.div([styles.progress]);
ProgressBar.displayName = 'ProgressBar';
const Content = styled.div([styles.content]);
const Container = styled.div([styles.container]);
const Meta = styled.div([styles.meta]);
const Name = styled.div([styles.name]);
const Size = styled.div([styles.size]);
const Closer = styled.a([styles.closer]);
Closer.displayName = 'Closer';

const clickHandler = (fn: () => void) => (event: React.SyntheticEvent<any>) => {
  event.preventDefault();
  fn();
};

export default (props: any) => {
  const { file, onRemove, error = false, progress = 0 } = props;
  const { name, size }: { name: string; size: number } = file;
  return (
    <Box>
      <Container>
        <ProgressBar styleProps={{ error, progress }} />
        <Content>
          <Meta>
            <Name>{name}</Name>
            <Size>({humanFileSize(size)})</Size>
          </Meta>
          <Closer href="#" onClick={clickHandler(onRemove.bind(null, name))}>
            <Icon color={'darkest'} name="close" />
          </Closer>
        </Content>
      </Container>
    </Box>
  );
};
