import { addIndex, reduce } from 'rambda';

export interface EventWithClipBoard extends Event {
  clipboardData: any;
}

const reduceWithIndex = addIndex(reduce);

const lazyEqualityCheck = (a: any, b: any): boolean =>
  a != null && b != null && a.toString() === b.toString();

export interface IPartDesc {
  mask: any;
  maxLength: number;
  isInput: boolean;
}

export const groupInputsByMaskType = (mask: Array<any>): Array<IPartDesc> => {
  const fieldDesc: any[] = [];
  let lastPart: any = null;
  let index: number = 0;
  mask.reduce((accum: any, part: any) => {
    if (lazyEqualityCheck(part, lastPart)) {
      // if the next part is the same as the last one, then update the last one
      const lastIndex = index - 1;
      const previousPart = accum[lastIndex];
      accum[lastIndex] = {
        ...previousPart,
        maxLength: previousPart.maxLength + 1
      };
    }

    if (!lazyEqualityCheck(part, lastPart)) {
      accum.push(addPartDescription(part));
      lastPart = part; // keep updating lastPart to the one we have just processed
      index++;
    }
    return accum;
  }, fieldDesc);

  return fieldDesc;
};

export const addPartDescription = (part: any, maxLength = 1): IPartDesc => ({
  mask: part,
  isInput: typeof part !== 'string',
  maxLength: 1
});

export const addPartDescriptionsToMask = (mask: Array<any>) =>
  mask.map((part: any) => addPartDescription(part));

export const trimSpaces = (value: string) => value && value.replace(/ /g, '');

export const isInValidCharacter = (character: any, part: IPartDesc) => {
  return character.split('').reduce((isInValid: boolean, char: string) => {
    if (!isInValid) {
      const withoutSpaces = trimSpaces(char);
      return !withoutSpaces.match(part.mask) && withoutSpaces !== '';
    }
    return isInValid;
  }, false);
};

export const getNextValidIndex = (
  index: number,
  character: any,
  mask: any
): number => {
  const part = mask[index];
  const nextIndex = index + part.maxLength;
  if (!isInValidCharacter(character, part)) {
    return nextIndex;
  }
  return getNextValidIndex(nextIndex + 1, character, mask);
};

export const selectCharacter = (input: any) => setTimeout(() => input.select());

export const invokeOnComplete = (callback: any) =>
  setTimeout(() => {
    if (typeof callback === 'function') {
      callback();
    }
  });

export const focusNextInput = (index: any, inputs: any, mask: any) => {
  for (let i = index + 1; i < mask.length; i++) {
    if (inputs[i]) {
      inputs[i].focus();
      break;
    }
  }
};

export const onPaste = (
  inputs: Array<HTMLInputElement>,
  pasteHandler: (e: Event) => void
) => {
  inputs.forEach(input => (input.onpaste = pasteHandler));
};

export const focusPreviousInput = (index: any, inputs: any) => {
  for (let i = index - 1; i >= 0; i--) {
    if (inputs[i]) {
      inputs[i].focus();
      break;
    }
  }
};

export const canDelete = (currentCharacter: any, key: any) =>
  (trimSpaces(currentCharacter) === '' || currentCharacter == null) &&
  key === 'Backspace';

export const blurCurrentInput = (currentInput: any, canBlur: any) =>
  canBlur && currentInput.blur();

export const fillBlanksInCharacters = (
  characters: string,
  mask: Array<IPartDesc>
) => {
  const filledChars = characters.split('').concat([]);
  const currentChars = characters.split('').concat([]);
  let lastPostion = 0;
  mask.forEach((part, index: number) => {
    // are there chars enough to fill the mask?
    const existingChars = currentChars.slice(index, index + part.maxLength);
    const charsToFill = part.maxLength - existingChars.length;
    if (charsToFill >= 0) {
      for (let i = 0; i < charsToFill; i++) {
        filledChars.push(' ');
      }
    }
  });
  return filledChars.join('');
};

export const convertStringToMaskValues = (
  mask: Array<IPartDesc>,
  characters: any
) => {
  let charsToChunk = characters.split('');
  const maskValues: Array<string> = [];
  let lastPostion: number = 0;
  mask.forEach((part: IPartDesc, index: number) => {
    const chunk = charsToChunk
      .slice(lastPostion, lastPostion + part.maxLength)
      .join('');

    maskValues[index] = trimSpaces(chunk);
    lastPostion += part.maxLength;
  });
  return maskValues;
};

interface IStringWithPad extends String {
  padEnd: (length: number) => string;
}

const padValue = (value: IStringWithPad, length: number) =>
  value.length === length ? value : value.padEnd(length);

export const convertMaskvaluesToString = (
  mask: Array<IPartDesc>,
  maskValues: any
) => {
  return reduceWithIndex(
    (accum: any, part: IPartDesc, index: any) => {
      const value = maskValues[index];
      accum += padValue(value, part.maxLength);
      return accum;
    },
    '',
    mask
  );
};

export const hasCorrectNumberOfChars = (
  chunks: Array<string>,
  mask: Array<IPartDesc>
) => {
  let count: number = 0;
  let expectedLength: number = 0;

  chunks.forEach((chunk: string, index: number) => {
    const { maxLength } = mask[index];
    expectedLength += maxLength;
    if (chunk.split('').length === maxLength) {
      count += maxLength;
    }
  });

  return count === expectedLength;
};

interface IPastedValues {
  canPaste: boolean;
  allowedPastedValues: { [key: number]: string };
}

export const getPastedValues = (
  e: EventWithClipBoard,
  mask: Array<IPartDesc>
): IPastedValues => {
  let canPaste: boolean = false;
  const pastedValues = e.clipboardData.getData('text').split('');
  let allowedPastedValues = {};
  let lastPosition: number = 0;

  canPaste = mask.reduce((isValid: boolean, part: IPartDesc, index: number) => {
    if (isValid) {
      const chunk = pastedValues
        .concat([])
        .slice(lastPosition, lastPosition + part.maxLength)
        .join('');

      lastPosition += part.maxLength;

      if (!isInValidCharacter(chunk, part)) {
        allowedPastedValues[index] = chunk;
        return true;
      } else {
        return false;
      }
    }
    return isValid;
  }, true);

  if (canPaste) {
    const pastedChunks = Object.keys(allowedPastedValues).map(
      key => allowedPastedValues[key]
    );
    canPaste = hasCorrectNumberOfChars(pastedChunks, mask);
  }

  return { canPaste, allowedPastedValues };
};
