import { component } from 'builder';
import { default as TaskLogProgress, TaskLogProgressProvider } from './base';
import { IProgressContextProps } from './props';

export const ProgressProvider = component<IProgressContextProps>({
  name: 'ProgressProvider',
  base: TaskLogProgressProvider,
  props: {
    apiUri: null,
    accessTokenFactory: null,
    children: null
  }
});

export const Progress = component<{
  jobId: string;
  onComplete: () => {};
}>({
  name: 'Progress',
  base: TaskLogProgress,
  props: {
    jobId: null,
    onComplete: null
  }
});
