import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const table = (colors: IColorsType, bounds: IBoundsType) => ({
  primary: colors.primary,
  inverted: {
    color: colors.lightest,
    borderColor: colors.neutrals.normal,
    hoverColor: tc(colors.lightest)
      .setAlpha(0.1)
      .toString()
  },
  standard: {
    color: colors.darkest,
    borderColor: colors.neutrals.light,
    hoverColor: colors.neutrals.lighter
  }
});
