import React from 'react';
import styled from 'styled-components';
import {
  successStyle,
  dangerStyle,
  warningStyle,
  infoStyle,
  defaultStyle
} from './style';

const getStyledComponent = (style: any) => styled.span([style]);

const TAG_TYPE_MAP = {
  success: getStyledComponent(successStyle),
  danger: getStyledComponent(dangerStyle),
  warning: getStyledComponent(warningStyle),
  info: getStyledComponent(infoStyle),
  default: getStyledComponent(defaultStyle)
};

export default (props: any) => {
  const { type, children, ...rest } = props;
  const Wrapper = TAG_TYPE_MAP.hasOwnProperty(type)
    ? TAG_TYPE_MAP[type]
    : TAG_TYPE_MAP['default'];

  return <Wrapper {...rest}>{children}</Wrapper>;
};
