import { component } from 'builder';
import Base, {
  PreferencesCookieName as CookieName,
  currentPreferences
} from './base';
import ReactDOM from 'react-dom';
import React from 'react';
import { ThemeProvider, theme as defaultTheme } from '../../';
import Cookies from 'js-cookie';

export const PreferencesCookieName = CookieName;

export const getCurrentCookiePreferences = currentPreferences;

export const addCookieBanner = (
  theme: any = {},
  zIndex = 1000,
  cookiePolicyUrl = '',
  privacyPolicyUrl = ''
) => {
  const host = window as any;
  Cookies.remove('hidecookieclause');

  if (!host.parmenionCookiePreferences) {
    ReactDOM.render(
      <ThemeProvider
        theme={{
          ...defaultTheme,
          ...theme
        }}
      >
        <Base
          cookiePolicyUrl={cookiePolicyUrl}
          privacyPolicyUrl={privacyPolicyUrl}
          zIndex={zIndex}
          ref={c => {
            host.parmenionCookiePreferences = c;
          }}
        />
      </ThemeProvider>,
      document.body.appendChild(document.createElement('div'))
    );
  }

  return host.parmenionCookiePreferences;
};

export const manageCookiePreferences = () => {
  const host = window as any;

  if (!host.parmenionCookiePreferences) {
    throw new Error('Parmenion.addCookieBanner has not been called');
  }

  host.parmenionCookiePreferences.openManagePreferences();
};

export interface IProps {
  background?: string;
  zIndex?: number;
  cookiePolicyUrl: string;
  privacyPolicyUrl: string;
}

export const CookieManagement = component<IProps>({
  name: 'cookie management',
  base: Base,
  props: {
    background: null,
    zIndex: 1000,
    cookiePolicyUrl: null,
    privacyPolicyUrl: null
  }
});
