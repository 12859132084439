import styled, { css } from 'styled-components';

import {
  getTypography,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Field);
const helpStyler = styleComponent(style);
const helpStylerCallback = (styles: any) => styles.Form.Field.HelpText;

const helpCss = (t: TThemeVariables) => css`
  color: ${props => t.help.textColor};
  ${getTypography('Hint')};
`;

export const HelpText = styled.span([helpStyler(helpCss, helpStylerCallback)]);
HelpText.displayName = 'HelpText';
