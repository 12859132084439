import React from 'react';

import {
  InnerInputGroup,
  InputContainer,
  AddonString,
  AddonButton
} from './style';

const wrapChildren = (children: React.ReactNode) =>
  React.Children.map(children, (input: any) => (
    <InputContainer key={input.props && input.props.id}>{input}</InputContainer>
  ));

const isString = (element: any) => typeof element === 'string';

const getAddOnComponent = (component: any) =>
  isString(component) ||
  (component && component.type && component.type.displayName === 'Icon')
    ? AddonString
    : AddonButton;

const eventTypeMap = {
  focus: true,
  blur: false
};

class WithEvents extends React.Component<
  {
    children: (props: any) => {};
  },
  any
> {
  constructor(props: any) {
    super(props);
    this.state = { childHasFocus: false };
  }

  updateFocus = (e: React.SyntheticEvent) => {
    // on accept focus/blur events
    if (eventTypeMap.hasOwnProperty(e.type))
      this.setState({ childHasFocus: eventTypeMap[e.type] });
  };

  render() {
    return this.props.children
      ? this.props.children({
          childHasFocus: this.state.childHasFocus,
          onFocus: this.updateFocus,
          onBlur: this.updateFocus
        })
      : null;
  }
}

export default ({
  start,
  end,
  children,
  shouldShowFocus,
  width,
  ...rest
}: any) => {
  const Start = getAddOnComponent(start);
  const End = getAddOnComponent(end);
  const hasSingleChild = React.Children.count(children) === 1;
  const showFocus = shouldShowFocus && hasSingleChild;
  return (
    <WithEvents>
      {(renderProps: any) => (
        <InnerInputGroup
          onFocus={renderProps.onFocus}
          onBlur={renderProps.onBlur}
          styleProps={{
            childHasFocus: showFocus && renderProps.childHasFocus,
            width
          }}
          {...rest}
        >
          {start && <Start>{start}</Start>}
          {children && wrapChildren(children)}
          {end && <End>{end}</End>}
        </InnerInputGroup>
      )}
    </WithEvents>
  );
};
