import { css } from 'styled-components';
import { TThemeVariables } from './types';

import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  px,
  styleComponent
} from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.Menu);

const menuItemStyler = styleComponent(style);

const themeStyleCallback = (styles: any) => styles.Menu.MenuItem;

const menuItemCss = (t: TThemeVariables, { isActive }: any) => css`
  > * {
    display: flex;
    width: 100%;
    padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
    border-bottom: ${px(t.link.borderSize)} solid ${t.link.borderColor};
    color: ${t.link.color};
    background-color: ${t.link.backgroundColor};
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    transition: transform 150ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    &:hover,
    &:focus {
      background-color: ${isActive
        ? t.link.states.hoverActive.backgroundColor
        : t.link.states.focus.backgroundColor};
    }

    &:active {
      transform: scale(0.99);
      transform-origin: center bottom;
    }

    ${isActive &&
      css`
        color: ${t.link.states.active.color};
        border-bottom-color: ${t.link.states.active.borderColor};
        background-color: ${t.link.states.active.backgroundColor};
      `};
  }
`;

export const menuItemStyle = menuItemStyler(
  menuItemCss,
  themeStyleCallback
) as any;
