import { component } from 'builder';
import { IRadioCheckboxProps } from '../core/RadioCheckboxButton/props';
import { addEvents } from '../core/events';
import { defaultProps } from '../core/RadioCheckboxButton/props';
import { wrapperStyle } from '../core/RadioCheckboxButton/styles';
import { CheckboxBase } from './base';

const CheckboxWithEvents = addEvents(CheckboxBase);

export const CheckboxButton = component<IRadioCheckboxProps>({
  name: 'CheckboxButton',
  base: CheckboxWithEvents,
  container: 'div',
  style: wrapperStyle,
  props: defaultProps
});
