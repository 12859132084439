import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

const errorColorScheme = (color: string) => ({
  backgroundColor: tc(color)
    .lighten(40)
    .toString(),
  borderColor: tc(color)
    .lighten(30)
    .toString(),
  arrowColor: tc(color)
    .lighten(30)
    .toString(),
  fillColor: tc(color)
    .darken(20)
    .toString(),
  textColor: tc(color)
    .darken(20)
    .toString()
});

const colorScheme = (color: string) => ({
  backgroundColor: tc(color)
    .lighten(30)
    .toString(),
  borderColor: tc(color)
    .lighten(20)
    .toString(),
  arrowColor: tc(color)
    .lighten(20)
    .toString(),
  fillColor: tc(color)
    .darken(30)
    .toString(),
  textColor: tc(color)
    .darken(30)
    .toString()
});

export const alert = (colors: IColorsType, bounds: IBoundsType) => ({
  borderRadius: bounds.border.radius,
  borderSize: bounds.border.size,
  default: {
    borderColor: colors.neutrals.lighter,
    backgroundColor: colors.neutrals.lighter,
    textColor: colors.neutrals.darker,
    arrowColor: colors.neutrals.lighter,
    fillColor: colors.neutrals.lighter
  },
  success: colorScheme(colors.success),
  warning: colorScheme(colors.warning),
  info: colorScheme(colors.informational),
  danger: errorColorScheme(colors.error)
});
