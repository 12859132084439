import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
import { IContainersType } from '../core/containers';
import { ILayoutType } from '../core/layout';

export const nativeSelect = (colors: IColorsType, bounds: IBoundsType) => ({
  outlineColor: colors.auxillary,
  outlineSize: bounds.outline.size,
  borderRadius: bounds.border.radius,
  borderSize: bounds.border.size,
  textColor: colors.neutrals.normal,
  backgroundColor: colors.lightest,
  borderColor: colors.neutrals.light,

  status: {
    focus: {
      borderColor: colors.auxillary
    },
    disabled: {
      backgroundColor: colors.neutrals.lighter
    }
  }
});
