import tc from 'tinycolor2';
import { IColorsType } from '../../core/colors';

export const transparentButton = (colors: IColorsType) => ({
  textColor: colors.neutrals.darker,
  backgroundColor: tc(colors.lightest)
    .setAlpha(0.15)
    .toString(),
  borderColor: 'transparent',

  status: {
    focus: {
      backgroundColor: tc(colors.lightest)
        .darken(10)
        .setAlpha(0.15)
        .toString()
    },
    hover: {
      backgroundColor: tc(colors.lightest)
        .darken(10)
        .setAlpha(0.15)
        .toString()
    },
    active: {
      backgroundColor: tc(colors.lightest)
        .darken(10)
        .setAlpha(0.15)
        .toString(),
      outline: 'none'
    },
    disabled: {
      backgroundColor: tc(colors.lightest)
        .setAlpha(0.15)
        .toString()
    },
    hoverWhenActive: {
      backgroundColor: tc(colors.lightest)
        .darken(17)
        .setAlpha(0.15)
        .toString()
    }
  }
});
