import tc from 'tinycolor2';
import { css } from 'styled-components';
import { getBoxShadow } from 'utils';
import { baseStyle } from './base';
import isActiveMixin from './active';
import { IButtonBuilderTheme } from 'theme/components/buttons/builder';

export default (style: any) =>
  style(
    (t: IButtonBuilderTheme, { isActive }: any) => css`
      ${baseStyle} color: ${t.textColor};
      background-color: ${t.backgroundColor};
      border-color: ${tc(t.backgroundColor)
        .darken(10)
        .toString()};

      &:focus {
        color: ${t.status.focus.color};
        background-color: ${t.status.focus.backgroundColor};
        border-color: ${t.status.focus.borderColor};
        outline: ${t.status.focus.outlineColor};
      }

      &:hover {
        color: ${t.status.hover.color};
        background-color: ${t.status.hover.backgroundColor};
        border-color: ${t.status.hover.borderColor};
        outline: ${t.status.hover.outlineColor};
      }

      &:active {
        color: ${t.status.active.color};
        background-color: ${t.status.active.backgroundColor};
        border-color: ${t.status.active.borderColor};
        outline: none;

        &:hover,
        &:focus {
          background-color: ${t.status.hoverWhenActive.backgroundColor};
          border-color: ${t.status.hoverWhenActive.borderColor};
          box-shadow: ${getBoxShadow('large')};
        }
      }
      ${isActive && isActiveMixin(t.status.active)};
    `
  );
