import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme);
const iconStyler = styleComponent(style);

const iconStyle = (t: TThemeVariables, isSmall: any) => css`
  padding: 0 ${isSmall ? paddingHorizontal(0) : paddingHorizontal(1)} 0 0;
`;

const themeStyleCallback = (styles: any) => styles.Alert.Icon;

export default styled.div([iconStyler(iconStyle, themeStyleCallback)]);
