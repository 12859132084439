import { component } from 'builder';
import { IAutoCompleteProps } from './props';
import Base from './base';

export const AutoComplete = component<IAutoCompleteProps>({
  name: 'AutoComplete',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    isClearable: null,
    placeholder: null,
    items: [],
    value: null,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    isSmall: false,
    isOpen: false,
    isActive: false,
    isDisabled: false
  }
});
