import {
  IInputTextProps,
  defaultProps as props
} from '../core/TextInput/props';

export interface IInputDateTimeLocal extends IInputTextProps {
  type?: 'datetime-local';
  min?: string | null;
  max?: string | null;
}

export const defaultProps: IInputDateTimeLocal = {
  ...props,
  type: 'datetime-local',
  min: null,
  max: null
};
