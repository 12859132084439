import { ITheme } from '../theme';

type IThemeStyles = ITheme['styles'];

type ComponentThemeVariablesStyler<ComponentThemeType> = {
  (styleCallback: (props: any) => any): string;
};

type StyleComponentCss = (props: any) => string | null;

export function selectComponentThemeStylesFor<InferredComponentThemeType>(
  styledComponentSelector: (styles: IThemeStyles) => StyleComponentCss
): any {
  return () => (props: any) => {
    const styledComponent = styledComponentSelector(props.theme.styles);
    const { styleProps, ...rest } = props;
    return styledComponent != null
      ? styledComponent.bind(styledComponent, { ...styleProps, ...rest })
      : null; // fallback;
  };
}
