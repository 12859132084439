import React from 'react';
import { Box } from '../../../core';
import { FormFieldVertical } from '../vertical-field';
import { FormFieldHorizontal } from '../horizontal-field';

const fieldMap = {
  FormFieldVertical,
  FormFieldHorizontal
};

const containerPropsMap = {
  FormFieldHorizontal: { horizontal: true },
  FormFieldVertical: { stacked: true }
};

const getDisplayName = (child: any) => child.type.displayName;

export const FormGroupBase: React.StatelessComponent<any> = ({
  children,
  label,
  ...rest
}: any) => {
  const ChildrenArray = React.Children.toArray(children);
  const displayName = getDisplayName(ChildrenArray[0]) || 'FormFieldHorizontal';
  const Field = fieldMap[displayName];
  const props = containerPropsMap[displayName];
  return (
    <Field label={label} {...rest}>
      <Box {...props}>{children}</Box>
    </Field>
  );
};

FormGroupBase.displayName = 'FormGroupBase';
