import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../';
import {
  wrapperStyle,
  iconStyle,
  containerStyle,
  titleStyle,
  subtitleStyle
} from './style';

const Wrapper = styled.div([wrapperStyle]);
const IconWrapper = styled.div([iconStyle]);
const Container = styled.div([containerStyle]);
const Title = styled.h6([titleStyle]);
const SubTitle = styled.div([subtitleStyle]);

const iconMap = {
  success: () => <Icon name="check" />,
  info: () => <Icon name="infoCircle" />
};

const IconBase = ({ type = 'info' }) => {
  const ThisIcon = iconMap[type];
  return (
    <IconWrapper>
      <ThisIcon />
    </IconWrapper>
  );
};
export default ({ title, subtitle, type, ...rest }: any) => {
  return (
    <Wrapper styleProps={{ type }} {...rest}>
      <Container>
        {title && (
          <>
            <IconBase type={type} />
            <Title styleProps={{ type }}>{title}</Title>
          </>
        )}
        {subtitle && <SubTitle styleProps={{ type }}>{subtitle}</SubTitle>}
      </Container>
    </Wrapper>
  );
};
