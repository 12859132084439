import invariant from 'invariant';
import React from 'react';
import styled from 'styled-components';
import { withTheme } from 'styled-components';
import { iconStyle } from './style';

export const StyledIcon = styled.span([iconStyle]);

const Base = ({
  Container,
  name,
  size,
  color,
  stroke,
  strokeWidth,
  strokeLineJoin,
  className,
  padding,
  ...rest
}: any) => {
  const iconMap = rest.iconMap != null ? rest.iconMap : rest.theme.icons;
  const nameSpaceExists = iconMap.hasOwnProperty(name);
  invariant(
    nameSpaceExists,
    `component(): Icon with '${name}' is missing in the iconMap provided`
  );
  return (
    <StyledIcon
      {...rest}
      styleProps={{ size, color, stroke, strokeWidth, strokeLineJoin, padding }}
    >
      {iconMap[name]}
    </StyledIcon>
  );
};
export const IconBase = withTheme(Base);

export const iconFactoryBase = (iconMap: any) => (props: any) => (
  <IconBase iconMap={iconMap} {...props} />
);
