import { component } from 'builder';

import { container, menu, item, link } from './styles';

import {
  IBarProps,
  IBarMenuProps,
  IBarMenuItemProps,
  IBarMenuItemDropDownProps
} from './props';

import {
  BarBase,
  BarMenuBase,
  BarMenuItemBase,
  BarMenuItemDropdownBase
} from './base';

export const Bar = component<IBarProps>({
  name: 'Bar',
  base: BarBase,
  container: 'nav',
  style: container,
  props: {
    children: null
  }
});

export const BarMenu = component<IBarMenuProps>({
  name: 'BarMenu',
  base: BarMenuBase,
  container: 'ul',
  canOverrideContainer: false,
  style: menu,
  props: {
    children: null,
    hAlign: 'left'
  },
  styleProps: ['hAlign']
});

export const BarMenuItem = component<IBarMenuItemProps>({
  name: 'BarMenuItem',
  base: BarMenuItemBase,
  container: 'a',
  canOverrideContainer: true,
  style: link,
  props: {
    children: null,
    isActive: false
  }
});

export const BarMenuItemDropdown = component<IBarMenuItemDropDownProps>({
  name: 'BarMenuItemDropdown',
  base: BarMenuItemDropdownBase,
  props: {
    children: null,
    menu: null,
    isHover: false,
    trigger: null,
    isOpen: false,
    isRight: false,
    toggleDropdown: () => {},
    closeDropdown: () => {},
    openDropdown: () => {},
    handlerOverlayClick: () => {}
  }
});
