import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  px,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Bar);
const linkStyler = styleComponent(style);

const linkCss = (t: TThemeVariables) => css`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  height: ${px(t.container.height)};
  padding: 0 ${paddingHorizontal(1)};
  color: ${t.link.color};
  text-decoration: none;
  transition: transform 150ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  &:active {
    transform: scale(0.99) rotateX(15deg);
    transform-origin: center bottom;
  }

  &:hover,
  &:focus {
    background: ${t.link.status.active.backgroundColor};
    color: ${t.link.status.active.color};
    text-decoration: inherit;
  }
`;

const themeStyleCallback = (styles: any) => styles.Bar.Link;

export const link = linkStyler(linkCss, themeStyleCallback);
