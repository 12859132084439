const paddingHorizontal = [10, 16, 20];
const paddingVertical = [5, 10, 15];
const spacing = [0, 10, 20, 40];

export const layout = {
  paddingHorizontal,
  paddingVertical,
  spacing
};

export type ILayoutType = typeof layout;
