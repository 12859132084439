import tc from 'tinycolor2';
import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  styleComponent,
  paddingHorizontal,
  paddingVertical,
  getBoxShadow,
  px,
  getTypography
} from 'utils';

import { ITheme } from '../../../../theme';
type TThemeVariables = ITheme['components']['Button'];

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Button
);

const themeStyleCallback = (styles: any) => styles.Button;
const buttonStyler = styleComponent(withComponentTheme);

const pressAnimationMixin = () => css`
  transform-origin: center bottom;
  touch-action: manipulation;
  user-select: none;

  &:active {
    transform: scale(0.99) rotateX(8deg);
  }
`;

const isActiveMixin = (
  isActive: boolean,
  { borderColor, backgroundColor }: any
) =>
  isActive
    ? css`
        background-color: ${backgroundColor};
        border-color: ${borderColor};
        outline: none;
      `
    : css``;

const paddingMixin = (isSmall: boolean) =>
  isSmall
    ? css`
        ${paddingVertical(0)} ${paddingHorizontal(0)};
      `
    : css`
        ${paddingVertical(1)} ${paddingHorizontal(1)};
      `;

const wrapTextMixin = (noWrap: boolean) =>
  noWrap
    ? css`
        white-space: nowrap;
      `
    : 'white-space: normal';

const baseCss = (
  t: TThemeVariables,
  {
    isActive,
    isSmall,
    noWrap
  }: { isActive: boolean; isSmall: boolean; noWrap: boolean }
) => css`
  position: relative;
  color: ${t.textColor};
  border: ${px(t.borderSize)} solid
    ${tc(t.borderColor)
      .darken(10)
      .toString()};
  background-color: ${t.backgroundColor};
  box-shadow: ${getBoxShadow('normal')};
  padding: ${paddingMixin(isSmall)};
  display: inline-block;
  ${getTypography('Default')};
  border-radius: ${px(t.borderRadius)};
  background-image: none;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  align-self: flex-start;

  ${wrapTextMixin(noWrap)};

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
  }

  &:focus {
    background-color: ${t.status.focus.backgroundColor};
    border-color: ${t.status.focus.borderColor};
    outline: ${t.status.focus.outlineColor};
  }

  &:hover {
    background-color: ${t.status.hover.backgroundColor};
    border-color: ${t.status.hover.borderColor};
  }

  &:active {
    background-color: ${t.status.active.backgroundColor};
    border-color: ${t.status.active.borderColor};
    outline: none;

    &:hover,
    &:focus {
      background-color: ${t.status.hoverWhenActive.backgroundColor};
      border-color: ${t.status.hoverWhenActive.borderColor};
    }
  }
  ${isActiveMixin(isActive, t.status.active)};
  ${pressAnimationMixin()};
`;

export const baseStyle = buttonStyler(baseCss, themeStyleCallback);
