import styled, { css, keyframes } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  px,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Details);
const styler = styleComponent(style);
const bodyStyleCallback = (styles: any) => styles.Details.Body;
const wrapperStyleCallback = (styles: any) => styles.Details.Wrapper;
const summaryStyleCallback = (styles: any) => styles.Details.Summary;

const DETAILS_OFFSET = 10;

const detailsTransition = keyframes`
  0% {
    transform: scaleY(0);
    opacity: 0;
    zoom: 0.8;
  }

  100% {
    transform: scaleY(1);
    opacity: 1;
    zoom: 1;
  }
`;

const wrapperCss = (t: TThemeVariables) => css`
  position: relative;
  &[open] > :last-child {
    animation-name: ${detailsTransition};
    animation-duration: 0.2s;
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

const bodyCss = (t: TThemeVariables) => css`
  ${getTypography('Default')};
  transform-origin: left top 0;
  border-left: ${px(t.body && t.body.borderSize ? t.body.borderSize : 2)} solid
    ${t.body && t.body.borderColor ? t.body.borderColor : 'darkgrey'};
  padding: ${px(DETAILS_OFFSET * 2)};
`;

const summaryCss = (t: TThemeVariables) => css`
  cursor: pointer;
  text-decoration: underline;
  user-select: none;
  ${getTypography('Default')};
  &:focus {
    display: inline;
    padding: 0 ${px(DETAILS_OFFSET)};
    margin-left: ${px(DETAILS_OFFSET * -1)};
    outline: ${px(
        t.summary && t.summary.outlineSize ? t.summary.outlineSize : 0
      )}
      solid
      ${t.summary && t.summary.outlineColor ? t.summary.outlineColor : 'black'};
  }
`;

export const detailsStyle = styler(wrapperCss, wrapperStyleCallback);
export const bodyStyle = styler(bodyCss, bodyStyleCallback);
export const summaryStyle = styler(summaryCss, summaryStyleCallback);
