import styled, { css } from 'styled-components';
import { px, selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Separated);
const separatedStyler = styleComponent(style);

const lineStyleCss = (t: TThemeVariables) => css`
  border-bottom: ${px(t.borderSize)} solid ${t.borderColor};
`;

const lineStyleCallback = (styles: any) => styles.Separated.Line;
export const lineStyle = separatedStyler(lineStyleCss, lineStyleCallback);

const wrapperStyleCss = (t: TThemeVariables) => css`
  background-color: inherit;
`;

const wrapperStyleCallback = (styles: any) => styles.Separated.Wrapper;
export const wrapperStyle = separatedStyler(
  wrapperStyleCss,
  wrapperStyleCallback
);
