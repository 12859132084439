import { css } from 'styled-components';
import {
  px,
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Tag);
const tagStyler = styleComponent(style);
const styleCallback = (styles: any) => styles.Tag;

const defaultStyleCss = (t: TThemeVariables) => css`
  padding: ${paddingVertical(0)} ${paddingHorizontal(0)};
  color: ${t.textColor};
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  ${getTypography('Default')};
  border-radius: ${px(t.borderRadius)};
  background-color: ${t.backgroundColor};
  &:empty {
    display: none;
  }
`;

export const defaultStyle = tagStyler(defaultStyleCss, styleCallback);

const successStyleCss = (t: TThemeVariables) => css`
  ${defaultStyle} background-color: ${t.success.backgroundColor};
`;

export const successStyle = tagStyler(successStyleCss, styleCallback);

const dangerStyleCss = (t: TThemeVariables) => css`
  ${defaultStyle} background-color: ${t.error.backgroundColor};
`;

export const dangerStyle = tagStyler(dangerStyleCss, styleCallback);

const warningStyleCss = (t: TThemeVariables) => css`
  ${defaultStyle} background-color: ${t.warning.backgroundColor};
`;

export const warningStyle = tagStyler(warningStyleCss, styleCallback);

const infoStyleCss = (t: TThemeVariables) => css`
  ${defaultStyle}background-color: ${t.informational.backgroundColor};
`;

export const infoStyle = tagStyler(infoStyleCss, styleCallback);
