import React from 'react';
import { Box } from '../core';
import styled from 'styled-components';

import { wrapperStyle, containerStyle, listStyle } from './style';

const Wrapper = styled.li([wrapperStyle]);

export const NavItemBase = (props: any) => {
  const { Container, label, ...rest } = props;
  return (
    <Wrapper>
      <Container {...rest}>{label}</Container>
    </Wrapper>
  );
};

const Container = styled.nav([containerStyle]);
const List = styled.ol([listStyle]);

export const NavBase = (props: any) => {
  const { children, ...rest } = props;
  return (
    <Container {...rest}>
      <List>{props.children}</List>
    </Container>
  );
};
