import { css } from 'styled-components';
import { ITheme } from 'theme';

import {
  selectComponentThemeVariablesFor,
  getBoxShadow,
  px,
  em,
  getTypography,
  styleComponent
} from 'utils';
import { ISelectProps } from './props';
type ComponentTheme = ITheme['components']['Select'];

interface IReactSelectState {
  data: any;
  isDisabled: boolean;
  isFocused: boolean;
  isSelected: boolean;
}

const style = selectComponentThemeVariablesFor(theme => theme.Select);
const selectStyler = styleComponent(style);

const defaultStyle = (t: ComponentTheme, isSmall: boolean) => css`
  padding: ${isSmall
    ? `${px(t.selectControl.small.paddingVertical)} ${px(
        t.selectControl.small.paddingHorizontal
      )}`
    : `${px(t.selectControl.paddingVertical)} ${px(
        t.selectControl.paddingHorizontal
      )}`};
  > input {
    ${getTypography('Default')};
  }
`;

const optionCustomStyles = (
  t: ComponentTheme,
  isSmall?: boolean,
  isActive?: boolean
) => css`
  ${getTypography('Default')}

  padding: ${
    isSmall
      ? `${px(t.selectControl.small.paddingVertical)} ${px(
          t.selectControl.small.paddingHorizontal
        )}`
      : `${px(t.selectControl.paddingVertical)} ${px(
          t.selectControl.paddingHorizontal
        )}`
  };

  border-bottom: ${px(t.borderSize)} solid ${t.options.active.borderColor};
  color: ${t.textColor};
  background-color: ${t.options.backgroundColor};

  cursor: pointer;
  user-select: none;
  &:hover,
  &:focus {
    background-color: ${
      isActive
        ? t.options.activeHover.backgroundColor
        : t.options.focus.backgroundColor
    };
    border-bottom: ${px(t.borderSize)} solid ${t.options.active.borderColor};
  }
  &:last-child {
    border-radius: ${t.borderRadius};
    border-bottom: none;
  }
`;

const menuCustomStyles = (t: ComponentTheme) => css`
  border: ${px(t.borderSize)} solid ${t.selectControl.borderColor};
  border-radius: ${px(t.borderRadius)};
  border-top: 0;
  margin-top: 0;
  padding: 0;
`;

const controlCustomStyles = (t: ComponentTheme, isSmall: boolean) => css`
    ${getTypography('Default')}
    font-size: ${
      isSmall
        ? em(t.selectControl.small.fontSize)
        : em(t.selectControl.fontSize)
    }

    color: ${t.selectControl.textColor};
    background-color: ${t.selectControl.backgroundColor};

    border: ${px(t.borderSize)} solid ${t.selectControl.borderColor};
    box-shadow: ${getBoxShadow('input')};
    border-radius: ${px(t.borderRadius)}

    cursor: pointer;
    opacity: 1;
    user-select: none;

  &[disabled]{
    background-color: ${t.selectControl.status.open.backgroundColor};
    cursor: not-allowed;
  }
`;

const selectCss = (
  t: ComponentTheme,
  { isSmall, isActive }: { isSmall: boolean; isActive: boolean }
) => css`
  .customSelect {
    min-width: 200px;
  }

  .customSelect__control {
    transition: none;
    ${controlCustomStyles(t, isSmall)};
  }

  .customSelect__control--is-focused {
    border-color: ${t.status.focus.borderColor};
    outline: ${t.borderRadius != null && t.borderRadius > 0
      ? `none`
      : `${px(t.outlineSize)} solid ${t.outlineColor}`};
    box-shadow: ${t.borderRadius != null && t.borderRadius > 0
      ? `0 0 0 ${px(t.outlineSize)} ${t.outlineColor}`
      : `none`};
  }
  .customSelect__control--is-disabled {
    background-color: ${t.selectControl.status.disabled.backgroundColor};
    cursor: not-allowed;
  }
  .customSelect__value-container {
    ${defaultStyle(t, isSmall)};
  }
  .customSelect__menu {
    ${menuCustomStyles(t)};
  }
  .customSelect__menu-list {
    padding: 0;
  }
  .customSelect__option {
    ${optionCustomStyles(t, isSmall, isActive)};
  }
  .customSelect__option--is-focused {
    background-color: ${t.options.focus.backgroundColor};
  }
  .customSelect__option--is-selected {
    background-color: ${t.options.active.backgroundColor};
  }
`;
const themeStyleCallback = (styles: any) => styles.Select;

export default selectStyler(selectCss, themeStyleCallback) as any;

export const convertStyles = (t: ComponentTheme, props: ISelectProps) => {
  // TODO: the ...styles parts below import the base styles from the react-select library
  // Inorder to fix various alignment issues, some of the margin/padding properties have been
  // adjusted to override the default styles.  It might be better to remove the import of base
  // styles and define the entirety of the styling.
  return {
    dropdownIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0
    }),
    clearIndicator: (styles: any) => ({
      ...styles,
      paddingTop: 0,
      paddingBottom: 0
    }),
    indicatorSeparator: (styles: any) => ({
      ...styles,
      marginTop: 0,
      marginBottom: 0
    }),
    input: (styles: any) => ({
      margin: 0,
      padding: 0
    })
  };
};
