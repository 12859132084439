import styled, { css } from 'styled-components';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const innerPanelStyler = styleComponent(withComponentTheme);

const innerPanelCss = (t: TThemeVariables) => css`
  margin-bottom: 0;
  background-color: ${t.panelBackgroundColor};
  border: ${px(t.panelBorderSize)} solid ${t.panelBorderColor};
  border-radius: ${px(t.panelBorderRadius)};
  overflow: hidden;

  & + & {
    margin-top: -${px(t.panelBorderSize)};
  }
`;
const themeStyleCallback = (styles: any) => styles.Accordion.InnerPanel;

export const InnerPanel = styled.div([
  innerPanelStyler(innerPanelCss, themeStyleCallback)
]) as any;
