import { buildMediaQueries } from '../../utils';

interface Breakpoint {
  min: number;
  max?: number;
}

interface Breakpoints {
  xs: Breakpoint;
  sm: Breakpoint;
  md: Breakpoint;
  lg: Breakpoint;
}

export function generateBreakpoints({ xs, sm, md, lg }: Breakpoints) {
  return {
    xs,
    sm,
    md,
    lg,
    media: buildMediaQueries({ xs, sm, md, lg })
  };
}

export const breakpoints = {
  xs: {
    min: 480,
    max: 767
  },
  sm: {
    min: 768,
    max: 991
  },
  md: {
    min: 992,
    max: 1199
  },
  lg: {
    min: 1300
  }
};
