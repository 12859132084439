import React from 'react';
import styled from 'styled-components';
import { defaultStyle } from './styles';

import Content from './components/content';
import WithIcon from './components/with-icon';
import Arrow from './components/arrow';

const Wrapper = styled.div([defaultStyle]);

export default (props: any) => {
  const { isSmall, hasArrow, title, icon, children, status, ...rest } = props;
  return (
    <Wrapper
      role={'alert'}
      styleProps={{ hasArrow, isSmall, status }}
      {...rest}
    >
      {hasArrow && <Arrow styleProps={{ isSmall, status }} />}
      {icon ? (
        <WithIcon isSmall={isSmall} title={title} icon={icon}>
          {children}
        </WithIcon>
      ) : (
        <Content title={title}>{children}</Content>
      )}
    </Wrapper>
  );
};
