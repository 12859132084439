import React from 'react';
import marked from 'marked';
import { PageHeader, Wrapper } from './ui';
import { Markdown } from './components/markdown';

import readme from '../../docs/why.md';

export default () => (
  <Wrapper>
    <PageHeader
      header="Welcome to the Component Library"
      lead="The Parmenion React Component library is a common library for user interface components and patterns designed to be used across Parmenion products."
    />
    <Markdown source={readme} />
  </Wrapper>
);
