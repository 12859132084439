import React from 'react';
import { WrapperStyle } from './breadcrumb-wrapper';
import styled from 'styled-components';

const Wrapper = styled.ol([WrapperStyle]);
export const Base = (props: any) => (
  <nav aria-label="Breadcrumb">
    <Wrapper>{props.children}</Wrapper>
  </nav>
);
