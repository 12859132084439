import React from 'react';
import ReactSelect, { components, Async } from 'react-select';
import { map } from 'rambda';
import { ISelectOption, ISelectProps, LoadOptions } from './props';
import { withTheme } from 'styled-components';
import { convertStyles } from './style';

interface OptionType {
  value: string;
  label: string;
  testID: string;
  data: any;
}

const remapOptions = map((option: ISelectOption) => ({
  value: option.id,
  label: option.name,
  testID: `select-item-${option.id}`,
  data: option
}));

const remapLoadOptions = (loader: LoadOptions) => (
  inputValue: string,
  callback: any
) => loader(inputValue).then(result => callback(remapOptions(result)));

const remapNoOptionsMessage = (message: string) => () => message;

const remapProps = (props: ISelectProps) => {
  const options = remapOptions(props.items);
  const selected = options.find((x: any) => x.value === props.value) || null;
  const icon = props.icon || null;

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      {icon}
    </components.DropdownIndicator>
  );

  return {
    defaultMenuIsOpen: props.isOpen || false,
    isClearable: setDefaultClearableBehaviour(props),
    isSearchable: props.isAutocomplete || !!props.asyncOptions || false,
    placeholder: props.placeholder || '',
    isDisabled: props.isDisabled,
    options: options,
    value: selected,
    'aria-labelledby': props.labelledby,
    components: {
      DropdownIndicator: (props: any) => <DropdownIndicator {...props} />
    },
    loadOptions: props.asyncOptions && remapLoadOptions(props.asyncOptions),
    cacheOptions: true,
    defaultOptions: options.length ? options : true,
    noOptionsMessage: props.noOptionsMessage
      ? remapNoOptionsMessage(props.noOptionsMessage)
      : undefined
  };
};

const getValueFromSelectedOption = (onChange: any) => (option: OptionType) => {
  option != null ? onChange(option.value, option.data) : onChange(null, null);
};

const callbackHandlerWithValue = (callback: any, value: any) => (
  event: React.SyntheticEvent<HTMLInputElement>
) => {
  if (callback != null) callback(value);
};

const setDefaultClearableBehaviour = ({ isClearable, isAutocomplete }: any) => {
  if (isClearable == null) {
    return isAutocomplete ? true : false;
  }
  return isClearable;
};

export default withTheme((props: any) => {
  const {
    name,
    Container,
    isAutocomplete,
    isClearable,
    isSmall,
    isActive,
    isDisabled,
    items,
    onChange,
    onFocus,
    onBlur,
    theme,
    isOpen,
    labelledby,
    icon,
    asyncOptions,
    noOptionsMessage,
    ...rest
  } = props;

  const Component = props.asyncOptions ? Async : ReactSelect;

  return (
    <Container {...rest}>
      <Component
        {...remapProps(props)}
        onChange={getValueFromSelectedOption(onChange)}
        onFocus={callbackHandlerWithValue(onFocus, rest.value)}
        onBlur={callbackHandlerWithValue(onBlur, rest.value)}
        name={name}
        menuShouldScrollIntoView={false}
        disabled={isDisabled}
        backspaceRemovesValue={false}
        className="customSelect"
        classNamePrefix="customSelect"
        styles={convertStyles(props.theme.components.Select, props)}
      />
    </Container>
  );
});
