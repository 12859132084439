import React from 'react';
import styled, { css } from 'styled-components';
import { elementStringExtractor } from 'utils';
import Title from './Title';
import Body from './Body';
import Controls from './Controls';
import { Box } from '../../core';
import {
  selectComponentThemeVariablesFor,
  getBoxShadow,
  paddingVertical,
  paddingHorizontal,
  px,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Modal);

const InnerStyle = (t: TThemeVariables) => css`
  position: relative;
  z-index: 1000;
  max-width: 900px;
  margin: auto;
  border: ${px(t.borderSize)} solid ${t.borderColor};
  border-radius: ${t.borderRadius};
  background-clip: padding-box;
  box-shadow: ${getBoxShadow('large')};
  background-color: ${t.wrapper.backgroundColor};
  div:focus {
    outline: 1px auto -webkit-focus-ring-color;
  }
`;

const ContentStyle = (t: TThemeVariables) => css`
  padding: ${paddingVertical(2)} ${paddingHorizontal(2)};
`;

const styler = styleComponent(style);
const innerStyleCallback = (styles: any) => styles.Modal.ModalInner;
const contentStyleCallback = (styles: any) => styles.Modal.Content;

const StyledContent = styled.div([styler(InnerStyle, innerStyleCallback)]);

const Content = styled.div([styler(ContentStyle, contentStyleCallback)]);

export default function ModalInner({
  title,
  children,
  handler,
  primaryButton,
  buttons,
  ...rest
}: any) {
  return (
    <StyledContent {...rest}>
      <Title handler={handler} id={elementStringExtractor(title)}>
        {title}
      </Title>
      <Content>
        <Box stacked spaceBetween={2}>
          <Body>{children}</Body>
          <Controls primaryButton={primaryButton} buttons={buttons} />
        </Box>
      </Content>
    </StyledContent>
  );
}
