import { css } from 'styled-components';
import tc from 'tinycolor2';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  px,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.ToolTip);
const styler = styleComponent(style);

const labelCustomStyleCallback = (styles: any) => styles.ToolTip.Label;
const wrapperCustomStyleCallback = (styles: any) => styles.ToolTip.Wrapper;

const wrapperCustomCss = (t: TThemeVariables) => css`
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
  & > span:first-child {
    border-bottom: 1px dotted ${t.linkBorder};
    margin: 0 0.25rem;
    padding-bottom: 0.25rem;
    &:hover {
      color: ${t.linkColor};
      border-bottom: 1px solid ${t.linkColor};
    }
  }
`;

export const left = (isVisible: boolean, collisions: any) => ({
  content: css`
    right: 100%;
    top: 50%;
    justify-content: flex-end;
    transform: translateY(-50%) translateX(0);
  `,
  label: css`
    transform-origin: center right;
    transform: scale(1) translateX(0px) ${getCollision(collisions)};
    ${isVisible
      ? `transform: scale(1) ${getCollision(collisions, `translateX(-10px)`)}`
      : ``};
  `
});

export const right = (isVisible: boolean, collisions: any) => ({
  content: css`
    left: 100%;
    top: 50%;
    justify-content: flex-start;
    transform: translateY(-50%) translateX(0);
  `,
  label: css`
    transform-origin: center left;
    transform: scale(1) translateX(0px) ${getCollision(collisions)};
    ${isVisible
      ? `transform: scale(1) ${getCollision(collisions, `translateX(10px)`)}`
      : ``};
  `
});

export const top = (isVisible: boolean, collisions: any, align: any) => ({
  content: css`
    ${TOOLTIP_ALIGNMENT_STYLE_MAP[align || 'left']} bottom: 100%;
    justify-content: center;
  `,
  label: css`
    transform-origin: bottom center;
    transform: scale(1) translateY(0) ${getCollision(collisions)};
    ${isVisible
      ? `transform: scale(1) translateY(-10px) ${getCollision(collisions)}`
      : ``};
  `
});

export const bottom = (isVisible: boolean, collisions: any, align: string) => ({
  content: css`
    ${TOOLTIP_ALIGNMENT_STYLE_MAP[align || 'left']} top: 100%;
    justify-content: center;
  `,
  label: css`
    transform-origin: top center;
    transform: scale(1) translateY(0) ${getCollision(collisions)};
    ${isVisible
      ? `transform: scale(1) translateY(10px) ${getCollision(collisions)}`
      : ``};
  `
});

const TOOLTIP_POSITION_STYLE_MAP = {
  top,
  right,
  bottom,
  left
};

const TOOLTIP_ALIGNMENT_STYLE_MAP = {
  left: css`
    left: 0%;
    transform: translateY(0) translateX(0%);
  `,
  center: css`
    left: 50%;
    transform: translateY(0) translateX(-50%);
  `,
  right: css`
    left: 100%;
    transform: translateY(0) translateX(-100%);
  `
};

export const contentCss = style(
  (t, { position, isVisible, align }) => css`
    position: absolute;
    display: flex;
    pointer-events: none;
    z-index: 10;
    ${TOOLTIP_POSITION_STYLE_MAP[position || 'top'](isVisible, undefined, align)
      .content};
  `
);

const isVisibleMixin = (isVisible: boolean) =>
  isVisible
    ? css`
        opacity: 1;
      `
    : ``;

const getShadowColor = (color: any) =>
  tc(color)
    .lighten(0.5)
    .setAlpha(0.2)
    .toString();

const LABEL_WIDTH = {
  short: `13.5rem`,
  long: `30rem`,
  normal: `20rem`
};

export const labelCss = style(
  (t, { isVisible, position, collisions, width }) => css`
    display: inline;
    word-wrap: break-word;
    white-space: normal;
    max-width: ${LABEL_WIDTH[width] || width};
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms,
      transform 0.15s cubic-bezier(0.23, 1, 0.32, 1) 0ms;

    ${isVisibleMixin(isVisible)};
    ${TOOLTIP_POSITION_STYLE_MAP[position || 'top'](isVisible, collisions)
      .label};
  `
);

const labelCustomCss = (t: TThemeVariables, { width }: any) => css`
  padding: ${paddingHorizontal(0)};
  background: ${t.label.backgroundColor};
  color: ${t.label.textColor};
  ${getTypography('Default')};
  width: ${LABEL_WIDTH[width] || width};
  border-radius: ${px(t.label.borderRadius)};
  box-shadow: 0 0 0 1px ${getShadowColor(t.label.shadowColor)},
    0 2px 4px ${getShadowColor(t.label.shadowColor)},
    0 8px 24px ${getShadowColor(t.label.shadowColor)};
`;

const getCollision = (collisions: any = {}, defaultOffset: string = ``) => {
  let output = defaultOffset;
  if (collisions.right > 0) output += `translateX(-${collisions.right}px)`;

  if (collisions.left) output += `translateX(${collisions.left}px)`;

  if (collisions.top) output += `translateY(${collisions.top}px)`;

  if (collisions.bottom) output += `translateY(-${collisions.bottom}px)`;

  return output;
};

export const label = styler(labelCustomCss, labelCustomStyleCallback);
export const wrapper = styler(wrapperCustomCss, wrapperCustomStyleCallback);
