import invariant from 'invariant';
import { ITheme } from 'theme';

interface PropsWithTheme {
  theme: ITheme;
}

export const getBoxShadow = (size: string) => (props: PropsWithTheme) => {
  const nameSpaceExists = props.theme.decoration.boxShadow.hasOwnProperty(size);
  invariant(
    nameSpaceExists,
    `component(): boxShadow '${size}' is missing in theme's decoration node`
  );
  return props.theme.decoration.boxShadow[size];
};
