import {
  SecondaryButton,
  SecondaryInvertedButton,
  SecondaryOutlineButton
} from '../Buttons/';
import { builder } from './builder';

export const SecondaryButtonWithIcon = builder(
  'SecondaryButtonWithIcon',
  SecondaryButton
);

export const SecondaryInvertedButtonWithIcon = builder(
  'SecondaryInvertedButtonWithIcon',
  SecondaryInvertedButton
);

export const SecondaryOutlineButtonWithIcon = builder(
  'SecondaryOutlineButtonWithIcon',
  SecondaryOutlineButton
);
