import React from 'react';
import { Container } from '@parmenion/library';
import { PageHeader, Wrapper } from './ui';
import { Markdown } from './components/markdown';

import readme from '../../docs/quick-start.md';

export default () => (
  <Wrapper>
    <PageHeader header="Quick Start" lead="Get up and running quickly" />
    <Markdown source={readme} />
  </Wrapper>
);
