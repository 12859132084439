import { component } from 'builder';
import { IDetailsProps } from './props';
import Base from './base';

export const Details = component<IDetailsProps>({
  name: 'Details',
  base: Base,
  props: {
    children: null,
    summary: null,
    open: null
  }
});
