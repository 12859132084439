import { component } from 'builder';

import { IProps } from './props';
import Base from './base';
import { Children } from 'react';

export const InputGroup = component<IProps>({
  name: 'InputGroup',
  base: Base,
  props: {
    start: null,
    end: null,
    children: null,
    shouldShowFocus: true,
    width: null
  }
});
