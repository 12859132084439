import { component } from 'builder';
import { HorizontalFormFieldBase } from './base/field';
import { IFormFieldProps } from './props';

export const FormFieldHorizontal = component<IFormFieldProps>({
  name: 'FormFieldHorizontal',
  base: HorizontalFormFieldBase,
  props: {
    hasError: false,
    hasWarning: false,
    children: null,
    error: null,
    warning: null,
    label: null,
    htmlFor: null,
    id: null,
    helpText: null,
    underHelpText: null,
    showErrorAbove: false
  }
});
