import styled, { css } from 'styled-components';
import { identity } from 'rambda';
import {
  getTypography,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../../types';

const style = selectComponentThemeVariablesFor(identity);
const labelStyler = styleComponent(style);
const labelStylerCallback = (styles: any) => styles.Form.Field.Label;
const labelCss = (t: TThemeVariables) => css`
  display: block;
  ${getTypography('Default')};
  font-weight: 500;
`;

export const Wrapper = styled.label([
  labelStyler(labelCss, labelStylerCallback)
]);
