import { createGlobalStyle } from 'styled-components';

const baseSize = '62.5%'; // 10px
const baseSizeSmall = '55%';
const fontSize = '19px';
const fontSizeSmall = '14px';
const fontFamily =
  '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif';

export default theme => {
  return createGlobalStyle`

  html {
    font-family: ${fontFamily};
    font-size: ${baseSizeSmall};

    ${theme.breakpoints.media.sm`
      font-size: ${baseSize};
    `}

  }

  #root {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 0;
    overflow-x: hidden;
  }

  body {
    margin: 0;
    font-size: ${fontSizeSmall};

    ${theme.breakpoints.media.sm`
      font-size: ${fontSize};
    `}

    * {
      box-sizing: border-box;
    }
  }
  `;
};
