import { component } from 'builder';
import { styleProps } from '../core/TextInput/props';
import { StepperBase } from './base';
import { defaultProps, IStepperProps } from './props';
import { wrapperStyle } from './style';

export const Stepper = component<IStepperProps>({
  name: 'Stepper',
  base: StepperBase,
  container: 'input',
  style: wrapperStyle,
  props: defaultProps,
  styleProps
});
