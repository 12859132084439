import { component } from 'builder';
import { IGaugeRadialChartProps } from './props';
import Base from './base';

export const GaugeRadialChart = component<IGaugeRadialChartProps>({
  name: 'GaugeRadialChart',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    children: null,
    progress: '',
    color: ''
  }
});
