import React from 'react';
import { IProps } from '.';
import Banner from './Banner';
import Modal from './Modal';
import Cookies from 'js-cookie';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { CookieCategories, cookieCategoryCookies } from './cookieCategories';
import moment from 'moment';

export const PreferencesCookieName = 'parmenion-cookie-preferences';

export type CookiePreferences = CookieCategories[];

const getDomain = () => {
  let host = document.location.hostname;
  let domainParts = host.split('.');
  if (domainParts.length === 1) {
    return host;
  } else {
    let topDomain = host.match(/[^.\s/]+\.([a-z]{3,}|[a-z]{2}.[a-z]{2})$/) || [
      ''
    ];
    return '.' + topDomain[0];
  }
};

const cookieOptions = {
  path: '/',
  domain: getDomain(),
  expires: moment()
    .add(6, 'months')
    .toDate()
};

const areCookiePreferencesSet = () =>
  Cookies.get(PreferencesCookieName) !== undefined;

export const isCategoryAccepted = (category: CookieCategories) =>
  currentPreferences().indexOf(category) >= 0;

export const currentPreferences = (): CookiePreferences => {
  if (!areCookiePreferencesSet()) {
    return [];
  }

  return Cookies.getJSON(PreferencesCookieName).map(
    (c: any) => CookieCategories[c]
  );
};

interface State {
  showBanner: boolean;
  managePreferencesOpen: boolean;
  currentPreferences: CookiePreferences;
}

export default class extends React.Component<IProps, State> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showBanner: !areCookiePreferencesSet(),
      managePreferencesOpen: false,
      currentPreferences: currentPreferences()
    };
  }
  targetElement: HTMLElement | null = null;

  componentDidMount() {
    this.targetElement = document.querySelector('body');
  }

  render() {
    return (
      <>
        {this.state.showBanner && (
          <Banner
            acceptAllCookies={() => this.acceptAllCookies()}
            openManagePreferences={() => this.openManagePreferences()}
            background={this.props.background}
          />
        )}
        {this.state.managePreferencesOpen && (
          <Modal
            acceptAllCookies={() => this.acceptAllCookies()}
            rejectAllCookies={() => this.rejectAllCookies()}
            close={() => this.closeManagePreferences()}
            toggleCategory={c => this.toggleCategory(c)}
            isCategoryAccepted={isCategoryAccepted}
            zIndex={this.props.zIndex}
            cookiePolicyUrl={this.props.cookiePolicyUrl}
            privacyPolicyUrl={this.props.privacyPolicyUrl}
          />
        )}
      </>
    );
  }

  openManagePreferences() {
    disableBodyScroll(this.targetElement);

    this.setState({
      ...this.state,
      managePreferencesOpen: true
    });
  }

  private acceptAllCookies() {
    Cookies.set(
      PreferencesCookieName,
      [
        CookieCategories[CookieCategories.marketing],
        CookieCategories[CookieCategories.liveChat]
      ],
      cookieOptions
    );

    this.closeManagePreferences(true);
  }

  private rejectAllCookies(closeManagePreferences: boolean = true) {
    this.deleteCookies(CookieCategories.marketing);
    this.deleteCookies(CookieCategories.liveChat);

    Cookies.set(PreferencesCookieName, [], cookieOptions);

    closeManagePreferences && this.closeManagePreferences();
  }

  private toggleCategory(category: CookieCategories) {
    const newState = !isCategoryAccepted(category);

    if (!newState) {
      this.deleteCookies(category);
    }

    const newPreferences = newState
      ? [...currentPreferences(), CookieCategories[category]]
      : currentPreferences().filter(c => c !== category);

    Cookies.set(PreferencesCookieName, newPreferences, cookieOptions);

    this.setState({
      ...this.state,
      currentPreferences: newPreferences
    });

    return newState;
  }

  private closeManagePreferences(reload: boolean = true) {
    enableBodyScroll(this.targetElement);

    if (!areCookiePreferencesSet()) {
      this.rejectAllCookies();
    }

    this.setState({
      ...this.state,
      managePreferencesOpen: false,
      showBanner: false
    });

    reload && window.location.reload();
  }

  private deleteCookies(category: CookieCategories) {
    let domain = getDomain();
    for (const cookie of cookieCategoryCookies[category]) {
      Cookies.remove(cookie.name, { path: '/', domain: domain });
    }
  }
}
