import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Notification);
const styler = styleComponent(style);

const wrapperCallback = (styles: any) => styles.Notification.Wrapper;
const iconCallback = (styles: any) => styles.Notification.Icon;
const containerCallback = (styles: any) => styles.Notification.Container;
const titleCallback = (styles: any) => styles.Notification.Title;
const subtitleCallback = (styles: any) => styles.Notification.Subtitle;

const setColor = (type: string | null, t: TThemeVariables) => {
  switch (type) {
    case 'success':
      return t.successColor;
    default:
      return t.infoColor;
  }
};

const setBackgroundColor = (type: string | null, t: TThemeVariables) => {
  switch (type) {
    case 'success':
      return t.successBgColor;
    default:
      return t.infoBgColor;
  }
};

const wrapperCustomCss = (t: TThemeVariables) => css`
  background: ${({ styleProps }: any) =>
    setBackgroundColor(styleProps.type, t)};
  padding: 1rem;
`;

const containerCustomCss = () => css`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: start;
  flex-direction: row;
`;

const titleCustomCss = (t: TThemeVariables) => css`
  color: ${({ styleProps }: any) => setColor(styleProps.type, t)};
  margin: 0;
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const subtitleCustomCss = (t: TThemeVariables) => css`
  margin: 0;
  display: flex;
  align-items: center;
  && * {
    color: ${({ styleProps }: any) => setColor(styleProps.type, t)};
    white-space: nowrap;
    text-align: right;
  }
`;

const iconCustomCss = () => css`
  margin: 0 1rem 0 0;
  display: flex;
  align-items: center;
`;

export const wrapperStyle = styler(wrapperCustomCss, wrapperCallback);
export const iconStyle = styler(iconCustomCss, iconCallback);
export const containerStyle = styler(containerCustomCss, containerCallback);
export const titleStyle = styler(titleCustomCss, titleCallback);
export const subtitleStyle = styler(subtitleCustomCss, subtitleCallback);
