import { component } from 'builder';
import { ISpinnerWithOverlayProps } from './props';
import Base from './base';

export const SpinnerWithOverlay = component<ISpinnerWithOverlayProps>({
  name: 'SpinnerWithOverlay',
  base: Base,
  props: {
    isInverted: false
  }
});
