import { component } from 'builder';
import { IModalProps } from './props';
import ModalBase from './ModalBase';

export const Modal = component<IModalProps>({
  name: 'Modal',
  base: ModalBase,
  props: {
    closeOnEsc: false,
    closeOnClickAway: true,
    title: null,
    isOpen: false,
    onClose: () => {},
    children: null,
    primaryButton: null,
    buttons: null,
    zIndex: 1000
  }
});
