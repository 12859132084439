import React from 'react';
import styled from 'styled-components';
import { detailsStyle, bodyStyle, summaryStyle } from './styles';

const Wrapper = styled.details([detailsStyle]);
const Summary = styled.summary([summaryStyle]);
const Body = styled.div([bodyStyle]);

export default (props: any) => {
  const { summary, children, open, ...rest } = props;

  return (
    <Wrapper open={open} {...rest}>
      <Summary>{summary}</Summary>
      <Body>{children}</Body>
    </Wrapper>
  );
};
