import { Button, InvertedButton, OutlineButton } from '../Buttons/';
import { builder } from './builder';

export const ButtonWithIcon = builder('ButtonWithIcon', Button);
export const InvertedButtonWithIcon = builder(
  'InvertedButtonWithIcon',
  InvertedButton
);
export const OutlineButtonWithIcon = builder(
  'OutlineButtonWithIcon',
  OutlineButton
);
