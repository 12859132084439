import styled, { css } from 'styled-components';
import {
  getTypography,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Alert);
const descriptionStyler = styleComponent(style);

const descStyle = (t: TThemeVariables) => css`
  margin: 0;
  ${getTypography('Default')};
`;

const themeStyleCallback = (styles: any) => styles.Alert.Description;

export default styled.div([descriptionStyler(descStyle, themeStyleCallback)]);
