import React from 'react';
import {
  IInputTextProps,
  defaultProps as inputDefaultProps
} from '../core/TextInput/props';
import { IEventProps } from '../core/event-props';
import { InputNumber } from '../InputNumber';

export interface IStepperProps extends IInputTextProps {
  minusButton: React.ReactElement<IEventProps> | null;
  plusButton: React.ReactElement<IEventProps> | null;
  inputField: React.ReactElement<typeof InputNumber> | null;
  increment: number | null;
  precision: number | null;
}

export const defaultProps: IStepperProps = {
  alignText: 'center',
  minusButton: null,
  plusButton: null,
  inputField: null,
  increment: null,
  precision: null,
  ...inputDefaultProps
};
