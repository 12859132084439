import React from 'react';
import styled from 'styled-components';

import { label } from './styles/label';

const Label = styled.label([label]);

export const LabelBase = ({ status, children }: any) => {
  return <Label>{children}</Label>;
};
