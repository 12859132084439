import React from 'react';
import styled from 'styled-components';
import { Default } from '../Typography';
import {
  gaugeStyle,
  valueStyle,
  remainingStyle,
  progressbarStyle
} from './style';

const GaugeWrapper = styled.div([gaugeStyle]);
const ValueWrapper = styled.div([valueStyle]);
const ValueRemainingWrapper = styled.div([remainingStyle]);
const ProgressBar = styled.div([progressbarStyle]);

export default ({
  value,
  valueText,
  remaining,
  remainingText,
  progress,
  barColor,
  ...props
}: any) => (
  <GaugeWrapper {...props}>
    <ValueWrapper styleProps={{ barColor }}>
      <h4>{value}</h4>
      <Default>{valueText}</Default>
    </ValueWrapper>
    <ProgressBar styleProps={{ progress, barColor }} />
    <ValueRemainingWrapper>
      <h4>{remaining}</h4>
      <Default>{remainingText}</Default>
    </ValueRemainingWrapper>
  </GaugeWrapper>
);
