import React from 'react';
import styled from 'styled-components';
import { repeat } from 'rambda';
import { Box as BoxBase, addSeparators } from '../core';
import { lineStyle, wrapperStyle } from './style';

export const Line = styled.div([lineStyle]);
Line.displayName = 'Line';

const childrenWithSeparators = (children: any) => {
  return addSeparators(children, <Line />);
};

const Box = styled(BoxBase)(wrapperStyle);

export default (props: any) => (
  <Box stacked spaceBetween={props.spaceBetween}>
    {childrenWithSeparators(props.children)}
  </Box>
);
