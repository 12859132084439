import React from 'react';
import { Box } from '../../../core/';
import { FormFields } from '../core';

export const FormBase: React.StatelessComponent<any> = ({
  children,
  primaryAction,
  secondaryActions,
  hiddenFields,
  ...rest
}: any) => (
  <form {...rest}>
    {hiddenFields}
    <Box stacked spaceBetween={3} key="wrapper">
      <FormFields>{children}</FormFields>
      {(primaryAction || secondaryActions) && (
        <Box horizontal key="actions" data-component-id="buttons-wrap">
          {secondaryActions && (
            <Box
              horizontal
              fill
              key="secondaryActions"
              data-component-id="button-primary"
            >
              {secondaryActions}
            </Box>
          )}
          {primaryAction && (
            <Box key="priamryAction" data-component-id="button-secondary">
              {primaryAction}
            </Box>
          )}
        </Box>
      )}
    </Box>
  </form>
);

FormBase.displayName = 'FormBase';
