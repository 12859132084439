import React from 'react';
import { Icon, iconFactory } from '../../Icon';
import { Box } from '../../core/';
import { IButtonWithIconProps } from '../props';
import { Button } from '../../Buttons';

const getIcon = (iconMap: any) =>
  iconMap != null ? iconFactory(iconMap) : Icon;

const getProps = (
  props: IButtonWithIconProps,
  icon: string | React.ReactElement<any>
) => {
  const propsToPass: any = {
    color: props.color,
    stroke: props.stroke,
    strokeWidth: props.strokeWidth,
    strokeLineJoin: props.strokeLineJoin
  };

  if (typeof icon === 'string') {
    propsToPass.name = icon;
  }

  return propsToPass;
};

const positionMap = {
  left: (
    icon: string | React.ReactElement<any>,
    IconComponent: any,
    children: any,
    props: IButtonWithIconProps
  ) => {
    const propsToPass: any = getProps(props, icon);
    const isValidElement: boolean = React.isValidElement(IconComponent);

    const component = [
      isValidElement ? (
        IconComponent
      ) : (
        <IconComponent {...propsToPass} className="ButtonIcon" key="left" />
      )
    ];

    if (children) {
      component.push(
        <Box
          className="ButtonCopy"
          shrink={props.noWrap ? false : true}
          key="right"
        >
          {children}
        </Box>
      );
    }

    return component;
  },
  right: (
    icon: string | React.ReactElement<any>,
    IconComponent: any,
    children: any,
    props: IButtonWithIconProps
  ) => {
    const propsToPass: any = getProps(props, icon);
    const isValidElement: boolean = React.isValidElement(IconComponent);

    const component = [
      isValidElement ? (
        IconComponent
      ) : (
        <IconComponent {...propsToPass} className="ButtonIcon" key="right" />
      )
    ];

    if (children) {
      component.unshift(
        <Box
          className="ButtonCopy"
          shrink={props.noWrap ? false : true}
          key="left"
        >
          {children}
        </Box>
      );
    }

    return component;
  }
};

type ButtonWithIconFactory = {
  Base: typeof Button;
  displayName: string;
};

export const buttonFactory = ({ Base, displayName }: ButtonWithIconFactory) => {
  const newComponent: React.StatelessComponent<IButtonWithIconProps> = ({
    icon,
    iconPosition,
    iconMap,
    children,
    ...rest
  }: any) => {
    const content = positionMap[iconPosition] || positionMap.left;
    const IconComponent = typeof icon !== 'string' ? icon : getIcon(iconMap);
    return (
      <Base
        {...rest}
        testId={rest['data-testid']}
        iconPosition={iconPosition}
        as={rest.Container}
      >
        <Box
          wrap={false}
          shrink={rest.noWrap ? false : true}
          horizontal
          vAlign="center"
          spaceBetween={1}
          key="container"
          className="ButtonWithIcon"
        >
          {content(icon, IconComponent, children, rest)}
        </Box>
      </Base>
    );
  };

  newComponent.displayName = displayName;
  newComponent.defaultProps = {
    iconPosition: 'left'
  };

  return newComponent;
};
