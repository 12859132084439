import {
  defaultStyle,
  defaultInvertedStyle,
  defaultOutlineStyle
} from './styles';

import { builder } from './builder';
export const Button = builder(defaultStyle, 'Button');
export const InvertedButton = builder(defaultInvertedStyle, 'InvertedButton');
export const OutlineButton = builder(defaultOutlineStyle, 'OutlineButton');
