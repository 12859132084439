export const containers = {
  textInput: {
    minHeight: {
      regular: 56,
      small: 42
    }
  }
};

export type IContainersType = typeof containers;
