import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../components/Icon';
import { Select } from '../Forms/Select';
import { search, wrapper } from './style';

const Wrapper = styled.div([wrapper]);
const styledSelect = styled(Select)([search]);
const withWrapper = (Component: any) => (props: any) => {
  return (
    <Wrapper className="FuzzySearch">
      <Component {...props} />
    </Wrapper>
  );
};
const Base = withWrapper(styledSelect);

export default (props: any) => (
  <Base
    {...props}
    isAutocomplete
    isClearable={false}
    icon={<Icon name="search" />}
  />
);
