import invariant from 'invariant';
import React from 'react';
import { omit } from 'rambda';
import {
  Row,
  Column,
  Hint,
  Separated,
  Stacked,
  Default
} from '@parmenion/library';
import { withTheme } from 'styled-components';
import { Markdown } from './markdown';
import settingReadme from '../../../docs/colors/setting.md';
import accessingReadme from '../../../docs/colors/accessing.md';
import { Heading } from '../ui';

import { CHECKERED_BACKGROUND } from './styles';

const walkColors = object =>
  Object.keys(object).map(key =>
    typeof object[key] === 'object' ? (
      <Layout title={key}>{walkColors(object[key])}</Layout>
    ) : (
      <ColorBox name={key} backgroundColor={object[key]} />
    )
  );

const Layout = props => (
  <Column shrink>
    <div style={styles.heading}>
      <Heading>{props.title.toLowerCase()}</Heading>
    </div>
    <Row>{props.children}</Row>
  </Column>
);

const ColorBox = props => (
  <div style={styles.container}>
    <Column>
      <div style={styles.renderFrame}>
        <div style={styles.background(props.backgroundColor)} />
      </div>
      <Heading>{props.name}</Heading>
      <Hint>{props.backgroundColor}</Hint>
    </Column>
  </div>
);

export default withTheme(({ theme }) => {
  return (
    <Separated>
      <Stacked>
        <Heading>UI Colors</Heading>
        <Default>
          UI colors emphasize interface elements such as buttons, links, accents
          and vizualization.
        </Default>
        <Layout title="">
          {walkColors(omit(['neutrals', 'active'], theme.colors))}
        </Layout>
      </Stacked>
      <Stacked>
        <Heading>Grayscale colors</Heading>
        <Default>
          Grayscale colors are used for containers, text, lines and borders.
        </Default>
        <Layout title="">{walkColors(theme.colors.neutrals)}</Layout>
      </Stacked>
      <Stacked>
        <Heading>Active colors</Heading>
        <Default>
          Active colors are used for active states for components, for example a
          button.
        </Default>
        <Layout title="">{walkColors(theme.colors.active)}</Layout>
      </Stacked>
      <Stacked>
        <Heading>Setting colors</Heading>
        <Markdown source={settingReadme} />
      </Stacked>
      <Stacked>
        <Heading>Use within your own styles</Heading>
        <Markdown source={accessingReadme} />
      </Stacked>
    </Separated>
  );
});

const styles = {
  heading: {
    padding: '5px 0'
  },
  container: {
    width: '150px',
    padding: '5px',
    margin: '2px',
    border: '1px solid #ccc'
  },
  renderFrame: {
    border: '1px solid #ccc',
    backgroundImage: CHECKERED_BACKGROUND,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 50px 50px'
  },
  background: backgroundColor => ({
    width: '100%',
    height: '150px',
    backgroundColor
  })
};
