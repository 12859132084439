import styled, { css } from 'styled-components';

import {
  getTypography,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Menu);

const menuStyler = styleComponent(style);

const innerMenuThemeStyleCallback = (styles: any) => styles.Menu.InnerMenu;

const innerMenuCss = (t: TThemeVariables, { isActive }: any) => css`
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: ${t.innerMenu.backgroundColor};
  border: 0;
  user-select: none;
`;

const iconMixin = () => css`
  width: 22px;
  min-width: 22px;
`;

export const InnerMenu = styled.ul([
  menuStyler(innerMenuCss, innerMenuThemeStyleCallback)
]);

const itemThemeStyleCallback = (styles: any) => styles.Menu.Item;

const itemCss = (t: TThemeVariables, { isActive }: any) => css`
  position: relative;
  &:last-child * {
    border-bottom: 0;
  }
`;

export const Item = styled.li([menuStyler(itemCss, itemThemeStyleCallback)]);

const iconContainerThemeStyleCallback = (styles: any) =>
  styles.Menu.IconContainer;

const iconContainerCss = (t: TThemeVariables) => css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  ${iconMixin()};
`;

export const IconContainer = styled.span([
  menuStyler(iconContainerCss, iconContainerThemeStyleCallback)
]);

const arrowContainerThemeStyleCallback = (styles: any) =>
  styles.Menu.ArrowContainer;

const arrowContainerCss = (t: TThemeVariables) => css`
  ${iconMixin()} display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ArrowContainer = styled.span([
  menuStyler(arrowContainerCss, arrowContainerThemeStyleCallback)
]);

const labelThemeStyleCallback = (styles: any) => styles.Menu.Label;

const labelCss = (t: TThemeVariables, { isActive }: any) => css`
  ${getTypography('Default')};
`;
const labelStyle = menuStyler(labelCss, labelThemeStyleCallback);

export const Label = styled.span([labelStyle]);

const descriptionThemeStyleCallback = (styles: any) => styles.Menu.Description;

const descriptionCss = (t: TThemeVariables, { isActive }: any) => css`
  ${getTypography('Hint')};
`;

export const Description = styled.span([
  menuStyler(descriptionCss, descriptionThemeStyleCallback)
]);
