import React from 'react';
import { Row, Container as ContainerBase } from '../';
import styled from 'styled-components';
import { px } from 'utils';

export interface IBannerProps {
  background?: string;
  acceptAllCookies: () => void;
  openManagePreferences: () => void;
}

const Layout = styled.div`
  position: fixed;
  bottom: 0;
  padding: 5rem 0;
  background: #fefefe;
  left: 0;
  right: 0;
  z-index: 999;
  color: #333333;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
`;

const Container = styled(ContainerBase)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  @media only screen and (min-width: ${props =>
      px(props.theme.breakpoints.md.min)}) {
    flex-direction: row;
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 2rem;
  @media only screen and (min-width: ${props =>
      px(props.theme.breakpoints.md.min)}) {
    margin-right: 5rem;
  }
`;

const Heading = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #050505 !important;
  margin-bottom: 4px;
`;

const Default = styled.span`
  font-size: 16px;
  line-height: 26px;
`;

const OutlineButton = styled.button`
  background-color: #f6f6f6;
  border: 1px solid #ededed;
  color: #050505;
  padding: 16px 48px;
  font-size: 17px;
  min-height: 48px;
  border-radius: 6px;
  box-shadow: unset;
  outline: 0 !important;
  cursor: pointer;
  min-height: 56px;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #ededed;
    border-color: #ededed;
    box-shadow: unset;
    transform: unset;
  }
`;

const SuccessButton = styled.button`
  background-color: #39a49d;
  border: 1px solid #ededed;
  color: #fefefe;
  padding: 16px 48px;
  font-size: 17px;
  min-height: 48px;
  border-radius: 6px;
  box-shadow: unset;
  outline: 0 !important;
  cursor: pointer;
  min-height: 56px;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #368d87;
    border-color: #ededed;
    box-shadow: unset;
    transform: unset;
  }
`;

export default ({
  acceptAllCookies,
  openManagePreferences,
  ...rest
}: IBannerProps) => {
  return (
    <Layout {...rest}>
      <Container>
        <ContentContainer>
          <Heading>Cookies</Heading>
          <Default>
            We use cookies to improve the performance of our website to give you
            a better online experience. To get more information about the
            cookies we use, or change your preferences click 'Manage cookies'.
          </Default>
        </ContentContainer>
        <div style={{ flexShrink: 0 }}>
          <Row spaceBetween={2}>
            <OutlineButton onClick={openManagePreferences}>
              Manage cookies
            </OutlineButton>
            <SuccessButton onClick={acceptAllCookies}>
              Accept all cookies
            </SuccessButton>
          </Row>
        </div>
      </Container>
    </Layout>
  );
};
