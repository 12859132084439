import { px } from './pixels';
import { ITheme } from 'theme';

interface PropsWithTheme {
  theme: ITheme;
}

export const paddingHorizontal = (index: number) => (props: PropsWithTheme) =>
  px(props.theme.layout.paddingHorizontal[index]);

export const noUnitsPaddingHorizontal = (index: number) => (
  props: PropsWithTheme
): number => props.theme.layout.paddingHorizontal[index];

export const paddingVertical = (index: number) => (props: PropsWithTheme) =>
  px(props.theme.layout.paddingVertical[index]);

export const noUnitsPaddingVertical = (index: number) => (
  props: PropsWithTheme
): number => props.theme.layout.paddingVertical[index];
