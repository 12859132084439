import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Bar);
const itemStyler = styleComponent(style);

const itemCss = (t: TThemeVariables) => css`
  display: inline-block;
  position: relative;
  height: ${px(t.container.height)};
  padding: 0;
  line-height: ${px(t.container.height)};
  vertical-align: middle;
`;

const themeStyleCallback = (styles: any) => styles.Bar.Item;

export const item = itemStyler(itemCss, themeStyleCallback) as any;
