import {
  WarningButton,
  WarningInvertedButton,
  WarningOutlineButton
} from '../Buttons/';
import { builder } from './builder';

export const WarningButtonWithIcon = builder(
  'WarningButtonWithIcon',
  WarningButton
);
export const WarningInvertedButtonWithIcon = builder(
  'WarningInvertedButtonWithIcon',
  WarningInvertedButton
);
export const WarningOutlineButtonWithIcon = builder(
  'WarningOutlineButtonWithIcon',
  WarningOutlineButton
);
