import { component } from 'builder';
import { IStackedProps } from './props';
import Base from './base';

export const Stacked = component<IStackedProps>({
  name: 'Stacked',
  base: Base,
  props: {
    children: null,
    spaceBetween: null,
    vAlign: null,
    hAlign: null,
    width: null,
    height: null
  }
});
