import { component } from 'builder';
import Base from './base';
import { IAlertProps } from './props';

export const Alert = component<IAlertProps>({
  name: 'Alert',
  base: Base,
  props: {
    children: null,
    title: null,
    icon: null,
    hasArrow: false,
    isSmall: false,
    status: 'default'
  }
});
