import { css } from 'styled-components';
import { baseStyle } from './base';
import { ITheme } from 'theme';

type ComponentTheme = ITheme['components']['TransparentButton'];

export default (style: any) =>
  style(
    (t: ComponentTheme) => css`
      ${baseStyle} color: ${t.textColor};
      border-color: ${t.borderColor};
      background-color: ${t.backgroundColor};
      box-shadow: none;

      &:focus {
        border-color: ${t.borderColor};
        background-color: ${t.status.focus.backgroundColor};
      }
      &:hover {
        border-color: ${t.borderColor};
        background-color: ${t.status.hover.backgroundColor};
      }
      &:active {
        border-color: ${t.borderColor};
        background-color: ${t.status.active.backgroundColor};
        outline: none;
        &:hover,
        &:focus {
          background-color: ${t.status.hoverWhenActive.backgroundColor};
          border-color: ${t.borderColor};
          box-shadow: none;
        }
      }
      &[disabled] {
        &:hover,
        &:focus {
          background-color: ${t.status.disabled.backgroundColor};
        }
      }
    `
  );
