import React from 'react';
import styled from 'styled-components';
import { IRowFormGroupedProps } from './props';

import { Form } from '../Forms/Form';

import { rowGroupedStyle } from './style';

const RowGrouped = styled.div([rowGroupedStyle]);

export default ({ children, ...rest }: IRowFormGroupedProps) => (
  <RowGrouped styleProps={{ rowType: 'form' }}>
    <Form {...rest}>{children}</Form>
  </RowGrouped>
);
