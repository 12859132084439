import { component } from 'builder';
import { IRowProps } from './props';
import Base from './base';

export const Row = component<IRowProps>({
  name: 'Row',
  base: Base,
  props: {
    children: null,
    spaceBetween: null,
    vAlign: null,
    hAlign: null,
    width: null,
    height: null,
    wrap: true
  }
});
