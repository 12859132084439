import { component } from 'builder';

import { addEvents } from '../core/events';
import { defaultProps, styleProps } from '../core/TextInput/props';
import { inputStyle } from '../core/TextInput/style';
import { IInputMaskedProps } from './props';
import { InputMaskedBase } from './base';

const InputWithEvents = addEvents(InputMaskedBase);

export const InputMasked = component<IInputMaskedProps>({
  name: 'InputMasked',
  base: InputWithEvents,
  style: inputStyle,
  props: {
    ...defaultProps,
    mask: null,
    guide: true
  },
  styleProps
});
