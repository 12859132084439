import { component } from 'builder';
import {
  IDetailListProps,
  IGroupedProps,
  IRowGroupedProps,
  IRowFreeTextGroupedProps,
  IRowFormGroupedProps
} from './props';
import Base from './base';
import GroupedBase from './groupedBase';
import RowFormGroupedBase from './rowFormGroupedBase';
import RowFreeTextGroupedBase from './rowFreeTextGroupedBase';
import RowGroupedBase from './rowGroupedBase';

export const DetailList = component<IDetailListProps>({
  name: 'DetailList',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    children: null,
    heading: null
  }
});
export const DetailListGrouped = component<IGroupedProps>({
  name: 'DetailListGrouped',
  base: GroupedBase,
  container: 'div',
  canOverrideContainer: true,
  props: {
    heading: null,
    action: null,
    children: null,
    noTopBorder: null,
    noBottomBorder: null
  },
  styleProps: ['noBottomBorder', 'noTopBorder', 'action', 'heading']
});
export const DetailListRowGrouped = component<IRowGroupedProps>({
  name: 'DetailListRowGrouped',
  base: RowGroupedBase,
  container: 'div',
  canOverrideContainer: true,
  props: {
    title: null,
    topBorder: null,
    bottomBorder: null,
    grouped: null,
    children: null
  },
  styleProps: ['title', 'topBorder', 'bottomBorder', 'grouped']
});
export const DetailListRowFreetextGrouped = component<IRowFreeTextGroupedProps>(
  {
    name: 'DetailListRowFreetextGrouped',
    base: RowFreeTextGroupedBase,
    container: 'div',
    canOverrideContainer: true,
    props: {
      children: null
    },
    styleProps: ['grouped']
  }
);
export const DetailListRowFormGrouped = component<IRowFormGroupedProps>({
  name: 'DetailListRowFormGrouped',
  base: RowFormGroupedBase,
  container: 'div',
  canOverrideContainer: true,
  props: {
    children: null
  },
  styleProps: ['rowType']
});
