import { component } from 'builder';
import { IDropdownProps } from './props';
import Base from './base';
import controller from './controller';

export const Dropdown = component<IDropdownProps>({
  name: 'Dropdown',
  base: Base,
  props: {
    isHover: false,
    trigger: null,
    children: null,
    isOpen: false,
    isRight: false,
    toggleDropdown: () => {},
    closeDropdown: () => {},
    openDropdown: () => {},
    handlerOverlayClick: () => {}
  }
});

export const StatefulDropdown = controller(Dropdown);
