import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const inputGroup = (colors: IColorsType, bounds: IBoundsType) => ({
  textColor: colors.neutrals.darker,
  backgroundColor: colors.neutrals.lighter,
  borderSize: bounds.border.size,
  borderColor: colors.neutrals.light,
  borderRadius: bounds.border.radius,
  status: {
    focus: {
      borderColor: colors.auxillary,
      outlineColor: colors.auxillary,
      outlineSize: bounds.outline.size + bounds.border.size
    }
  }
});
