import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.ShowHide);
const styler = styleComponent(style);

const wrapperCustomStyleCallback = (styles: any) => styles.ShowHide.Wrapper;
const showHideCustomStyleCallback = (styles: any) => styles.ShowHide.Toggle;

const MIN_WIDTH = 75;

const shadowMixin = (shadow: any) =>
  css`
    inset -1px 0 0 0 ${shadow}, 
    inset 0 1px  0 0 ${shadow}, 
    inset 0 -1px 0 0 ${shadow},
    inset 1px 0 0 0 ${shadow};
  `;

export const wrapperCustomCss = (t: TThemeVariables) => css`
  && {
    .InputWrapper {
      width: calc(100% - 1px);
      border-right: none;
      &:focus,
      &:focus-within,
      &:active {
        box-shadow: inset 0 0 0 2px ${t.shadowColorDark},
          inset -1px 0 0 2px ${t.shadowColorDark};
      }
    }
    > * {
      z-index: 0;
      display: flex;
    }
    > * > :first-child {
      flex-grow: 1;
    }
    > * > :last-child {
      flex-basis: ${MIN_WIDTH}px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 50%;
    }
    > div {
      outline: none;
    }
  }
`;

export const showHideCustomCss = (t: TThemeVariables) => css`
  font-weight: 900;
  min-width: ${MIN_WIDTH}px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  padding: 0;
  line-height: 1.5rem;
  font-size: 1rem;
  position: absolute;

  & svg {
    width: auto;
    height: auto;

    & path {
      stroke-width: unset;
      fill: none;
    }

    & circle,
    & path,
    & line {
      stroke: #a3a3a3;
    }
  }
`;

export const wrapperStyle = styler(
  wrapperCustomCss,
  wrapperCustomStyleCallback
);
export const showHideStyle = styler(
  showHideCustomCss,
  showHideCustomStyleCallback
);
