import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  styleComponent,
  getTypography
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Breadcrumb);
const itemStyler = styleComponent(style);

const link = (t: TThemeVariables) => css`
  padding: ${paddingVertical(1)};
  display: inline-block;
  ${getTypography('Default')};
`;

const themeStyleCallback = (styles: any) => styles.Breadcrumb.Link;

export const linkStyle = itemStyler(link, themeStyleCallback);
