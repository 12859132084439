import { component } from 'builder';
import { IDatePickerDropdownProps } from './props';
import { DatePickerDropdownBase } from './base';

export const DatePickerDropdown = component<IDatePickerDropdownProps>({
  name: 'DatePickerDropdown',
  base: DatePickerDropdownBase,
  props: {
    name: null,
    value: null,
    startYear: new Date().getFullYear() - 120,
    endYear: new Date().getFullYear(),
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    isSmall: false,
    isDisabled: false,
    isClearable: false
  }
});
