import { component } from 'builder';
import { ISpinnerProps } from './props';
import Base from './base';

export const Spinner = component<ISpinnerProps>({
  name: 'Spinner',
  base: Base,
  props: {
    isInverted: false,
    isCentered: false
  }
});
