import { component } from 'builder';
import { IContentProps } from './props';
import Base from './base';

export const Content = component<IContentProps>({
  name: 'Content',
  base: Base,
  props: {
    children: null,
    align: 'left'
  }
});
