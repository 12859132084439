import { css } from 'styled-components';
import { reduce } from 'rambda';

const mediaQueryHandlers = {};

interface Breakpoint {
  min: number;
  max?: number;
}

interface ScreenSizes {
  [size: string]: Breakpoint;
}

export const buildMediaQueries = (screenSizes: ScreenSizes) =>
  reduce(
    (acc: any, label: any) => {
      const accumulator = acc;
      accumulator[label] = (...args: any[]) =>
        css`
          @media (min-width: ${screenSizes[label].min}px) {
            ${(css as any)(...args)};
          }
        `;
      return accumulator;
    },
    mediaQueryHandlers,
    Object.keys(screenSizes)
  );
