import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
export const fileUpload = (colors: IColorsType, bounds: IBoundsType) => ({
  container: {
    backgroundColor: colors.lightest,
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.light,
    borderRadius: bounds.border.radius
  },
  progress: {
    status: {
      success: {
        backgroundColor: colors.success
      },
      error: {
        backgroundColor: colors.error
      }
    }
  }
});
