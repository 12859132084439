import theme, { buildTheme, withCustomTheme } from './theme';
import { getTypography, getColor, getSpacing } from './utils';
export { ThemeProvider } from 'styled-components';
export * from './components';
export const utils = {
  getTypography,
  getColor,
  buildTheme,
  withCustomTheme,
  getSpacing
};

export { theme };
