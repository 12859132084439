import { component } from 'builder';
import React from 'react';
import { components } from 'react-select';
import { ISelectProps, INativeSelectProps } from './props';
import baseStyle from './style';
import nativeStyle from './nativeStyle';
import SelectBase from './base';
import NativeSelectBase from './nativeSelect';

const { DownChevron } = components;
const defaultIconMap = {
  chevronDown: React.createElement(DownChevron)
};

const isMobile =
  window.navigator.msMaxTouchPoints || 'ontouchstart' in document;

const Base = isMobile ? NativeSelectBase : SelectBase;
const style = isMobile ? nativeStyle : baseStyle;

export const Select = component<ISelectProps>({
  name: 'Select',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  style,
  props: {
    name: null,
    isClearable: null,
    isAutocomplete: false,
    placeholder: null,
    items: [],
    value: null,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    isSmall: false,
    isOpen: false,
    isActive: false,
    isDisabled: false,
    labelledby: null,
    icon: null,
    asyncOptions: null,
    noOptionsMessage: null
  },
  styleProps: ['isSmall', 'isActive']
});

export const NativeSelect = component<INativeSelectProps>({
  name: 'NativeSelect',
  base: NativeSelectBase,
  container: 'div',
  canOverrideContainer: true,
  style: nativeStyle,
  props: {
    name: null,
    isAutocomplete: false,
    placeholder: null,
    items: [],
    value: null,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    isSmall: false,
    isOpen: false,
    isActive: false,
    isDisabled: false,
    isRequired: null,
    isInvalid: null,
    icon: null,
    labelledby: null
  },
  styleProps: ['isSmall', 'isActive']
});
