import tc from 'tinycolor2';
import { IColorsType } from '../../core/colors';

export interface IButtonBuilderTheme {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  outlineColor: string;

  inverted: {
    color: string;
    status: {
      disabled: {
        color: string;
      };
      hover: {
        color: string;
      };
      active: {
        color: string;
        backgroundColor: string;
        borderColor: string;
      };
      focus: {
        color: string;
      };
    };
  };
  outline: {
    color: string;
    borderColor: string;
    status: {
      disabled: {
        color: string;
        backgroundColor: string;
      };
      hover: {
        borderColor: string;
        color: string;
      };
      active: {
        color: string;
        backgroundColor: string;
        borderColor: string;
      };
      focus: {
        color: string;
      };
    };
  };

  status: {
    focus: {
      color: string;
      backgroundColor: string;
      borderColor: string;
      outlineColor: string;
    };
    hover: {
      color: string;
      backgroundColor: string;
      borderColor: string;
      outlineColor: string;
    };
    active: {
      color: string;
      backgroundColor: string;
      borderColor: string;
    };
    hoverWhenActive: {
      backgroundColor: string;
      borderColor: string;
    };
  };
}

export const buttonBuilder = (
  colors: IColorsType,
  type: string
): IButtonBuilderTheme => ({
  textColor: colors.lightest,
  backgroundColor: colors[type],
  borderColor: colors[type],
  outlineColor: colors[type],

  inverted: {
    color: colors.lightest,
    status: {
      disabled: {
        color: colors.lightest
      },
      hover: {
        color: colors.lightest
      },
      active: {
        color: colors.lightest,
        backgroundColor: tc(colors[type])
          .darken(10)
          .toString(),
        borderColor: tc(colors[type])
          .darken(17)
          .toString()
      },
      focus: {
        color: colors.lightest
      }
    }
  },

  outline: {
    color: colors[type],
    borderColor: colors[type],
    status: {
      disabled: {
        color: colors[type],
        backgroundColor: colors[type]
      },
      hover: {
        borderColor: colors[type],
        color: colors.lightest
      },
      active: {
        color: colors.lightest,
        backgroundColor: tc(colors[type])
          .darken(10)
          .toString(),
        borderColor: colors[type]
      },
      focus: {
        color: colors.lightest
      }
    }
  },

  status: {
    focus: {
      color: colors.lightest,
      backgroundColor: tc(colors[type])
        .darken(5)
        .toString(),
      borderColor: tc(colors[type])
        .darken(21)
        .toString(),
      outlineColor: tc(colors[type])
        .darken(21)
        .toString()
    },
    hover: {
      color: colors.lightest,
      backgroundColor: tc(colors[type])
        .darken(5)
        .toString(),
      borderColor: tc(colors[type])
        .darken(21)
        .toString(),
      outlineColor: tc(colors[type])
        .darken(21)
        .toString()
    },
    active: {
      color: colors.lightest,
      backgroundColor: tc(colors[type])
        .darken(10)
        .toString(),
      borderColor: tc(colors[type])
        .darken(17)
        .toString()
    },
    hoverWhenActive: {
      backgroundColor: tc(colors[type])
        .darken(17)
        .toString(),
      borderColor: tc(colors[type])
        .darken(30)
        .toString()
    }
  }
});
