import { component } from 'builder';
import { addEvents } from '../core/events';
import {
  defaultProps,
  styleProps,
  IInputTextProps
} from '../core/TextInput/props';
import { inputStyle } from '../core/TextInput/style';
import InputTextBase from './base';

const InputWithEvents = addEvents(InputTextBase);

export const InputText = component<IInputTextProps>({
  name: 'InputText',
  base: InputWithEvents,
  container: 'input',
  style: inputStyle,
  props: {
    ...defaultProps
  },
  styleProps
});
