import { component } from 'builder';
import {
  CardBase,
  CardDeckBase,
  CardListBase,
  CardListItemBase,
  CardWithImageBase
} from './base';
import {
  ICardProps,
  ICardListProps,
  ICardDeckProps,
  ICardItemProps,
  ICardWithImageProps
} from './props';

export const Card = component<ICardProps>({
  name: 'Card',
  base: CardBase,
  props: {
    heading: null,
    children: null,
    icon: null
  }
});

export const CardWithImage = component<ICardWithImageProps>({
  name: 'CardWithImage',
  base: CardWithImageBase,
  props: {
    heading: null,
    children: null,
    image: null
  }
});

export const CardList = component<ICardListProps>({
  name: 'CardList',
  base: CardListBase,
  props: {
    children: null
  }
});

export const CardListItem = component<ICardItemProps>({
  name: 'CardListItem',
  base: CardListItemBase,
  canOverrideContainer: true,
  container: 'a',
  props: {
    icon: null,
    label: null,
    description: null,
    hasArrow: false,
    isActive: false
  }
});

export const CardDeck = component<ICardDeckProps>({
  name: 'CardDeck',
  base: CardDeckBase,
  props: {
    heading: null,
    background: null,
    children: null,
    button: null
  }
});
