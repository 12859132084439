import React from 'react';
import { Heading, Content, Default, Hint, Icon } from '@parmenion/library';
import { shouldUpdate } from 'recompose';
import styled from 'styled-components';
import components from './all-components';
import {
  PageHeader,
  Box,
  Horizontal,
  Size,
  DebugBox,
  Wrapper,
  Link
} from '../ui';
import { ComponentSearch } from './search';
import placeholder from '../assets/placeholder.png';
import { formatDate, omitTimeZone } from './format-date';

var options = {
  keys: ['name', 'meta.description', 'meta.tags'],
  shouldSort: true,
  threshold: 0.8,
  findAllMatches: true
};

const memoizeCacheKeyResolver = key => `${key}`;

export default () => (
  <Wrapper>
    <PageHeader
      header="Components"
      lead="All components are listed below. You can search this list by name, description and tags."
    />
    <ComponentSearch
      itemsToSearch={components.modules[0].components}
      options={options}
      onMatch={match => (
        <SearchResult
          key={match.key}
          name={match.key}
          title={match.name}
          description={match.meta.description}
          moduleName={'all'}
          log={match.log}
        />
      )}
    />
  </Wrapper>
);

const SearchResult = shouldUpdate(() => false)(
  ({ title, name, description, moduleName, log }) => (
    <Box key={name} w={[1]} horizontal wrap={false} spaceBetween={2}>
      <Box w={[200]}>
        <ComponentThumbnail to={`/components/${moduleName}/${name}`}>
          <ComponentTitle>
            <img width="180" src={placeholder} />
          </ComponentTitle>
        </ComponentThumbnail>
      </Box>
      <Box fill shrink vAlign="top">
        <Heading>
          <Link to={`/components/${moduleName}/${name}`}>{title}</Link>
        </Heading>
        <Default>
          {description || 'Please add a description for this component'}
        </Default>
        <FormatLog log={log} />
      </Box>
    </Box>
  )
);

const FormatItem = ({ children, icon }) => (
  <Box key={icon} horizontal vAlign="center">
    <Icon name={icon} />
    <Hint>{children}</Hint>
  </Box>
);

const FormatLog = ({ log }) => {
  const [hash, date, description, author] = log.split('///');
  return (
    <Box key={hash} horizontal vAlign="center">
      <FormatItem icon="check">{hash}</FormatItem>
      <FormatItem icon="calendar">{formatDate(omitTimeZone(date))}</FormatItem>
      <FormatItem icon="infoCircle">{description}</FormatItem>
      <FormatItem icon="user">{author}</FormatItem>
    </Box>
  );
};

const ComponentThumbnail = styled(Link)`
  position: relative;
  overflow: hidden;
  height: 160px;
`;

const ComponentTitle = styled.div`
  font-family: monospace;
  text-align: center;
  font-size: 24px;
  text-align: left;
`;
