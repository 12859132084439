import { css } from 'styled-components';

import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Checkbox);
const radioStyler = styleComponent(style);

const wrapperStyleCb = (styles: any) => styles.Checkbox.Wrapper;
const inputWrapperCb = (styles: any) => styles.Checkbox.InputWrapper;
const inputCb = (styles: any) => styles.Checkbox.Input;

const wrapperCss = (t: TThemeVariables) => css`
  display: inline-block;
`;

const inputWrapperCss = (t: TThemeVariables) => css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${px(t.label.offset)};
`;

const inputCss = (t: TThemeVariables) => css`
  position: absolute;
  top: 50%;
  left: 0;
  width: ${px(t.input.width)};
  height: ${px(t.input.height)};
  margin: 0;
  transform: translate(0, -50%);
`;

export const wrapperStyle = radioStyler(wrapperCss, wrapperStyleCb);

export const inputWrapperStyle = radioStyler(inputWrapperCss, inputWrapperCb);

export const inputStyle = radioStyler(inputCss, inputCb);
