import { css } from 'styled-components';
import {
  px,
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent
} from 'utils';
import { ITheme } from 'theme';
import { IAlertStatus } from '../props';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Alert);
const alertStyler = styleComponent(style);

const paddingMixin = (isSmall: any) =>
  isSmall
    ? css`
        ${paddingVertical(0)} ${paddingHorizontal(0)};
      `
    : css`
        ${paddingHorizontal(1)};
      `;

const statusMixin = (
  t: ITheme['components']['Alert'],
  status: IAlertStatus
) => {
  const statusTheme = t.hasOwnProperty(status) ? t[status] : t.default;
  return css`
    border: ${px(t.borderSize)} solid ${statusTheme.borderColor};
    background: ${statusTheme.backgroundColor};
    color: ${statusTheme.textColor};
    fill: ${statusTheme.textColor};
  `;
};

const defaultStyleCss = (
  t: TThemeVariables,
  { hasArrow, isSmall, status }: any
) =>
  css`
    position: relative;
    width: 100%;
    text-align: left;

    padding: ${paddingMixin(isSmall)};
    margin-top: ${hasArrow ? px(10) : 0};
    border-radius: ${px(t.borderRadius)};

    ${statusMixin(t, status)};
  `;

const alertWrapperStyleCallback = (styles: any) => styles.Alert.Wrapper;

export const defaultStyle = alertStyler(
  defaultStyleCss,
  alertWrapperStyleCallback
);
