import React from 'react';
import styled from 'styled-components';

import { wrapperStyle, circleStyle } from './styles';

const Wrapper = styled.span([wrapperStyle]);
const Circle = styled.span([circleStyle]);

export default ({ isInverted, isCentered }: any) => (
  <Wrapper styleProps={{ isCentered }}>
    <Circle styleProps={{ isInverted }} />
    <Circle styleProps={{ isInverted }} />
    <Circle styleProps={{ isInverted }} />
  </Wrapper>
);
