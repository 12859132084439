import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  px,
  getTypography
} from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.InputGroup);

const focusMixin = (t: any, childHasFocus: boolean) =>
  childHasFocus
    ? css`
        ${t.borderRadius != null && t.borderRadius > 0
          ? css`
              outline: none;
              box-shadow: 0 0 0 ${px(t.status.focus.outlineSize)}
                ${t.status.focus.outlineColor};
            `
          : css`
              outline: ${px(t.status.focus.outlineSize)} solid
                ${t.status.focus.outlineColor};
              box-shadow: none;
            `};

        outline-offset: 0;

        *.is-focused:not(.is-open) > [class$='control'],
        *:focus {
          outline: none;
          box-shadow: none;
          border-color: ${t.borderColor};
        }
      `
    : '';

const widthMixin = (width?: number) => (!!width ? `${width}rem` : '100%');

export const InnerInputGroup = styled.div([
  style(
    (t, { childHasFocus, width }) => css`
      position: relative;
      display: table;
      width: ${widthMixin(width)};
      border-collapse: separate;
      border-radius: ${px(t.borderRadius)};
      ${focusMixin(t, childHasFocus)};
    `
  )
]);
InnerInputGroup.displayName = 'InnerInputGroup';

export const InputContainer = styled.div`
  display: table-cell;
  vertical-align: top;
  &:not(:last-child) input,
  &:not(:last-child) [role='listbox'],
  &:not(:last-child) [class$='control'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:not(:first-child) input,
  &:not(:first-child) [role='listbox'],
  &:not(:first-child) [class$='control'] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & + & input,
  & + & [role='listbox'],
  & + & [class$='control'],
  [class$='control'] + [class$='control'] {
    border-left: 0;
  }
`;

export const AddonString = styled.div([
  style(
    t => css`
      padding: 0 ${paddingHorizontal(1)};
      ${getTypography('Default')};
      color: ${t.textColor};
      background-color: ${t.backgroundColor};
      border: ${px(t.borderSize)} solid ${t.borderColor};
      border-radius: ${px(t.borderRadius)};
      display: table-cell;
      width: 1%;
      font-weight: normal;
      line-height: 1;
      vertical-align: middle;
      text-align: center;
      white-space: nowrap;
      &:first-child {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `
  )
]);

export const AddonButton = styled.span([
  style(
    t => css`
      display: table-cell;
      position: relative;
      width: 1%;
      white-space: nowrap;
      vertical-align: middle;
      > * {
        box-shadow: none;
        margin-left: -${px(t.borderSize)};
        &:hover,
        &:focus,
        &:active {
          z-index: 2;
        }
      }
      &:first-child > * {
        margin-left: 0;
        margin-right: -${px(t.borderSize)};
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child > * {
        z-index: 2;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `
  )
]);
