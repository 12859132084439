import styled, { css } from 'styled-components';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Alert);
const wrapperStyler = styleComponent(style);
const wrapperStyle = (t: TThemeVariables) => css`
  > * + * {
    margin-top: ${px(0)};
  }
`;

const themeStyleCallback = (styles: any) => styles.Alert.ContentWrapper;

export default styled.div([wrapperStyler(wrapperStyle, themeStyleCallback)]);
