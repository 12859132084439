import styled, { css } from 'styled-components';
import {
  getTypography,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Alert);
const headerStyler = styleComponent(style);

const headerStyle = (t: TThemeVariables) => css`
  ${getTypography('Heading')};
  margin: 0;
  color: inherit;
`;

const themeStyleCallback = (styles: any) => styles.Alert.Header;

export default styled.div([headerStyler(headerStyle, themeStyleCallback)]);
