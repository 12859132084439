import React from 'react';
import styled from 'styled-components';

import { label, success, warning, danger, neutral } from './styles/label';

const Default = styled.label([label]);
const Success = styled.label([success]);
const Warning = styled.label([warning]);
const Danger = styled.label([danger]);
const Neutral = styled.label([neutral]);

const CHECKBOX_STATUS_MAP = {
  success: Success,
  warning: Warning,
  danger: Danger,
  neutral: Neutral,
  default: Default
};

export const LabelBase = ({ isSmall, status, children }: any) => {
  const Label = CHECKBOX_STATUS_MAP[status];
  return <Label styleProps={{ isSmall }}>{children}</Label>;
};
