import { css } from 'styled-components';
import { TThemeVariables } from './types';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.Tabs);
const tabStyler = styleComponent(style);

const wrapperCss = (t: TThemeVariables) => css`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;

  background-color: white;

  && .tab-bar {
    border-bottom: 1px solid lightGrey;
    width: 100%;
    margin: 0.25rem auto;
    padding-bottom: 0;
    margin-bottom: 1rem;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
  }

  .tab-content {
    height: 100%;
  }
`;

const barCss = (t: TThemeVariables) => css`
  & div {
    margin: 0.5rem;
    padding: 0.5rem;
  }
`;

const itemCss = (t: TThemeVariables) => css`
  background-color: white;
  color: black;

  display: flex;
  flex: 0 1 auto;
  align-items: center;

  list-style: none;
  margin-bottom: -1px;
  color: #4a4a4a;
  line-height: 1.5rem;
  padding: 16px 24px;
  text-align: center;
  cursor: default;
  flex: 0 1 auto;

  &.tab-item-active,
  &.tab-item:active {
    color: red;
    box-shadow: inset 0 -3px 0 0 red;
  }

  &.tab-item {
    :hover:not(.tab-item-active) {
      color: black;
      box-shadow: inset 0 -3px 0 0 black;
    }

    :not(.tab-item-active) {
      border-bottom: 1px solid lightgrey;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
`;

const wrapperStyleCallback = (styles: any) => styles.Tabs.Wrapper;
export const wrapperStyle = tabStyler(wrapperCss, wrapperStyleCallback);

const barStyleCallback = (styles: any) => styles.Tabs.Bar;
export const barStyle = tabStyler(barCss, barStyleCallback);

const itemStyleCallback = (styles: any) => styles.Tabs.Item;
export const itemStyle = tabStyler(itemCss, itemStyleCallback);
