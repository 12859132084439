import { IColorsType } from '../core/colors';

export const spinnerWithOverlay = (colors: IColorsType) => ({
  backgroundColor: colors.lightest,
  opacity: 0.75,
  inverted: {
    backgroundColor: colors.darkest,
    opacity: 0.5
  }
});
