import React from 'react';
import styled, { css } from 'styled-components';

import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { Box } from '../../core';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Modal);

const BodyStyle = (t: TThemeVariables) => css`
  min-height: 40px;
  background-color: ${t.body.backgroundColor};
`;

const styler = styleComponent(style);
const styleCallback = (styles: any) => styles.Modal.Body;

const StyledContent = styled.div([styler(BodyStyle, styleCallback)]);

export default ({ children }: any) => (
  <StyledContent>
    <Box>{children}</Box>
  </StyledContent>
);
