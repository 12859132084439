import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  getBoxShadow,
  px,
  styleComponent
} from 'utils';
import { TThemeVariables } from './types';
import { getScale, positionProps, rotationProps } from './patternUtils';
import { IPositionProps } from './props';

const style = selectComponentThemeVariablesFor(theme => theme.Pattern);
const patternStyler = styleComponent(style);

const BackgroundCss = (props: any) => css`
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  background-color: ${props.background};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  overflow: hidden;
  z-index: -1;
`;

export const Background = styled.div(BackgroundCss);
Background.displayName = 'Background';

const PositionCss = (t: TThemeVariables) => css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
  > svg {
    position: absolute;
    display: block;
    fill: ${t.fill};

    ${(props: any) => getScale(props)};
    ${(props: any) =>
      props.position &&
      positionProps(rotationProps[props.rotate])(props.position)};
  }
`;
const positionThemeStyleCallback = (styles: any) => styles.Pattern.Position;

export const Position = styled.div<IPositionProps>([
  patternStyler(PositionCss, positionThemeStyleCallback)
]);
Position.displayName = 'Position';
