export enum CookieCategories {
  liveChat = 'liveChat',
  marketing = 'marketing',
  necessary = 'necessary'
}

export type CookieCategoryCookie = {
  name: string;
  source: string;
  purpose: string;
  expiry: string;
};

export const cookieCategoryCookies = {
  [CookieCategories.liveChat]: [
    {
      name: 'liveagent_sid',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: 'End of session'
    },
    {
      name: 'liveagent_chatted',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: 'End of session'
    },
    {
      name: 'liveagent_vc',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: '1 year'
    },
    {
      name: 'liveagent_oref',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: '1 year'
    },
    {
      name: 'liveagent_ptid',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: '1 year'
    }
  ],
  [CookieCategories.marketing]: [
    {
      name: 'ao_optin',
      source: 'Sales Force - Act On',
      purpose: 'Marketing',
      expiry: '1 year'
    },
    {
      name: 'ao_optout',
      source: 'Sales Force - Act On',
      purpose: 'Marketing',
      expiry: '1 year'
    }
  ],
  [CookieCategories.necessary]: [
    {
      name: 'parmenion-cookie-preferences',
      source: 'Parmenion',
      purpose: 'Optional Cookie Management',
      expiry: '6 months'
    },
    {
      name: '"liveagent_invite_rejected_"+this.buttonId',
      source: 'Sales Force - Live chat',
      purpose: 'Allows live chat assistance',
      expiry: 'End of session'
    },
    {
      name: 'ApplicationGatewayAffinity',
      source: 'Load Balancer',
      purpose: 'Session Management',
      expiry: 'End of session'
    },
    {
      name: 'ApplicationGatewayAffinityCORS',
      source: 'Load Balancer',
      purpose: 'Session Management',
      expiry: 'End of session'
    },
    {
      name: '.Auth',
      source: 'IdentityManagement',
      purpose: 'Parmenion',
      expiry: 'End of session'
    },
    {
      name: '2FAuth',
      source: 'Parmenion',
      purpose: 'Identity Management',
      expiry: '<1 day'
    },
    {
      name: 'ASP.Net_SessionId',
      source: 'Parmenion',
      purpose: 'Web session management',
      expiry: 'End of session'
    },
    {
      name: 'XSRF-TOKEN',
      source: 'Parmenion',
      purpose: 'Security',
      expiry: 'End of session'
    },
    {
      name: '.AspNetCore.Antiforgery',
      source: 'Parmenion',
      purpose: 'Security',
      expiry: 'End of session'
    },
    {
      name: '_RequestVerificationToken',
      source: 'Parmenion',
      purpose: 'Security',
      expiry: 'End of session'
    }
  ]
};
