import styled, { css } from 'styled-components';

const MARGIN_DIRECTION = {
  forward: 'margin-top',
  reverse: 'margin-bottom'
};

const defaultMargin = '3rem';

const getSpacingForEachBreakPoint = (breakPoints: any, marginDirection: any) =>
  Object.keys(breakPoints).map(
    key =>
      css`
        @media (min-width: ${props => props.theme.breakpoints[key].max}px) {
          ${marginDirection}: ${breakPoints[key]};
        }
      `
  );

const addSpaceBetweenForEachBreakPoint = (
  breakPoints: any,
  spaceBetween: any
) => Object.keys(breakPoints).forEach(key => (breakPoints[key] = spaceBetween));

const stackedStyle = ({
  spaceBetween,
  isReversed,
  theme,
  hAlign,
  vAlign
}: any) => {
  let breakPointSpacing = Object.keys(theme.breakpoints).reduce(
    // eslint-disable-next-line
    (accum, key) => ((accum[key] = defaultMargin), accum),
    {}
  );
  const marginDirection = isReversed
    ? MARGIN_DIRECTION['reverse']
    : MARGIN_DIRECTION['forward'];

  if (typeof spaceBetween === 'string') {
    addSpaceBetweenForEachBreakPoint(breakPointSpacing, spaceBetween);
  }

  if (typeof spaceBetween === 'object') {
    if (spaceBetween) breakPointSpacing = spaceBetween;
  }
  return css`
    width: 100%;
    display: flex;
    flex-direction: ${isReversed ? `column-reverse` : `column`};
    justify-content: ${hAlign || 'unset'};
    align-items: ${vAlign || 'unset'};
    && > * + * {
      ${getSpacingForEachBreakPoint(breakPointSpacing, marginDirection)};
    }
  `;
};

export default styled.span`
  ${(props: any) => stackedStyle(props)};
`;
