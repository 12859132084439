import React from 'react';
import styled from 'styled-components';
import {
  DropTarget,
  DropTargetConnector,
  ConnectDropTarget,
  DropTargetMonitor
} from 'react-dnd';

import { dragContainer } from '../style';

const Container = styled.div([dragContainer]);
const boxTarget = {
  drop(props: TargetBoxProps, monitor: DropTargetMonitor) {
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  }
};

export interface TargetBoxProps {
  accepts: string[];
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  canDrop?: boolean;
  onDrop: (props: TargetBoxProps, monitor: DropTargetMonitor) => void;
}

class TargetBox extends React.Component<TargetBoxProps> {
  public render() {
    const { canDrop, isOver, connectDropTarget } = this.props;
    const isActive = canDrop && isOver;

    return (
      connectDropTarget &&
      connectDropTarget(
        <div>
          <Container>
            {isActive ? 'Release to drop' : 'Drag file(s) here'}
          </Container>
        </div>
      )
    );
  }
}

export default DropTarget(
  (props: TargetBoxProps) => props.accepts,
  boxTarget,
  (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(TargetBox) as typeof TargetBox;
