import { component } from 'builder';
import { IFormFieldProps } from './props';
import { VerticalFormFieldBase } from './base/field';

export const FormFieldVertical = component<IFormFieldProps>({
  name: 'FormFieldVertical',
  base: VerticalFormFieldBase,
  props: {
    hasError: false,
    hasWarning: false,
    children: null,
    error: null,
    warning: null,
    label: null,
    htmlFor: null,
    id: null,
    helpText: null,
    underHelpText: null,
    showErrorAbove: false
  }
});
