import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.Stepper);

const stepperStyler = styleComponent(style);

const wrapperCss = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  flex: 0 1 auto;
  > * {
    flex: 0 1 auto;
  }
`;

export const inputWrapperCss = css`
  & > * {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;

const wrapperStyleCallback = (styles: any) => styles.Stepper.Wrapper;

export const wrapperStyle = stepperStyler(
  wrapperCss,
  wrapperStyleCallback
) as any;
