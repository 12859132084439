import React from 'react';
import styled from 'styled-components';
import { wrapperStyle, circleStyle, contentStyle, svgStyle } from './style';

const Wrapper = styled.div([wrapperStyle]);
const Circle = styled.circle([circleStyle]);
const Content = styled.div([contentStyle]);
const Svg = styled.svg([svgStyle]);

export default ({ children, progress, color }: any) => (
  <Wrapper>
    <Svg viewBox="0 0 32 32">
      <Circle r="16" cx="16" cy="16" styleProps={{ progress, color }} />
    </Svg>
    <Content>{children}</Content>
  </Wrapper>
);
