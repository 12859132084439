import { component } from 'builder';
import { ISvgProps } from './props';
import { SvgBase } from './base';

export const Svg = component<ISvgProps>({
  name: 'Svg',
  base: SvgBase,
  props: {
    image: null,
    stroke: null,
    viewBox: null
  }
});
