import React from 'react';
import ReactDOM from 'react-dom';
import { htmlSafeString } from 'utils';
import { IAccordionPanelProps } from './props/';
import { InnerPanel, Heading, Title, Body, BodyInner, Arrow } from './styles';

Heading.displayName = 'Heading';
Body.displayName = 'Body';
Title.displayName = 'Title';

type StateType = {
  height: number;
};

const keyMap = {
  enter: 'Enter',
  space: ' '
};

export default class AccordionPanel extends React.Component<
  IAccordionPanelProps,
  StateType
> {
  body: any;

  static defaultProps = {
    isOpen: false
  };

  constructor(props: IAccordionPanelProps) {
    super(props);
    this.state = { height: 0 };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.toggleOpen();
  }

  componentDidUpdate() {
    this.toggleOpen();
  }

  toggleOpen() {
    const domNode = ReactDOM.findDOMNode(this.body);
    if (
      this.props.isOpen &&
      this.state.height === 0 &&
      domNode instanceof HTMLElement
    ) {
      this.setState({ height: domNode.scrollHeight });
    } else if (!this.props.isOpen && this.state.height > 0) {
      this.setState({ height: 0 });
    }
  }

  handleKeyDown({ key }: React.KeyboardEvent<HTMLInputElement>) {
    if (key != null && (key === keyMap.enter || key === keyMap.space))
      this.props.onClickHeader && this.props.onClickHeader();
  }

  render() {
    const {
      isOpen,
      onClickHeader,
      title,
      children,
      icon,
      ariaLevel,
      ...rest
    } = this.props;

    const iconComponent = icon != null ? icon : null;

    return (
      <InnerPanel {...rest} aria-expanded={isOpen ? true : false}>
        <Heading
          onClick={onClickHeader}
          onKeyDown={this.handleKeyDown}
          aria-controls={htmlSafeString(`${title}-region`)}
          role={'heading'}
          aria-level={ariaLevel ? ariaLevel : '3'}
          tabIndex={0}
          styleProps={{ isOpen }}
        >
          <Title id={htmlSafeString(`${title}-label`)}>{title}</Title>
          <Arrow styleProps={{ isOpen }}>{iconComponent}</Arrow>
        </Heading>

        <Body
          aria-labelledby={htmlSafeString(`${title}-label`)}
          id={htmlSafeString(`${title}-region`)}
          role={'region'}
          ref={(ref: any) => (this.body = ref)}
          styleProps={{ height: this.state.height }}
        >
          <BodyInner>{children}</BodyInner>
        </Body>
      </InnerPanel>
    );
  }
}
