import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from 'theme/core/bounds';

export const accordion = (colors: IColorsType, bounds: IBoundsType) => ({
  headingColor: colors.darkest,
  headingBackgroundColor: colors.neutrals.lightest,

  panelBackgroundColor: colors.lightest,
  panelBorderSize: bounds.border.size,
  panelBorderColor: colors.neutrals.lighter,
  panelBorderRadius: bounds.border.radius,

  status: {
    isOpen: {
      headingBackgroundColor: tc(colors.neutrals.lightest)
        .darken(5)
        .toString()
    },
    hoverWhenOpen: {
      headingBackgroundColor: tc(colors.neutrals.lightest)
        .darken(7)
        .toString()
    },
    hover: {
      headingBackgroundColor: tc(colors.neutrals.lightest)
        .darken(3)
        .toString()
    }
  },
  arrowBackgroundColor: colors.neutrals.dark,
  arrowBorderColor: colors.active.primary.color,
  arrowHoverBackgroundColor: colors.active.primary.background
});
