import tc from 'tinycolor2';
import { IColorsType } from '../../core/colors';
import { IBoundsType } from '../../core/bounds';

export const baseButton = (colors: IColorsType, bounds: IBoundsType) => ({
  borderColor: tc(colors.neutrals.light)
    .lighten(10)
    .toString(),
  backgroundColor: colors.lightest,
  textColorLight: colors.lightest,
  textColorDark: colors.darkest,
  textColor: colors.neutrals.dark,
  borderRadius: bounds.border.radius,
  borderSize: bounds.border.size,

  inverted: {
    color: colors.lightest,
    status: {
      disabled: {
        color: colors.neutrals.dark
      },
      hover: {
        color: colors.neutrals.dark
      },
      active: {
        color: colors.neutrals.dark,
        backgroundColor: tc(colors.lightest)
          .darken(10)
          .toString(),
        borderColor: tc(colors.neutrals.light)
          .darken(17)
          .toString()
      },
      focus: {
        color: colors.neutrals.dark
      }
    }
  },

  outline: {
    color: colors.neutrals.dark,
    status: {
      disabled: {
        color: colors.neutrals.dark
      },
      hover: {
        borderColor: colors.lightest,
        color: colors.neutrals.dark
      },
      active: {
        color: colors.neutrals.dark,
        backgroundColor: tc(colors.lightest)
          .darken(10)
          .toString(),
        borderColor: tc(colors.neutrals.light)
          .darken(17)
          .toString()
      },
      focus: {
        color: colors.neutrals.dark
      }
    }
  },

  status: {
    focus: {
      backgroundColor: tc(colors.neutrals.lightest)
        .darken(5)
        .toString(),
      borderColor: tc(colors.neutrals.light)
        .darken(21)
        .toString(),
      outlineColor: tc(colors.neutrals.light)
        .darken(21)
        .toString()
    },
    hover: {
      backgroundColor: tc(colors.lightest)
        .darken(5)
        .toString(),
      borderColor: tc(colors.neutrals.light)
        .darken(17)
        .toString()
    },
    hoverWhenActive: {
      backgroundColor: tc(colors.lightest)
        .darken(17)
        .toString(),
      borderColor: tc(colors.neutrals.light)
        .darken(30)
        .toString()
    },
    active: {
      backgroundColor: tc(colors.lightest)
        .darken(10)
        .toString(),
      borderColor: tc(colors.neutrals.light)
        .darken(17)
        .toString()
    }
  }
});
