import { css } from 'styled-components';
import {
  paddingVertical,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Breadcrumb);
const breadcrumbWrapperStyler = styleComponent(style);

const cssStyle = (t: TThemeVariables) => css`
  list-style: none;
  display: inline;
  :before {
    content: '';
    display: inline;
    padding: ${paddingVertical(1)};
  }
  & + &:before {
    content: '/';
  }
`;

const themeStyleCallback = (styles: any) => styles.Breadcrumb.ItemWrapper;

export const ItemWrapperStyle = breadcrumbWrapperStyler(
  cssStyle,
  themeStyleCallback
);
