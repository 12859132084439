import {
  SuccessButton,
  SuccessInvertedButton,
  SuccessOutlineButton
} from '../Buttons/';
import { builder } from './builder';

export const SuccessButtonWithIcon = builder(
  'SuccessButtonWithIcon',
  SuccessButton
);

export const SuccessInvertedButtonWithIcon = builder(
  'SuccessInvertedButtonWithIcon',
  SuccessInvertedButton
);

export const SuccessOutlineButtonWithIcon = builder(
  'SuccessOutlineButtonWithIcon',
  SuccessOutlineButton
);
