import React from 'react';
import styled from 'styled-components';
import { components } from 'react-select';
import { Icon, iconFactory } from '../../Icon';
import { inputStyle, indicatorsStyle } from './nativeStyle';
import { ISelectOption } from './props';

const { DownChevron } = components;

const Select = styled.select([inputStyle]);
const Indicators = styled.div([indicatorsStyle]);

const getValueFromSelectedOption = (onChange: any) => (
  event: React.SyntheticEvent<HTMLInputElement>
) => {
  onChange(event.currentTarget.value);
};

const addPlaceHolder = (values: Array<ISelectOption>, placeholder: string) =>
  placeholder != null
    ? [...[{ id: '', name: placeholder }], ...values]
    : values;

const callbackHandlerWithValue = (callback: any, value: any) => (
  event: React.SyntheticEvent<HTMLInputElement>
) => {
  if (callback != null) callback(value);
};

export default (props: any) => {
  const {
    Container = 'div',
    name,
    icon,
    isAutocomplete,
    isClearable,
    isOpen,
    labelledby,
    isSmall,
    isActive,
    isDisabled,
    isRequired,
    isInvalid,
    items,
    placeholder,
    value,
    className,
    onChange,
    onBlur,
    onFocus,
    ...rest
  } = props;

  const itemsWithPlaceHolder = addPlaceHolder(items, placeholder);

  const iconComponent = icon !== null ? icon : <DownChevron />;
  const ariaProps = {
    'aria-required': false,
    'aria-invalid': false
  };
  if (labelledby !== null) ariaProps['aria-labelledby'] = labelledby;
  if (isRequired !== null) ariaProps['aria-required'] = isRequired;
  if (isInvalid !== null) ariaProps['aria-invalid'] = isInvalid;

  return (
    <Container {...rest} className={`${className} customSelect__control`}>
      <Select
        {...rest}
        {...ariaProps}
        onChange={getValueFromSelectedOption(onChange)}
        onFocus={callbackHandlerWithValue(onFocus, value)}
        onBlur={callbackHandlerWithValue(onBlur, value)}
        value={value}
        disabled={isDisabled}
        styleProps={{ isSmall, isActive }}
        name={name}
      >
        {itemsWithPlaceHolder.map((item: ISelectOption) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </Select>
      <Indicators styleProps={{ isSmall }}>
        <span>{iconComponent}</span>
      </Indicators>
    </Container>
  );
};
