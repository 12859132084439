import React from 'react';
import styled from 'styled-components';

type TouchableProps = {
  onClick: Function;
  children: React.ReactNode;
};

const TouchableOpacityStyle = styled.span`
  transition: opacity 150ms;
  cursor: pointer;
  user-select: none;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:active {
    opacity: 0.5;
  }
` as any;

export const Touchable = (props: TouchableProps) => (
  <TouchableOpacityStyle role="button" {...props}>
    {props.children}
  </TouchableOpacityStyle>
);
