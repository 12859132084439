import React from 'react';
import { InputText as Sizer } from '../InputText';

interface IProps {
  setDimensions: (inputDimensions?: {
    charWidth?: number;
    padding?: number;
  }) => void;
}

export default class MeasureInputCharacterSize extends React.PureComponent<
  IProps
> {
  isInitialRender: boolean = true;
  sizeRef: any;

  componentDidMount() {
    this.setMinTextWidth();
  }

  setMinTextWidth() {
    this.isInitialRender = false;
    const { font, paddingLeft, paddingRight } = window.getComputedStyle(
      this.sizeRef
    );
    const sizeNode = document.createElement('span');
    const text = document.createTextNode('W');
    sizeNode.setAttribute('id', 'sizeNode');
    sizeNode.setAttribute(
      'style',
      `
        visibility:hidden;
        pointer-events:none;
        position:absolute;
        font:${font};
        box-sizing: border-box;
      `
    );
    sizeNode.appendChild(text);
    document!.querySelector('body')!.appendChild(sizeNode);

    const sizer = document!.getElementById('sizeNode');
    this.props.setDimensions({
      charWidth: sizer!.clientWidth,
      padding: parseFloat(paddingLeft!) + parseFloat(paddingRight!)
    });

    document!.querySelector('body')!.removeChild(sizeNode);
  }

  render() {
    return this.isInitialRender ? (
      <div
        style={{
          position: 'absolute',
          visibility: 'hidden',
          pointerEvents: 'none'
        }}
      >
        <Sizer id="sizeCheck" inputRef={(node: any) => (this.sizeRef = node)} />
      </div>
    ) : null;
  }
}
