import React from 'react';
import ReactDOM from 'react-dom';
import marked from 'marked';
import highlight from 'highlight.js';
import 'highlight.js/styles/github.css';
import { Content } from '@parmenion/library';

marked.setOptions({
  renderer: new marked.Renderer(),
  gfm: true,
  pedantic: false,
  sanitize: false
});

export class Markdown extends React.Component {
  componentDidMount() {
    this.highlightCode();
  }

  componentDidUpdate() {
    this.highlightCode();
  }

  highlightCode() {
    const domNode = ReactDOM.findDOMNode(this);
    const nodes = domNode.querySelectorAll('pre code');
    Object.keys(nodes).map(key => {
      return highlight.highlightBlock(nodes[key]);
    });
  }

  render() {
    const html = marked(this.props.source);

    return (
      <Content>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Content>
    );
  }
}
