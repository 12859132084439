import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  px,
  getBoxShadow,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Bar);
const containerStyler = styleComponent(style);

const containerCss = (t: TThemeVariables) => css`
  margin: 0;
  height: ${px(t.container.height)};
  background-color: ${t.container.backgroundColor};
  box-shadow: ${getBoxShadow('normal')};
`;

const themeStyleCallback = (styles: any) => styles.Bar.Container;

export const container = containerStyler(
  containerCss,
  themeStyleCallback
) as any;
