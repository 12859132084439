import React from 'react';
import { ItemWrapperStyle } from './breadcrumb-item-wrapper';
import styled from 'styled-components';

const ItemWrapper = styled.li([ItemWrapperStyle]);

export const Base = (props: any) => {
  const { Container, ...rest } = props;
  return (
    <ItemWrapper>
      <Container {...rest} href={props.href} onClick={props.onClick}>
        {props.label}
      </Container>
    </ItemWrapper>
  );
};
