import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
import tc from 'tinycolor2';

export const menu = (colors: IColorsType, bounds: IBoundsType) => ({
  innerMenu: {
    backgroundColor: colors.lightest
  },

  link: {
    color: colors.neutrals.darker,
    backgroundColor: colors.lightest,
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.lighter,
    states: {
      active: {
        color: colors.active.primary.color,
        borderColor: colors.transparent,
        backgroundColor: colors.active.primary.background
      },
      focus: {
        color: colors.active.primary.color,
        backgroundColor: colors.active.primary.background
      },
      hoverActive: {
        backgroundColor: tc(colors.active.primary.background)
          .darken(17)
          .toString()
      }
    }
  }
});
