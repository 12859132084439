import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon/';
import {
  wrapperStyle,
  resultLinkStyle,
  iconWrapperStyle,
  textWrapperStyle
} from './style';

const LinkWrapper = styled.a([wrapperStyle]);
const ResultLink = styled.a([resultLinkStyle]);
const IconWrapper = styled.div([iconWrapperStyle]);
const TextWrapper = styled.div([textWrapperStyle]);

export const Link = ({ children, ...rest }: any) => (
  <LinkWrapper {...rest}>{children}</LinkWrapper>
);

const createLinkWithIcon = (iconName: any) => ({
  children,
  direction = 'rtl',
  onClick = null,
  ...rest
}: any) => (
  <LinkWrapper
    {...rest}
    styleProps={{ direction }}
    onClick={event => {
      if (onClick) {
        event.preventDefault();
        onClick(event);
      }
    }}
  >
    <span>{children}</span>
    <Icon name={iconName} />
  </LinkWrapper>
);

export const LinkDownload = createLinkWithIcon('download');
export const LinkChevronRight = createLinkWithIcon('chevronRight');
export const LinkExternal = createLinkWithIcon('externalLink');
export const LinkPdf = createLinkWithIcon('filePdf');
export const LinkInfo = createLinkWithIcon('infoCircle');
export const LinkQuestion = createLinkWithIcon('questionCircle');
export const LinkToggleOpen = createLinkWithIcon('chevronRight');
export const LinkToggleClose = createLinkWithIcon('chevronDown');

export const LinkResult = ({ children, ...rest }: any) => (
  <ResultLink {...rest} styleProps={{ direction: 'rtl' }}>
    <TextWrapper>{children}</TextWrapper>
    <IconWrapper>
      <Icon name="chevronRight" />
    </IconWrapper>
  </ResultLink>
);
