import { component } from 'builder';
import { IButtonProps } from './props';
import { ButtonBase } from './ButtonBase';

export const builder = (style?: any, name: string = 'Button') => {
  return component<IButtonProps>({
    name,
    style,
    base: ButtonBase,
    container: 'button',
    canOverrideContainer: true,
    props: {
      children: null,
      name: null,
      value: null,
      type: 'button',
      isDisabled: false,
      isActive: false,
      isSmall: false,
      onClick: () => {},
      onMouseDown: null,
      id: null,
      'aria-describedby': null,
      'aria-label': null,
      noWrap: false
    },
    styleProps: ['noWrap', 'isActive', 'isSmall', 'iconPosition']
  });
};
