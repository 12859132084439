import { component } from 'builder';
import { IColumnProps } from './props';
import Base from './base';

export const Column = component<IColumnProps>({
  name: 'Column',
  base: Base,
  props: {
    children: null,
    width: null,
    order: null,
    fill: null
  }
});
