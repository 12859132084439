import React from 'react';
import { Box } from '../core';

export default (props: any) => {
  const { spaceBetween, vAlign, hAlign, width, height, wrap } = props;
  return (
    <Box
      horizontal
      spaceBetween={spaceBetween}
      vAlign={vAlign}
      hAlign={hAlign}
      w={width}
      h={height}
      wrap={wrap}
    >
      {props.children}
    </Box>
  );
};
