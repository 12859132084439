import { component } from 'builder';
import { addEvents } from '../core/events';
import { defaultProps } from '../core/TextInput/props';
import { ITextAreaProps } from './props';
import { TextAreaBase } from './base';
import { inputStyle } from './core/style';

const TextAreaWithEvents = addEvents(TextAreaBase);

const { type, ...rest } = defaultProps;

export const TextArea = component<ITextAreaProps>({
  name: 'TextArea',
  base: TextAreaWithEvents,
  container: 'textarea',
  canOverrideContainer: true,
  style: inputStyle,
  props: {
    ...rest,
    rows: 4
  }
});
