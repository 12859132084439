import { component } from 'builder';

import { defaultProps } from '../core/TextInput/props';
import { IInputCharactersProps } from './props';
import InputCharactersBase from './base';

export const InputCharacters = component<IInputCharactersProps>({
  name: 'InputCharacters',
  base: InputCharactersBase,
  container: 'input',
  props: {
    ...defaultProps,
    value: '',
    onComplete: () => {},
    mask: [],
    blurOnChange: true,
    grouped: false,
    type: 'text',
    labels: null
  }
});
