import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const details = (colors: IColorsType, bounds: IBoundsType) => ({
  body: {
    borderSize: bounds.border.size * 2,
    borderColor: colors.neutrals.lighter
  },
  summary: {
    outlineColor: colors.auxillary,
    outlineSize: bounds.outline.size * 3
  }
});
