import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';

const styles = {
  closed: {
    display: 'none'
  },
  open: {
    display: 'block'
  },
  title: {
    fontSize: 14
  },
  editor: {
    fontSize: 14,
    border: '1px solid #eee',
    height: 'auto'
  }
};

const defaultOptions = {
  viewportMargin: Infinity
};

const getStyleFromState = (state, styles) =>
  Object.assign(
    {},
    state.isOpen && styles.open,
    !state.isOpen && styles.closed
  );

export class CodeReader extends React.Component {
  constructor() {
    super();
    this.state = { isOpen: true };
  }
  componentDidMount() {
    this.setState({ isOpen: false });
  }
  static getDerivedStateFromProps(nextProps) {
    return { isOpen: nextProps.isOpen };
  }
  render() {
    const { isOpen, title, onChange, value, options } = this.props;
    const withDefaults = {
      ...options,
      ...defaultOptions
    };
    return isOpen ? (
      <div style={getStyleFromState(this.state, styles)}>
        <p style={styles.title}>{title}</p>
        <div style={Object.assign({}, styles.editor)}>
          <CodeMirror
            autoCursor={false}
            value={value}
            options={withDefaults}
            onChange={onChange}
          />
        </div>
      </div>
    ) : null;
  }
}
