import { component } from 'builder';
import styled from 'styled-components';
import {
  ITableProps,
  ITableCell,
  ITableRow,
  ITableCellExpandedProps
} from './props';
import Base from './base';
import TableRowExpandedBase from './rowExpandableBase';
import TableCellWithExpanderBase from './cellWithExpanderBase';
import { rowStyle, headerStyle, cellStyle } from './styles';

export const Table = component<ITableProps>({
  name: 'Table',
  base: Base,
  props: {
    children: null,
    isStriped: false,
    isLight: false,
    withHover: false,
    isInverted: false,
    breakpoint: null
  }
});

const TableRowBase = styled.li`
  ${rowStyle};
`;
export const TableRow = component<ITableRow>({
  name: 'TableRow',
  base: TableRowBase,
  props: {
    children: null,
    noHeader: false,
    withHover: false,
    isInverted: false,
    isStriped: false
  }
});

const TableHeaderBase = styled.span([headerStyle]);
export const TableHeader = component<ITableCell>({
  name: 'TableHeaderBase',
  base: TableHeaderBase,
  props: {
    children: null,
    vAlign: null,
    hAlign: null,
    horizontal: true
  }
});
TableHeader.displayName = 'TableHeader';

const TableCellBase = styled.span([cellStyle]);
export const TableCell = component<ITableCell>({
  name: 'TableCell',
  base: TableCellBase,
  props: {
    children: null,
    vAlign: null,
    hAlign: null,
    horizontal: true
  }
});
export const TableRowExpandable = component<ITableRow>({
  name: 'TableRowExpandable',
  base: TableRowExpandedBase,
  props: {
    children: null,
    noHeader: false,
    isOpen: false,
    withHover: false,
    isInverted: false,
    isStriped: false
  }
});

const TableCellExpandedBase = styled(TableCell)`
  && {
    &::before {
      display: none;
    }
    grid-template-columns: 1fr;
  }
`;

export const TableCellExpanded = component<ITableCellExpandedProps>({
  name: 'TableCellExpanded',
  base: TableCellExpandedBase,
  props: {
    children: null,
    isOpen: false,
    ariaControls: null,
    setIsOpen: null
  }
});
export const TableCellWithExpander = component<ITableCellExpandedProps>({
  name: 'TableCellWithExpander',
  base: TableCellWithExpanderBase,
  props: {
    children: null,
    isOpen: false,
    ariaControls: null,
    setIsOpen: null
  }
});
