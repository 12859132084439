import { css } from 'styled-components';
import isActiveMixin from './active';
import { IButtonBuilderTheme } from 'theme/components/buttons/builder';

export default (baseStyle: any, style: any) =>
  style(
    (t: IButtonBuilderTheme, { isActive }: any) => css`
      ${baseStyle} color: ${t.outline.color};
      border-color: ${t.outline.borderColor};
      background-color: transparent;

      ${isActive && isActiveMixin(t.outline.status.active)};
      &:hover {
        border-color: ${t.outline.status.hover.borderColor};
        color: ${t.outline.status.hover.color};
      }
      &:focus {
        color: ${t.outline.status.focus.color};
      }
      &[disabled] {
        color: ${t.outline.status.disabled.color};
        &:hover,
        &:focus {
          color: ${t.outline.status.hover.color};
        }
      }
    `
  );
