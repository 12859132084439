import React from 'react';
import { Touchable } from '../core';
import { Icon, iconFactory } from '../Icon';

const getIcon = (iconMap: any) =>
  iconMap != null ? iconFactory(iconMap) : Icon;

export const Base = (props: any) => {
  const { onClick, iconMap, Container, ...rest } = props;
  const IconComponent = getIcon(iconMap);
  return (
    <Touchable onClick={onClick} aria-label={rest.name && rest.name} {...rest}>
      <Container>
        <IconComponent {...rest} />
      </Container>
    </Touchable>
  );
};
