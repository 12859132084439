import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Bar);
const menuStyler = styleComponent(style);

const menuCss = (t: TThemeVariables, { hAlign }: any) => css`
  margin: 0;
  padding: 0;
  list-style: none;
  float: ${hAlign};
  display: inline-flex;
`;

const themeStyleCallback = (styles: any) => styles.Bar.Menu;

export const menu = menuStyler(menuCss, themeStyleCallback) as any;
