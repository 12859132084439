import React from 'react';
import { length } from 'rambda';
import warning from 'warning';
import styled from 'styled-components';
import { htmlSafeString } from 'utils';
import { wrapper, contentCss, labelCss, label } from './styles';
import { DetectScreenCollisions } from './detect-screen-collisions';

const Wrapper = styled.span([wrapper]);
Wrapper.displayName = 'Wrapper';
const LabelWrapper = styled.span([labelCss]);
const LabelCustomWrapper = styled.span([label]);
const Content = styled.span([contentCss]);

const MAX_LABEL_LENGTH = 50;

const longTextWarning = (str: string) =>
  warning(
    length(str) <= MAX_LABEL_LENGTH,
    `The following label is quite long!!: ${str}`
  );

const callWithValue = (fn: any, val: any) => () => fn(val);

const Base: React.StatelessComponent<any> = (props: any) => {
  const { setIsVisible, isVisible, position, label, width, align } = props;

  longTextWarning(label);
  const labelId = `${htmlSafeString(props.label)}-label`;

  return (
    <span role="tooltip">
      <Wrapper
        onFocus={callWithValue(setIsVisible, true)}
        onBlur={callWithValue(setIsVisible, false)}
        onMouseEnter={callWithValue(setIsVisible, true)}
        onMouseLeave={callWithValue(setIsVisible, false)}
        styleProps={{ setIsVisible, isVisible, position, label, width, align }}
      >
        {React.cloneElement(props.children, { 'aria-describedby': labelId })}
        <Content styleProps={{ position, isVisible, align }}>
          <DetectScreenCollisions>
            <LabelWrapper
              id={labelId}
              styleProps={{ position, isVisible, width }}
            >
              <LabelCustomWrapper
                styleProps={{
                  setIsVisible,
                  isVisible,
                  position,
                  label,
                  width,
                  align
                }}
              >
                {props.label}
              </LabelCustomWrapper>
            </LabelWrapper>
          </DetectScreenCollisions>
        </Content>
      </Wrapper>
    </span>
  );
};

Base.displayName = 'Base';

export default Base;
