import {
  warningStyle,
  warningOutlineStyle,
  warningInvertedStyle
} from './styles';

import { builder } from './builder';
export const WarningButton = builder(warningStyle, 'WarningButton');
export const WarningInvertedButton = builder(
  warningInvertedStyle,
  'WarningInvertedButton'
);
export const WarningOutlineButton = builder(
  warningOutlineStyle,
  'WarningOutlineButton'
);
