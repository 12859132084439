import React from 'react';
import { IEventProps } from './event-props';

const handleChange = (onChange?: (value: string) => void) => (e: any) => {
  onChange && onChange(e.target.value);
};

export const addEvents = (Component: React.ComponentType<any>) =>
  class ComponentWithEvents extends React.Component<IEventProps> {
    render() {
      const {
        onClick,
        onFocus,
        onBlur,
        onKeyDown,
        onChange,
        onPaste,
        onInput
      } = this.props;
      const events = {
        onClick,
        onFocus,
        onBlur,
        onKeyDown,
        onPaste,
        onChange: handleChange(onChange),
        onInput: handleChange(onInput)
      };
      return <Component {...this.props} {...events} />;
    }
  };
