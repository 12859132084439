import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingVertical,
  paddingHorizontal,
  getBoxShadow,
  px,
  styleComponent,
  getTypography
} from 'utils';
import { ITheme } from 'theme';
import { TThemeVariables } from '../types';

type ComponentTheme = ITheme['components']['RadioCheckboxButton'];

const paddingMixin = (isSmall: boolean) =>
  isSmall
    ? css`
        ${paddingVertical(0)} ${paddingHorizontal(0)};
      `
    : css`
        ${paddingVertical(1)} ${paddingHorizontal(1)};
      `;

const style = selectComponentThemeVariablesFor(
  theme => theme.RadioCheckboxButton
);

const radioCheckboxButtonStyler = styleComponent(style);
const labelStylerCallback = (styles: any) => styles.RadioCheckboxButton.Label;

const labelCss = (t: TThemeVariables, { isSmall }: any) => css`
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding: ${paddingMixin(isSmall)};
  padding-left: ${px(t.label.offset)};
  text-align: left;
  cursor: pointer;
  border: ${px(t.label.borderSize)} solid ${t.label.borderColor};
  border-radius: ${t.label.borderRadius};
  background-color: ${t.label.backgroundColor};
  transition: transform 150ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  user-select: none;
  ${getTypography('Default')};

  &:focus,
  &:hover,
  &:active {
    background-color: ${t.label.status.focus.backgroundColor};
    border-color: ${t.label.status.focus.borderColor};
  }

  &:active {
    transform: scale(0.99) rotateX(15deg);
    transform-origin: center bottom;
    outline: 0;
    &:hover,
    &:focus {
      background-color: ${t.label.status.activeHover.backgroundColor};
      box-shadow: ${getBoxShadow('large')};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.65;
    box-shadow: none;
    &:hover,
    &:focus {
      background-color: ${t.label.backgroundColor};
    }
  }
`;

export const label = radioCheckboxButtonStyler(labelCss, labelStylerCallback);

const statusMixin = (type: any, t: ComponentTheme) => css`
  ${label} border-bottom: ${px(t.label.borderBottom)} solid ${
  t.label.status[type].borderColor
};
  &:focus,
  &:hover,
  &:active {
    border-bottom-color: ${t.label.status[type].borderColor};
  }
`;

export const success = style(
  t =>
    css`
      ${statusMixin('success', t)};
    `
);
export const warning = style(
  t =>
    css`
      ${statusMixin('warning', t)};
    `
);
export const danger = style(
  t =>
    css`
      ${statusMixin('danger', t)};
    `
);
export const neutral = style(
  t =>
    css`
      ${statusMixin('neutral', t)};
    `
);
