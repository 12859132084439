import { component } from 'builder';
import { withState } from 'recompose';

import { IAccordionProps, IAccordionPanelProps } from './props/';

import AccordionBase from './AccordionBase';
import AccordionPanelBase from './AccordionPanelBase';

export const AccordionPanel = component<IAccordionPanelProps>({
  name: 'AccordionPanel',
  base: AccordionPanelBase,
  props: {
    title: null,
    isOpen: false,
    onClickHeader: null,
    icon: null,
    children: null,
    ariaLevel: null
  }
});

export const Accordion = component<IAccordionProps>({
  name: 'Accordion',
  base: AccordionBase,
  props: {
    children: null,
    openPanelIndex: NaN,
    onChangeOpenPanelIndex: null
  }
});

const statefulAccordionConfig = {
  initialValue: -1
};

export const StatefulAccordion = withState<
  IAccordionProps,
  number,
  'openPanelIndex',
  'onChangeOpenPanelIndex'
>('openPanelIndex', 'onChangeOpenPanelIndex', ({ openPanelIndex }: any) =>
  isNaN(openPanelIndex) ? statefulAccordionConfig.initialValue : openPanelIndex
)(Accordion as any);
