import { component } from 'builder';
import MenuBase from './MenuBase';
import MenuItemBase from './MenuItemBase';
import { menuItemStyle } from './style';
import { IMenuProps, IMenuItemProps } from './props';

export * from './props';

export const Menu = component<IMenuProps>({
  name: 'Menu',
  base: MenuBase,
  props: {
    children: null
  }
});

export const MenuItem = component<IMenuItemProps>({
  name: 'MenuItem',
  base: MenuItemBase,
  container: 'a',
  canOverrideContainer: true,
  style: menuItemStyle,
  props: {
    icon: null,
    label: null,
    description: null,
    hasArrow: false,
    isActive: false
  },
  styleProps: ['isActive']
});
