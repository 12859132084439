import React from 'react';
import FileUploadInput from './components/FileUploadInput';
import DragContainer from './components/DragContainer';
import { IFiles, IProps, IEventFiles } from './props';
import { Row, Label, Column } from '../';

interface IState extends IFiles {}

export default class Base extends React.Component<IProps, IState> {
  changeHandler = (value: IEventFiles) => {
    const rawFiles: Array<File> = Array.prototype.slice.call(value);
    const files = {};
    [...rawFiles].forEach(
      (file: File, index: number) => (files[file.name] = rawFiles[index])
    );
    this.props.onChange({ files });
  };

  render() {
    return (
      <div>
        <Row vAlign="center">
          <FileUploadInput
            name={this.props.name}
            handler={this.changeHandler}
          />
          <Label>Or</Label>
          <Column fill>
            <DragContainer handler={this.changeHandler} />
          </Column>
        </Row>
        {this.props.children}
      </div>
    );
  }
}
