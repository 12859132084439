import { css } from 'styled-components';
import isActiveMixin from './active';
import { ITheme } from 'theme';

type ComponentTheme = ITheme['components']['Button'];

export default (baseStyle: any, style: any) =>
  style(
    (t: ComponentTheme, { isActive }: any) => css`
      ${baseStyle};

      color: ${t.inverted.color};
      border-color: ${t.borderColor};
      background-color: transparent;

      &:hover {
        color: ${t.inverted.status.hover.color};
      }
      &:focus {
        color: ${t.inverted.status.focus.color};
      }
      &[disabled] {
        color: ${t.inverted.status.disabled.color};
      }
      ${isActive && isActiveMixin(t.inverted.status.active)};
    `
  );
