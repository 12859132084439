import { component } from 'builder';
import { IFormProps } from './props';
import { FormBase } from './base/form';

export const Form = component<IFormProps>({
  name: 'Form',
  base: FormBase,
  props: {
    children: null,
    hiddenFields: null,
    primaryAction: null,
    secondaryActions: null,
    onSubmit: null,
    method: null,
    action: null
  }
});
