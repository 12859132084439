import React from 'react';
import { init, flatten, pipe, repeat } from 'rambda';

import { zip } from '../zip';

type ElementType = React.ReactElement<any> | string;

const addKey = (Element: React.ReactElement<{ key: string }>, i: number) =>
  React.isValidElement(Element)
    ? React.cloneElement(Element, { key: `separator_${i}` })
    : Element;

export const addSeparators = (children: any, Separator: ElementType) => {
  const childrenArray = React.Children.toArray(children);
  const separatorsArray = repeat(Separator, childrenArray.length).map(addKey);
  return pipe(
    ([children, separators]: [any, any]) => zip(children, separators),
    flatten,
    init
  )([childrenArray, separatorsArray]);
};
