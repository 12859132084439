const noop = null; //() => ``;

export const styles = {
  Accordion: {
    Title: noop,
    Heading: noop,
    InnerPanel: noop,
    Body: noop,
    BodyInner: noop,
    Arrow: noop
  },
  Alert: {
    Wrapper: noop,
    ContentWrapper: noop,
    Arrow: noop,
    Icon: noop,
    Header: noop,
    Description: noop
  },
  Bar: {
    Container: noop,
    Menu: noop,
    Item: noop,
    Link: noop
  },
  Breadcrumb: {
    Wrapper: noop,
    ItemWrapper: noop,
    Link: noop
  },
  Button: noop,
  Card: {
    CardWrapper: noop,
    CardList: noop,
    CardListItem: noop,
    CardDeck: {
      Deck: noop,
      Background: noop,
      HeadingWrapper: noop,
      ButtonWrapper: noop,
      CardsWrapper: noop
    }
  },
  CardWithImage: {
    CardWithImageWrapper: noop,
    ImageWrapper: noop
  },
  Checkbox: {
    Wrapper: noop,
    InputWrapper: noop,
    Input: noop,
    Label: noop
  },
  DatePickerDropdown: noop,
  Details: {
    Wrapper: noop,
    Body: noop,
    Summary: noop
  },
  Form: {
    Field: {
      Error: noop,
      ErrorText: noop,
      Label: noop,
      HelpText: noop
    }
  },
  Icon: {
    Wrapper: noop
  },
  InputText: noop,
  InputNumber: noop,
  Menu: {
    MenuItem: noop,
    InnerMenu: noop,
    Item: noop,
    IconContainer: noop,
    ArrowContainer: noop,
    Label: noop,
    Description: noop
  },
  Modal: {
    Body: noop,
    Controls: noop,
    ModalInner: noop,
    Overlay: noop,
    Title: noop,
    Content: noop
  },
  Nav: {
    Container: noop,
    List: noop,
    Wrapper: noop,
    Item: noop
  },
  RadioCheckboxButton: {
    Wrapper: noop,
    InputWrapper: noop,
    Input: noop,
    Label: noop
  },
  Select: noop,
  Separated: {
    Wrapper: noop,
    Line: noop
  },
  NativeSelect: {
    Input: noop,
    Indicators: noop,
    Wrapper: noop
  },
  Pattern: {
    Background: noop
  },
  Spinner: noop,
  Stepper: {
    Wrapper: noop
  },
  Table: {
    Wrapper: noop,
    TableCellExpanded: noop,
    Row: noop,
    Header: noop,
    Cell: noop,
    TableRowExpandable: noop,
    CellExpander: noop
  },
  Tabs: {
    Wrapper: noop,
    Bar: noop,
    Item: noop
  },
  Tag: noop,
  TextInput: noop,
  TextArea: noop,
  ToolTip: {
    Label: noop,
    Wrapper: noop
  },
  FuzzySearch: {
    Search: noop,
    Wrapper: noop
  },
  GaugeChart: {
    Gauge: noop,
    Remaining: noop,
    Progressbar: noop,
    Value: noop
  },
  GaugeRadialChart: {
    Wrapper: noop,
    Svg: noop,
    Circle: noop,
    Content: noop
  },
  Notification: {
    Wrapper: noop,
    Icon: noop,
    Container: noop,
    Title: noop,
    Subtitle: noop
  },
  ShowHide: {
    Wrapper: noop,
    Toggle: noop
  },
  StepProgressBar: {
    Wrapper: noop,
    Toggle: noop
  },
  DetailList: {
    Wrapper: noop,
    Row: noop,
    Heading: noop,
    Content: noop,
    RowGrouped: noop,
    WrapperGrouped: noop,
    ActionWrapperGrouped: noop,
    HeadingWrapperGrouped: noop,
    ContentWrapperGrouped: noop
  },
  Link: {
    Wrapper: noop,
    ResultLink: noop,
    IconWrapper: noop,
    TextWrapper: noop
  }
};
