import React from 'react';
import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent
} from 'utils';

import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Modal);

const OverlayStyle = (t: TThemeVariables) => css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${(props: any) => props.zIndex};
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${paddingVertical(2)} ${paddingHorizontal(2)};
  background: ${t.overlay.backgroundColor};
  -webkit-overflow-scrolling: touch;
`;

const styler = styleComponent(style);
const styleCallback = (styles: any) => styles.Modal.Overlay;

const StyledContent = styled.div([styler(OverlayStyle, styleCallback)]);

export default function({ children, ...rest }: any): JSX.Element {
  return <StyledContent {...rest}>{children}</StyledContent>;
}
