import { handleActions } from 'redux-actions';
import * as actions from './actions';

const INITIAL_STATE = {
  theme: 'styleguide'
};

export default handleActions(
  {
    [actions.changeTheme]: (state, action) => ({
      ...state,
      theme: action.payload
    })
  },
  INITIAL_STATE
);
