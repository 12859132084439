import React from 'react';

export const arrowDown = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M28.768 14.857q0 0.946-0.661 1.607l-11.625 11.643q-0.696 0.661-1.625 0.661-0.946 0-1.607-0.661l-11.625-11.643q-0.679-0.643-0.679-1.607 0-0.946 0.679-1.625l1.321-1.339q0.696-0.661 1.625-0.661 0.946 0 1.607 0.661l5.25 5.25v-12.571q0-0.929 0.679-1.607t1.607-0.679h2.286q0.929 0 1.607 0.679t0.679 1.607v12.571l5.25-5.25q0.661-0.661 1.607-0.661 0.929 0 1.625 0.661l1.339 1.339q0.661 0.696 0.661 1.625z" />
  </svg>
);

export const arrowLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M27.429 16v2.286q0 0.946-0.58 1.616t-1.509 0.67h-12.571l5.232 5.25q0.679 0.643 0.679 1.607t-0.679 1.607l-1.339 1.357q-0.661 0.661-1.607 0.661-0.929 0-1.625-0.661l-11.625-11.643q-0.661-0.661-0.661-1.607 0-0.929 0.661-1.625l11.625-11.607q0.679-0.679 1.625-0.679 0.929 0 1.607 0.679l1.339 1.321q0.679 0.679 0.679 1.625t-0.679 1.625l-5.232 5.232h12.571q0.929 0 1.509 0.67t0.58 1.616z" />
  </svg>
);

export const arrowRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.286 17.143q0 0.964-0.661 1.625l-11.625 11.625q-0.696 0.661-1.625 0.661-0.911 0-1.607-0.661l-1.339-1.339q-0.679-0.679-0.679-1.625t0.679-1.625l5.232-5.232h-12.571q-0.929 0-1.509-0.67t-0.58-1.616v-2.286q0-0.946 0.58-1.616t1.509-0.67h12.571l-5.232-5.25q-0.679-0.643-0.679-1.607t0.679-1.607l1.339-1.339q0.679-0.679 1.607-0.679 0.946 0 1.625 0.679l11.625 11.625q0.661 0.625 0.661 1.607z" />
  </svg>
);

export const arrowUp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M28.768 17.339q0 0.911-0.661 1.607l-1.339 1.339q-0.679 0.679-1.625 0.679-0.964 0-1.607-0.679l-5.25-5.232v12.571q0 0.929-0.67 1.509t-1.616 0.58h-2.286q-0.946 0-1.616-0.58t-0.67-1.509v-12.571l-5.25 5.232q-0.643 0.679-1.607 0.679t-1.607-0.679l-1.339-1.339q-0.679-0.679-0.679-1.607 0-0.946 0.679-1.625l11.625-11.625q0.625-0.661 1.607-0.661 0.964 0 1.625 0.661l11.625 11.625q0.661 0.696 0.661 1.625z" />
  </svg>
);

export const balanceScale = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 32">
    <path d="M30.857 8l-6.857 12.571h13.714zM8 8l-6.857 12.571h13.714zM22.661 4.571q-0.25 0.714-0.813 1.277t-1.277 0.813v23.054h10.857q0.25 0 0.411 0.161t0.161 0.411v1.143q0 0.25-0.161 0.411t-0.411 0.161h-24q-0.25 0-0.411-0.161t-0.161-0.411v-1.143q0-0.25 0.161-0.411t0.411-0.161h10.857v-23.054q-0.714-0.25-1.277-0.813t-0.813-1.277h-8.768q-0.25 0-0.411-0.161t-0.161-0.411v-1.143q0-0.25 0.161-0.411t0.411-0.161h8.768q0.375-1.018 1.25-1.652t1.982-0.634 1.982 0.634 1.25 1.652h8.768q0.25 0 0.411 0.161t0.161 0.411v1.143q0 0.25-0.161 0.411t-0.411 0.161h-8.768zM19.429 4.857q0.589 0 1.009-0.42t0.42-1.009-0.42-1.009-1.009-0.42-1.009 0.42-0.42 1.009 0.42 1.009 1.009 0.42zM38.857 20.571q0 1.304-0.83 2.339t-2.098 1.625-2.58 0.884-2.491 0.295-2.491-0.295-2.58-0.884-2.098-1.625-0.83-2.339q0-0.196 0.625-1.446t1.643-3.116 1.911-3.491 1.821-3.286 1-1.786q0.321-0.589 1-0.589t1 0.589q0.071 0.125 1 1.786t1.821 3.286 1.911 3.491 1.643 3.116 0.625 1.446zM16 20.571q0 1.304-0.83 2.339t-2.098 1.625-2.58 0.884-2.491 0.295-2.491-0.295-2.58-0.884-2.098-1.625-0.83-2.339q0-0.196 0.625-1.446t1.643-3.116 1.911-3.491 1.821-3.286 1-1.786q0.321-0.589 1-0.589t1 0.589q0.071 0.125 1 1.786t1.821 3.286 1.911 3.491 1.643 3.116 0.625 1.446z" />
  </svg>
);

export const ban = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M23.429 15.946q0-2.875-1.554-5.268l-13.464 13.446q2.446 1.589 5.304 1.589 1.982 0 3.777-0.777t3.098-2.080 2.071-3.116 0.768-3.795zM5.589 21.286l13.482-13.464q-2.411-1.625-5.357-1.625-2.643 0-4.875 1.304t-3.536 3.554-1.304 4.893q0 2.893 1.589 5.339zM27.429 15.946q0 2.804-1.089 5.357t-2.92 4.393-4.375 2.929-5.33 1.089-5.33-1.089-4.375-2.929-2.92-4.393-1.089-5.357 1.089-5.348 2.92-4.384 4.375-2.929 5.33-1.089 5.33 1.089 4.375 2.929 2.92 4.384 1.089 5.348z" />
  </svg>
);

export const bank = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
    <path d="M17.143 0l17.143 6.857v2.286h-2.286q0 0.464-0.366 0.804t-0.866 0.339h-27.25q-0.5 0-0.866-0.339t-0.366-0.804h-2.286v-2.286zM4.571 11.429h4.571v13.714h2.286v-13.714h4.571v13.714h2.286v-13.714h4.571v13.714h2.286v-13.714h4.571v13.714h1.054q0.5 0 0.866 0.339t0.366 0.804v1.143h-29.714v-1.143q0-0.464 0.366-0.804t0.866-0.339h1.054v-13.714zM33.054 28.571q0.5 0 0.866 0.339t0.366 0.804v2.286h-34.286v-2.286q0-0.464 0.366-0.804t0.866-0.339h31.821z" />
  </svg>
);

export const bell = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M16.286 30.286q0-0.286-0.286-0.286-1.054 0-1.813-0.759t-0.759-1.813q0-0.286-0.286-0.286t-0.286 0.286q0 1.304 0.92 2.223t2.223 0.92q0.286 0 0.286-0.286zM30.857 25.143q0 0.929-0.679 1.607t-1.607 0.679h-8q0 1.893-1.339 3.232t-3.232 1.339-3.232-1.339-1.339-3.232h-8q-0.929 0-1.607-0.679t-0.679-1.607q0.893-0.75 1.625-1.571t1.518-2.134 1.33-2.83 0.893-3.679 0.348-4.643q0-2.714 2.089-5.045t5.482-2.83q-0.143-0.339-0.143-0.696 0-0.714 0.5-1.214t1.214-0.5 1.214 0.5 0.5 1.214q0 0.357-0.143 0.696 3.393 0.5 5.482 2.83t2.089 5.045q0 2.482 0.348 4.643t0.893 3.679 1.33 2.83 1.518 2.134 1.625 1.571z" />
  </svg>
);

export const bolt = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
    <path d="M15.804 10.107q0.321 0.357 0.125 0.786l-9.643 20.661q-0.232 0.446-0.75 0.446-0.071 0-0.25-0.036-0.304-0.089-0.455-0.339t-0.080-0.536l3.518-14.429-7.25 1.804q-0.071 0.018-0.214 0.018-0.321 0-0.554-0.196-0.321-0.268-0.232-0.696l3.589-14.732q0.071-0.25 0.286-0.411t0.5-0.161h5.857q0.339 0 0.571 0.223t0.232 0.527q0 0.143-0.089 0.321l-3.054 8.268 7.071-1.75q0.143-0.036 0.214-0.036 0.339 0 0.607 0.268z" />
  </svg>
);

export const book = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M29.268 8.536q0.714 1.018 0.321 2.304l-4.911 16.179q-0.339 1.143-1.366 1.92t-2.188 0.777h-16.482q-1.375 0-2.652-0.955t-1.777-2.348q-0.429-1.196-0.036-2.268 0-0.071 0.054-0.482t0.071-0.661q0.018-0.143-0.054-0.384t-0.054-0.348q0.036-0.196 0.143-0.375t0.295-0.42 0.295-0.42q0.411-0.679 0.804-1.634t0.536-1.634q0.054-0.179 0.009-0.536t-0.009-0.5q0.054-0.196 0.304-0.5t0.304-0.411q0.375-0.643 0.75-1.643t0.446-1.607q0.018-0.161-0.045-0.571t0.009-0.5q0.071-0.232 0.393-0.545t0.393-0.402q0.339-0.464 0.759-1.509t0.491-1.723q0.018-0.143-0.054-0.455t-0.036-0.473q0.036-0.143 0.161-0.321t0.321-0.411 0.304-0.375q0.143-0.214 0.295-0.545t0.268-0.625 0.286-0.643 0.348-0.571 0.473-0.42 0.643-0.205 0.848 0.098l-0.018 0.054q0.679-0.161 0.911-0.161h13.589q1.321 0 2.036 1t0.321 2.321l-4.893 16.179q-0.643 2.125-1.277 2.741t-2.295 0.616h-15.518q-0.482 0-0.679 0.268-0.196 0.286-0.018 0.768 0.429 1.25 2.571 1.25h16.482q0.518 0 1-0.277t0.625-0.741l5.357-17.625q0.125-0.393 0.089-1.018 0.679 0.268 1.054 0.768zM10.268 8.571q-0.071 0.232 0.036 0.402t0.357 0.17h10.857q0.232 0 0.455-0.17t0.295-0.402l0.375-1.143q0.071-0.232-0.036-0.402t-0.357-0.17h-10.857q-0.232 0-0.455 0.17t-0.295 0.402zM8.786 13.143q-0.071 0.232 0.036 0.402t0.357 0.17h10.857q0.232 0 0.455-0.17t0.295-0.402l0.375-1.143q0.071-0.232-0.036-0.402t-0.357-0.17h-10.857q-0.232 0-0.455 0.17t-0.295 0.402z" />
  </svg>
);

export const calendar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M2.286 29.714h5.143v-5.143h-5.143v5.143zM8.571 29.714h5.714v-5.143h-5.714v5.143zM2.286 23.429h5.143v-5.714h-5.143v5.714zM8.571 23.429h5.714v-5.714h-5.714v5.714zM2.286 16.571h5.143v-5.143h-5.143v5.143zM15.429 29.714h5.714v-5.143h-5.714v5.143zM8.571 16.571h5.714v-5.143h-5.714v5.143zM22.286 29.714h5.143v-5.143h-5.143v5.143zM15.429 23.429h5.714v-5.714h-5.714v5.714zM9.143 8v-5.143q0-0.232-0.17-0.402t-0.402-0.17h-1.143q-0.232 0-0.402 0.17t-0.17 0.402v5.143q0 0.232 0.17 0.402t0.402 0.17h1.143q0.232 0 0.402-0.17t0.17-0.402zM22.286 23.429h5.143v-5.714h-5.143v5.714zM15.429 16.571h5.714v-5.143h-5.714v5.143zM22.286 16.571h5.143v-5.143h-5.143v5.143zM22.857 8v-5.143q0-0.232-0.17-0.402t-0.402-0.17h-1.143q-0.232 0-0.402 0.17t-0.17 0.402v5.143q0 0.232 0.17 0.402t0.402 0.17h1.143q0.232 0 0.402-0.17t0.17-0.402zM29.714 6.857v22.857q0 0.929-0.679 1.607t-1.607 0.679h-25.143q-0.929 0-1.607-0.679t-0.679-1.607v-22.857q0-0.929 0.679-1.607t1.607-0.679h2.286v-1.714q0-1.179 0.839-2.018t2.018-0.839h1.143q1.179 0 2.018 0.839t0.839 2.018v1.714h6.857v-1.714q0-1.179 0.839-2.018t2.018-0.839h1.143q1.179 0 2.018 0.839t0.839 2.018v1.714h2.286q0.929 0 1.607 0.679t0.679 1.607z" />
  </svg>
);

export const caretDown = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 32">
    <path d="M18.286 12.571q0 0.464-0.339 0.804l-8 8q-0.339 0.339-0.804 0.339t-0.804-0.339l-8-8q-0.339-0.339-0.339-0.804t0.339-0.804 0.804-0.339h16q0.464 0 0.804 0.339t0.339 0.804z" />
  </svg>
);

export const caretUp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 32">
    <path d="M18.286 21.714q0 0.464-0.339 0.804t-0.804 0.339h-16q-0.464 0-0.804-0.339t-0.339-0.804 0.339-0.804l8-8q0.339-0.339 0.804-0.339t0.804 0.339l8 8q0.339 0.339 0.339 0.804z" />
  </svg>
);

export const chain = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M25.126 22.515q0-0.658-0.461-1.119l-3.423-3.423q-0.461-0.461-1.119-0.461-0.691 0-1.185 0.527 0.049 0.049 0.313 0.304t0.354 0.354 0.247 0.313 0.214 0.42 0.058 0.453q0 0.658-0.461 1.119t-1.119 0.461q-0.247 0-0.453-0.058t-0.42-0.214-0.313-0.247-0.354-0.354-0.304-0.313q-0.543 0.51-0.543 1.201 0 0.658 0.461 1.119l3.39 3.407q0.444 0.444 1.119 0.444 0.658 0 1.119-0.428l2.419-2.403q0.461-0.461 0.461-1.103zM13.557 10.913q0-0.658-0.461-1.119l-3.39-3.407q-0.461-0.461-1.119-0.461-0.642 0-1.119 0.444l-2.419 2.403q-0.461 0.461-0.461 1.103 0 0.658 0.461 1.119l3.423 3.423q0.444 0.444 1.119 0.444 0.691 0 1.185-0.51-0.049-0.049-0.313-0.304t-0.354-0.354-0.247-0.313-0.214-0.42-0.058-0.453q0-0.658 0.461-1.119t1.119-0.461q0.247 0 0.453 0.058t0.42 0.214 0.313 0.247 0.354 0.354 0.304 0.313q0.543-0.51 0.543-1.201zM28.286 22.515q0 1.975-1.399 3.341l-2.419 2.403q-1.366 1.366-3.341 1.366-1.991 0-3.357-1.399l-3.39-3.407q-1.366-1.366-1.366-3.341 0-2.024 1.448-3.439l-1.448-1.448q-1.415 1.448-3.423 1.448-1.975 0-3.357-1.382l-3.423-3.423q-1.382-1.382-1.382-3.357t1.399-3.341l2.419-2.403q1.366-1.366 3.341-1.366 1.991 0 3.357 1.399l3.39 3.407q1.366 1.366 1.366 3.341 0 2.024-1.448 3.439l1.448 1.448q1.415-1.448 3.423-1.448 1.975 0 3.357 1.382l3.423 3.423q1.382 1.382 1.382 3.357z" />
  </svg>
);

export const check = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M29.839 10.107q0 0.714-0.5 1.214l-15.357 15.357q-0.5 0.5-1.214 0.5t-1.214-0.5l-8.893-8.893q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.268 11.714-11.732q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214z" />
  </svg>
);

export const checkCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M22.929 13.107q0-0.5-0.321-0.821l-1.625-1.607q-0.339-0.339-0.804-0.339t-0.804 0.339l-7.286 7.268-4.036-4.036q-0.339-0.339-0.804-0.339t-0.804 0.339l-1.625 1.607q-0.321 0.321-0.321 0.821 0 0.482 0.321 0.804l6.464 6.464q0.339 0.339 0.804 0.339 0.482 0 0.821-0.339l9.696-9.696q0.321-0.321 0.321-0.804zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const chevronDown = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M30.054 14.429l-13.25 13.232q-0.339 0.339-0.804 0.339t-0.804-0.339l-13.25-13.232q-0.339-0.339-0.339-0.813t0.339-0.813l2.964-2.946q0.339-0.339 0.804-0.339t0.804 0.339l9.482 9.482 9.482-9.482q0.339-0.339 0.804-0.339t0.804 0.339l2.964 2.946q0.339 0.339 0.339 0.813t-0.339 0.813z" />
  </svg>
);

export const chevronLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 32">
    <path d="M20.911 5.375l-9.482 9.482 9.482 9.482q0.339 0.339 0.339 0.804t-0.339 0.804l-2.964 2.964q-0.339 0.339-0.804 0.339t-0.804-0.339l-13.25-13.25q-0.339-0.339-0.339-0.804t0.339-0.804l13.25-13.25q0.339-0.339 0.804-0.339t0.804 0.339l2.964 2.964q0.339 0.339 0.339 0.804t-0.339 0.804z" />
  </svg>
);

export const chevronRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 32">
    <path d="M19.768 15.661l-13.25 13.25q-0.339 0.339-0.804 0.339t-0.804-0.339l-2.964-2.964q-0.339-0.339-0.339-0.804t0.339-0.804l9.482-9.482-9.482-9.482q-0.339-0.339-0.339-0.804t0.339-0.804l2.964-2.964q0.339-0.339 0.804-0.339t0.804 0.339l13.25 13.25q0.339 0.339 0.339 0.804t-0.339 0.804z" />
  </svg>
);

export const chevronUp = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M30.054 23.768l-2.964 2.946q-0.339 0.339-0.804 0.339t-0.804-0.339l-9.482-9.482-9.482 9.482q-0.339 0.339-0.804 0.339t-0.804-0.339l-2.964-2.946q-0.339-0.339-0.339-0.813t0.339-0.813l13.25-13.232q0.339-0.339 0.804-0.339t0.804 0.339l13.25 13.232q0.339 0.339 0.339 0.813t-0.339 0.813z" />
  </svg>
);

export const clock = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M16 9.714v8q0 0.25-0.161 0.411t-0.411 0.161h-5.714q-0.25 0-0.411-0.161t-0.161-0.411v-1.143q0-0.25 0.161-0.411t0.411-0.161h4v-6.286q0-0.25 0.161-0.411t0.411-0.161h1.143q0.25 0 0.411 0.161t0.161 0.411zM23.429 16q0-2.643-1.304-4.875t-3.536-3.536-4.875-1.304-4.875 1.304-3.536 3.536-1.304 4.875 1.304 4.875 3.536 3.536 4.875 1.304 4.875-1.304 3.536-3.536 1.304-4.875zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const close = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
    <path d="M23.179 23.607q0 0.714-0.5 1.214l-2.429 2.429q-0.5 0.5-1.214 0.5t-1.214-0.5l-5.25-5.25-5.25 5.25q-0.5 0.5-1.214 0.5t-1.214-0.5l-2.429-2.429q-0.5-0.5-0.5-1.214t0.5-1.214l5.25-5.25-5.25-5.25q-0.5-0.5-0.5-1.214t0.5-1.214l2.429-2.429q0.5-0.5 1.214-0.5t1.214 0.5l5.25 5.25 5.25-5.25q0.5-0.5 1.214-0.5t1.214 0.5l2.429 2.429q0.5 0.5 0.5 1.214t-0.5 1.214l-5.25 5.25 5.25 5.25q0.5 0.5 0.5 1.214z" />
  </svg>
);

export const fork = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 32">
    <path d="M5.143 26.286q0-0.714-0.5-1.214t-1.214-0.5-1.214 0.5-0.5 1.214 0.5 1.214 1.214 0.5 1.214-0.5 0.5-1.214zM5.143 5.714q0-0.714-0.5-1.214t-1.214-0.5-1.214 0.5-0.5 1.214 0.5 1.214 1.214 0.5 1.214-0.5 0.5-1.214zM16.571 8q0-0.714-0.5-1.214t-1.214-0.5-1.214 0.5-0.5 1.214 0.5 1.214 1.214 0.5 1.214-0.5 0.5-1.214zM18.286 8q0 0.929-0.464 1.723t-1.25 1.241q-0.036 5.125-4.036 7.393-1.214 0.679-3.625 1.446-2.286 0.714-3.027 1.268t-0.741 1.786v0.464q0.786 0.446 1.25 1.241t0.464 1.723q0 1.429-1 2.429t-2.429 1-2.429-1-1-2.429q0-0.929 0.464-1.723t1.25-1.241v-14.643q-0.786-0.446-1.25-1.241t-0.464-1.723q0-1.429 1-2.429t2.429-1 2.429 1 1 2.429q0 0.929-0.464 1.723t-1.25 1.241v8.875q0.964-0.464 2.75-1.018 0.982-0.304 1.562-0.527t1.259-0.554 1.054-0.705 0.723-0.911 0.5-1.241 0.152-1.634q-0.786-0.446-1.25-1.241t-0.464-1.723q0-1.429 1-2.429t2.429-1 2.429 1 1 2.429z" />
  </svg>
);

export const cog = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M18.286 16q0-1.893-1.339-3.232t-3.232-1.339-3.232 1.339-1.339 3.232 1.339 3.232 3.232 1.339 3.232-1.339 1.339-3.232zM27.429 14.054v3.964q0 0.214-0.143 0.411t-0.357 0.232l-3.304 0.5q-0.339 0.964-0.696 1.625 0.625 0.893 1.911 2.464 0.179 0.214 0.179 0.446t-0.161 0.411q-0.482 0.661-1.768 1.929t-1.679 1.268q-0.214 0-0.464-0.161l-2.464-1.929q-0.786 0.411-1.625 0.679-0.286 2.429-0.518 3.321-0.125 0.5-0.643 0.5h-3.964q-0.25 0-0.438-0.152t-0.205-0.384l-0.5-3.286q-0.875-0.286-1.607-0.661l-2.518 1.911q-0.179 0.161-0.446 0.161-0.25 0-0.446-0.196-2.25-2.036-2.946-3-0.125-0.179-0.125-0.411 0-0.214 0.143-0.411 0.268-0.375 0.911-1.188t0.964-1.259q-0.482-0.893-0.732-1.768l-3.268-0.482q-0.232-0.036-0.375-0.223t-0.143-0.42v-3.964q0-0.214 0.143-0.411t0.339-0.232l3.321-0.5q0.25-0.821 0.696-1.643-0.714-1.018-1.911-2.464-0.179-0.214-0.179-0.429 0-0.179 0.161-0.411 0.464-0.643 1.759-1.92t1.688-1.277q0.232 0 0.464 0.179l2.464 1.911q0.786-0.411 1.625-0.679 0.286-2.429 0.518-3.321 0.125-0.5 0.643-0.5h3.964q0.25 0 0.438 0.152t0.205 0.384l0.5 3.286q0.875 0.286 1.607 0.661l2.536-1.911q0.161-0.161 0.429-0.161 0.232 0 0.446 0.179 2.304 2.125 2.946 3.036 0.125 0.143 0.125 0.393 0 0.214-0.143 0.411-0.268 0.375-0.911 1.188t-0.964 1.259q0.464 0.893 0.732 1.75l3.268 0.5q0.232 0.036 0.375 0.223t0.143 0.42z" />
  </svg>
);

export const comment = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M32 16q0 3.107-2.143 5.741t-5.821 4.161-8.036 1.527q-1.25 0-2.589-0.143-3.536 3.125-8.214 4.321-0.875 0.25-2.036 0.393-0.304 0.036-0.545-0.161t-0.313-0.518v-0.018q-0.054-0.071-0.009-0.214t0.036-0.179 0.080-0.17l0.107-0.161t0.125-0.152 0.143-0.161q0.125-0.143 0.554-0.616t0.616-0.679 0.554-0.705 0.58-0.911 0.482-1.054 0.464-1.357q-2.804-1.589-4.42-3.929t-1.616-5.018q0-2.321 1.268-4.438t3.411-3.652 5.107-2.438 6.214-0.902q4.357 0 8.036 1.527t5.821 4.161 2.143 5.741z" />
  </svg>
);

export const commentCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M29.857 10.259c-1.429-1.755-3.37-3.143-5.821-4.161s-5.13-1.527-8.036-1.527c-2.166 0-4.238 0.3-6.214 0.902s-3.679 1.414-5.107 2.438c-1.429 1.023-2.566 2.241-3.411 3.652s-1.268 2.889-1.268 4.438c0 1.786 0.539 3.459 1.616 5.018s2.55 2.87 4.42 3.929c-0.13 0.477-0.286 0.929-0.464 1.357s-0.339 0.78-0.482 1.054-0.336 0.577-0.58 0.911-0.429 0.568-0.554 0.705-0.33 0.363-0.616 0.679-0.47 0.521-0.554 0.616l-0.143 0.161-0.125 0.152-0.107 0.161c-0.059 0.089-0.086 0.146-0.080 0.17s-0.005 0.084-0.036 0.179-0.027 0.166 0.009 0.214v0.018c0.048 0.214 0.152 0.387 0.313 0.518s0.343 0.184 0.545 0.161c0.773-0.095 1.452-0.227 2.036-0.393 3.12-0.798 5.857-2.238 8.214-4.321 0.893 0.095 1.755 0.143 2.589 0.143 2.905 0 5.584-0.509 8.036-1.527s4.393-2.405 5.821-4.161 2.143-3.67 2.143-5.741-0.714-3.986-2.143-5.741zM24.595 13.377l-8.152 8.152-1.53 1.637c-0.211 0.211-0.466 0.316-0.766 0.316s-0.555-0.105-0.766-0.316l-5.605-5.605c-0.211-0.211-0.316-0.466-0.316-0.766s0.105-0.555 0.316-0.766l1.53-1.53c0.211-0.211 0.466-0.316 0.766-0.316s0.555 0.105 0.766 0.316l3.311 3.321 7.386-7.396c0.211-0.211 0.466-0.316 0.766-0.316s0.555 0.105 0.766 0.316l1.53 1.53c0.211 0.211 0.316 0.466 0.316 0.766-0.002 0.3-0.107 0.555-0.318 0.657z" />
  </svg>
);

export const commenting = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M11.429 16q0-0.946-0.67-1.616t-1.616-0.67-1.616 0.67-0.67 1.616 0.67 1.616 1.616 0.67 1.616-0.67 0.67-1.616zM18.286 16q0-0.946-0.67-1.616t-1.616-0.67-1.616 0.67-0.67 1.616 0.67 1.616 1.616 0.67 1.616-0.67 0.67-1.616zM25.143 16q0-0.946-0.67-1.616t-1.616-0.67-1.616 0.67-0.67 1.616 0.67 1.616 1.616 0.67 1.616-0.67 0.67-1.616zM32 16q0 3.107-2.143 5.741t-5.821 4.161-8.036 1.527q-1.964 0-3.768-0.321-3.089 3.089-7.768 4.089-0.929 0.179-1.536 0.232-0.214 0.018-0.393-0.107t-0.232-0.321q-0.071-0.268 0.357-0.661 0.089-0.089 0.42-0.384t0.455-0.42 0.42-0.455 0.429-0.563 0.366-0.661 0.357-0.857 0.259-1.027 0.223-1.295q-2.607-1.607-4.098-3.866t-1.491-4.813q0-3.107 2.143-5.741t5.821-4.161 8.036-1.527 8.036 1.527 5.821 4.161 2.143 5.741z" />
  </svg>
);

export const compress = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M13.714 17.143v8q0 0.464-0.339 0.804t-0.804 0.339-0.804-0.339l-2.571-2.571-5.929 5.929q-0.179 0.179-0.411 0.179t-0.411-0.179l-2.036-2.036q-0.179-0.179-0.179-0.411t0.179-0.411l5.929-5.929-2.571-2.571q-0.339-0.339-0.339-0.804t0.339-0.804 0.804-0.339h8q0.464 0 0.804 0.339t0.339 0.804zM27.196 5.143q0 0.232-0.179 0.411l-5.929 5.929 2.571 2.571q0.339 0.339 0.339 0.804t-0.339 0.804-0.804 0.339h-8q-0.464 0-0.804-0.339t-0.339-0.804v-8q0-0.464 0.339-0.804t0.804-0.339 0.804 0.339l2.571 2.571 5.929-5.929q0.179-0.179 0.411-0.179t0.411 0.179l2.036 2.036q0.179 0.179 0.179 0.411z" />
  </svg>
);

export const creditCard = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
    <path d="M31.429 2.286q1.179 0 2.018 0.839t0.839 2.018v21.714q0 1.179-0.839 2.018t-2.018 0.839h-28.571q-1.179 0-2.018-0.839t-0.839-2.018v-21.714q0-1.179 0.839-2.018t2.018-0.839h28.571zM2.857 4.571q-0.232 0-0.402 0.17t-0.17 0.402v4h29.714v-4q0-0.232-0.17-0.402t-0.402-0.17h-28.571zM31.429 27.429q0.232 0 0.402-0.17t0.17-0.402v-10.857h-29.714v10.857q0 0.232 0.17 0.402t0.402 0.17h28.571zM4.571 25.143v-2.286h4.571v2.286h-4.571zM11.429 25.143v-2.286h6.857v2.286h-6.857z" />
  </svg>
);

export const download = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M22.857 24q0-0.464-0.339-0.804t-0.804-0.339-0.804 0.339-0.339 0.804 0.339 0.804 0.804 0.339 0.804-0.339 0.339-0.804zM27.429 24q0-0.464-0.339-0.804t-0.804-0.339-0.804 0.339-0.339 0.804 0.339 0.804 0.804 0.339 0.804-0.339 0.339-0.804zM29.714 20v5.714q0 0.714-0.5 1.214t-1.214 0.5h-26.286q-0.714 0-1.214-0.5t-0.5-1.214v-5.714q0-0.714 0.5-1.214t1.214-0.5h8.304l2.411 2.429q1.036 1 2.429 1t2.429-1l2.429-2.429h8.286q0.714 0 1.214 0.5t0.5 1.214zM23.911 9.839q0.304 0.732-0.25 1.25l-8 8q-0.321 0.339-0.804 0.339t-0.804-0.339l-8-8q-0.554-0.518-0.25-1.25 0.304-0.696 1.054-0.696h4.571v-8q0-0.464 0.339-0.804t0.804-0.339h4.571q0.464 0 0.804 0.339t0.339 0.804v8h4.571q0.75 0 1.054 0.696z" />
  </svg>
);

export const envelope = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M32 12.679v14.179q0 1.179-0.839 2.018t-2.018 0.839h-26.286q-1.179 0-2.018-0.839t-0.839-2.018v-14.179q0.786 0.875 1.804 1.554 6.464 4.393 8.875 6.161 1.018 0.75 1.652 1.17t1.688 0.857 1.964 0.438h0.036q0.911 0 1.964-0.438t1.688-0.857 1.652-1.17q3.036-2.196 8.893-6.161 1.018-0.696 1.786-1.554zM32 7.429q0 1.411-0.875 2.696t-2.179 2.196q-6.714 4.661-8.357 5.804-0.179 0.125-0.759 0.545t-0.964 0.679-0.929 0.58-1.027 0.482-0.893 0.161h-0.036q-0.411 0-0.893-0.161t-1.027-0.482-0.929-0.58-0.964-0.679-0.759-0.545q-1.625-1.143-4.679-3.259t-3.661-2.545q-1.107-0.75-2.089-2.063t-0.982-2.438q0-1.393 0.741-2.321t2.116-0.929h26.286q1.161 0 2.009 0.839t0.848 2.018z" />
  </svg>
);

export const exchange = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M32 21.143v3.429q0 0.232-0.17 0.402t-0.402 0.17h-24.571v3.429q0 0.232-0.17 0.402t-0.402 0.17q-0.214 0-0.429-0.179l-5.696-5.714q-0.161-0.161-0.161-0.393 0-0.25 0.161-0.411l5.714-5.714q0.161-0.161 0.411-0.161 0.232 0 0.402 0.17t0.17 0.402v3.429h24.571q0.232 0 0.402 0.17t0.17 0.402zM32 11.429q0 0.25-0.161 0.411l-5.714 5.714q-0.161 0.161-0.411 0.161-0.232 0-0.402-0.17t-0.17-0.402v-3.429h-24.571q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h24.571v-3.429q0-0.25 0.161-0.411t0.411-0.161q0.214 0 0.429 0.179l5.696 5.696q0.161 0.161 0.161 0.411z" />
  </svg>
);

export const exclamation = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M13.714 2.286q3.732 0 6.884 1.839t4.991 4.991 1.839 6.884-1.839 6.884-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839zM16 24.554v-3.393q0-0.25-0.161-0.42t-0.393-0.17h-3.429q-0.232 0-0.411 0.179t-0.179 0.411v3.393q0 0.232 0.179 0.411t0.411 0.179h3.429q0.232 0 0.393-0.17t0.161-0.42zM15.964 18.411l0.321-11.089q0-0.214-0.179-0.321-0.179-0.143-0.429-0.143h-3.929q-0.25 0-0.429 0.143-0.179 0.107-0.179 0.321l0.304 11.089q0 0.179 0.179 0.313t0.429 0.134h3.304q0.25 0 0.42-0.134t0.188-0.313z" />
  </svg>
);

export const expand = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M13.482 18.857q0 0.232-0.179 0.411l-5.929 5.929 2.571 2.571q0.339 0.339 0.339 0.804t-0.339 0.804-0.804 0.339h-8q-0.464 0-0.804-0.339t-0.339-0.804v-8q0-0.464 0.339-0.804t0.804-0.339 0.804 0.339l2.571 2.571 5.929-5.929q0.179-0.179 0.411-0.179t0.411 0.179l2.036 2.036q0.179 0.179 0.179 0.411zM27.429 3.429v8q0 0.464-0.339 0.804t-0.804 0.339-0.804-0.339l-2.571-2.571-5.929 5.929q-0.179 0.179-0.411 0.179t-0.411-0.179l-2.036-2.036q-0.179-0.179-0.179-0.411t0.179-0.411l5.929-5.929-2.571-2.571q-0.339-0.339-0.339-0.804t0.339-0.804 0.804-0.339h8q0.464 0 0.804 0.339t0.339 0.804z" />
  </svg>
);

export const externalLink = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M25.143 16.571v5.714q0 2.125-1.509 3.634t-3.634 1.509h-14.857q-2.125 0-3.634-1.509t-1.509-3.634v-14.857q0-2.125 1.509-3.634t3.634-1.509h12.571q0.25 0 0.411 0.161t0.161 0.411v1.143q0 0.25-0.161 0.411t-0.411 0.161h-12.571q-1.179 0-2.018 0.839t-0.839 2.018v14.857q0 1.179 0.839 2.018t2.018 0.839h14.857q1.179 0 2.018-0.839t0.839-2.018v-5.714q0-0.25 0.161-0.411t0.411-0.161h1.143q0.25 0 0.411 0.161t0.161 0.411zM32 1.143v9.143q0 0.464-0.339 0.804t-0.804 0.339-0.804-0.339l-3.143-3.143-11.643 11.643q-0.179 0.179-0.411 0.179t-0.411-0.179l-2.036-2.036q-0.179-0.179-0.179-0.411t0.179-0.411l11.643-11.643-3.143-3.143q-0.339-0.339-0.339-0.804t0.339-0.804 0.804-0.339h9.143q0.464 0 0.804 0.339t0.339 0.804z" />
  </svg>
);

export const file = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M18.286 9.143v-8.429q0.393 0.25 0.643 0.5l7.286 7.286q0.25 0.25 0.5 0.643h-8.429zM16 9.714q0 0.714 0.5 1.214t1.214 0.5h9.714v18.857q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h14.286v9.714z" />
  </svg>
);

export const fileExcel = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 6.786q0.5 0.5 0.857 1.357t0.357 1.571v20.571q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h16q0.714 0 1.571 0.357t1.357 0.857zM18.286 2.429v6.714h6.714q-0.179-0.518-0.393-0.732l-5.589-5.589q-0.214-0.214-0.732-0.393zM25.143 29.714v-18.286h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-13.714v27.429h22.857zM7.661 25.536v1.893h5.018v-1.893h-1.339l1.839-2.875q0.089-0.125 0.179-0.295t0.134-0.241 0.063-0.071h0.036q0.018 0.071 0.089 0.179 0.036 0.071 0.080 0.134t0.107 0.143 0.116 0.152l1.911 2.875h-1.357v1.893h5.196v-1.893h-1.214l-3.429-4.875 3.482-5.036h1.196v-1.911h-4.982v1.911h1.321l-1.839 2.839q-0.071 0.125-0.179 0.295t-0.161 0.241l-0.036 0.054h-0.036q-0.018-0.071-0.089-0.179-0.107-0.196-0.304-0.411l-1.893-2.839h1.357v-1.911h-5.179v1.911h1.214l3.375 4.857-3.464 5.054h-1.214z" />
  </svg>
);

export const fileImage = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 6.786q0.5 0.5 0.857 1.357t0.357 1.571v20.571q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h16q0.714 0 1.571 0.357t1.357 0.857zM18.286 2.429v6.714h6.714q-0.179-0.518-0.393-0.732l-5.589-5.589q-0.214-0.214-0.732-0.393zM25.143 29.714v-18.286h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-13.714v27.429h22.857zM22.857 21.714v5.714h-18.286v-3.429l3.429-3.429 2.286 2.286 6.857-6.857zM8 18.286q-1.429 0-2.429-1t-1-2.429 1-2.429 2.429-1 2.429 1 1 2.429-1 2.429-2.429 1z" />
  </svg>
);

export const filePdf = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 6.786q0.5 0.5 0.857 1.357t0.357 1.571v20.571q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h16q0.714 0 1.571 0.357t1.357 0.857zM18.286 2.429v6.714h6.714q-0.179-0.518-0.393-0.732l-5.589-5.589q-0.214-0.214-0.732-0.393zM25.143 29.714v-18.286h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-13.714v27.429h22.857zM15.964 19.125q0.589 0.464 1.5 1 1.054-0.125 2.089-0.125 2.625 0 3.161 0.875 0.286 0.393 0.036 0.929 0 0.018-0.018 0.036l-0.036 0.036v0.018q-0.107 0.679-1.268 0.679-0.857 0-2.054-0.357t-2.321-0.946q-3.946 0.429-7 1.482-2.732 4.679-4.321 4.679-0.268 0-0.5-0.125l-0.429-0.214q-0.018-0.018-0.107-0.089-0.179-0.179-0.107-0.643 0.161-0.714 1-1.634t2.357-1.723q0.25-0.161 0.411 0.107 0.036 0.036 0.036 0.071 0.929-1.518 1.911-3.518 1.214-2.429 1.857-4.679-0.429-1.464-0.545-2.848t0.116-2.277q0.196-0.714 0.75-0.714h0.393q0.411 0 0.625 0.268 0.321 0.375 0.161 1.214-0.036 0.107-0.071 0.143 0.018 0.054 0.018 0.143v0.536q-0.036 2.196-0.25 3.429 0.982 2.929 2.607 4.25zM5.679 26.464q0.929-0.429 2.446-2.821-0.911 0.714-1.563 1.5t-0.884 1.321zM12.786 10.036q-0.268 0.75-0.036 2.357 0.018-0.125 0.125-0.786 0-0.054 0.125-0.768 0.018-0.071 0.071-0.143-0.018-0.018-0.018-0.036t-0.009-0.027-0.009-0.027q-0.018-0.393-0.232-0.643 0 0.018-0.018 0.036v0.036zM10.571 21.839q2.411-0.964 5.071-1.446-0.036-0.018-0.232-0.17t-0.286-0.241q-1.357-1.196-2.268-3.143-0.482 1.536-1.482 3.518-0.536 1-0.804 1.482zM22.107 21.554q-0.429-0.429-2.5-0.429 1.357 0.5 2.214 0.5 0.25 0 0.321-0.018 0-0.018-0.036-0.054z" />
  </svg>
);

export const filePowerpoint = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 6.786q0.5 0.5 0.857 1.357t0.357 1.571v20.571q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h16q0.714 0 1.571 0.357t1.357 0.857zM18.286 2.429v6.714h6.714q-0.179-0.518-0.393-0.732l-5.589-5.589q-0.214-0.214-0.732-0.393zM25.143 29.714v-18.286h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-13.714v27.429h22.857zM7.429 25.536v1.893h5.839v-1.893h-1.661v-2.982h2.446q1.357 0 2.107-0.268 1.196-0.411 1.902-1.554t0.705-2.607q0-1.446-0.661-2.518t-1.786-1.554q-0.857-0.339-2.321-0.339h-6.571v1.911h1.643v9.911h-1.643zM13.732 20.536h-2.125v-4.786h2.143q0.929 0 1.482 0.321 1 0.589 1 2.054 0 1.589-1.107 2.143-0.554 0.268-1.393 0.268z" />
  </svg>
);

export const fileText = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 8.5q0.25 0.25 0.5 0.643h-8.429v-8.429q0.393 0.25 0.643 0.5zM17.714 11.429h9.714v18.857q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h14.286v9.714q0 0.714 0.5 1.214t1.214 0.5zM20.571 24.571v-1.143q0-0.25-0.161-0.411t-0.411-0.161h-12.571q-0.25 0-0.411 0.161t-0.161 0.411v1.143q0 0.25 0.161 0.411t0.411 0.161h12.571q0.25 0 0.411-0.161t0.161-0.411zM20.571 20v-1.143q0-0.25-0.161-0.411t-0.411-0.161h-12.571q-0.25 0-0.411 0.161t-0.161 0.411v1.143q0 0.25 0.161 0.411t0.411 0.161h12.571q0.25 0 0.411-0.161t0.161-0.411zM20.571 15.429v-1.143q0-0.25-0.161-0.411t-0.411-0.161h-12.571q-0.25 0-0.411 0.161t-0.161 0.411v1.143q0 0.25 0.161 0.411t0.411 0.161h12.571q0.25 0 0.411-0.161t0.161-0.411z" />
  </svg>
);

export const fileWord = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M26.214 6.786q0.5 0.5 0.857 1.357t0.357 1.571v20.571q0 0.714-0.5 1.214t-1.214 0.5h-24q-0.714 0-1.214-0.5t-0.5-1.214v-28.571q0-0.714 0.5-1.214t1.214-0.5h16q0.714 0 1.571 0.357t1.357 0.857zM18.286 2.429v6.714h6.714q-0.179-0.518-0.393-0.732l-5.589-5.589q-0.214-0.214-0.732-0.393zM25.143 29.714v-18.286h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-13.714v27.429h22.857zM4.161 13.714v1.911h1.25l2.929 11.804h2.839l2.286-8.661q0.125-0.357 0.179-0.821 0.036-0.286 0.036-0.429h0.071l0.054 0.429q0.018 0.054 0.063 0.357t0.098 0.464l2.286 8.661h2.839l2.929-11.804h1.25v-1.911h-5.357v1.911h1.607l-1.768 7.821q-0.089 0.357-0.125 0.821l-0.036 0.375h-0.071l-0.054-0.375q-0.018-0.089-0.071-0.375t-0.089-0.446l-2.571-9.732h-2.036l-2.571 9.732q-0.036 0.161-0.080 0.438t-0.063 0.384l-0.071 0.375h-0.071l-0.036-0.375q-0.036-0.464-0.125-0.821l-1.768-7.821h1.607v-1.911h-5.357z" />
  </svg>
);

export const group = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
    <path d="M10.589 16q-2.893 0.089-4.732 2.286h-2.393q-1.464 0-2.464-0.723t-1-2.116q0-6.304 2.214-6.304 0.107 0 0.777 0.375t1.741 0.759 2.125 0.384q1.196 0 2.375-0.411-0.089 0.661-0.089 1.179 0 2.482 1.446 4.571zM29.714 27.375q0 2.143-1.304 3.384t-3.464 1.241h-15.607q-2.161 0-3.464-1.241t-1.304-3.384q0-0.946 0.063-1.848t0.25-1.946 0.473-1.938 0.768-1.741 1.107-1.446 1.527-0.955 1.991-0.357q0.179 0 0.768 0.384t1.304 0.857 1.911 0.857 2.411 0.384 2.411-0.384 1.911-0.857 1.304-0.857 0.768-0.384q1.089 0 1.991 0.357t1.527 0.955 1.107 1.446 0.768 1.741 0.473 1.938 0.25 1.946 0.063 1.848zM11.429 4.571q0 1.893-1.339 3.232t-3.232 1.339-3.232-1.339-1.339-3.232 1.339-3.232 3.232-1.339 3.232 1.339 1.339 3.232zM24 11.429q0 2.839-2.009 4.848t-4.848 2.009-4.848-2.009-2.009-4.848 2.009-4.848 4.848-2.009 4.848 2.009 2.009 4.848zM34.286 15.446q0 1.393-1 2.116t-2.464 0.723h-2.393q-1.839-2.196-4.732-2.286 1.446-2.089 1.446-4.571 0-0.518-0.089-1.179 1.179 0.411 2.375 0.411 1.054 0 2.125-0.384t1.741-0.759 0.777-0.375q2.214 0 2.214 6.304zM32 4.571q0 1.893-1.339 3.232t-3.232 1.339-3.232-1.339-1.339-3.232 1.339-3.232 3.232-1.339 3.232 1.339 1.339 3.232z" />
  </svg>
);

export const home = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M25.143 17.714v8.571q0 0.464-0.339 0.804t-0.804 0.339h-6.857v-6.857h-4.571v6.857h-6.857q-0.464 0-0.804-0.339t-0.339-0.804v-8.571q0-0.018 0.009-0.054t0.009-0.054l10.268-8.464 10.268 8.464q0.018 0.036 0.018 0.107zM29.125 16.482l-1.107 1.321q-0.143 0.161-0.375 0.196h-0.054q-0.232 0-0.375-0.125l-12.357-10.304-12.357 10.304q-0.214 0.143-0.429 0.125-0.232-0.036-0.375-0.196l-1.107-1.321q-0.143-0.179-0.125-0.42t0.196-0.384l12.839-10.696q0.571-0.464 1.357-0.464t1.357 0.464l4.357 3.643v-3.482q0-0.25 0.161-0.411t0.411-0.161h3.429q0.25 0 0.411 0.161t0.161 0.411v7.286l3.911 3.25q0.179 0.143 0.196 0.384t-0.125 0.42z" />
  </svg>
);

export const cursor = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 32">
    <path d="M13.875 4.249q-4.896 0-4.896 3.427v6.365h1.959v1.959h-1.959v8.324q0 3.427 4.896 3.427h0.979v1.959h-0.979q-4.162 0-5.876-2.234-1.714 2.234-5.876 2.234h-0.979v-1.959h0.979q4.896 0 4.896-3.427v-8.324h-1.959v-1.959h1.959v-6.365q0-3.427-4.896-3.427h-0.979v-1.959h0.979q4.162 0 5.876 2.234 1.714-2.234 5.876-2.234h0.979v1.959h-0.979z" />
  </svg>
);

export const infoCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M18.286 24.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-1.714v-9.143q0-0.25-0.161-0.411t-0.411-0.161h-5.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h1.714v5.714h-1.714q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h8q0.25 0 0.411-0.161t0.161-0.411zM16 8.571v-2.857q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v2.857q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const key = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M14.857 9.143q0-1.429-1-2.429t-2.429-1-2.429 1-1 2.429q0 0.75 0.339 1.482-0.732-0.339-1.482-0.339-1.429 0-2.429 1t-1 2.429 1 2.429 2.429 1 2.429-1 1-2.429q0-0.75-0.339-1.482 0.732 0.339 1.482 0.339 1.429 0 2.429-1t1-2.429zM30.054 21.714q0 0.304-0.875 1.179t-1.179 0.875q-0.161 0-0.509-0.286t-0.652-0.589-0.688-0.714-0.438-0.464l-1.714 1.714 3.929 3.929q0.5 0.5 0.5 1.214 0 0.75-0.696 1.446t-1.446 0.696q-0.714 0-1.214-0.5l-11.982-11.982q-3.143 2.339-6.518 2.339-2.911 0-4.741-1.83t-1.83-4.741q0-2.857 1.696-5.589t4.429-4.429 5.589-1.696q2.911 0 4.741 1.83t1.83 4.741q0 3.375-2.339 6.518l6.339 6.339 1.714-1.714q-0.054-0.054-0.464-0.438t-0.714-0.688-0.589-0.652-0.286-0.509q0-0.304 0.875-1.179t1.179-0.875q0.232 0 0.411 0.179 0.107 0.107 0.821 0.795t1.464 1.42 1.545 1.536 1.304 1.393 0.509 0.732z" />
  </svg>
);

export const lineChart = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 32">
    <path d="M36.571 27.429v2.286h-36.571v-27.429h2.286v25.143h34.286zM34.286 5.143v7.768q0 0.375-0.348 0.527t-0.634-0.134l-2.161-2.161-11.304 11.304q-0.179 0.179-0.411 0.179t-0.411-0.179l-4.161-4.161-7.429 7.429-3.429-3.429 10.446-10.446q0.179-0.179 0.411-0.179t0.411 0.179l4.161 4.161 8.286-8.286-2.161-2.161q-0.286-0.286-0.134-0.634t0.527-0.348h7.768q0.25 0 0.411 0.161t0.161 0.411z" />
  </svg>
);

export const list = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M4.571 23.429v3.429q0 0.232-0.17 0.402t-0.402 0.17h-3.429q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h3.429q0.232 0 0.402 0.17t0.17 0.402zM4.571 16.571v3.429q0 0.232-0.17 0.402t-0.402 0.17h-3.429q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h3.429q0.232 0 0.402 0.17t0.17 0.402zM4.571 9.714v3.429q0 0.232-0.17 0.402t-0.402 0.17h-3.429q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h3.429q0.232 0 0.402 0.17t0.17 0.402zM32 23.429v3.429q0 0.232-0.17 0.402t-0.402 0.17h-24q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h24q0.232 0 0.402 0.17t0.17 0.402zM4.571 2.857v3.429q0 0.232-0.17 0.402t-0.402 0.17h-3.429q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h3.429q0.232 0 0.402 0.17t0.17 0.402zM32 16.571v3.429q0 0.232-0.17 0.402t-0.402 0.17h-24q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h24q0.232 0 0.402 0.17t0.17 0.402zM32 9.714v3.429q0 0.232-0.17 0.402t-0.402 0.17h-24q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h24q0.232 0 0.402 0.17t0.17 0.402zM32 2.857v3.429q0 0.232-0.17 0.402t-0.402 0.17h-24q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h24q0.232 0 0.402 0.17t0.17 0.402z" />
  </svg>
);

export const listNumber = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M6.804 28.929q0 1.429-0.973 2.25t-2.42 0.821q-1.893 0-3.071-1.179l1.018-1.571q0.875 0.804 1.893 0.804 0.518 0 0.902-0.259t0.384-0.759q0-1.143-1.875-1l-0.464-1q0.143-0.179 0.58-0.777t0.759-0.964 0.661-0.688v-0.018q-0.286 0-0.866 0.018t-0.866 0.018v0.946h-1.893v-2.714h5.946v1.571l-1.696 2.054q0.911 0.214 1.446 0.875t0.536 1.571zM6.839 17.732v2.839h-6.464q-0.107-0.643-0.107-0.964 0-0.911 0.42-1.661t1.009-1.214 1.179-0.848 1.009-0.777 0.42-0.804q0-0.446-0.259-0.688t-0.705-0.241q-0.821 0-1.446 1.036l-1.518-1.054q0.429-0.911 1.277-1.42t1.884-0.509q1.304 0 2.196 0.741t0.893 2.009q0 0.893-0.607 1.634t-1.339 1.152-1.348 0.902-0.634 0.938h2.268v-1.071h1.875zM32 23.429v3.429q0 0.232-0.17 0.402t-0.402 0.17h-21.714q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.25 0.161-0.411t0.411-0.161h21.714q0.232 0 0.402 0.17t0.17 0.402zM6.857 7.375v1.768h-5.982v-1.768h1.911q0-0.732 0.009-2.179t0.009-2.161v-0.214h-0.036q-0.143 0.304-0.893 0.964l-1.268-1.357 2.429-2.268h1.893v7.214h1.929zM32 14.286v3.429q0 0.232-0.17 0.402t-0.402 0.17h-21.714q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.25 0.161-0.411t0.411-0.161h21.714q0.232 0 0.402 0.17t0.17 0.402zM32 5.143v3.429q0 0.232-0.17 0.402t-0.402 0.17h-21.714q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h21.714q0.232 0 0.402 0.17t0.17 0.402z" />
  </svg>
);

export const lock = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 32">
    <path d="M5.714 13.714h9.143v-3.429q0-1.893-1.339-3.232t-3.232-1.339-3.232 1.339-1.339 3.232v3.429zM20.571 15.429v10.286q0 0.714-0.5 1.214t-1.214 0.5h-17.143q-0.714 0-1.214-0.5t-0.5-1.214v-10.286q0-0.714 0.5-1.214t1.214-0.5h0.571v-3.429q0-3.286 2.357-5.643t5.643-2.357 5.643 2.357 2.357 5.643v3.429h0.571q0.714 0 1.214 0.5t0.5 1.214z" />
  </svg>
);

export const minus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
    <path d="M25.143 13.143v3.429q0 0.714-0.5 1.214t-1.214 0.5h-21.714q-0.714 0-1.214-0.5t-0.5-1.214v-3.429q0-0.714 0.5-1.214t1.214-0.5h21.714q0.714 0 1.214 0.5t0.5 1.214z" />
  </svg>
);

export const minusCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M21.714 17.143v-2.286q0-0.464-0.339-0.804t-0.804-0.339h-13.714q-0.464 0-0.804 0.339t-0.339 0.804v2.286q0 0.464 0.339 0.804t0.804 0.339h13.714q0.464 0 0.804-0.339t0.339-0.804zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const pencil = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M6.482 27.429l1.625-1.625-4.196-4.196-1.625 1.625v1.911h2.286v2.286h1.911zM15.821 10.857q0-0.393-0.393-0.393-0.179 0-0.304 0.125l-9.679 9.679q-0.125 0.125-0.125 0.304 0 0.393 0.393 0.393 0.179 0 0.304-0.125l9.679-9.679q0.125-0.125 0.125-0.304zM14.857 7.429l7.429 7.429-14.857 14.857h-7.429v-7.429zM27.054 9.143q0 0.946-0.661 1.607l-2.964 2.964-7.429-7.429 2.964-2.946q0.643-0.679 1.607-0.679 0.946 0 1.625 0.679l4.196 4.179q0.661 0.696 0.661 1.625z" />
  </svg>
);

export const pieChart = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M13.714 15.893l9.75 9.75q-1.893 1.929-4.42 3t-5.33 1.071q-3.732 0-6.884-1.839t-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839v13.607zM17.054 16h13.804q0 2.804-1.071 5.33t-3 4.42zM29.714 13.714h-13.714v-13.714q3.732 0 6.884 1.839t4.991 4.991 1.839 6.884z" />
  </svg>
);

export const plus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
    <path d="M25.143 13.143v3.429q0 0.714-0.5 1.214t-1.214 0.5h-7.429v7.429q0 0.714-0.5 1.214t-1.214 0.5h-3.429q-0.714 0-1.214-0.5t-0.5-1.214v-7.429h-7.429q-0.714 0-1.214-0.5t-0.5-1.214v-3.429q0-0.714 0.5-1.214t1.214-0.5h7.429v-7.429q0-0.714 0.5-1.214t1.214-0.5h3.429q0.714 0 1.214 0.5t0.5 1.214v7.429h7.429q0.714 0 1.214 0.5t0.5 1.214z" />
  </svg>
);

export const plusCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M21.714 17.143v-2.286q0-0.464-0.339-0.804t-0.804-0.339h-4.571v-4.571q0-0.464-0.339-0.804t-0.804-0.339h-2.286q-0.464 0-0.804 0.339t-0.339 0.804v4.571h-4.571q-0.464 0-0.804 0.339t-0.339 0.804v2.286q0 0.464 0.339 0.804t0.804 0.339h4.571v4.571q0 0.464 0.339 0.804t0.804 0.339h2.286q0.464 0 0.804-0.339t0.339-0.804v-4.571h4.571q0.464 0 0.804-0.339t0.339-0.804zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const print = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M6.857 27.429h16v-4.571h-16v4.571zM6.857 16h16v-6.857h-2.857q-0.714 0-1.214-0.5t-0.5-1.214v-2.857h-11.429v11.429zM27.429 17.143q0-0.464-0.339-0.804t-0.804-0.339-0.804 0.339-0.339 0.804 0.339 0.804 0.804 0.339 0.804-0.339 0.339-0.804zM29.714 17.143v7.429q0 0.232-0.17 0.402t-0.402 0.17h-4v2.857q0 0.714-0.5 1.214t-1.214 0.5h-17.143q-0.714 0-1.214-0.5t-0.5-1.214v-2.857h-4q-0.232 0-0.402-0.17t-0.17-0.402v-7.429q0-1.411 1.009-2.42t2.42-1.009h1.143v-9.714q0-0.714 0.5-1.214t1.214-0.5h12q0.714 0 1.571 0.357t1.357 0.857l2.714 2.714q0.5 0.5 0.857 1.357t0.357 1.571v4.571h1.143q1.411 0 2.42 1.009t1.009 2.42z" />
  </svg>
);

export const questionCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M16 24.571v-3.429q0-0.25-0.161-0.411t-0.411-0.161h-3.429q-0.25 0-0.411 0.161t-0.161 0.411v3.429q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411zM20.571 12.571q0-1.571-0.991-2.911t-2.473-2.071-3.036-0.732q-4.339 0-6.625 3.804-0.268 0.429 0.143 0.75l2.357 1.786q0.125 0.107 0.339 0.107 0.286 0 0.446-0.214 0.946-1.214 1.536-1.643 0.607-0.429 1.536-0.429 0.857 0 1.527 0.464t0.67 1.054q0 0.679-0.357 1.089t-1.214 0.804q-1.125 0.5-2.063 1.545t-0.938 2.241v0.643q0 0.25 0.161 0.411t0.411 0.161h3.429q0.25 0 0.411-0.161t0.161-0.411q0-0.339 0.384-0.884t0.973-0.884q0.571-0.321 0.875-0.509t0.821-0.625 0.795-0.857 0.5-1.080 0.223-1.446zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const search = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M20.571 14.857q0-3.304-2.348-5.652t-5.652-2.348-5.652 2.348-2.348 5.652 2.348 5.652 5.652 2.348 5.652-2.348 2.348-5.652zM29.714 29.714q0 0.929-0.679 1.607t-1.607 0.679q-0.964 0-1.607-0.679l-6.125-6.107q-3.196 2.214-7.125 2.214-2.554 0-4.884-0.991t-4.018-2.679-2.679-4.018-0.991-4.884 0.991-4.884 2.679-4.018 4.018-2.679 4.884-0.991 4.884 0.991 4.018 2.679 2.679 4.018 0.991 4.884q0 3.929-2.214 7.125l6.125 6.125q0.661 0.661 0.661 1.607z" />
  </svg>
);

export const shield = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 32">
    <path d="M19.429 17.143v-11.429h-8v20.304q2.125-1.125 3.804-2.446 4.196-3.286 4.196-6.429zM22.857 3.429v13.714q0 1.536-0.598 3.045t-1.482 2.679-2.107 2.277-2.259 1.839-2.161 1.384-1.598 0.884-0.759 0.357q-0.214 0.107-0.464 0.107t-0.464-0.107q-0.286-0.125-0.759-0.357t-1.598-0.884-2.161-1.384-2.259-1.839-2.107-2.277-1.482-2.679-0.598-3.045v-13.714q0-0.464 0.339-0.804t0.804-0.339h20.571q0.464 0 0.804 0.339t0.339 0.804z" />
  </svg>
);

export const signIn = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M21.143 16q0 0.464-0.339 0.804l-9.714 9.714q-0.339 0.339-0.804 0.339t-0.804-0.339-0.339-0.804v-5.143h-8q-0.464 0-0.804-0.339t-0.339-0.804v-6.857q0-0.464 0.339-0.804t0.804-0.339h8v-5.143q0-0.464 0.339-0.804t0.804-0.339 0.804 0.339l9.714 9.714q0.339 0.339 0.339 0.804zM27.429 9.714v12.571q0 2.125-1.509 3.634t-3.634 1.509h-5.714q-0.232 0-0.402-0.17t-0.17-0.402q0-0.071-0.018-0.357t-0.009-0.473 0.054-0.42 0.179-0.348 0.366-0.116h5.714q1.179 0 2.018-0.839t0.839-2.018v-12.571q0-1.179-0.839-2.018t-2.018-0.839h-5.571t-0.205-0.018-0.205-0.054-0.143-0.098-0.125-0.161-0.036-0.241q0-0.071-0.018-0.357t-0.009-0.473 0.054-0.42 0.179-0.348 0.366-0.116h5.714q2.125 0 3.634 1.509t1.509 3.634z" />
  </svg>
);

export const signOut = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M11.429 25.714q0 0.071 0.018 0.357t0.009 0.473-0.054 0.42-0.179 0.348-0.366 0.116h-5.714q-2.125 0-3.634-1.509t-1.509-3.634v-12.571q0-2.125 1.509-3.634t3.634-1.509h5.714q0.232 0 0.402 0.17t0.17 0.402q0 0.071 0.018 0.357t0.009 0.473-0.054 0.42-0.179 0.348-0.366 0.116h-5.714q-1.179 0-2.018 0.839t-0.839 2.018v12.571q0 1.179 0.839 2.018t2.018 0.839h5.571t0.205 0.018 0.205 0.054 0.143 0.098 0.125 0.161 0.036 0.241zM28 16q0 0.464-0.339 0.804l-9.714 9.714q-0.339 0.339-0.804 0.339t-0.804-0.339-0.339-0.804v-5.143h-8q-0.464 0-0.804-0.339t-0.339-0.804v-6.857q0-0.464 0.339-0.804t0.804-0.339h8v-5.143q0-0.464 0.339-0.804t0.804-0.339 0.804 0.339l9.714 9.714q0.339 0.339 0.339 0.804z" />
  </svg>
);

export const tag = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M8 8q0-0.946-0.67-1.616t-1.616-0.67-1.616 0.67-0.67 1.616 0.67 1.616 1.616 0.67 1.616-0.67 0.67-1.616zM27.054 18.286q0 0.946-0.661 1.607l-8.768 8.786q-0.696 0.661-1.625 0.661-0.946 0-1.607-0.661l-12.768-12.786q-0.679-0.661-1.152-1.804t-0.473-2.089v-7.429q0-0.929 0.679-1.607t1.607-0.679h7.429q0.946 0 2.089 0.473t1.821 1.152l12.768 12.75q0.661 0.696 0.661 1.625z" />
  </svg>
);

export const tasks = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M18.286 25.143h11.429v-2.286h-11.429v2.286zM11.429 16h18.286v-2.286h-18.286v2.286zM22.857 6.857h6.857v-2.286h-6.857v2.286zM32 21.714v4.571q0 0.464-0.339 0.804t-0.804 0.339h-29.714q-0.464 0-0.804-0.339t-0.339-0.804v-4.571q0-0.464 0.339-0.804t0.804-0.339h29.714q0.464 0 0.804 0.339t0.339 0.804zM32 12.571v4.571q0 0.464-0.339 0.804t-0.804 0.339h-29.714q-0.464 0-0.804-0.339t-0.339-0.804v-4.571q0-0.464 0.339-0.804t0.804-0.339h29.714q0.464 0 0.804 0.339t0.339 0.804zM32 3.429v4.571q0 0.464-0.339 0.804t-0.804 0.339h-29.714q-0.464 0-0.804-0.339t-0.339-0.804v-4.571q0-0.464 0.339-0.804t0.804-0.339h29.714q0.464 0 0.804 0.339t0.339 0.804z" />
  </svg>
);

export const timesCircle = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 32">
    <path d="M20.518 20.036q0-0.464-0.339-0.804l-3.232-3.232 3.232-3.232q0.339-0.339 0.339-0.804 0-0.482-0.339-0.821l-1.607-1.607q-0.339-0.339-0.821-0.339-0.464 0-0.804 0.339l-3.232 3.232-3.232-3.232q-0.339-0.339-0.804-0.339-0.482 0-0.821 0.339l-1.607 1.607q-0.339 0.339-0.339 0.821 0 0.464 0.339 0.804l3.232 3.232-3.232 3.232q-0.339 0.339-0.339 0.804 0 0.482 0.339 0.821l1.607 1.607q0.339 0.339 0.821 0.339 0.464 0 0.804-0.339l3.232-3.232 3.232 3.232q0.339 0.339 0.804 0.339 0.482 0 0.821-0.339l1.607-1.607q0.339-0.339 0.339-0.821zM27.429 16q0 3.732-1.839 6.884t-4.991 4.991-6.884 1.839-6.884-1.839-4.991-4.991-1.839-6.884 1.839-6.884 4.991-4.991 6.884-1.839 6.884 1.839 4.991 4.991 1.839 6.884z" />
  </svg>
);

export const tint = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 32">
    <path d="M9.143 20.571q0-0.643-0.357-1.232-0.018-0.018-0.277-0.402t-0.455-0.679-0.446-0.786-0.375-0.902q-0.071-0.286-0.375-0.286t-0.375 0.286q-0.125 0.411-0.375 0.902t-0.446 0.786-0.455 0.679-0.277 0.402q-0.357 0.589-0.357 1.232 0 0.946 0.67 1.616t1.616 0.67 1.616-0.67 0.67-1.616zM18.286 18.286q0 3.786-2.679 6.464t-6.464 2.679-6.464-2.679-2.679-6.464q0-2.589 1.446-4.911 0.107-0.161 1.116-1.616t1.804-2.696 1.777-3.179 1.482-3.598q0.161-0.536 0.607-0.839t0.911-0.304 0.92 0.304 0.598 0.839q0.5 1.661 1.482 3.598t1.777 3.179 1.804 2.696 1.116 1.616q1.446 2.268 1.446 4.911z" />
  </svg>
);

export const upload = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 32">
    <path d="M22.857 26.286q0-0.464-0.339-0.804t-0.804-0.339-0.804 0.339-0.339 0.804 0.339 0.804 0.804 0.339 0.804-0.339 0.339-0.804zM27.429 26.286q0-0.464-0.339-0.804t-0.804-0.339-0.804 0.339-0.339 0.804 0.339 0.804 0.804 0.339 0.804-0.339 0.339-0.804zM29.714 22.286v5.714q0 0.714-0.5 1.214t-1.214 0.5h-26.286q-0.714 0-1.214-0.5t-0.5-1.214v-5.714q0-0.714 0.5-1.214t1.214-0.5h7.625q0.375 1 1.259 1.643t1.973 0.643h4.571q1.089 0 1.973-0.643t1.259-1.643h7.625q0.714 0 1.214 0.5t0.5 1.214zM23.911 10.714q-0.304 0.714-1.054 0.714h-4.571v8q0 0.464-0.339 0.804t-0.804 0.339h-4.571q-0.464 0-0.804-0.339t-0.339-0.804v-8h-4.571q-0.75 0-1.054-0.714-0.304-0.696 0.25-1.232l8-8q0.321-0.339 0.804-0.339t0.804 0.339l8 8q0.554 0.536 0.25 1.232z" />
  </svg>
);

export const user = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
    <path d="M25.143 25.089q0 2.143-1.304 3.384t-3.464 1.241h-15.607q-2.161 0-3.464-1.241t-1.304-3.384q0-0.946 0.063-1.848t0.25-1.946 0.473-1.938 0.768-1.741 1.107-1.446 1.527-0.955 1.991-0.357q0.161 0 0.75 0.384t1.33 0.857 1.929 0.857 2.384 0.384 2.384-0.384 1.929-0.857 1.33-0.857 0.75-0.384q1.089 0 1.991 0.357t1.527 0.955 1.107 1.446 0.768 1.741 0.473 1.938 0.25 1.946 0.063 1.848zM19.429 9.143q0 2.839-2.009 4.848t-4.848 2.009-4.848-2.009-2.009-4.848 2.009-4.848 4.848-2.009 4.848 2.009 2.009 4.848z" />
  </svg>
);

export const userPlus = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 32">
    <path d="M12.571 16q-2.839 0-4.848-2.009t-2.009-4.848 2.009-4.848 4.848-2.009 4.848 2.009 2.009 4.848-2.009 4.848-4.848 2.009zM29.714 18.286h6.286q0.232 0 0.402 0.17t0.17 0.402v3.429q0 0.232-0.17 0.402t-0.402 0.17h-6.286v6.286q0 0.232-0.17 0.402t-0.402 0.17h-3.429q-0.232 0-0.402-0.17t-0.17-0.402v-6.286h-6.286q-0.232 0-0.402-0.17t-0.17-0.402v-3.429q0-0.232 0.17-0.402t0.402-0.17h6.286v-6.286q0-0.232 0.17-0.402t0.402-0.17h3.429q0.232 0 0.402 0.17t0.17 0.402v6.286zM16.571 22.286q0 0.929 0.679 1.607t1.607 0.679h4.571v4.25q-1.214 0.893-3.054 0.893h-15.607q-2.161 0-3.464-1.232t-1.304-3.393q0-0.946 0.063-1.848t0.25-1.946 0.473-1.938 0.768-1.741 1.107-1.446 1.527-0.955 1.991-0.357q0.339 0 0.696 0.304 1.411 1.089 2.759 1.634t2.938 0.545 2.938-0.545 2.759-1.634q0.357-0.304 0.696-0.304 2.357 0 3.875 1.714h-3.982q-0.929 0-1.607 0.679t-0.679 1.607v3.429z" />
  </svg>
);

export const userSecret = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 32">
    <path d="M10.286 27.429l1.714-8-1.714-2.286-2.286-1.143zM14.857 27.429l2.286-11.429-2.286 1.143-1.714 2.286zM17.714 9.393q-0.036-0.071-0.071-0.107-0.179-0.143-1.714-0.143-1.25 0-2.982 0.339-0.125 0.036-0.375 0.036t-0.375-0.036q-1.732-0.339-2.982-0.339-1.536 0-1.714 0.143-0.036 0.036-0.071 0.107 0.036 0.321 0.071 0.482 0.036 0.054 0.134 0.116t0.134 0.188q0.036 0.071 0.134 0.366t0.125 0.366 0.134 0.304 0.152 0.304 0.161 0.25 0.214 0.241 0.25 0.17 0.313 0.143 0.366 0.071 0.438 0.036q0.643 0 1.054-0.223t0.58-0.536 0.259-0.616 0.205-0.527 0.313-0.223h0.214q0.196 0 0.313 0.223t0.205 0.527 0.259 0.616 0.58 0.536 1.054 0.223q0.232 0 0.438-0.036t0.366-0.071 0.312-0.143 0.25-0.17 0.214-0.241 0.161-0.25 0.152-0.304 0.134-0.304 0.125-0.366 0.134-0.366q0.036-0.125 0.134-0.188t0.134-0.116q0.036-0.161 0.071-0.482zM25.143 25.089q0 2.161-1.304 3.393t-3.464 1.232h-15.607q-2.161 0-3.464-1.232t-1.304-3.393q0-1.089 0.080-2.107t0.339-2.241 0.67-2.205 1.134-1.848 1.67-1.33l-1.607-3.929h3.821q-0.393-1.143-0.393-2.286 0-0.214 0.036-0.571-3.464-0.714-3.464-1.714 0-1.018 3.75-1.768 0.304-1.107 0.92-2.393t1.259-2.036q0.571-0.661 1.357-0.661 0.536 0 1.5 0.554t1.5 0.554 1.5-0.554 1.5-0.554q0.786 0 1.357 0.661 0.643 0.75 1.259 2.036t0.92 2.393q3.75 0.75 3.75 1.768 0 1-3.464 1.714 0.125 1.446-0.357 2.857h3.821l-1.464 4.018q1.125 0.589 1.92 1.723t1.17 2.563 0.518 2.705 0.143 2.652z" />
  </svg>
);

export const userTimes = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 32">
    <path d="M12.571 16q-2.839 0-4.848-2.009t-2.009-4.848 2.009-4.848 4.848-2.009 4.848 2.009 2.009 4.848-2.009 4.848-4.848 2.009zM31.804 21.714l4.446 4.446q0.161 0.161 0.161 0.411 0 0.232-0.161 0.393l-2.429 2.429q-0.161 0.161-0.393 0.161-0.25 0-0.411-0.161l-4.446-4.446-4.446 4.446q-0.161 0.161-0.411 0.161-0.232 0-0.393-0.161l-2.429-2.429q-0.161-0.161-0.161-0.393 0-0.25 0.161-0.411l4.446-4.446-4.446-4.446q-0.161-0.161-0.161-0.411 0-0.232 0.161-0.393l2.429-2.429q0.161-0.161 0.393-0.161 0.25 0 0.411 0.161l4.446 4.446 4.446-4.446q0.161-0.161 0.411-0.161 0.232 0 0.393 0.161l2.429 2.429q0.161 0.161 0.161 0.393 0 0.25-0.161 0.411zM22.911 21.714l-3.232 3.232q-0.661 0.661-0.661 1.625 0 0.946 0.661 1.607l1.482 1.482q-0.375 0.054-0.786 0.054h-15.607q-2.161 0-3.464-1.232t-1.304-3.393q0-0.946 0.063-1.848t0.25-1.946 0.473-1.938 0.768-1.741 1.107-1.446 1.527-0.955 1.991-0.357q0.339 0 0.696 0.304 2.75 2.179 5.696 2.179t5.696-2.179q0.357-0.304 0.696-0.304 0.5 0 1.018 0.107-0.5 0.482-0.732 0.893t-0.232 1q0 0.964 0.661 1.625z" />
  </svg>
);

export const showHideIconEyeOn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
    <circle cx="12" cy="12" r="3" />
  </svg>
);

export const showHideIconEyeOff = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24" />
    <line x1="1" y1="1" x2="23" y2="23" />
  </svg>
);
