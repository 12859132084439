import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const card = (colors: IColorsType, bounds: IBoundsType) => ({
  wrapper: {
    backgroundColor: colors.lightest,
    boxShadow: colors.neutrals.darkest
  },

  list: {
    borderSize: bounds.border.size,
    borderColor: colors.neutrals.lighter
  }
});
