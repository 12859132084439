import {
  successStyle,
  successOutlineStyle,
  successInvertedStyle
} from './styles';

import { builder } from './builder';
export const SuccessButton = builder(successStyle, 'SuccessButton');
export const SuccessInvertedButton = builder(
  successInvertedStyle,
  'SuccessInvertedButton'
);
export const SuccessOutlineButton = builder(
  successOutlineStyle,
  'SuccessOutlineButton'
);
