import React from 'react';
import { IEventProps } from '../event-props';

type InputType = 'checkbox' | 'radio';

export interface IRadioCheckboxProps extends IEventProps {
  name?: string;
  value?: string;
  type?: InputType;
  isChecked?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  labelledby?: string | null;
  children?: React.ReactNode;
}

export const defaultProps: IRadioCheckboxProps = {
  name: undefined,
  value: undefined,
  isChecked: false,
  isDisabled: false,
  isRequired: false,
  isInvalid: false,
  children: null,
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {},
  labelledby: null
};
