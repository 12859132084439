import invariant from 'invariant';
import { ITheme } from 'theme';
import { px } from '../utils';

interface PropsWithTheme {
  theme: ITheme;
}

type AllowedOrientation = 'vertical' | 'horizontal';

const orientationMap = {
  vertical: 'paddingVertical',
  horizontal: 'paddingHorizontal',
  spacing: 'spacing'
};

export const getSpacing = ({ theme }: PropsWithTheme) => (
  orientation: AllowedOrientation,
  index: number
) => {
  const nameSpaceExists = theme.layout.hasOwnProperty(
    orientationMap[orientation]
  );
  invariant(
    nameSpaceExists,
    `getSpacing(): There is no node named '${orientation}' within the theme.layout`
  );
  const valueExists = theme.layout[orientationMap[orientation]].hasOwnProperty(
    index
  );
  invariant(
    valueExists,
    `getSpacing(): There is no value for index '${index}' within the theme.layout.${orientationMap[orientation]}`
  );
  return px(theme.layout[orientationMap[orientation]][index]);
};
