export const CHECKERED_BACKGROUND =
  'linear-gradient(45deg, #fcfcfc 25%, transparent 25%,transparent 75%, #fcfcfc 75%, #fcfcfc 100%), linear-gradient(45deg, #fcfcfc 25%, transparent 25%,transparent 75%, #fcfcfc 75%, #fcfcfc 100%)';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  preview: {
    position: 'relative'
  },
  tabs: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  tab: {
    padding: '10px',
    display: 'block',
    textDecoration: 'none',
    color: '#222',
    fontSize: '14px',
    backgroundColor: '#e9e9e9',
    border: '1px solid #eee'
  },
  tabActive: {
    color: '#fff',
    backgroundColor: '#ccc'
  },
  renderFrame: {
    border: '1px solid #eee',
    padding: '50px 80px',

    backgroundImage: CHECKERED_BACKGROUND,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 50px 50px'
  },
  renderFrameInner: {
    transition: 'opacity 200ms'
  },
  renderFrameInnerInvalid: {
    opacity: 0.3
  },
  redBox: {
    background: 'rgb(204, 0, 0)',
    color: 'white',
    fontFamily: 'monospace',
    padding: 10,
    fontSize: 15,
    whiteSpace: 'pre'
  }
};
