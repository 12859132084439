import { component } from 'builder';
import { Base } from './base/breadcrumb';
import { IBreadcrumbProps } from './props';

export const Breadcrumb = component<IBreadcrumbProps>({
  name: 'Breadcrumb',
  base: Base,
  props: {
    children: null
  }
});
