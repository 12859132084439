import React from 'react';
import styled from 'styled-components';
interface IProps {
  id: string;
  children: React.ReactNode;
}

const StyledLabel = styled.label`
  position: absolute;
  left: -9999px;
`;

export const Label = ({ id, children }: IProps) => (
  <StyledLabel id={id}>{children}</StyledLabel>
);
