import { map, addIndex } from 'rambda';
import deepmerge from 'deepmerge';

// hack to deal with jest not supporting "module" in package.json
// https://github.com/facebook/jest/issues/2702
const merge = !!deepmerge.default ? deepmerge.default : deepmerge;

const mapWithIndex = addIndex(map);
const overwriteByIndex = (target: any[], source: any[]) => {
  if (target.length === 0) return source;

  return mapWithIndex((value: any, index: number) => {
    return !!source[index] ? source[index] : value;
  }, target);
};

export function createTheme(defaults: any = {}, overrides: any = {}) {
  return merge(defaults, overrides, { arrayMerge: overwriteByIndex });
}
