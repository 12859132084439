import { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  px,
  em,
  paddingHorizontal,
  paddingVertical,
  getTypography
} from 'utils';
import { ITheme } from 'theme';

const style = selectComponentThemeVariablesFor(theme => theme.Content);

type ComponentTheme = ITheme['components']['Content'];

const setMargin = (t: ComponentTheme, name: string) => {
  if (t.margins != null && t.margins[name]) {
    return css`
      margin-top: 0;
      margin-bottom: ${px(t.margins[name])};
    `;
  }

  return '';
};

const getStyle = (t: ComponentTheme, name: string) =>
  css`
    ${setMargin(t, name)};
  `;

const fallbackMixin = () =>
  style(
    t => css`
      ul,
      ol,
      p {
        ${getStyle(t, 'Body')};
      }
    `
  );

const titleMixin = () =>
  style(
    t => css`
      h1 {
        ${getTypography('Title')};
        ${getStyle(t, 'h1')};
      }
    `
  );

const headingMixin = () =>
  style(
    t => css`
      h2 {
        ${getTypography('Heading')};
        ${getStyle(t, 'h2')};
      }
    `
  );

const subHeadingMixin = () =>
  style(
    t => css`
      h3 {
        ${getTypography('SubHeading')};
        ${getStyle(t, 'h3')};
      }
    `
  );

const leadMixin = () =>
  style(
    t => css`
      p {
        ${getTypography('Lead')};
        ${getStyle(t, 'h4')};
      }
    `
  );

const bodyMixin = () =>
  style(
    t => css`
      p {
        ${getTypography('Default')};
        ${getStyle(t, 'p')};
      }
    `
  );

const hintMixin = () =>
  style(
    t => css`
      p {
        ${getTypography('Hint')};
        ${getStyle(t, 'h6')};
      }
    `
  );

const linkMixin = () =>
  style(
    t => css`
      a {
        color: ${t.link.color};
        text-decoration: ${t.link.decoration};

        &:hover {
          color: ${t.link.status.hover};
        }
      }
    `
  );

const blockquoteMixin = () =>
  style(
    t => css`
      blockquote {
        ${getTypography('Hint')};
        ${getStyle(t, 'h6')};
        margin-left: 0;
        margin-right: 0;
        border-left: 5px solid ${t.blockquote.borderColor};
        background-color: ${t.blockquote.backgroundColor};
        padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
      }
    `
  );

const codeMixin = () =>
  style(
    t => css`
      pre {
        ${getTypography('Default')};
        ${getStyle(t, 'pre')};
        white-space: pre-wrap;
      }
    `
  );

export const content = style(
  (t, { align }) => css`
    display: block;
    text-align: ${align != null ? align : 'initial'};
    ${getTypography('Default')};
    * {
      margin-top: 0;
    }

    *:last-child {
      margin-bottom: 0;
    }

    ${titleMixin()};
    ${headingMixin()};
    ${subHeadingMixin()};
    ${leadMixin()};
    ${bodyMixin()};
    ${hintMixin()};
    ${linkMixin()};
    ${fallbackMixin()};
    ${blockquoteMixin()};
    ${codeMixin()};
  `
);
