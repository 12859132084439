import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const textArea = (colors: IColorsType, bounds: IBoundsType) => ({
  textColor: colors.neutrals.normal,
  backgroundColor: colors.lightest,
  borderSize: bounds.border.size,
  borderColor: colors.neutrals.light,
  borderRadius: bounds.border.radius,

  status: {
    focus: {
      borderColor: colors.auxillary,
      outlineColor: colors.auxillary,
      outlineSize: bounds.outline.size
    },
    disabled: {
      backgroundColor: colors.neutrals.lighter
    }
  },

  placeholder: {
    textColor: tc(colors.neutrals.light)
      .darken(15)
      .toString()
  }
});
