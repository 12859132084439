import { component } from 'builder';
import { addEvents } from '../core/events';
import { IRadioCheckboxProps } from '../core/RadioCheckbox/props';
import { defaultProps } from '../core/RadioCheckbox/props';
import { RadioBase } from './base';
import { wrapperStyle } from '../core/RadioCheckbox/styles';

const RadioWithEvents = addEvents(RadioBase);

export const Radio = component<IRadioCheckboxProps>({
  name: 'Radio',
  base: RadioWithEvents,
  container: 'div',
  style: wrapperStyle,
  props: defaultProps
});
