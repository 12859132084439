import React from 'react';
import styled from 'styled-components';
import {
  Card as CardBase,
  Stacked,
  Toggle,
  Row,
  Container,
  Table,
  TableRow,
  TableCell,
  TableHeader
} from '../';
import ModalBase from '../Modal/ModalBase';
import { isCategoryAccepted } from './base';
import {
  CookieCategories,
  CookieCategoryCookie,
  cookieCategoryCookies
} from './cookieCategories';

const Host = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  background: #fafafa;
  z-index: 999;
  padding-bottom: 5rem;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
`;

const ToggleCardContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;

  & input:focus + div {
    box-shadow: unset;
  }

  & input:checked + div {
    background-color: #4c4c4c;
  }

  & input:checked + div:before {
    border-color: #4c4c4c;
  }
`;

const OptionalControls = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  margin: 60px 0 20px;
  font-size: 32px;
  font-weight: 500;
`;

const Heading = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: #050505;
  margin-bottom: 4px;
  margin-top: 0;
`;

const SubHeading = styled.h3`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 0;
`;

const Card = styled(CardBase)`
  border: 1px solid #ededed;
  box-shadow: 0px 2px 6px 0px #0000000a;
  border-radius: 12px;
  padding: 16px 24px;
`;

const Well = styled.div`
  background-color: #fafafa;
  border: 1px solid #ededed;
  border-radius: 12px;
  margin-top: 20px !important;
  padding: 25px 30px;
`;

const Lead = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

const Default = styled.span`
  font-size: 15px;
  line-height: 25px;
  margin-top: 0 !important;
`;

const List = styled.div`
  margin: 25px 40px;
`;

const Line = styled.div`
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
`;

const WarningAlert = styled.div`
  padding: 12px 20px 20px;
  margin: 20px 0 !important;
  background-color: #fef2e6;
  border: 1px solid #fef2e6;
  border-left: 5px solid #f9a656;
  border-radius: 6px;
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #000000;
    text-decoration: underline;
  }

  & p {
    font-weight: 500;
  }

  & p::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%2339A49D' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-arrow-right'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3Cpolyline points='12 5 19 12 12 19'%3E%3C/polyline%3E%3C/svg%3E");
    position: relative;
    top: 3px;
    left: 2px;
  }
`;

const LinkExternal = styled.a`
  color: #000000;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #000000;
    text-decoration: underline;
  }

  & p,
  & span {
    font-weight: 500;
  }

  & p::after,
  & span::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-external-link'%3E%3Cpath d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'%3E%3C/path%3E%3Cpolyline points='15 3 21 3 21 9'%3E%3C/polyline%3E%3Cline x1='10' y1='14' x2='21' y2='3'%3E%3C/line%3E%3C/svg%3E");
    position: relative;
    top: 3px;
    left: 2px;
  }
`;

const OutlineButton = styled.button`
  background-color: #f6f6f6;
  border: 1px solid #ededed;
  color: #050505;
  padding: 16px 48px;
  font-size: 17px;
  min-height: 48px;
  border-radius: 6px;
  box-shadow: unset;
  outline: 0;
  cursor: pointer;
  min-height: 56px;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #ededed;
    border-color: #ededed;
    box-shadow: unset;
    transform: unset;
  }
`;

const SuccessButton = styled.button`
  background-color: #39a49d;
  border: 1px solid #ededed;
  color: #fefefe;
  padding: 16px 48px;
  font-size: 17px;
  min-height: 48px;
  border-radius: 6px;
  box-shadow: unset;
  outline: 0;
  cursor: pointer;
  min-height: 56px;
  font-family: 'Libre Franklin', 'Helvetica Neue', Helvetica, sans-serif;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: #368d87;
    border-color: #ededed;
    box-shadow: unset;
    transform: unset;
  }
`;

const Modal = styled(ModalBase)`
  box-shadow: unset;
  border: none;
  border-radius: 6px;

  & > div:first-child {
    background: #ededed;
    border-radius: 6px 6px 0 0;
    border: none;
    color: #333333;
    font-size: 25px;
    padding: 18px;
    outline: none;
  }

  & > div:first-child span[aria-label='close'] svg {
    display: none;
  }

  & > div:first-child span[aria-label='close'] > span::after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
  }
`;

const TableContainer = styled.div`
  & ol,
  & li,
  & li:first-child,
  & li:last-child {
    border-color: #ededed;
    font-size: 16px;
  }
`;

const CookieList = ({ listTitle, isOpen, close, cookies, zIndex }: any) => (
  <Modal title={listTitle} isOpen={isOpen} onClose={close} zIndex={zIndex}>
    <TableContainer>
      <Table>
        <TableRow>
          <TableHeader>Cookie name</TableHeader>
          <TableHeader>Source</TableHeader>
          <TableHeader>Purpose</TableHeader>
          <TableHeader>Expiry</TableHeader>
        </TableRow>
        {cookies.map((c: CookieCategoryCookie) => (
          <TableRow>
            <TableCell>{c.name}</TableCell>
            <TableCell>{c.source}</TableCell>
            <TableCell>{c.purpose}</TableCell>
            <TableCell>{c.expiry}</TableCell>
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  </Modal>
);

class ToggleCard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      toggled: isCategoryAccepted(props.category),
      isModalOpen: false
    };
  }

  render() {
    return (
      <Well>
        <ToggleCardContainer>
          <Stacked spaceBetween="2rem" vAlign="flex-start">
            <SubHeading>{this.props.title}</SubHeading>
            {this.props.children}
            <Link onClick={() => this.setState({ isModalOpen: true })}>
              <Lead>{this.props.listTitle}</Lead>
            </Link>
          </Stacked>
          <div
            style={{
              display: 'flex',
              marginLeft: '2rem',
              fontSize: '16px',
              lineHeight: '30px',
              fontWeight: 600,
              color: this.state.toggled ? '#333333' : '#A3A3A3'
            }}
          >
            <Toggle
              isChecked={this.state.toggled}
              value={this.props.category}
              onChange={() =>
                this.setState({
                  toggled: this.props.toggleCategory(this.props.category)
                })
              }
            >
              Accept
            </Toggle>
          </div>
        </ToggleCardContainer>
        <CookieList
          listTitle={this.props.listTitle}
          isOpen={this.state.isModalOpen}
          cookies={cookieCategoryCookies[this.props.category]}
          close={() => this.setState({ isModalOpen: false })}
          zIndex={this.props.zIndex}
        />
      </Well>
    );
  }
}

export interface ModalProps {
  acceptAllCookies: () => void;
  rejectAllCookies: () => void;
  close: () => void;
  toggleCategory: (category: CookieCategories) => boolean;
  isCategoryAccepted: (category: CookieCategories) => boolean;
  zIndex?: number;
  cookiePolicyUrl: string;
  privacyPolicyUrl: string;
}

export default class extends React.Component<ModalProps, any> {
  constructor(props: ModalProps) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  render() {
    return (
      <Host {...this.props}>
        <Container>
          <Title>Cookie Management</Title>
        </Container>
        <Container>
          <Card>
            <Stacked>
              <Heading>Please select your cookie preferences</Heading>
              <Well>
                <Stacked>
                  <Lead>
                    We use cookies to improve the performance of our website and
                    make improvements to your online experience. A cookie is a
                    small piece of data that a website - when visited by a user
                    - asks your browser to store on your device so that it can
                    remember information about you, like your language
                    preference or login information. Cookies set directly by
                    secure.parmenion.co.uk are called first-party cookies.
                  </Lead>
                  <Lead>
                    Our marketing and analytics systems also set cookies to help
                    us with our marketing efforts. These cookies are set by
                    incorporated elements from external sites via
                    parmenion.co.uk and are known as third-party cookies.
                  </Lead>
                  <Lead>
                    <>
                      This cookie page is related to the use of the Parmenion
                      platform (secure.parmenion.co.uk). You will find details
                      about the cookies that we use for Parmenion.co.uk on the
                      website{' '}
                      <LinkExternal
                        href="https://www.parmenion.co.uk/security-centre/cookies"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <span>{`https://www.parmenion.co.uk/security-centre/cookies`}</span>
                      </LinkExternal>
                    </>
                  </Lead>
                  <Lead>
                    We use cookies and other tracking technologies for the
                    following purposes:
                  </Lead>
                </Stacked>
              </Well>
              <List>
                <SubHeading>Necessary cookies - always on</SubHeading>
                <Stacked>
                  <Default>
                    These cookies support our website's basic functionality,
                    allowing it to work properly. This means they're always on
                    for secure.parmenion.co.uk. They perform roles like:
                  </Default>
                  <Default>
                    <ul>
                      <li>Supporting the navigation</li>
                      <li>Supporting the look and feel</li>
                    </ul>
                  </Default>
                  <Default>
                    They don't store any personally identifiable information.
                  </Default>
                  <Default>
                    <div style={{ marginTop: '20px' }}>
                      <LinkExternal
                        href={this.props.cookiePolicyUrl}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        <Lead>Our cookie policy can be found here</Lead>
                      </LinkExternal>
                    </div>
                  </Default>
                  <div style={{ marginTop: '20px' }}>
                    <Link
                      onClick={() =>
                        this.setState({
                          isModalOpen: true
                        })
                      }
                    >
                      <Lead>List of necessary cookies</Lead>
                    </Link>
                    <CookieList
                      listTitle="List of necessary cookies"
                      isOpen={this.state.isModalOpen}
                      cookies={
                        cookieCategoryCookies[CookieCategories.necessary]
                      }
                      close={() => this.setState({ isModalOpen: false })}
                      zIndex={this.props.zIndex}
                    />
                  </div>
                </Stacked>
              </List>
            </Stacked>
            <Line />
            <Stacked>
              <OptionalControls>
                <Heading>Optional cookie settings</Heading>
                <div>
                  <Row spaceBetween={2}>
                    <OutlineButton onClick={this.props.rejectAllCookies}>
                      Reject All
                    </OutlineButton>
                    <SuccessButton onClick={this.props.acceptAllCookies}>
                      Accept All
                    </SuccessButton>
                  </Row>
                </div>
              </OptionalControls>
              <ToggleCard
                title="Cookies that we use for marketing"
                category={CookieCategories.marketing}
                listTitle="List of marketing cookies"
                toggleCategory={this.props.toggleCategory}
                zIndex={this.props.zIndex}
              >
                <Lead>
                  These cookies allow us to share relevant marketing and
                  communications with our customers. They don't store direct
                  personal information but work by uniquely identifying your
                  browser / internet device to build a profile of you. If you
                  don't allow these cookies, you may experience less relevant
                  marketing from us.
                </Lead>
              </ToggleCard>
              <ToggleCard
                title="Cookies that we use for live chat"
                category={CookieCategories.liveChat}
                listTitle="List of live chat cookies"
                toggleCategory={this.props.toggleCategory}
                zIndex={this.props.zIndex}
              >
                <Lead>
                  These cookies help make your live chat experience even better
                  by letting us know which page you started the chat on, your
                  chat history and more. If you don&apos;t allow these cookies,
                  we might need to ask more questions to help you.
                </Lead>
              </ToggleCard>
            </Stacked>
            <Stacked>
              <div
                style={{
                  margin: '20px 0 5px',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <SuccessButton onClick={this.props.close}>
                  Save &amp; Exit
                </SuccessButton>
              </div>
              <WarningAlert>
                <Default>
                  <p style={{ marginTop: '0', fontWeight: 500 }}>
                    Other help and information
                  </p>
                </Default>
                <Lead>
                  <>
                    To find out a bit more about Parmenion's approach to data
                    privacy, read our{' '}
                    <LinkExternal
                      href={this.props.privacyPolicyUrl}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <span>Privacy Policy</span>
                    </LinkExternal>
                    . It contains more important information about what personal
                    details we collect; what we do with that information; who we
                    may share it with and why; and your choices and rights when
                    it comes to the personal information you have given us.
                  </>
                </Lead>
              </WarningAlert>
            </Stacked>
          </Card>
        </Container>
      </Host>
    );
  }
}
