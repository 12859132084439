import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const bar = (colors: IColorsType, bounds: IBoundsType) => ({
  container: {
    backgroundColor: colors.auxillary,
    height: 60
  },
  link: {
    color: colors.lightest,
    status: {
      active: {
        color: colors.lightest,
        backgroundColor: tc(colors.auxillary)
          .lighten(5)
          .toString()
      }
    }
  }
});
