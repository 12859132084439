import React from 'react';
import styled from 'styled-components';
import { InputGroup } from '../InputGroup';
import { InputText } from '../InputText';
import { InputPassword } from '../InputPassword';
import { IShowHideProps } from './props';
import { wrapperStyle, showHideStyle } from './style';
import { Icon } from '../../Icon';

const SPACE = 32;
const RETURN = 13;

const Wrapper = styled.span([wrapperStyle]);
const ShowHideBase = styled.span([showHideStyle]);

export default class ShowHide extends React.Component<IShowHideProps> {
  state = { show: false };

  render() {
    const { showLabel, hideLabel } = this.props;

    const label = this.state.show ? hideLabel : showLabel;
    const Component = this.state.show ? InputText : InputPassword;
    return (
      <Wrapper>
        <InputGroup
          end={
            <ShowHideBase
              tabIndex={0}
              onKeyPress={(event: any) => {
                if (event.charCode === RETURN || event.charCode === SPACE) {
                  event.preventDefault();
                  this.setState({ show: !this.state.show });
                }
              }}
              onClick={() => this.setState({ show: !this.state.show })}
              className="show-hide-button"
              aria-label={`${label}`}
            >
              <Icon
                name={
                  this.state.show ? 'showHideIconEyeOff' : 'showHideIconEyeOn'
                }
              />
            </ShowHideBase>
          }
        >
          <Component {...this.props} placeholder={this.props.placeholder} />
        </InputGroup>
      </Wrapper>
    );
  }
}
