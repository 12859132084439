import React from 'react';

export class InputTextBase extends React.PureComponent<any> {
  static displayName: string = 'InputTextBase';
  private inputRef: HTMLInputElement;

  componentDidUpdate() {
    this.props.didUpdate && this.props.didUpdate(this.inputRef);
  }

  render() {
    const {
      isDisabled,
      isReadOnly,
      inputRef,
      Container,
      labelledby,
      isRequired,
      isInvalid,
      didUpdate,
      isSmall,
      alignText,
      width,
      ...rest
    } = this.props;

    const ariaProps = {
      'aria-required': false,
      'aria-invalid': false
    };
    if (labelledby !== null) ariaProps['aria-labelledby'] = labelledby;
    if (isRequired !== null) ariaProps['aria-required'] = isRequired;
    if (isInvalid !== null) ariaProps['aria-invalid'] = isInvalid;

    return (
      <Container
        {...rest}
        {...ariaProps}
        ref={(ref: HTMLInputElement) => {
          this.inputRef = ref;
          inputRef(ref);
        }}
        disabled={isDisabled}
        readOnly={isReadOnly}
      />
    );
  }
}
