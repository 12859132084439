import React from 'react';
import { IEventProps } from '../event-props';

type StatusType = 'success' | 'warning' | 'danger' | 'neutral' | 'default';
type InputType = 'checkbox' | 'radio';

export interface IRadioCheckboxProps extends IEventProps {
  name?: string;
  type?: InputType;
  value?: string;
  isSmall?: boolean;
  status?: StatusType;
  isChecked?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
  labelledby?: string | null;
  isDisabled?: boolean;
  children?: React.ReactNode;
}

export const defaultProps: IRadioCheckboxProps = {
  name: undefined,
  value: undefined,
  status: 'default',
  isSmall: false,
  isChecked: false,
  isDisabled: false,
  isRequired: false,
  isInvalid: false,
  children: null,
  onChange: () => {},
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {},
  labelledby: null
};
