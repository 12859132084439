import React from 'react';
import { IEventProps } from '../event-props';

export interface IInputTextProps extends IEventProps {
  placeholder?: string;
  type?: 'text' | 'number' | 'password' | 'tel' | 'datetime-local';
  isReadOnly?: boolean;
  isDisabled: boolean;
  isActive?: boolean;
  isSmall?: boolean;
  isRequired?: boolean | null;
  isInvalid?: boolean | null;
  value?: string;
  inputRef?: React.Ref<'input'>;
  alignText?: 'left' | 'center' | 'right';
  maxLength?: string | null;
  name?: string | null;
  id?: string | null;
  labelledby?: string | null;
  inputMode?: string | null;
  pattern?: string | null;
  width?: number | null;
  didUpdate?: () => void;
}

export const defaultProps: IInputTextProps = {
  placeholder: '',
  isSmall: false,
  isReadOnly: false,
  isDisabled: false,
  isRequired: null,
  isInvalid: null,
  value: undefined,
  alignText: 'left',
  maxLength: null,
  name: null,
  didUpdate: undefined,
  inputRef: () => {},
  onChange: () => {},
  onInput: () => {},
  onClick: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  onPaste: () => {},
  id: null,
  labelledby: null,
  inputMode: null,
  pattern: null,
  width: null
};

export const styleProps = ['alignText', 'isSmall', 'width'];
