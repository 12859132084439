import React from 'react';
import styled from 'styled-components';
import { Icon } from '../Icon';
import { Default } from '../Typography';
import { stepStyle, wrapperStyle, barStyle, listStyle, bgStyle } from './style';

const Wrapper = styled.div([wrapperStyle]);

const Step = styled.span([stepStyle]);

const BarDiv = styled.span([barStyle]);

const ListItem = styled.li([listStyle]);

const BackgroundWrapper = styled.div([bgStyle]);

export default ({ steps, currentStep, progress, ...props }: any) => {
  const stepList: any[] = [];
  const listItems: any[] = [];
  if (steps && Object.keys(steps).length > 0) {
    const stepKeys = Object.keys(steps);
    const currentStepIndex =
      currentStep && stepKeys.indexOf(currentStep.toString());
    stepKeys.forEach((key, index) => {
      const isCurrentStep = index === currentStepIndex;
      const isCompletedStep = index < currentStepIndex;
      stepList.push(
        <Step
          key={key}
          className={`progress-item ${isCurrentStep ? `current-step` : ``} ${
            isCompletedStep ? `completed-step` : ``
          }`}
          styleProps={{ step: key, currentStep }}
        >
          <span>
            {isCompletedStep ? (
              <Icon name="check" size={1} strokeWidth={3} />
            ) : (
              <Default>{key}</Default>
            )}
          </span>
        </Step>
      );
      if (index !== stepKeys.length - 1) {
        stepList.push(
          <BarDiv
            key={`bar-${key}`}
            styleProps={{
              progress: isCurrentStep
                ? progress
                : isCompletedStep
                ? `100`
                : `0`,
              isCurrentStep,
              step: key
            }}
            className="step-bar"
          />
        );
      }
      listItems.push(
        isCurrentStep ? (
          <ListItem
            className={`step-title step-title-current`}
            styleProps={{ step: key }}
            key={key}
          >
            <Default>
              <b>{steps[key]}</b>
            </Default>
          </ListItem>
        ) : (
          <ListItem
            className={`step-title`}
            styleProps={{ step: key }}
            key={key}
          >
            <Default>{steps[key]}</Default>
          </ListItem>
        )
      );
    });
  }

  return (
    <BackgroundWrapper>
      <Wrapper
        styleProps={{ steps: steps && Object.keys(steps).length }}
        {...props}
      >
        <div>{stepList}</div>
        <ol>{listItems}</ol>
      </Wrapper>
    </BackgroundWrapper>
  );
};
