import { component } from 'builder';
import { IStepProgressBarProps } from './props';
import Base from './base';

export const StepProgressBar = component<IStepProgressBarProps>({
  name: 'StepProgressBar',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    steps: {},
    progress: 0,
    currentStep: 0
  }
});
