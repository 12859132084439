const invalidKey = (key: any, defaults: any) => !defaults.hasOwnProperty(key);

const checkThemeOverrides = (
  defaults: any,
  overrides: any,
  parent = 'root'
) => {
  if (typeof overrides !== 'object' || overrides == null) return;
  const keys = Object.keys(overrides);
  while (keys.length > 0) {
    const key: any = keys.pop();
    if (invalidKey(key, defaults)) {
      throw new Error(
        `${JSON.stringify(key)} does not exist in ${JSON.stringify(
          parent
        )} node of theme`
      );
    } else {
      checkThemeOverrides(defaults[key], overrides[key], key);
    }
  }
  return true;
};

export const validateThemeOverrides = (defaults: {}) => (overrides: {}) => {
  try {
    return checkThemeOverrides(defaults, overrides);
  } catch (e) {
    throw e;
  }
};
