import React from 'react';

export const HORIZONTAL_VALIGN_MAP = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end'
};

export const HORIZONTAL_HALIGN_MAP = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  spaceAround: 'space-around',
  spaceBetween: 'space-between'
};

export const STACKED_VALIGN_MAP = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  spaceAround: 'space-around',
  spaceBetween: 'space-between'
};

export const STACKED_HALIGN_MAP = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};

export const space = (count, spacing) => spacing[count];

export const calculateSize = size => {
  if (size == null) {
    return undefined;
  }

  if (size <= 1) {
    // percentage
    return `${size * 100}%`;
  }

  return `${size}px`;
};

const DEFAULT_SPACE_BETWEEN = 1;

export const getSpaceBetween = props => {
  if (props.noSpaceBetween) {
    return 0;
  } else if (props.spaceBetween == null) {
    return DEFAULT_SPACE_BETWEEN;
  }

  return props.spaceBetween;
};

export const computeAlignItems = props => {
  if (props.horizontal) {
    return props.vAlign != null ? HORIZONTAL_VALIGN_MAP[props.vAlign] : null;
  }

  return props.hAlign != null ? STACKED_HALIGN_MAP[props.hAlign] : null;
};

export const computeJustifyContent = props => {
  if (props.horizontal) {
    return props.hAlign != null ? HORIZONTAL_HALIGN_MAP[props.hAlign] : null;
  }

  return props.vAlign != null ? STACKED_VALIGN_MAP[props.vAlign] : null;
};

export const computeWrap = props => {
  let flexWrap = 'nowrap';
  if (props.horizontal && props.wrap !== false) {
    flexWrap = 'wrap';
  }
  return flexWrap;
};

export const computeShrink = props => {
  let flexShrink = '0';
  if (props.horizontal && props.shrink !== false) {
    flexShrink = '1';
  }
  return flexShrink;
};

export const BREAKPOINTS = [767, 991, 1199, 1300];
