import {
  primaryStyle,
  primaryOutlineStyle,
  primaryInvertedStyle
} from './styles';

import { builder } from './builder';
export const PrimaryButton = builder(primaryStyle, 'PrimaryButton');
export const PrimaryInvertedButton = builder(
  primaryInvertedStyle,
  'PrimaryInvertedButton'
);
export const PrimaryOutlineButton = builder(
  primaryOutlineStyle,
  'PrimaryOutlineButton'
);
