import React from 'react';
import styled from 'styled-components';
import { Button } from '../../';
import { IEventFiles } from '../props';
import * as styles from '../style';

const Container = styled.div([styles.inputContainer]);
const Input = styled.input([styles.input]);
const Label = styled.label([styles.label]);

interface IProps {
  name: string;
  handler: (value: IEventFiles) => void;
}

export default class FileUploadInput extends React.Component<IProps, any> {
  static displayName = 'FileUploadInput';
  private input: HTMLInputElement;
  render() {
    return (
      <Container>
        <Button as={<Label htmlFor="filesToUpload" />}>
          Add new file(s)
          <Input
            ref={(ref: any) => (this.input = ref)}
            onChange={e => this.props.handler(e.target.files)}
            onClick={e => (this.input.value = '')}
            name={`${this.props.name}[]`}
            id="filesToUpload"
            type="file"
            multiple
          />
        </Button>
      </Container>
    );
  }
}
