import { css } from 'styled-components';
import {
  em,
  getColor,
  selectComponentThemeVariablesFor,
  px,
  styleComponent
} from 'utils';
import { ITheme } from '../../theme';

const style = selectComponentThemeVariablesFor(theme => theme);
const iconStyler = styleComponent(style);

const dimensionsMixin = (size: number) => css`
  height: ${em(size)};
  width: ${em(size)};
`;

const colorMixin = (color: string) => css`
  path {
    fill: ${color ? getColor(color) : 'currentColor'};
  }
`;

const strokeMixin = (
  stroke: string,
  strokeWidth: number,
  strokeLineJoin: string
) => css`
  path {
    stroke: ${stroke ? getColor(stroke) : 'currentColor'};
    stroke-width: ${px(strokeWidth)};
    stroke-linejoin: ${strokeLineJoin};
  }
`;

const iconStyleCallback = (styles: any) => styles.Icon.Wrapper;

const defaultStyleCss = (
  t: ITheme,
  { size, color, stroke, strokeWidth, strokeLineJoin, padding }: any
) => css`
  display: flex;
  ${padding && `padding: ${padding}px;`} svg {
    ${dimensionsMixin(size)};
    ${colorMixin(color)};
    ${strokeMixin(stroke, strokeWidth, strokeLineJoin)};
  }
`;

export const iconStyle = iconStyler(defaultStyleCss, iconStyleCallback);
