import { component } from 'builder';
import { IButtonWithIconProps } from './props';
import { buttonFactory } from './core/factory';
import { Button } from '../Buttons';

export const builder = (name: string, Base: typeof Button) =>
  component<IButtonWithIconProps>({
    name,
    base: buttonFactory({
      Base,
      displayName: `${name}Base`
    }),
    container: 'button',
    canOverrideContainer: true,
    props: {
      children: null,
      name: null,
      value: null,
      isDisabled: false,
      isActive: false,
      isSmall: false,
      onClick: () => {},
      onMouseDown: null,
      icon: null,
      iconPosition: 'left',
      iconMap: null,
      type: 'button',
      noWrap: false,
      color: null,
      stroke: null,
      strokeWidth: 0,
      strokeLineJoin: null,
      'aria-describedby': null,
      'aria-label': null
    }
  });
