import tc from 'tinycolor2';
import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const modal = (colors: IColorsType, bounds: IBoundsType) => ({
  borderSize: bounds.border.size,
  borderRadius: 0,
  borderColor: tc(colors.darkest)
    .setAlpha(0.2)
    .toRgbString(),

  title: {
    borderSize: bounds.border.size / 2,
    borderColor: colors.transparent,
    backgroundColor: colors.auxillary,
    textColor: colors.lightest
  },

  overlay: {
    backgroundColor: tc(colors.darkest)
      .setAlpha(0.5)
      .toRgbString()
  },

  body: {
    backgroundColor: colors.transparent
  },

  wrapper: {
    backgroundColor: colors.lightest
  },

  controls: {
    backgroundColor: colors.transparent
  }
});
