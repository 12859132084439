import React from 'react';
import styled, { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { Box } from '../../core';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Modal);

const ControlsStyle = (t: TThemeVariables) => css`
  background-color: ${t.controls.backgroundColor};
`;

const styler = styleComponent(style);
const controlsStyleCallback = (styles: any) => styles.Modal.Controls;

const StyledContent = styled.div([
  styler(ControlsStyle, controlsStyleCallback)
]);

export default ({ primaryButton, buttons }: any) => {
  return primaryButton != null || buttons != null ? (
    <StyledContent>
      <Box horizontal>
        <Box horizontal fill>
          {buttons}
        </Box>
        {primaryButton && <Box>{primaryButton}</Box>}
      </Box>
    </StyledContent>
  ) : null;
};
