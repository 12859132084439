import { css } from 'styled-components';
import tc from 'tinycolor2';
import { TThemeVariables } from './types';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.GaugeRadialChart);
const styler = styleComponent(style);

const wrapperCallback = (styles: any) => styles.GaugeRadialChart.Wrapper;
const svgCallback = (styles: any) => styles.GaugeRadialChart.Svg;
const circleCallback = (styles: any) => styles.GaugeRadialChart.Circle;
const contentCallback = (styles: any) => styles.GaugeRadialChart.Content;

const backgroundColor = (color: string) =>
  tc(color)
    .setAlpha(0.5)
    .toString();

const sizeOuter = css`
  height: 15rem;
  width: 15rem;
`;

const sizeInner = css`
  height: 12rem;
  width: 12rem;
`;

const wrapperCustomCss = () => css`
  ${sizeOuter};
  position: relative;
`;

const svgCustomCss = (t: TThemeVariables) => css`
  ${sizeOuter};
  background: ${backgroundColor(t.bgColor)};
  transform: rotate(-90deg);
  border-radius: 50%;
`;

const offsetNumber = (number: string) => {
  const parsed = parseInt(number, 10);
  const clamped = Math.min(Math.max(parsed, 0), 100);
  return clamped === 100 ? clamped + 1 : clamped;
};

const circleCustomCss = (t: TThemeVariables) => css`
  fill: ${backgroundColor(t.bgColor)};
  stroke: ${({ styleProps }: any) =>
    styleProps.color ? styleProps.color : t.strokeColor};
  stroke-width: 32;
  stroke-dasharray: ${({ styleProps }: any) =>
      offsetNumber(styleProps.progress)}
    100;
  transition: stroke-dasharray 0.1s ease-in;
`;

const contentCustomCss = (t: TThemeVariables) => css`
  ${sizeInner};
  position: absolute;
  background: ${t.contentBgColor};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const wrapperStyle = styler(wrapperCustomCss, wrapperCallback);
export const svgStyle = styler(svgCustomCss, svgCallback);
export const circleStyle = styler(circleCustomCss, circleCallback);
export const contentStyle = styler(contentCustomCss, contentCallback);
