import React from 'react';
import tc from 'tinycolor2';
import styled from 'styled-components';
import { shouldUpdate } from 'recompose';
import {
  Title,
  Heading,
  Label,
  Separated,
  Content,
  Column,
  Accordion,
  AccordionPanel,
  utils,
  theme
} from '@parmenion/library';
import { Gateway } from 'react-gateway';
import { Link } from 'react-router';
import { Box } from '../ui';
import { getTypography } from '../../../src/utils';
const { getColor, withCustomTheme } = utils;

const CustomAccordionPanel = withCustomTheme(AccordionPanel, {
  typography: {
    SubHeading: {
      fontSize: '16px'
    },
    Default: {
      fontSize: '16px'
    }
  },
  colors: {
    lightest: 'white',
    neutrals: {
      lightest: 'whitesmoke'
    }
  },
  bounds: {
    border: {
      size: 0
    }
  },
  layout: {
    paddingHorizontal: [5, 20],
    paddingVertical: [5, 8]
  }
});

const ComponentList = ({ components, selected }) => (
  <div>
    {components.map((component, key) => (
      <Sidebar.Item
        key={key}
        isSelected={component.key === selected}
        href={`/components/all/${component.key}`}
      >
        {component.name}
      </Sidebar.Item>
    ))}
  </div>
);

class SideBarMenu extends React.Component {
  constructor() {
    super();
    this.state = { openPanelIndex: NaN };
  }

  handlerClicks = openPanelIndex => this.setState({ openPanelIndex });
  render = () => {
    const { children } = this.props;
    return (
      <Accordion
        openPanelIndex={this.state.openPanelIndex}
        onChangeOpenPanelIndex={this.handlerClicks}
      >
        {children}
      </Accordion>
    );
  };
}

export const PageSidebar = shouldUpdate((props, nextProps) => {
  return props.router.params.component !== nextProps.router.params.component;
})(props => (
  <Sidebar>
    <Sidebar.Title>Core</Sidebar.Title>
    <Sidebar.Item
      isSelected={'colors' === props.router.params.article}
      href={'/page/core/colors'}
    >
      colors
    </Sidebar.Item>
    <Sidebar.Item
      isSelected={'typography' === props.router.params.article}
      href={'/page/core/typography'}
    >
      typography
    </Sidebar.Item>
    <Sidebar.Item
      isSelected={'spacing' === props.router.params.article}
      href={'/page/core/spacing'}
    >
      spacing
    </Sidebar.Item>
    <Sidebar.Item
      isSelected={'icons' === props.router.params.article}
      href={'/page/core/icons'}
    >
      icons
    </Sidebar.Item>

    <Sidebar.Title>Components</Sidebar.Title>
    <SideBarMenu>
      {Object.keys(props.categories).map(category => (
        <CustomAccordionPanel
          key={`${category}`}
          title={category}
          isOpen={props.categories[category].find(
            component => component.key === props.router.params.component
          )}
        >
          <ComponentList
            selected={props.router.params.component}
            components={props.categories[category]}
          />
        </CustomAccordionPanel>
      ))}
    </SideBarMenu>
  </Sidebar>
));

const Sidebar = styled.div``;
Sidebar.Title = styled.div`
  margin-top: 15px;
  padding: 20px 20px 5px;
  font-weight: bold;
`;

Sidebar.Item = styled.a`
  padding: 8px 20px;
  display: block;
  text-decoration: none;
  color: ${getColor('darkest')};
  ${getTypography('Hint')};
  ${props =>
    props.isSelected &&
    `
    box-shadow: inset 4px 0 0 0 ${getColor('primary')(props)};
    font-weight: bold;
    background-color: ${
      props.theme.components.Accordion.status.hoverWhenOpen
        .headingBackgroundColor
    };
  `};

  &:hover {
    background-color: ${props =>
      props.theme.components.Accordion.status.isOpen.headingBackgroundColor};
  }
`;
