import React from 'react';
import styled, { css } from 'styled-components';
import invariant from 'invariant';
import { pick } from 'rambda';

const ALLOWED_PROPS = ['className'];

const baseMixin = css`
  * {
    box-sizing: border-box;
  }
`;

const createDefaultBase = (name: any, allowedProps: any) => (props: any) => {
  const { Container, ...propsToPass } = props;

  return React.createElement(Container, propsToPass);
};

const createStyledComponent = (spec: any, baseToRender: any) => {
  const baseRemoveStyleProps = ({ styleProps, ...props }: any) =>
    React.createElement(baseToRender, props);

  const newComponent = styled(baseRemoveStyleProps)(
    [] as any,
    baseMixin,
    spec.style
  );
  newComponent.displayName = `Styled(${spec.name})`;

  return newComponent;
};

interface IGlobalProps {
  testID?: string;
}

type IBuiltComponent<IProps, IDefaultProps> = React.ComponentType<
  { [key in keyof IProps]?: IProps[key] } & IGlobalProps
>;

interface ComponentSpec<IDefaultProps> {
  name: string;
  base?: any;
  style?: {};
  styleProps?: string[];
  canOverrideContainer?: boolean;
  container?: string;
  props: IDefaultProps;
}

export type DefaultPropsFor<IProps> = {
  [key in keyof IProps]: IProps[key] | null;
};

export function component<IProps>(
  spec: ComponentSpec<DefaultPropsFor<IProps>>
): IBuiltComponent<IProps, DefaultPropsFor<IProps>>;
export function component(spec: any): any {
  const componentNameIsProvided = typeof spec.name === 'string';
  const componentTypeIsNotAString = typeof spec.type !== 'string';
  const propsSpecMustBeObject = typeof spec.props === 'object';
  const baseOrContainerIsProvided = spec.base != null || spec.container != null;
  const overrideContainerThenMustHaveContainer =
    (spec.canOverrideContainer && spec.container != null) ||
    !spec.canOverrideContainer;

  invariant(
    componentNameIsProvided,
    'component(): Spec `name` must be provided as a string'
  );
  invariant(
    componentTypeIsNotAString,
    'component(): Spec `type` must not be a string element'
  );
  invariant(
    propsSpecMustBeObject,
    'component(): Spec `props` must be provided'
  );
  invariant(
    baseOrContainerIsProvided,
    `component(): Component ${spec.name} is missing either a \`base\` or \`container\``
  );
  invariant(
    overrideContainerThenMustHaveContainer,
    `component(): Component ${spec.name} cannot have \`canOverrideContainer\` without default container`
  );

  let allowedProps = [...Object.keys(spec.props), ...ALLOWED_PROPS];
  const defaultProps = spec.props;

  let baseToRender = spec.base || createDefaultBase(spec.name, allowedProps);

  const StyledComponent = createStyledComponent(spec, baseToRender);

  const newComponent: React.StatelessComponent<any> = props => {
    let containerElement: React.StatelessComponent<any> = spec.container;

    const cloneAllowedProps = allowedProps.concat([]);

    if (spec.canOverrideContainer && props.as && spec.container != null) {
      if (React.isValidElement(props.as)) {
        const index = cloneAllowedProps.indexOf('type');
        if (index >= 0) {
          cloneAllowedProps.splice(index, 1);
        }
      }
      containerElement = props.as;
    }

    if (containerElement != null) {
      const _container = containerElement;

      if (React.isValidElement(_container)) {
        containerElement = _props =>
          React.cloneElement(_container, { ..._props, ...spec.containerProps });
        containerElement.displayName = `ContainerElement(${spec.name})`;
      } else if (spec.containerProps != null) {
        containerElement = _props =>
          React.createElement(_container, {
            ..._props,
            ...spec.containerProps
          });
        containerElement.displayName = `ContainerElement(${spec.name})`;
      }
    }

    const propsToPass = {
      ...pick(cloneAllowedProps, props)
    };
    if (props.testId) {
      propsToPass['data-testid'] = props.testId;
    }

    if (spec.container != null) {
      propsToPass.Container = containerElement;
    }

    if (spec.styleProps != null) {
      propsToPass.styleProps = pick(spec.styleProps, props);
    }
    return React.createElement(StyledComponent, {
      ...propsToPass
    });
  };

  newComponent.displayName = spec.name;
  newComponent.defaultProps = defaultProps;

  return newComponent;
}
