import invariant from 'invariant';
import styled, { css } from 'styled-components';
import { ITheme } from 'theme';

interface PropsWithTheme {
  theme: ITheme;
}

const getFontShorthand = (fontSize: string, lineHeight: string | null) =>
  fontSize && lineHeight ? `${fontSize}/${lineHeight}` : `${fontSize}`;

export const getTypography = (name: keyof ITheme['typography']) => (
  props: PropsWithTheme
) => {
  const nameSpaceExists = props.theme.typography.hasOwnProperty(name);
  invariant(
    nameSpaceExists,
    `component(): Typography '${name}' is missing in theme's typography node`
  );

  const {
    fontStyle,
    fontVariant,
    fontWeight,
    fontSize,
    lineHeight,
    fontFamily
  } = props.theme.typography[name];

  return css`
    font: ${fontStyle} ${fontVariant} ${fontWeight}
      ${getFontShorthand(fontSize, lineHeight)} ${fontFamily};
  `;
};
