import React from 'react';
import styled from 'styled-components';
import AlertIcon from './alert-icon';
import Content from './content';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

export default ({ title, children, icon, isSmall }: any) => (
  <Wrapper>
    <AlertIcon styleProps={{ isSmall }}>{icon}</AlertIcon>
    <div style={{ flexShrink: 1 }}>
      <Content title={title}>{children}</Content>
    </div>
  </Wrapper>
);
