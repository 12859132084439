import React from 'react';
import styled from 'styled-components';
import { IRowGroupedProps } from './props';
import { Default } from '../Typography';
import { rowGroupedStyle } from './style';

const RowGrouped = styled.div([rowGroupedStyle]);

export default (props: IRowGroupedProps) => {
  const { title, topBorder, bottomBorder, grouped = true, children } = props;
  return title ? (
    <RowGrouped
      key={title}
      styleProps={{ title, topBorder, bottomBorder, grouped }}
      role="listitem"
    >
      <Default>{`${title}:`}</Default>
      {children}
    </RowGrouped>
  ) : null;
};
