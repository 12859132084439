import React from 'react';
import styled from 'styled-components';
import { IconButton } from '../IconButton';
import { ITableCellExpandedProps, ITableCellWithExpanderProps } from './props';
import { cellWithExpanderStyle } from './styles';

const TableCellWithExpander = styled.span<ITableCellWithExpanderProps>([
  cellWithExpanderStyle
]);

class TableCellExpandedBase extends React.Component<ITableCellExpandedProps> {
  render() {
    const { ariaControls, isOpen, setIsOpen } = this.props;
    const SPACE = 32;
    const RETURN = 13;
    const button = <IconButton name="plus">{''}</IconButton>;
    return (
      <TableCellWithExpander
        aria-expanded={!!isOpen}
        aria-controls={`${ariaControls}`}
        open={!!isOpen}
        tabIndex={0}
        onClick={setIsOpen}
        onKeyPress={(event: React.KeyboardEvent) => {
          if (event.charCode === RETURN || event.charCode === SPACE) {
            event.preventDefault();
            setIsOpen();
          }
        }}
      >
        {button}
      </TableCellWithExpander>
    );
  }
}

export default ({ children, ...rest }: any) => (
  <TableCellExpandedBase {...rest}>{children}</TableCellExpandedBase>
);
