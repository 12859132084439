import React from 'react';
import { InnerMenu } from './components';

export default ({
  children,
  isActive,
  ...rest
}: {
  children: React.ReactNode;
  isActive: boolean;
}) => (
  <InnerMenu
    role="menu"
    styleProps={{
      isActive
    }}
    {...rest}
  >
    {children}
  </InnerMenu>
);
