import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const fuzzySearch = (colors: IColorsType, bounds: IBoundsType) => ({
  backgroundColor: colors.neutrals.lightest,
  backgroundColorFocus: colors.neutrals.lighter,
  borderColor: colors.neutrals.normal,
  borderColorDark: colors.neutrals.darkest,
  borderColorDisabled: colors.neutrals.light,
  borderColorActive: colors.secondary,
  selectedColor: colors.primary
});
