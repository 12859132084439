import React from 'react';
import { Separated } from '@parmenion/library';
import { Gateway } from 'react-gateway';
import { PageHeader, Box, Wrapper } from '../ui';

import Colors from './colors';
import Typography from './typography';
import Spacing from './spacing';
import Semantic from './semantic';
import Icons from './icons';

const contentMap = {
  colors: {
    Component: Colors,
    title: 'Colors',
    description:
      'Colors are applied to components via the theme. There are a finite sets of colors, set using the theme.'
  },
  typography: {
    Component: Typography,
    title: 'Typography',
    description:
      'Typography is applied via the theme, but can the be used in standalone components for use throughout your code base.'
  },
  spacing: {
    Component: Spacing,
    title: 'Spacing',
    description:
      'Spacing can be applied using margin or padding. There are a limitless number of spacing sizes as they are set as an array on the theme.'
  },
  semantic: {
    Component: Semantic,
    title: 'Design System',
    description:
      "When using the component library it's important to think about how you will compose new more complex compoents from the smaller, basic components in the library."
  },
  icons: {
    Component: Icons,
    title: 'Icons',
    description: 'A list of all pre-existing icons.'
  }
};

const Page = props => (
  <Wrapper stacked>
    <Gateway into="header">
      <PageHeader header={props.header} lead={props.lead} />
    </Gateway>
    <Box stacked spaceBetween={2}>
      {props.children}
    </Box>
  </Wrapper>
);

const DefaultContent = {
  description: 'Please create content for this page',
  Component: () => null
};

export default ({ router }) => {
  const content = contentMap[router.params.article] || DefaultContent;
  const { Component, description, title } = content;
  return (
    <Page header={`${title}`}>
      <Separated>
        {description}
        <Component />
      </Separated>
    </Page>
  );
};
