import { component } from 'builder';
import { ITagProps } from './props';
import Base from './base';

export const Tag = component<ITagProps>({
  name: 'Tag',
  base: Base,
  props: {
    children: null,
    type: 'default'
  }
});
