import { InfoButton, InfoInvertedButton, InfoOutlineButton } from '../Buttons/';
import { builder } from './builder';

export const InfoButtonWithIcon = builder('InfoButtonWithIcon', InfoButton);
export const InfoInvertedButtonWithIcon = builder(
  'InfoInvertedButtonWithIcon',
  InfoInvertedButton
);
export const InfoOutlineButtonWithIcon = builder(
  'InfoOutlineButtonWithIcon',
  InfoOutlineButton
);
