import { addIndex, map, range, pipe, reverse } from 'rambda';
import memoize from 'fast-memoize';

const mapWithIndex = addIndex(map);

function pad(n: number) {
  return n < 10 ? '0' + n : `${n}`;
}

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const days = new Array(31);

const formatItem = (id: any, name: any) => ({ id: `${id}`, name });
const formatEach = (formater: any) =>
  mapWithIndex((value: any, index: any) => formater(value, index));

export const monthItems = pipe(
  formatEach((month: any, index: any) => formatItem(pad(index + 1), month))
)(months);

export const dayItems = pipe(
  formatEach((day: any, index: any) =>
    formatItem(pad(index + 1), pad(index + 1))
  )
)(days);

export const yearItems = memoize((startYear: number, endYear: number) => {
  return pipe(formatEach((year: any, index: any) => formatItem(year, year)))(
    reverse(range(startYear, endYear + 1))
  );
});
