import { css } from 'styled-components';
import {
  paddingVertical,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(theme => theme.Breadcrumb);
const breadcrumbWrapperStyler = styleComponent(style);

const wrapperStyle = (t: TThemeVariables) => css`
  margin: -${paddingVertical(1)};
  padding: 0;
`;

const themeStyleCallback = (styles: any) => styles.Breadcrumb.Wrapper;

export const WrapperStyle = breadcrumbWrapperStyler(
  wrapperStyle,
  themeStyleCallback
);
