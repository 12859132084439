import React from 'react';

export class DetectScreenCollisions extends React.Component<{}, {}> {
  element: any;
  collisions: any;

  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getCollisions();
  }

  getCollisions() {
    if (Boolean(this.element)) {
      const {
        x,
        y,
        width,
        height,
        top,
        bottom
      } = this.element.getBoundingClientRect();

      const body = document.body,
        html = document.documentElement;

      const windowHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      const screen = {
        width: window && window.innerWidth,
        height: window && windowHeight
      };

      this.collisions = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };

      this.collisions.top = y < 0 ? Math.abs(y) : 0;
      this.collisions.left = x < 0 ? Math.abs(x) : 0;
      this.collisions.right =
        x + width > screen.width ? x + width - screen.width : 0;
      this.collisions.bottom =
        y + height > screen.height ? y + height - screen.height : 0;

      this.setState({ collisions: this.collisions });
    }
  }

  render() {
    const propsToPass = (props: any) => ({
      ref: (ref: any) => (this.element = ref),
      styleProps: {
        collisions: this.collisions,
        ...props.styleProps
      }
    });

    const childrenWithProps = React.Children.map(
      React.Children.only(this.props.children),
      (child: any) => React.cloneElement(child, propsToPass(child.props))
    );

    return childrenWithProps[0]; // safe as we're using React.Children.only
  }
}
