import { component } from 'builder';
import { InputNumber } from '../InputNumber';
import Base from './base';

type AllowChildren = typeof InputNumber;

export interface IRemoveSpinnerControlsProps {
  children: React.ReactElement<AllowChildren>[];
}

export const RemoveSpinnerControls = component<IRemoveSpinnerControlsProps>({
  name: 'RemoveSpinnerControls',
  base: Base,
  props: {
    children: null
  }
});
