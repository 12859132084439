import { css } from 'styled-components';
import { px, selectComponentThemeVariablesFor, paddingHorizontal } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme);

export default style(
  (t, { breakpoints, alignment }) =>
    css`
      margin: 0 ${alignment === 'left' ? '0' : 'auto'};
      position: relative;
      padding: 0 ${props => paddingHorizontal(2)};
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      ${breakpoints.media.sm`
    padding: 0;
  `} ${breakpoints.media.sm`
    width: ${px(breakpoints.sm.min - 100)};
  `} ${breakpoints.media.md`
    width: ${px(breakpoints.md.min - 100)};
  `} ${breakpoints.media.lg`
    width: ${px(breakpoints.lg.min - 100)};
  `};
    `
);
