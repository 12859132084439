import styled, { css } from 'styled-components';

import {
  getBoxShadow,
  selectComponentThemeVariablesFor,
  px,
  em,
  styleComponent,
  getTypography
} from 'utils';
import { ITheme } from 'theme';

const style = selectComponentThemeVariablesFor(theme => theme.Select);
const indicatorsStyleCallback = (styles: any) => styles.NativeSelect.Indicators;
const inputStyleCallback = (styles: any) => styles.NativeSelect.Input;
const wrapperStyleCallback = (styles: any) => styles.NativeSelect.Wrapper;
const styler = styleComponent(style);

type TComponentTheme = ITheme['components']['Select'];
interface IStyleProps {
  isSmall: boolean;
  isActive: boolean;
}

const indicatorsCss = (t: TComponentTheme, { isSmall }: IStyleProps) => css`
  pointer-events: none;
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: 1 0 auto;
      box-shadow: -1px 0 0 0 ${t.selectControl.borderColor};
      color: ${t.selectControl.borderColor}
      background-color: ${t.selectControl.backgroundColor};
      content: '';
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      padding: 0 8px;


`;

const wrapperCss = (t: TComponentTheme, { isSmall }: IStyleProps) => css`
  position: relative;
  min-width: 200px;
  background-color: ${t.selectControl.backgroundColor};
  border: ${px(t.borderSize)} solid ${t.selectControl.borderColor};
  box-shadow: ${getBoxShadow('input')};
  border-radius: ${px(t.borderRadius)};
`;

const selectCss = (
  t: TComponentTheme,
  { isSmall, isActive }: IStyleProps
) => css`
    outline: none;
  appearance: none;
  user-select: none;
  width: 100%;
  padding: ${
    isSmall
      ? `${px(t.selectControl.small.paddingVertical)} ${px(
          t.selectControl.small.paddingHorizontal
        )}`
      : `${px(t.selectControl.paddingVertical)} ${px(
          t.selectControl.paddingHorizontal
        )}`
  };
  ${getTypography('Default')}
    font-size: ${
      isSmall
        ? em(t.selectControl.small.fontSize)
        : em(t.selectControl.fontSize)
    }

    color: ${t.selectControl.textColor};
    background-color: transparent;
    border: none; 
    cursor: pointer;
    opacity: 1;

    `;

export const indicatorsStyle = styler(indicatorsCss, indicatorsStyleCallback);
export const inputStyle = styler(selectCss, inputStyleCallback);
export default styler(wrapperCss, wrapperStyleCallback);
