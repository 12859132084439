import { component } from 'builder';
import { addEvents } from '../core/events';
import { styleProps } from '../core/TextInput/props';
import { IInputDateTimeLocal, defaultProps } from './props';
import { inputStyle } from '../core/TextInput/style';
import InputDateTimeLocalBase from './base';

const InputWithEvents = addEvents(InputDateTimeLocalBase);

export const InputDateTimeLocal = component<IInputDateTimeLocal>({
  name: 'InputDateTimeLocal',
  base: InputWithEvents,
  container: 'input',
  style: inputStyle,
  props: defaultProps,
  styleProps
});
