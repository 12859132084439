import React from 'react';
import { InputBase } from './base-input';
import { LabelBase } from './base-label';

export const Base = (props: any) => {
  const {
    children,
    name,
    type,
    value,
    isChecked,
    isDisabled,
    labelledby,
    isRequired,
    isInvalid,
    isSmall,
    status,
    Container,
    onChange,
    onInput,
    onClick,
    onFocus,
    onBlur,
    onKeyDown,
    onPaste,
    ...rest
  } = props;

  const ariaProps = {
    'aria-required': false,
    'aria-invalid': false
  };
  if (labelledby !== null) ariaProps['aria-labelledby'] = labelledby;
  if (isRequired !== null) ariaProps['aria-required'] = isRequired;
  if (isInvalid !== null) ariaProps['aria-invalid'] = isInvalid;

  const eventProps = {
    onChange,
    onInput,
    onClick,
    onFocus,
    onBlur,
    onKeyDown
  };

  return (
    <Container {...rest}>
      <LabelBase isSmall={isSmall} isDisabled={isDisabled} status={status}>
        <InputBase
          name={name}
          type={type}
          disabled={isDisabled}
          checked={isChecked}
          value={value}
          {...eventProps}
          {...ariaProps}
        />
        {children}
      </LabelBase>
    </Container>
  );
};
