import React from 'react';
import { css } from 'styled-components';
import { build } from './build';
import { createTheme } from './create-theme';
import { colors } from './core/colors';
import * as icons from './assets/icons';
import { typography } from './core/typography';
import { layout } from './core/layout';
import { bounds } from './core/bounds';
import { containers } from './core/containers';
import { breakpoints, generateBreakpoints } from './core/breakpoints';
import { decoration } from './core/decoration';
import { styles } from './core/styles';
import { validateThemeOverrides } from './validate-overrides';
import { withCustomTheme } from './with-custom-theme';

const defaults = {
  colors,
  decoration,
  layout,
  bounds,
  containers,
  typography,
  icons,
  breakpoints: generateBreakpoints(breakpoints),
  styles
};

export { withCustomTheme };

export const buildTheme = (overrides: any) =>
  validateThemeOverrides(defaults)(overrides) &&
  build(createTheme(defaults, overrides));

const theme = build(createTheme(defaults));

export type ITheme = typeof theme;

export default theme;
