import { component } from 'builder';
import { IContainerProps } from './props';
import Base from './base';

export const Container = component<IContainerProps>({
  name: 'Container',
  base: Base,
  props: {
    children: null,
    alignment: 'center'
  }
});
