import {
  Link as LinkBase,
  LinkDownload as LinkDownloadBase,
  LinkChevronRight as LinkChevronRightBase,
  LinkExternal as LinkExternalBase,
  LinkPdf as LinkPdfBase,
  LinkInfo as LinkInfoBase,
  LinkQuestion as LinkQuestionBase,
  LinkToggleOpen as LinkToggleOpenBase,
  LinkToggleClose as LinkToggleCloseBase,
  LinkResult as LinkResultBase
} from './base';
import { builder } from './builder';

export const Link = builder(LinkBase, 'Link');
export const LinkDownload = builder(LinkDownloadBase, 'LinkDownload');
export const LinkChevronRight = builder(
  LinkChevronRightBase,
  'LinkChevronRight'
);
export const LinkExternal = builder(LinkExternalBase, 'LinkExternal');
export const LinkPdf = builder(LinkPdfBase, 'LinkPdf');
export const LinkInfo = builder(LinkInfoBase, 'LinkInfo');
export const LinkQuestion = builder(LinkQuestionBase, 'LinkQuestion');
export const LinkToggleOpen = builder(LinkToggleOpenBase, 'LinkToggleOpen');
export const LinkToggleClose = builder(LinkToggleCloseBase, 'LinkToggleClose');

export const LinkResult = builder(LinkResultBase, 'LinkResult');
