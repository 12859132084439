import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
import { ILayoutType } from '../core/layout';

export const checkbox = (
  colors: IColorsType,
  layout: ILayoutType,
  bounds: IBoundsType
) => ({
  input: {
    width: layout.paddingVertical[1] * 2,
    height: layout.paddingVertical[1] * 2
  },

  label: {
    offset: layout.paddingHorizontal[1] * 2,
    backgroundColor: colors.lightest
  }
});
