import { css } from 'styled-components';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const style = selectComponentThemeVariablesFor(
  theme => theme.RadioCheckboxButton
);

const radioStyler = styleComponent(style);

const wrapperStyleCb = (styles: any) => styles.RadioCheckboxButton.Wrapper;
const inputWrapperCb = (styles: any) => styles.RadioCheckboxButton.InputWrapper;
const inputCb = (styles: any) => styles.RadioCheckboxButton.Input;

const wrapperCss = (t: TThemeVariables) => css`
  display: inline-block;
  perspective: 1000px;
`;

const inputWrapperCss = (t: TThemeVariables) => css`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${px(t.label.offset)};
`;

const inputCss = (t: TThemeVariables) => css`
  position: absolute;
  top: 50%;
  left: ${px(t.label.offset / 2)};
  width: ${px(t.input.width)};
  height: ${px(t.input.height)};
  margin: 0;
  transform: translate(-50%, -50%);
`;

export const wrapperStyle = radioStyler(wrapperCss, wrapperStyleCb) as any;

export const inputWrapperStyle = radioStyler(
  inputWrapperCss,
  inputWrapperCb
) as any;

export const inputStyle = radioStyler(inputCss, inputCb) as any;
