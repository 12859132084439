import { component } from 'builder';
import { addEvents } from '../core/events';
import { IRadioCheckboxProps } from '../core/RadioCheckboxButton/props';
import { defaultProps } from '../core/RadioCheckboxButton/props';
import { wrapperStyle } from '../core/RadioCheckboxButton/styles';
import { RadioBase } from './base';

const CheckboxWithEvents = addEvents(RadioBase);

export const RadioButton = component<IRadioCheckboxProps>({
  name: 'RadioButton',
  base: CheckboxWithEvents,
  container: 'div',
  style: wrapperStyle,
  props: defaultProps
});
