import React from 'react';
const noop = () => {};

export function ButtonBase({
  children,
  onClick,
  isDisabled,
  Container,
  isSmall,
  isActive,
  noWrap,
  ...rest
}: any) {
  const handler = isDisabled ? noop : onClick;
  return (
    <Container {...rest} onClick={handler} disabled={isDisabled}>
      {children}
    </Container>
  );
}
