import { component } from 'builder';
import { IGaugeChartProps } from './props';
import Base from './base';

export const GaugeChart = component<IGaugeChartProps>({
  name: 'GaugeChart',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    barColor: '',
    progress: '',
    value: '',
    valueText: '',
    remaining: '',
    remainingText: ''
  }
});
