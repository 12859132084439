import { component } from 'builder';
import { IPatternProps } from './props';
import { PatternBase } from './base';

export const Pattern = component<IPatternProps>({
  name: 'Pattern',
  base: PatternBase,
  props: {
    background: null,
    position: null,
    image: null,
    rotate: null,
    stroke: null,
    scale: null,
    viewBox: null
  }
});
