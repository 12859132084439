import { component } from 'builder';
import { ILinkProps } from './props';

export const builder = (base: any, name: string) => {
  return component<ILinkProps>({
    name,
    base,
    container: 'a',
    canOverrideContainer: true,
    props: {
      href: '#',
      children: null,
      direction: 'rtl',
      rel: null,
      target: null,
      onClick: null
    },
    styleProps: ['direction']
  });
};
