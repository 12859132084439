import React from 'react';
import styled from 'styled-components';

interface AllowedProps {
  color: string;
  fontFamily: string;
}

const allowedStyleProps: AllowedProps = {
  color: 'color',
  fontFamily: 'font-family'
};

const withAllowedProps = (Component: React.ComponentType<{}>) => (
  props: any
) => {
  const styleProps: string[] = [];
  Object.keys(allowedStyleProps).forEach(key => {
    if (props[key] != null) {
      styleProps.push(key);
    }
  });
  const StyledComponent = styled(Component)`
    ${styleProps.map(prop => `${allowedStyleProps[prop]}:${props[prop]};`)};
  `;

  const omit = (keys: string[], obj: any) =>
    keys.reduce((accum, key) => {
      const { [key]: _, ...rest } = accum;
      return rest;
    }, obj);

  const propsToPass = omit(styleProps, props);

  return React.cloneElement(<StyledComponent />, propsToPass);
};

export default (props: any) => {
  const { as = 'h1', using, children, ...rest } = props;
  const Component = withAllowedProps(styled(as)`
    ${using && using};
  `);

  return <Component {...props}>{children}</Component>;
};
