import React from 'react';
import styled from 'styled-components';

import {
  Title,
  Lead as NativeHeading,
  Label,
  Separated,
  Content,
  Column
} from '@parmenion/library';
import { Gateway } from 'react-gateway';
import { Link as NativeLink } from 'react-router';
import { Box } from '../../src/components/core';
export { Box } from '../../src/components/core';

const HeadingWrapper = styled.span`
  font-style: italic;
  font-weight: bold;
`;

export const Heading = ({ children }) => (
  <NativeHeading>
    <HeadingWrapper>{children}</HeadingWrapper>
  </NativeHeading>
);

export const PageHeader = props => (
  <Gateway into="header">
    <Box stacked>
      <Title>{props.header}</Title>

      {props.lead != null && <Heading>{props.lead}</Heading>}
    </Box>
  </Gateway>
);

export const DebugBox = props => (
  <div
    style={{
      flex: 1,
      flexBasis: 'auto',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      padding: 16,
      borderColor: 'rgba(0, 0, 0, 0.2)',
      borderWidth: 1,
      borderStyle: 'dashed',
      fontSize: 32,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.2)',
      height: props.height,
      fontFamily: 'monospace'
    }}
  >
    {props.label || 'Content here'}
  </div>
);

export const Wrapper = props => (
  <div style={{ padding: 40, paddingTop: 20, paddingBottom: 20 }}>
    {props.children}
  </div>
);

export const DarkBackground = props => (
  <div
    style={{
      background: 'grey',
      padding: props.padding != null ? props.padding : 20,
      backgroundImage: props.backgroundImage,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}
  >
    {props.children}
  </div>
);
export const LightBackground = props => (
  <div
    style={{
      background: 'white',
      padding: props.padding != null ? props.padding : 20
    }}
  >
    {props.children}
  </div>
);

export const Link = props => (
  <NativeLink
    {...props}
    style={{
      textDecoration: 'none'
    }}
  >
    {props.children}
  </NativeLink>
);
