import { css } from 'styled-components';
import { ITheme } from 'theme';
import { selectComponentThemeVariablesFor, px, styleComponent } from 'utils';

type TThemeVariables = ITheme['components']['DatePickerDropdown'];

const style = selectComponentThemeVariablesFor(
  theme => theme.DatePickerDropdown
);

const wrapperStyler = styleComponent(style);

const wrapperStyle = (t: TThemeVariables) => css`
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.xs.max}px) {
  > * + * { 
    margin-top ${px(t.padding)};
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
    flex-direction: row;
  > * + * { 
    margin-left: ${px(t.padding)};
  }
  }
`;

const themeStyleCallback = (styles: any) => styles.DatePickerDropdown;

export default wrapperStyler(wrapperStyle, themeStyleCallback) as any;
