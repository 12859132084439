import { component } from 'builder';

import { ITabItemProps, ITabsProps } from './props';

import { TabsBase } from './base';
import { TabItemBase } from './TabItem';

import { barStyle, itemStyle } from './style';

export const Tab = component<ITabItemProps>({
  name: 'Tab',
  base: TabItemBase,
  container: 'li',
  style: itemStyle,
  props: {
    children: null,
    index: null,
    activeTab: null,
    label: null,
    onClick: null,
    onKeyPress: null,
    className: null,
    tabIndex: null
  }
});

export const Tabs = component<ITabsProps>({
  name: 'Tabs',
  base: TabsBase,
  container: 'div',
  style: barStyle,
  props: {
    children: null,
    className: null,
    config: null
  }
});
