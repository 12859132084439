import { css } from 'styled-components';
import {
  buildLinearGradient,
  selectComponentThemeVariablesFor,
  styleComponent
} from 'utils';
import { TThemeVariables } from './types';

const style = selectComponentThemeVariablesFor(theme => theme.StepProgressBar);
const styler = styleComponent(style);

const stepCallback = (styles: any) => styles.StepProgressBar.Step;
const bgCallback = (styles: any) => styles.StepProgressBar.Bg;
const wrapperCallback = (styles: any) => styles.StepProgressBar.Wrapper;
const barCallback = (styles: any) => styles.StepProgressBar.Bar;
const listCallback = (styles: any) => styles.StepProgressBar.List;

const itemColumnTemplate = `minmax(0.55rem, 5rem) minmax(2rem, 3rem) minmax(0.25rem, 5rem)`;

const buildTemplateColumns = (steps: any) => {
  return `repeat(${steps -
    1}, ${itemColumnTemplate} minmax(auto, 100%)) ${itemColumnTemplate}`;
};

/**
 * Builds a grid template area definition for the progress bar. The final item does not have a bar.
 * | .     | item  | bar   | bar | bar   | item  | bar   | bar | bar | item  | bar   | ... |
 * | title | title | title | .   | title | title | title | .   |title| title | title | ... |
 *
 * @param {*} steps
 */
const buildTemplateAreas = (steps: any) => {
  if (steps && steps > 0) {
    const rowOne = [];
    const rowTwo = [];
    rowOne.push('". item-1 bar-1 bar-1 bar-1 ');
    rowTwo.push('"title-1 title-1 title-1 . ');
    for (let i = 2; i < steps + 1; i++) {
      rowOne.push(`item-${i} `);
      rowOne.push(`bar-${i} `);
      rowOne.push(`bar-${i} `);
      rowOne.push(`bar-${i} `);
      rowTwo.push(`title-${i} title-${i} title-${i} `);
      rowTwo.push(`. `);
    }
    rowOne.pop();
    rowOne.pop();
    rowTwo.pop();
    rowOne[rowOne.length - 1] = rowOne[rowOne.length - 1].trim();
    rowTwo[rowTwo.length - 1] = rowTwo[rowTwo.length - 1].trim();
    rowOne.push('"');
    rowTwo.push('"');
    return `${rowOne.join('').trim()} ${rowTwo.join('').trim()}`;
  } else {
    return '';
  }
};

const stepCustomCss = (t: TThemeVariables) => css`
  && {
    flex: 0 1 auto;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 25%;
    grid-area: ${({ styleProps }: any) => `item-${styleProps.step}`};

    @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
      p.step-title {
        display: none;
      }
    }
  }

  &&.progress-item::before {
    content: '';
  }

  &&.progress-item {
    margin: unset;
  }

  &&.progress-item > span {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: ${t.lightestBg};
    border: 1px solid ${t.borderLight};
    margin: 0 0.5rem 1rem;
    color: ${t.textColor};
    font-weight: 700;
    span {
      font-size: 1.5rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.md.max}px) {
      margin: 0 0.125rem 0.5rem;
      width: 2rem;
      height: 2rem;
      span {
        font-size: 1rem;
      }
    }
  }
  &&.progress-item.current-step > span,
  &&.progress-item.completed-step > span {
    color: ${t.lightestBg};
    background: ${t.primaryBg};
    p {
      font-weight: 900;
    }
  }
`;

const bgCustomCss = (t: TThemeVariables) => css`
  position: relative;
  background: ${t.lighterBg};
  border-bottom: 1px solid ${t.borderLight};
`;

const wrapperCustomCss = (t: TThemeVariables) => css`
  position: relative;
  margin: 0 auto;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 75rem;
  padding: 1rem 1rem 1.5rem;

  @media (max-width: ${props => props.theme.breakpoints.xs.max}px) {
    padding: 1.5rem 1.5rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
    padding: 2rem 1rem;
  }

  && div,
  && ol {
    width: 100%;
    padding-left: unset;
    display: inline-grid;
    justify-content: center;
    justify-items: center;
    grid-template-columns: ${({ styleProps }: any) =>
      buildTemplateColumns(styleProps.steps)};
    grid-template-areas: ${({ styleProps }: any) =>
      buildTemplateAreas(styleProps.steps)};

    @media (max-width: ${props => props.theme.breakpoints.sm.max}px) {
      .step-title:not(.step-title-current) {
        display: none;
      }
      .step-title-current {
        grid-column: 1 / span
          ${({ styleProps }: any) => styleProps.steps * 4 - 1 || 1};
        max-width: unset;
      }
    }
  }
`;

const barCustomCss = (t: TThemeVariables) => css`
  &&.step-bar {
    list-style: none;
    width: calc(100% - 1rem);
    height: 2px;
    grid-area: ${({ styleProps }: any) => `bar-${styleProps.step}`};
    background: ${({ styleProps }: any) =>
      buildLinearGradient(t.primaryBg, t.borderLight, styleProps.progress)};
    margin: calc(1.5rem - 1px) auto auto;
    @media (max-width: ${props => props.theme.breakpoints.md.max}px) {
      width: 100%;
      margin: calc(1rem - 1px) auto auto;
    }
  }
`;

const listCustomCss = (t: TThemeVariables) => css`
  &&.step-title {
    grid-area: ${({ styleProps }: any) => `title-${styleProps.step}`};
    margin: unset;
    text-align: center;
    max-width: 15rem;
    display: flex;
    justify-content: center;
    &::before {
      display: none;
      content: '';
    }
  }
`;
export const stepStyle = styler(stepCustomCss, stepCallback);
export const bgStyle = styler(bgCustomCss, bgCallback);
export const wrapperStyle = styler(wrapperCustomCss, wrapperCallback);
export const barStyle = styler(barCustomCss, barCallback);
export const listStyle = styler(listCustomCss, listCallback);
