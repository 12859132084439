import invariant from 'invariant';
import { path } from 'rambda';
import { ITheme } from 'theme';

interface PropsWithTheme {
  theme: ITheme;
}

export const getColor = (name: string) => (props: PropsWithTheme) => {
  const output = path(name.split('.'), props.theme.colors);
  const nameSpaceExists = output != null;
  invariant(
    nameSpaceExists,
    `component(): Color '${name}' is missing in theme's colors node`
  );

  return output;
};
