import React from 'react';
import { length } from 'rambda';
import styled from 'styled-components';
import { StatefulDropdown as Dropdown } from '../Dropdown';
import { item, link } from './styles';

const Item = styled.li([item]);

const Trigger = styled.a([link]);
Trigger.displayName = 'Trigger';

export const BarBase = ({ Container, ...rest }: any) => <Container {...rest} />;

export const BarMenuBase = ({ Container, hAlign, ...rest }: any) => {
  return <Container {...rest} role="menubar" />;
};

export const BarMenuItemBase = ({ Container, isActive, ...rest }: any) => {
  return (
    <Item>
      <Container {...rest}>{rest.children}</Container>
    </Item>
  );
};

export const BarMenuItemDropdownBase = (props: any) => (
  <Item>
    <Dropdown
      isHover={props.isHover}
      isRight={props.isRight}
      trigger={<Trigger>{props.children}</Trigger>}
    >
      {props.menu}
    </Dropdown>
  </Item>
);
