import { generateMedia } from 'styled-media-query';
import { breakpoints } from '../../theme/core/breakpoints';

breakpoints.xs.min = 0;

export const media = generateMedia({
  xs: `${breakpoints.xs.min}px`,
  sm: `${breakpoints.sm.min}px`,
  md: `${breakpoints.md.min}px`
});

export const strokeWidths = {
  xs: '12px',
  sm: '12px',
  md: '16px',
  lg: '14px',
  xl: '13px',
  xxl: '13px'
};

export const patternScales = {
  xs: {
    xs: {
      height: '3rem',
      'stroke-width': strokeWidths.xs
    },
    sm: {
      height: '4.5rem',
      'stroke-width': strokeWidths.xs
    },
    md: {
      height: '6rem',
      'stroke-width': strokeWidths.xs
    }
  },
  sm: {
    xs: {
      height: '5rem',
      'stroke-width': strokeWidths.sm
    },
    sm: {
      height: '7.5rem',
      'stroke-width': strokeWidths.sm
    },
    md: {
      height: '10rem',
      'stroke-width': strokeWidths.sm
    }
  },
  md: {
    xs: {
      height: '10rem',
      'stroke-width': strokeWidths.md
    },
    sm: {
      height: '15rem',
      'stroke-width': strokeWidths.md
    },
    md: {
      height: '20rem',
      'stroke-width': strokeWidths.md
    }
  },
  lg: {
    xs: {
      height: '20rem',
      'stroke-width': strokeWidths.lg
    },
    sm: {
      height: '30rem',
      'stroke-width': strokeWidths.lg
    },
    md: {
      height: '40rem',
      'stroke-width': strokeWidths.lg
    }
  },
  xl: {
    xs: {
      height: '25rem',
      'stroke-width': strokeWidths.xl
    },
    sm: {
      height: '40rem',
      'stroke-width': strokeWidths.xl
    },
    md: {
      height: '50rem',
      'stroke-width': strokeWidths.xl
    }
  },
  xxl: {
    xs: {
      height: '30rem',
      'stroke-width': strokeWidths.xxl
    },
    sm: {
      height: '45rem',
      'stroke-width': strokeWidths.xxl
    },
    md: {
      height: '60rem',
      'stroke-width': strokeWidths.xxl
    }
  }
};

export const rotationProps = {
  horizontal: `rotate(0deg)`,
  vertical: `rotate(90deg)`,
  'diagonal-right': `rotate(-45deg)`,
  'diagonal-left': `rotate(45deg)`
};

export const positionProps = (rotate = 'vertical') => (position = 'center') => {
  const positions = {
    top: `
    top: 0%;
    transform: translate(-50%, 0) ${rotate};
  `,
    right: `
    left: 85%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    bottom: `
    top: 100%;
    transform: translate(-50%, -100%) ${rotate};
  `,
    center: `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    left: `
    left: 20%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    'connect-top': `
    top: 0%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    'connect-bottom': `
    top: 100%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    'connect-left': `
    left: 0%;
    transform: translate(-50%, -50%) ${rotate};
  `,
    'connect-right': `
    left: 100%;
    transform: translate(-50%, -50%) ${rotate};
  `
  };
  let res: any[] = [];
  position.split(' ').forEach(pos => {
    res.push(positions[pos]);
  });

  return res;
};

export const getScale = (props: any) => {
  const mediaQuery = [];
  const scale = props.scale || 'md';
  const bpKeys = Object.keys(breakpoints).reduce((keys, key) => {
    keys[key] = key;
    return keys;
  }, {});

  for (let bp in bpKeys) {
    const dimensionSet = patternScales[scale];
    if (dimensionSet[bp]) {
      mediaQuery.push(`
        ${media.greaterThan(bpKeys[bp])`
          height: ${dimensionSet[bp].height};
          stroke-width: ${dimensionSet[bp]['stroke-width']};
        `}
      `);
    }
  }
  const query = mediaQuery
    .toString()
    .split(',')
    .join('');

  return query;
};
