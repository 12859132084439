import {
  pipe,
  map,
  reject,
  isNil,
  flatten,
  uniq,
  split,
  curry,
  reduce,
  filter,
  contains,
  sort,
  prop
} from 'rambda';

export default componentList => {
  const getComponentsByCategory = curry((list, category) =>
    filter(item => category === item.meta.category, list)
  );

  const getComponentsByCategoryWithList = getComponentsByCategory(
    componentList
  );
  const getUniqueCategories = pipe(
    map(item => item.meta.category),
    flatten,
    uniq,
    reject(isNil)
  );

  const buildListOfCategories = reduce((accum, category) => {
    accum[category] = getComponentsByCategoryWithList(category);
    return accum;
  }, {});

  const sortByCategory = item =>
    console.log(item) && item; /* eslint-disable-line */

  return pipe(getUniqueCategories, buildListOfCategories)(componentList);
};
