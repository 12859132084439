import React from 'react';
import styled from 'styled-components';
import { ITableRowExpandableProps, ITableRowExpandProps } from './props';
import { rowExpandStyle } from './styles';

const TableRowExpand = styled.li<ITableRowExpandProps>([rowExpandStyle]);

TableRowExpand.displayName = 'TableRowExpand';

class TableRowExpandableBase extends React.Component<ITableRowExpandableProps> {
  rowRef: React.RefObject<any> = React.createRef<HTMLLIElement>();
  render() {
    const { children, isOpen = false, ...rest } = this.props;
    const toggleHeight = isOpen ? this.rowRef.current.scrollHeight : 0;
    return (
      <TableRowExpand
        className="TableRowExpand"
        isOpen={isOpen}
        ref={this.rowRef}
        expandHeight={toggleHeight}
        {...rest}
      >
        {children}
      </TableRowExpand>
    );
  }
}

export default ({ children, ...rest }: any) => (
  <TableRowExpandableBase {...rest}>{children}</TableRowExpandableBase>
);
