import React from 'react';
import invariant from 'invariant';
import { Box } from '../../core/';
import { Select } from '../Select';
import { Wrapper } from './components/wrapper';
import { dayItems, monthItems, yearItems } from './dates';

type State = {
  tempDay: string;
  tempMonth: string;
  tempYear: string;
};

const EMPTY = '-';
const INVALID_DATE_MESSAGE = `component(): Please supply a date with the following format 'YYYY-MM-DD'`;

const mergeWithCurrentDate = (name: string, value: string, currentDate: any) =>
  Object.assign({}, currentDate, {
    [name]: value
  });

const datePattern = /^\d{4}-([0][1-9]|1[0-2])-([0-2]\d|3[01])$/;
const isValidDate = (date: string) => datePattern.test(date);

export class DatePickerDropdownBase extends React.PureComponent<any, State> {
  yearItems: any[];

  constructor(props: any) {
    super(props);
    this.loadStateFromProps(props);
    this.yearItems = this.calculateYearItems(props);
  }

  componentDidUpdate(nextProps: any) {
    this.yearItems = this.calculateYearItems(nextProps);
  }

  calculateYearItems(props: any) {
    return yearItems(props.startYear, props.endYear);
  }

  getValue() {
    if (this.props.value) {
      return this.parseDateValue(this.props.value);
    }

    return {
      year: this.state.tempYear,
      month: this.state.tempMonth,
      day: this.state.tempDay
    };
  }

  loadStateFromProps(props: any) {
    const tempState = this.parseDateValue(props.value);
    this.state = {
      tempDay: tempState.day,
      tempMonth: tempState.month,
      tempYear: tempState.year
    };
  }

  parseDateValue(value: any) {
    if (value) {
      invariant(isValidDate(value), INVALID_DATE_MESSAGE);

      const [year, month, day] = value.split('-');

      return { year, month, day };
    }

    return {
      year: EMPTY,
      month: EMPTY,
      day: EMPTY
    };
  }

  updateDate(year: string, month: string, day: string) {
    this.setState({
      tempDay: day,
      tempMonth: month,
      tempYear: year
    });

    if (year === EMPTY || month === EMPTY || day === EMPTY) {
      this.props.onChange(null);
    } else {
      this.props.onChange(`${year}-${month}-${day}`);
    }
  }

  getDateHandler = (name: string) => (value: string) => {
    const currentDate = this.getValue();
    const newValue = value !== null ? value : EMPTY;
    const newDate = mergeWithCurrentDate(name, newValue, currentDate);
    this.updateDate(newDate.year, newDate.month, newDate.day);
  };

  onBlur = (name: string) => (value: string): void => {
    this.props.onBlur({
      target: {
        name,
        value
      }
    });
  };

  render() {
    const { year, month, day } = this.getValue();

    const { name, isDisabled, isSmall, isClearable, onFocus } = this.props;

    const sharedProps = {
      onFocus,
      isDisabled,
      isSmall,
      isClearable
    };
    return (
      <Wrapper>
        <Select
          isAutocomplete
          name={`${name}[0]`}
          items={dayItems}
          value={day}
          placeholder={'Day'}
          onChange={this.getDateHandler('day')}
          onBlur={this.onBlur(`${name}[0]`)}
          {...sharedProps}
        />
        <Select
          isAutocomplete
          name={`${name}[1]`}
          items={monthItems}
          value={month}
          placeholder={'Month'}
          onChange={this.getDateHandler('month')}
          onBlur={this.onBlur(`${name}[1]`)}
          {...sharedProps}
        />
        <Select
          isAutocomplete
          name={`${name}[2]`}
          items={this.yearItems}
          value={year}
          placeholder={'Year'}
          onChange={this.getDateHandler('year')}
          onBlur={this.onBlur(`${name}[2]`)}
          {...sharedProps}
        />
      </Wrapper>
    );
  }
}
