import { component } from 'builder';
import { IRadioCheckboxGroupProps } from '../core/RadioCheckboxGroup/props';
import Base from './base';

export const RadioGroup = component<IRadioCheckboxGroupProps>({
  name: 'RadioGroup',
  base: Base,
  props: {
    children: null
  }
});
