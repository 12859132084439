import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const pattern = (colors: IColorsType, bounds: IBoundsType) => ({
  background: {
    backgroundColor: colors.lightest,
    boxShadow: colors.neutrals.darkest
  },
  fill: 'none'
});
