import React from 'react';
import { DropTargetMonitor } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import DragTargetBox from './DragTargetBox';
import withDragDropContext from './withDragDropContext';

export interface ContainerState {
  droppedFiles: any[];
}

class Container extends React.Component<any, ContainerState> {
  constructor(props: {}) {
    super(props);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.state = { droppedFiles: [] };
  }

  public render() {
    const { FILE } = NativeTypes;
    const { droppedFiles } = this.state;

    return <DragTargetBox accepts={[FILE]} onDrop={this.handleFileDrop} />;
  }

  private handleFileDrop(item: any, monitor: DropTargetMonitor) {
    if (monitor) {
      const droppedFiles = monitor.getItem().files;
      this.props.handler(droppedFiles);
    }
  }
}

export default withDragDropContext(Container);
