import { component } from 'builder';
import { IShowHideProps } from './props';
import Base from './base';

export const ShowHide = component<IShowHideProps>({
  name: 'ShowHide',
  base: Base,
  container: 'div',
  canOverrideContainer: true,
  props: {
    showLabel: 'Show',
    hideLabel: 'Hide',
    name: null,
    value: null,
    placeholder: '',
    onChange: () => {},
    onClick: () => {},
    onFocus: () => {},
    onBlur: () => {}
  }
});
