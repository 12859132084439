import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';
import { IContainersType } from '../core/containers';
import { ILayoutType } from '../core/layout';

export const select = (
  colors: IColorsType,
  bounds: IBoundsType,
  containers: IContainersType,
  layout: ILayoutType
) => ({
  outlineColor: colors.auxillary,
  outlineSize: bounds.outline.size,
  borderRadius: bounds.border.radius,
  borderSize: bounds.border.size,
  textColor: colors.neutrals.darker,

  placeholder: {
    lineHeight: 52,
    lineHeightSmall: 40
  },

  status: {
    focus: {
      borderColor: colors.auxillary
    }
  },

  options: {
    backgroundColor: colors.lightest,
    focus: {
      backgroundColor: colors.neutrals.lighter,
      borderColor: colors.neutrals.lighter
    },
    hover: {
      backgroundColor: colors.neutrals.lightest
    },
    active: {
      borderColor: colors.neutrals.lightest,
      backgroundColor: colors.neutrals.lightest
    },
    activeHover: {
      backgroundColor: colors.neutrals.lighter
    }
  },

  selectControl: {
    textColor: colors.neutrals.normal,
    fontSize: 1,
    minHeight: containers.textInput.minHeight.regular,
    backgroundColor: colors.lightest,
    borderColor: colors.neutrals.light,
    paddingVertical: layout.paddingVertical[1],
    paddingHorizontal: layout.paddingHorizontal[1],
    small: {
      fontSize: 0.9,
      minHeight: containers.textInput.minHeight.small,
      paddingVertical: layout.paddingVertical[0],
      paddingHorizontal: layout.paddingHorizontal[0]
    },
    status: {
      open: {
        borderColor: colors.auxillary,
        borderBottomColor: colors.lightest,
        backgroundColor: colors.neutrals.lighter,
        outlineSize: bounds.outline.size
      },
      disabled: {
        backgroundColor: colors.neutrals.lighter
      }
    }
  }
});
