import {
  secondaryStyle,
  secondaryOutlineStyle,
  secondaryInvertedStyle
} from './styles';

import { builder } from './builder';
export const SecondaryButton = builder(secondaryStyle, 'SecondaryButton');
export const SecondaryInvertedButton = builder(
  secondaryInvertedStyle,
  'SecondaryInvertedButton'
);
export const SecondaryOutlineButton = builder(
  secondaryOutlineStyle,
  'SecondaryOutlineButton'
);
