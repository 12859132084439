import styled, { css } from 'styled-components';
import {
  selectComponentThemeVariablesFor,
  paddingHorizontal,
  paddingVertical,
  styleComponent,
  getTypography
} from 'utils';

import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const bodyInnerStyler = styleComponent(withComponentTheme);

const bodyInnerCss = (t: TThemeVariables) => css`
  padding: ${paddingVertical(1)} ${paddingHorizontal(1)};
  ${getTypography('Default')};
`;

const themeStyleCallback = (styles: any) => styles.Accordion.BodyInner;

export const BodyInner = styled.div([
  bodyInnerStyler(bodyInnerCss, themeStyleCallback)
]) as any;
