import React from 'react';
import { PageHeader, Wrapper } from './ui';
import { Markdown } from './components/markdown';

import readme from '../../docs/guide.md';

export default () => (
  <Wrapper>
    <PageHeader
      header="Guide"
      lead="Before you start building any components, we've created a guide to help you make the right design decisions."
    />
    <Markdown source={readme} />
  </Wrapper>
);
