import { IColorsType } from '../core/colors';
import { IBoundsType } from '../core/bounds';

export const tag = (colors: IColorsType, bounds: IBoundsType) => ({
  textColor: colors.lightest,
  backgroundColor: colors.neutrals.normal,
  borderRadius: bounds.border.size * 2,
  success: {
    backgroundColor: colors.success
  },
  error: {
    backgroundColor: colors.error
  },
  warning: {
    backgroundColor: colors.warning
  },
  informational: {
    backgroundColor: colors.informational
  }
});
