import styled, { css } from 'styled-components';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';
import { TThemeVariables } from '../types';

const withComponentTheme = selectComponentThemeVariablesFor(
  theme => theme.Accordion
);

const accordionStyler = styleComponent(withComponentTheme);

const titleCss = (t: TThemeVariables) => css`
  font-weight: bold;
  margin: 0;
`;
const themeStyleCallback = (styles: any) => styles.Accordion.Title;

export const Title = styled.span([
  accordionStyler(titleCss, themeStyleCallback)
]) as any;
