import { css } from 'styled-components';
import { TThemeVariables } from './types';
import { selectComponentThemeVariablesFor, styleComponent } from 'utils';

const style = selectComponentThemeVariablesFor(theme => theme.DetailList);
const styler = styleComponent(style);

const wrapperCallback = (styles: any) => styles.DetailList.Wrapper;
const rowCallback = (styles: any) => styles.DetailList.Row;
const headingCallback = (styles: any) => styles.DetailList.Heading;
const contentCallback = (styles: any) => styles.DetailList.Content;
const rowGroupedCallback = (styles: any) => styles.DetailList.RowGrouped;
const wrapperGroupedCallback = (styles: any) =>
  styles.DetailList.WrapperGrouped;
const actionGroupedCallback = (styles: any) =>
  styles.DetailList.ActionWrapperGrouped;
const headingGroupedCallback = (styles: any) =>
  styles.DetailList.HeadingWrapperGrouped;
const contentGroupedCallback = (styles: any) =>
  styles.DetailList.ContentWrapperGrouped;

const wrapperCustomCss = (t: TThemeVariables) => css`
  & {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-template-areas: 'heading' 'content';
    padding-bottom: 2rem;
    border-bottom: 2px solid ${t.borderColor};
    margin-bottom: 3rem;
    &:last-child {
      border-bottom: none;
    }
    @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
      grid-template-columns: 20rem auto;
      grid-template-rows: auto;
      grid-template-areas: 'heading content';
    }
  }
`;

const headingCustomCss = (t: TThemeVariables) => css`
  grid-area: heading;
  color: ${t.primary};
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    max-width: 20rem;
  }
`;

const contentCustomCss = (t: TThemeVariables) => css`
  grid-area: content;
`;

const rowCustomCss = (t: TThemeVariables) => css`
  & {
    > {
      :last-child {
        cursor: pointer;
        margin-top: 1.5rem;
      }
      * {
        width: 100%;
      }
    }

    border-bottom: ${({ styleProps }: any) =>
      styleProps.grouped ? 'none;' : `1px solid ${t.borderColor};`}    
    &:last-child {
      border-bottom: none;
    }
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    padding: ${({ styleProps }: any) =>
      styleProps.grouped ? '1.5rem 0' : `2rem 0`};

    @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: 1fr;

      > :last-child {
        justify-self: end;
        text-align: ${({ styleProps }: any) =>
          styleProps.grouped ? `left` : `right`};
        margin-top: unset;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
      grid-template-columns: 25% 1fr 1fr;
    }
  }
`;

const wrapperGroupedCustomCss = (t: TThemeVariables) => css`
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'heading' 'content';
  border-top: ${({ styleProps }: any) =>
    styleProps.noTopBorder ? 'none;' : `2px solid ${styleProps.borderColor};`}
  &:last-child {
    border-bottom: ${({ styleProps }: any) =>
      styleProps.noBottomBorder ? 'none;' : `2px solid ${t.borderColor};`}
  }
  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
  grid-template-columns: 20rem auto;
  grid-template-rows: auto;
  grid-template-areas: 'heading content';
}
  grid-template-areas: 'heading' 'content' 
  ${({ styleProps }: any) => (styleProps.action ? "'action'" : "'content'")};
  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
      grid-template-columns: auto 10rem;
      grid-template-areas: 'heading heading' ${({ styleProps }: any) =>
        styleProps.action ? "'content action'" : "'content content'"};
    }
    @media (min-width: ${props => props.theme.breakpoints.md.max}px) {
      grid-template-columns: ${({ styleProps }: any) =>
        styleProps.heading ? '20rem' : ''} auto 10rem;
      grid-template-areas: '${({ styleProps }: any) =>
        styleProps.heading && 'heading'} ${({ styleProps }: any) =>
  styleProps.action ? 'content action' : 'content content'}';
    }
`;

const actionWrapperGroupedCustomCss = (t: TThemeVariables) => css`
  grid-area: action;
  width: 100%;
  padding-top: 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    padding-top: 0;
    max-width: 20rem;
    text-align: right;
  }
`;
const headingWrapperGroupedCustomCss = (t: TThemeVariables) => css`
  grid-area: heading;
  color: ${t.primary};
  width: 100%;

  max-width: 20rem;
  padding-bottom: 2rem;
`;

const contentWrapperGroupedCustomCss = (t: TThemeVariables) => css`
  grid-area: content;
`;

const getBorderCss = (type: string, t: TThemeVariables) => {
  const borderTypes = {
    top: 'top',
    bottom: 'bottom'
  };

  return (
    borderTypes[type] &&
    `& {
    border-${borderTypes[type]}: 2px solid ${t.borderColor};
    padding-${borderTypes[type]}: 2rem;
  }`
  );
};

const addBorders = (t: TThemeVariables, styleProps: any) => {
  const borders = [];

  if (styleProps.topBorder) {
    borders.push(getBorderCss('top', t));
  }

  if (styleProps.bottomBorder) {
    borders.push(getBorderCss('bottom', t));
  }

  return borders;
};

const rowGroupedCustomCss = (t: TThemeVariables) => css`
  display: grid;
  ${({ styleProps }: any) => {
    return addBorders(t, styleProps);
  }} ${({ styleProps }: any) =>
    styleProps.rowType
      ? groupedRowCss[styleProps.rowType]
      : groupedRowCss.default};
`;

const RowDefaultGroupedCss = (t: TThemeVariables) => css`
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);

  @media (min-width: ${props => props.theme.breakpoints.xs.max}px) {
    grid-template-rows: 1fr;
  }

  @media (min-width: ${props => props.theme.breakpoints.sm.max}px) {
    grid-template-columns: 20rem 1fr;
  }
`;

const RowFreetextGroupedCss = (t: TThemeVariables) => css`
  & {
    grid-template-columns: 1fr;
  }
`;

const groupedRowCss = {
  default: RowDefaultGroupedCss,
  freetext: RowFreetextGroupedCss,
  form: RowFreetextGroupedCss
};

export const wrapperStyle = styler(wrapperCustomCss, wrapperCallback);
export const rowStyle = styler(rowCustomCss, rowCallback);
export const headingStyle = styler(headingCustomCss, headingCallback);
export const contentStyle = styler(contentCustomCss, contentCallback);
export const rowGroupedStyle = styler(rowGroupedCustomCss, rowGroupedCallback);
export const wrapperGroupedStyle = styler(
  wrapperGroupedCustomCss,
  wrapperGroupedCallback
);
export const actionWrapperGroupedStyle = styler(
  actionWrapperGroupedCustomCss,
  actionGroupedCallback
);
export const headingWrapperGroupedStyle = styler(
  headingWrapperGroupedCustomCss,
  headingGroupedCallback
);
export const contentWrapperGroupedStyle = styler(
  contentWrapperGroupedCustomCss,
  contentGroupedCallback
);
