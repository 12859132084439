import React from 'react';
import styled from 'styled-components';
import { inputStyle, inputWrapperStyle } from './styles';

const InputWrapper = styled.div([inputWrapperStyle]);
const Input = styled.input([inputStyle]);

export const InputBase = (props: any) => {
  const wrapperProps = {
    name: props.name,
    value: props.value,
    checked: props.checked,
    disabled: props.disabled
  };
  return (
    <>
      <Input {...props} />
      <InputWrapper className="InputWrapper" {...wrapperProps} />
    </>
  );
};
