import React from 'react';
import styled from 'styled-components';
import { TableCellWithExpander } from '.';
import { IWithTitlesProps } from './props';
import { cellWithExpanderWrapperStyle, tableContainerStyle } from './styles';

const TableWrapper = styled.ol([tableContainerStyle]);
const TableCellWithExpanderWrapper = styled.span([
  cellWithExpanderWrapperStyle
]);
class WithTitles extends React.Component<IWithTitlesProps> {
  state = {};
  render() {
    const { children, titles, isStriped, ...rest }: any = this.props;
    if (titles.length === 0) return children;
    return React.Children.map(
      children,
      (child: React.ReactElement<any>, rowIndex: number) => {
        const children = React.Children.map(
          child.props.children,
          (child: React.ReactElement<any>, index: number) => {
            const propsToPass = titles[index]
              ? {
                  'data-name': titles[index]
                }
              : {};
            return React.cloneElement(child, {
              ...propsToPass
            });
          }
        );
        if (child.props.isExpandable) {
          const lastChild = children[children.length - 1];
          const expander = React.cloneElement(<TableCellWithExpander />, {
            setIsOpen: () =>
              this.setState({
                ...this.state,
                [rowIndex]: !this.state[rowIndex]
              }),
            isOpen: this.state[rowIndex],
            ariaControls: `row${rowIndex + 1}`
          });
          children[children.length - 1] = (
            <TableCellWithExpanderWrapper>
              {lastChild}
              {expander}
            </TableCellWithExpanderWrapper>
          );
        }

        return React.cloneElement(child, {
          children,
          isOpen: this.state[rowIndex - 1],
          id: `row${rowIndex}`,
          isStriped: isStriped && !(rowIndex % 2 === 0),
          ...rest
        });
      }
    );
  }
}

const getTitles = (firstRow: any) => {
  let titles: any[] = [];
  const firstChild = Array.isArray(firstRow.props.children)
    ? firstRow.props.children[0]
    : firstRow.props.children;

  if (firstChild.type.displayName === 'TableHeader') {
    if (firstRow.props.children.forEach) {
      firstRow.props.children.forEach((child: any, index: any) => {
        titles[index] = child.props.children;
      });
    } else {
      titles[0] = firstRow.props.children.props.children;
    }
  }
  return titles;
};

export default ({ children, ...rest }: any) => {
  const [firstRow, ...otherRows] = children;
  const titles = getTitles(firstRow);
  const firstRowWithProps = React.cloneElement(firstRow, { ...rest });
  return (
    <TableWrapper {...rest}>
      {firstRowWithProps}
      <WithTitles titles={titles} {...rest}>
        {otherRows}
      </WithTitles>
    </TableWrapper>
  );
};
